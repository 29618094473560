import {Col, ColProps} from "antd";
import React from "react";
import {Metric as MetricContent} from "@recoinsights/types";
import classNames from "classnames";

interface Props extends ColProps {
    metric: MetricContent,
}

function Metric({children, className, metric, ...props}: Props) {
    return (
        <Col className={classNames("p-2 text-center", className)} {...props}>
            <h4>{metric.name}</h4>
            <p style={{ color: "gray" }}>{metric.description}</p>

            {children}
        </Col>
    )
}

export default Metric;
