import React from "react";
import {ResultSet} from "@recoinsights/types";
import {Col, Row, Space, Spin} from "antd";
import {useRouter} from "../../helpers/location";
import {useMetrics, useResults} from "../../helpers/metrics";
import {useFunds} from "../../helpers/portfolio";
import PortfolioMultiYearResults from "../results/PortfolioMultiYearResults";
import AlertUnauthorized from "../../components/results/AlertUnauthorized";
import AlertNoResults from "../../components/results/AlertNoResults";
import SelectYear from "../../components/results/SelectYear";
import PortfolioSingleYearResults from "../results/PortfolioSingleYearResults";
import SelectFund from "../../components/results/SelectFund";
import FundCompanyInterface from "../../../../shared/types/FundCompany";
import ResultsDownloadButton from "../results/ResultsDownloadButton";

interface RouteParams {
    groupId: string,
}

interface SearchParams {
    year?: string,
    fundId?: string,
}

export default function PortfolioResultDashboard() {
    const { routeParams, searchParams } = useRouter<RouteParams, SearchParams>();
    const groupId = routeParams.groupId ? Number(routeParams.groupId) : undefined;
    const year = searchParams.year ? Number(searchParams.year) : undefined;
    const fundId = searchParams.fundId ? Number(searchParams.fundId) : undefined;

    const { companies, funds, fundCompanies, isLoadingFunds } = useFunds();
    const { metrics, isLoadingMetrics } = useMetrics();
    const { results, isLoadingResults, isAuthorised } = useResults({groupId});
    const years = results.map((result: ResultSet) => result.year).filter((value, index, list) => list.indexOf(value) === index);

    const selectedFundCompanies = fundId ? fundCompanies.filter((fundCompany: FundCompanyInterface) => {
        return fundCompany.fundId === fundId
    }) : fundCompanies;

    const filteredResults = results.filter((result) => {
        return selectedFundCompanies.some((fundCompany) => {
            return fundCompany?.company?.uuid === result.company;
        })
    });

    return (
        <div className="page-wrapper company-result-wrapper" style={{boxShadow: "none"}}>
            <Row className="justify-content-between">
                <Col>
                    <h1 className="page-title">Portfolio Results</h1>
                </Col>
                <Col>
                    <Space size={12}>
                        <SelectFund groupId={groupId} fundId={fundId} funds={funds} />
                        <SelectYear year={year} years={years} />
                        <ResultsDownloadButton groupId={groupId} />
                    </Space>
                </Col>
            </Row>
            {isLoadingResults || isLoadingMetrics || isLoadingFunds ? <div data-testid="loading-spinner" className="d-flex align-items-center justify-content-center py-5"><Spin /></div> : (
                !isAuthorised ? <AlertUnauthorized /> : (
                    !results.length ? <AlertNoResults /> : (
                        !year ? <PortfolioMultiYearResults metrics={metrics} results={filteredResults} companies={companies} years={years} /> : (
                            <PortfolioSingleYearResults metrics={metrics} results={filteredResults} year={year} companies={companies} />
                        )
                    )
                )
            )}
        </div>
    );
}
