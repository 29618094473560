import React, {useEffect, useState} from "react";
import {Button, notification, Pagination, Space, Table, Typography, Spin} from "antd";
import {useHistory} from "react-router-dom";
import AdminListLayout from "@recoinsights/shared/components/layout/AdminListLayout/AdminListLayout";
import {ConfirmationModal} from "components";
import API_SERVICE from "@recoinsights/client/src/services/api-service";
import moment from "moment";
import {getUser} from "@recoinsights/client/src/services/local-storage";
import {
    DATA,
} from "@recoinsights/shared/constants/RouteConstants";
import AppConstants from '@recoinsights/shared/constants/Constants';
import {downloadFile} from "../../services/util";

const {Text} = Typography;

export default function SurveysListPage(props: any) {
    const [groups, setGroups] = useState([] as any[]);
    const [surveyYears, setSurveyYears] = useState([] as any[]);
    const [search] = useState("" as string);
    const [fetching, setFetching] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        perpage: "10",
    } as any);
    const [total, setTotal] = useState(10);
    const [filters, setFilters] = useState({} as any);
    const [confirmationModal, setConfirmationModal] = useState({} as any);
    const history = useHistory();
    const [surveys, setSurveys] = useState([] as any[]);
    const roleId = getUser()?.user?.role?.id;
    const [fetchingFile, setFetchingFile] = useState(false);

    const columns = [
        {
            title: "Year",
            key: "year",
            ...(
                (roleId === AppConstants.roles.FUND_MANAGER_ID) ?
                    {
                        filters: surveyYears.map((year: any) => {
                            return {text: year, value: year};
                        })
                    }
                    : {}
            ),
            render: (a: any) => (
                <div>
                    <Text>{a.year}</Text>
                </div>
            )
        },
        {
            title: 'Company',
            key: "name",
            width: "100%",
            render: (a: any) => <Text>{a?.company?.name}</Text>,
        },
        {
            title: "Group",
            dataIndex: "pef",
            filters: groups.map((group: any) => {
                return {text: group.name, value: group.id};
            }),
            render: (a: any) => <Text>{a && a.name ? a.name : "-"}</Text>,
        },
        {
            title: "Freeze Date",
            dataIndex: "freezeDate",
            width: 180,
            render: (a: any) => (
                <Text>{a ? moment(a).format("DD/MM/YYYY") : "-"}</Text>
            ),
        },
        {
            title: "",
            key: "action",
            width: 180,
            render: (value: any) => {
                let editMode = false;
                if (roleId === AppConstants.roles.COMPANY_ANALYST_ID) {
                    const today = new Date();
                    const freezeDate = new Date(value.freezeDate);
                    if (today <= freezeDate) {
                        editMode = true;
                    }
                }
                return (
                    <Space>
                        <Button
                            onClick={() => {downloadSurveyResponseCSV(value.id, {})}}
                            type="ghost"
                        >
                            <i className={"bi bi-download mr-1"}></i>
                            {"Download"}
                        </Button>
                        <Button
                            type={editMode ? "primary" : "default"}
                            style={editMode ? {minWidth: '146px'} : {backgroundColor: '#E4E4E4', minWidth: '146px'}}
                            onClick={() => {
                                history.push(`${DATA}/${value.id}#${editMode ? 'edit' : 'read'}`);
                            }}>
                            {
                                editMode ?
                                    "Complete Survey" : "View Survey"
                            }
                        </Button>
                    </Space>
                );
            }
        },
    ];

    const getGroups = async () => {
        try {
            const {
                data: {data},
            } = await API_SERVICE.getGroups();
            setGroups(data.items);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };

    const getSurveyYears = async () => {
        try {
            const {
                data: {data},
            } = await API_SERVICE.getSurveyYears();
            setSurveyYears(data);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };

    const handleTableChange = (
        pagination: any,
        filterValues: any
    ) => {
        setFilters({...filters, ...filterValues, page: 1});
    };

    const refresh = async (page?: number, perPage?: number) => {
        const reqParams = {...pagination, ...filters};
        if (search) {
            reqParams["q"] = search;
            reqParams["page"] = 1;
        }
        if (page) {
            reqParams["page"] = page;
        }
        if (perPage) {
            reqParams["perpage"] = perPage;
        }
        if (roleId === AppConstants.roles.FUND_MANAGER_ID) {
            reqParams['companyName'] = 'filter';
        }

        setFetching(true);
        try {
            const {
                data: {data},
            } = await API_SERVICE.getDataCollection(reqParams);
            setSurveys(data.items);
            setTotal(data.total);
            setPagination({page: data.page, perpage: data.perpage});
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        } finally {
            setFetching(false);
        }
    };

    const downloadSurveyResponseCSV = async (id: number, reqParams: Record<string, any>) => {
        try {
            setFetchingFile(true);

            await API_SERVICE.downloadSurveyResponsesZip(id, reqParams);
        } catch (e: any) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        } finally {
            setFetchingFile(false);
        }
    }

    useEffect(() => {
        refresh();
        getGroups();
        getSurveyYears();
    }, [props.location, search, filters]);

    return (
        <div className={"company-wrapper page-wrapper"}>
            {/*@ts-ignore*/}
            <AdminListLayout
                title="Surveys"
                pagination={
                    <Pagination
                        current={pagination.page}
                        pageSize={pagination.perpage}
                        pageSizeOptions={[10, 20, 50]}
                        showSizeChanger
                        showQuickJumper
                        total={total}
                        onChange={(page, size) => {
                            setPagination({page: page, perpage: size});
                            refresh(page, size);
                        }}
                    />
                }
            >
                {
                    fetchingFile &&
                    <div className="d-flex align-items-center justify-content-center" style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(0,0,0,0.3)",
                        zIndex: 999
                    }}><Spin/></div>
                }
                <Table
                    className="table-responsive"
                    loading={fetching}
                    pagination={false}
                    dataSource={surveys}
                    columns={columns.filter(value => JSON.stringify(value) !== '{}')}
                    onChange={handleTableChange}
                />

                <ConfirmationModal
                    {...confirmationModal}
                    callback={(a: boolean) => {
                        setConfirmationModal({});
                        if (a) {
                            refresh();
                        }
                    }}
                />
            </AdminListLayout>
        </div>
    );
}
