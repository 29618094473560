import {Metric} from "@recoinsights/metrics";
import {CaretDownFilled, CaretUpFilled} from "@ant-design/icons";
import {Typography} from "antd";
import {TextProps} from "antd/lib/typography/Text";

interface Props extends TextProps {
    metricResultA: Metric,
    metricResultB: Metric,
}

function ScoreComparisonIndicator({metricResultA, metricResultB, ...props}: Props) {
    const comparison = metricResultA.compare(metricResultB);

    if (!comparison.isPositive && !comparison.isNegative) {
        return null;
    }

    return (
        <Typography.Text type={comparison.isPositive ? "success" : comparison.isNegative ? "danger" : undefined} {...props}>
            {comparison.isIncreased && <CaretUpFilled />}
            {comparison.isDecreased && <CaretDownFilled />}
        </Typography.Text>
    )
}

export default ScoreComparisonIndicator;
