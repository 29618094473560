import {Select} from "antd";
import React from "react";
import {useRouter} from "../../helpers/location";

interface Props {
    year?: number;
    years: number[];
}

function SelectYear({ year, years }: Props) {
    const { navigate, route } = useRouter();

    function handleYearChange(value: number|null) {
        navigate(route({
            searchParams: {
                year: value,
            }
        }));
    }

    return (
        <Select value={year}
                showSearch={true}
                filterOption={true}
                placeholder={'Select Year'}
                style={{minWidth: 150}}
                onChange={(value) => {
                    handleYearChange(value)
                }}
                data-testid="years-dropdown"
        >
            {years.length > 1 && <Select.Option data-testid="all-option" value={null}>All years</Select.Option>}
            {
                years.map((year: any) => {
                    return <Select.Option
                        value={year} key={year}>{year}</Select.Option>;
                })
            }
        </Select>
    );
}

export default SelectYear;
