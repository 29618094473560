import React from 'react';
import {Col, Row, Space, Spin} from "antd";
import {ResultSet} from "@recoinsights/types";
import CompanySingleYearResults from "../results/CompanySingleYearResults";
import CompanyMultiYearResults from "../results/CompanyMultiYearResults";
import AlertNoResults from "../results/AlertNoResults";
import AlertUnauthorized from "../results/AlertUnauthorized";
import SelectYear from "../results/SelectYear";
import {useRouter} from "../../helpers/location";
import {useMetrics, useResults} from "../../helpers/metrics";
import ResultsDownloadButton from "../results/ResultsDownloadButton";

interface RouteParams {
    companyId: string,
}

interface SearchParams {
    year?: string,
}

function CompanyResultDashboard() {
    const { routeParams, searchParams } = useRouter<RouteParams, SearchParams>();
    const companyId = routeParams.companyId ? Number(routeParams.companyId) : undefined;
    const year = searchParams.year ? Number(searchParams.year) : undefined;

    const { results, isLoadingResults, isAuthorised } = useResults({companyId});
    const { metrics, isLoadingMetrics } = useMetrics();
    const years = results.map((result: ResultSet) => result.year);

    return (
        <>
            <div className="page-wrapper company-result-wrapper" style={{boxShadow: "none"}}>
                <Row className="justify-content-between">
                    <Col>
                        <h1 className="page-title">Company Results</h1>
                    </Col>
                    <Col>
                        <Space size={12}>
                            <SelectYear year={year} years={years} />
                            <ResultsDownloadButton companyId={companyId} />
                        </Space>
                    </Col>
                </Row>
                {(isLoadingResults || isLoadingMetrics) ? <div data-testid="loading-spinner" className="d-flex align-items-center justify-content-center py-5"><Spin /></div> : (
                    !isAuthorised ? <AlertUnauthorized /> : (
                        !results.length ? <AlertNoResults /> : (
                            year ? <CompanySingleYearResults results={results} year={year} metrics={metrics} /> : (
                                <CompanyMultiYearResults results={results} metrics={metrics} />
                            )
                        )
                    )
                )}
            </div>
        </>
    );
}

export default CompanyResultDashboard;
