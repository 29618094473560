import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import 'antd/dist/antd.css';
import '../index.scss';
import './index.scss';
import {Button, Input, InputNumber, notification, Popconfirm, Select, Space, Tooltip, Typography,} from "antd";
import {InfoCircleOutlined, MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import API_SERVICE from "../../../../services/api-service";
import {getScopeTableData} from "@recoinsights/shared/services/Utility";
import QuestionComments from "../QuestionComments";

const {Option} = Select;
const {Text} = Typography;
export default function ScopeTwoTable1GHGQuestion({questionItem, j,roleId, surveyId, activeQuestionComments, setActiveQuestionComments, surveyYear}: any) {
    const location = useLocation();
    const [countries, setCountries] = useState([] as any []);

    const getScopeCountries = async () => {
        const params = {
            year: surveyYear,
            scope: 'scope2',
            table_mapper: 'table1',
            get: 'location'
        }
        try {
            const {data: {data}} = await API_SERVICE.getGHGQuestionTableData(params);
            setCountries(data);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };

    const [rows, setRows] = useState(getScopeTableData(questionItem.id) as any[]);
    const _validateLastRow = () => {
        // TODO validate Last Row else throw error
        return true;
    }
    useEffect(() => {
        getScopeCountries();
    }, []);

    return (
        <div className="question-card" key={j}>
            <h4 className="question">
                {questionItem.question}
                {!questionItem.description && questionItem.toolTipText &&
                <Tooltip className="pl-1 cursor-pointer text-primary"
                         title={questionItem.toolTipText}><InfoCircleOutlined/></Tooltip>}
            </h4>
            {questionItem.description && <small>{questionItem.description}</small>}
            {questionItem.description && questionItem.toolTipText &&
            <Tooltip className="pl-1 cursor-pointer text-primary" title={questionItem.toolTipText}><InfoCircleOutlined/></Tooltip>}
            <div style={{width: '100%', overflowY: 'auto'}}>
                {rows && rows.length > 0 && <table className="custom-scope-data-table">
                    <thead>
                        <tr>
                            <td style={{minWidth: 50}} />
                            <td style={{minWidth: 50}}>S.No</td>
                            <td>Facility Name</td>
                            <td>Facility Type</td>
                            <td>Country</td>
                            <td>Region</td>
                            <td>Category</td>
                            <td>Amount</td>
                            <td>Unit</td>
                            <td>GHG Factor</td>
                            <td>KgCO2e GHG Emission</td>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        rows.map((row, index: number) => {
                            return <TablesRow
                                year={surveyYear}
                                value={row}
                                countries={countries}
                                roleId={roleId}
                                handleDelete={
                                    () => {
                                        const temp = [...rows]
                                        temp.splice(index, 1);
                                        const scopeTableData = localStorage.getItem('scopeTableData') ? JSON.parse(localStorage.getItem('scopeTableData') as string) : {};
                                        scopeTableData[questionItem.id] = temp;
                                        localStorage.setItem('scopeTableData', JSON.stringify(scopeTableData));
                                        setRows(temp)
                                    }
                                } index={index} disabled={index < (rows.length - 1)}
                                onChange={
                                    (e: any) => {
                                        const temp = [...rows];
                                        temp[index] = e;
                                        setRows(temp)
                                        const scopeTableData = localStorage.getItem('scopeTableData') ? JSON.parse(localStorage.getItem('scopeTableData') as string) : {};
                                        scopeTableData[questionItem.id] = temp;
                                        localStorage.setItem('scopeTableData', JSON.stringify(scopeTableData));
                                    }
                                }/>
                        })
                    }
                    </tbody>
                </table>}
            </div>
            <Button type={'primary'} htmlType='submit'
            disabled={(roleId === 4 && (location.hash === '#edit')) ? false : true}
            className='theme-button primary mt-3' onClick={async () => {
                const isLasRowValid = _validateLastRow();
                if (isLasRowValid) {
                    setRows([...rows, {}]);
                } else {
                    notification.error({message: 'Last row is invalid'});
                }
            }}
                    icon={<PlusOutlined/>}>
                Add Row
            </Button>
            <QuestionComments surveyId={surveyId} questionId={questionItem.id}
                activeQuestionComments={activeQuestionComments} setActiveQuestionComments={setActiveQuestionComments} />
        </div>

    );
}
const TablesRow = ({index, year, countries, onChange, handleDelete, value,roleId}: any) => {
    const location = useLocation();
    const [categories, setCategories] = useState([]);
    const [regions, setRegions] = useState([]);
    const [factorDetails, setGHGFactorDetails] = useState(null as any);
    const [GHGFactorData, setGHGFactorData] = useState({} as any);
    const [units, setUnits] = useState([]);

    const getGHGQuestionTableData = async (year: any, get: any, optional: any = {
        location: '',
        category: '',
        fuelType: '',
        unit: ''
    }) => {
        const params: Record<string, string | number> = {
            year: year,
            scope: 'scope2',
            table_mapper: 'table1',
            get: get
        }

        if (optional.location) {
            params['location'] = optional.location;
        }

        if (optional.region) {
            params['region'] = optional.region;
        }

        if (optional.category) {
            params['customer_category'] = optional.category;
        }

        if (optional.fuelType) {
            params['entity'] = optional.fuelType;
        }

        if (optional.unit) {
            params['input_unit'] = optional.unit;
        }
        try {
            const {data: {data}} = await API_SERVICE.getGHGQuestionTableData(params);
            if(get === 'region'){
                setRegions(data);
            }else if(get === 'customer_category'){
                setCategories(data);
            }else if (get === 'input_unit') {
                setUnits(data);
            } else if (get === 'ghg_factor_kgco2e') {
                setGHGFactorDetails(data[0].ghgFactorKgco2e);
                setGHGFactorData(data[0]);
            }
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };

    useEffect(() => {
        if (factorDetails || factorDetails === 0) {
            handleChange(factorDetails, 'ghgFactor')
        }
    }, [factorDetails, GHGFactorData]);

    const handleChange = (_value: any, key: any, resetKeys?: string[]) => {
        const _temp = {...value, [key]: _value, ...GHGFactorData};
        if (!isNaN(_temp.ghgFactor * _temp.amount)) {
            _temp['temp_energyFactoryKwh'] = _temp.ghgFactor * _temp.amount
        }
        if (resetKeys) {
            resetKeys.forEach((k) => {
                delete _temp[k]
            })
        }
        onChange(_temp);

        if (key === 'countryId') {
            setRegions([]);
            setCategories([]);
            setUnits([]);
            getGHGQuestionTableData(year, 'region', {location: _value});
        }

        if (key === 'region') {
            setCategories([]);
            setUnits([]);
            getGHGQuestionTableData(year, 'customer_category', {
                location: value.countryId,
                region: _value
            });
        }

        if (key === 'category') {
            setUnits([]);
            getGHGQuestionTableData(year, 'input_unit', {
                location: value.countryId,
                region: value.region,
                category: _value
            });
        }

        if (key === 'unit') {
            getGHGQuestionTableData(year, 'ghg_factor_kgco2e', {
                location: value.countryId,
                region: value.region,
                category: value.category,
                fuelType: value.fuel,
                unit: _value
            });
        }
    }
    return <tr>
        <td>
            {
                <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete()}>
                    <div className='text-primary cursor-pointer text-center'><MinusCircleOutlined/></div>
                </Popconfirm>
            }
        </td>
        <td>
            <Text> {index + 1}</Text>
        </td>
        <td>
            <Space size="middle">
                <div style={{width: 150}}><Input disabled={(!(roleId === 4 && (location.hash === '#edit')))} //disabled={disabled}
                                                value={value.facilityName}
                                                 placeholder="enter facility name"
                                                 onChange={(e) => {
                                                     handleChange(e.target.value, 'facilityName')
                                                 }}/></div>
            </Space>
        </td>
        <td>
            <Space size="middle">
                <div style={{width: 100}}><Input disabled={(!(roleId === 4 && (location.hash === '#edit')))} //disabled={disabled}
                                                value={value.facilityType} placeholder="enter type"
                                                 onChange={(e) => {
                                                     handleChange(e.target.value, 'facilityType')
                                                 }}/></div>
            </Space>
        </td>

        <td>
            <Space size="middle">
                <div><Select showSearch={true} disabled={(!(roleId === 4 && (location.hash === '#edit')))} //disabled={disabled}
                            value={value.countryId} placeholder="select"
                             style={{width: 100}}
                             onChange={(e) => {
                                 handleChange(e, 'countryId',['region', 'category', 'unit', 'ghgFactor'])
                             }}>
                    {
                        countries.map((item: any, index: number) => {
                            return <Option value={item} key={index}>{item}</Option>
                        })
                    }
                </Select></div>
            </Space>
        </td>
        <td>
            <Space size="middle">
                <div><Select showSearch={true} disabled={(!(roleId === 4 && (location.hash === '#edit')))} //disabled={disabled}
                            value={value.region} placeholder="select"
                             style={{width: 150}}
                             onChange={(e) => {
                                 handleChange(e, 'region',['category', 'unit', 'ghgFactor'])
                             }}
                             onFocus={() => {
                                 regions.length < 1 && value.countryId && getGHGQuestionTableData(year, 'region', {location: value.countryId});
                             }}>
                    {
                        regions.map((item: any, index: number) => {
                            return <Option value={item} key={index}>{item}</Option>
                        })
                    }
                </Select></div>
            </Space>
        </td>
        <td>
            <Space size="middle">
                <div><Select showSearch={true} placeholder="select"
               disabled={(!(roleId === 4 && (location.hash === '#edit')))}
                             value={value.category} style={{width: 150}}
                             onChange={(e) => {
                                 handleChange(e, 'category', ['unit', 'ghgFactor'])
                             }}
                             onFocus={() => {
                                 categories.length < 1 && value.countryId && value.region && getGHGQuestionTableData(year, 'customer_category', {
                                     location: value.countryId,
                                     region: value.region
                                 });
                             }}>
                    {
                        categories && categories.map((item: any, index: number) => {
                            return <Option value={item} key={index}>{item}</Option>
                        })
                    }
                </Select></div>
            </Space>
        </td>
        <td>
            <Space size="middle">
                <div style={{width: 100}}><InputNumber disabled={(!(roleId === 4 && (location.hash === '#edit')))} //disabled={disabled}
                                                value={value.amount} placeholder="enter amt."
                                                 onChange={(e) => {
                                                     handleChange(e, 'amount',)
                                                 }}/></div>
            </Space>
        </td>
        <td>
            <Space size="middle">
                <div><Select showSearch={true} placeholder="select"
                             disabled={(!(roleId === 4 && (location.hash === '#edit')))}
                             value={value.unit} style={{width: 100}}
                             onChange={(e) => {
                                 handleChange(e, 'unit',)
                             }}
                             onFocus={() => {
                                 units.length < 1 && value.countryId && value.region && value.category && getGHGQuestionTableData(year, 'input_unit', {
                                     location: value.countryId,
                                     region: value.region,
                                     category: value.category
                                 });
                             }}>
                    {
                        units && units.map((item: any, index: number) => {
                            return <Option value={item} key={index}>{item}</Option>
                        })
                    }
                </Select></div>
            </Space>
        </td>
        <td>
            <Space size="middle">
                <div style={{width: 100}}><InputNumber
                    value={value.ghgFactor} placeholder="--" disabled={true}/></div>
            </Space>
        </td>

        <td>
            <Space size="middle">
                <div style={{width: 150}}><InputNumber
                    value={
                        value.temp_energyFactoryKwh
                            ? value.temp_energyFactoryKwh
                            : (
                                (!isNaN(value.ghgFactor * value.amount))
                                    ? value.ghgFactor * value.amount
                                    : '--'
                            )
                    }
                    placeholder="--" disabled={true}/></div>
            </Space>
        </td>
    </tr>
}
