import React, { useState, useEffect } from "react";
import { Popover, Select, Space, Button } from "antd";
import { FilePptFilled } from "@ant-design/icons";


const ExportPPT = (props) => {
  const [open, setOpen] = useState(false);
  const [size, setSize] = useState(3);
  const [options, setOptions] = useState([]);
  const { Option } = Select;

  const content = (
    <Space direction="vertical">
      <div>Select rows per slide :</div>
      <Space>
        <Select style={{width: 64}} onChange={setSize} value={size}>
          {
            options.map((k,i)=>(
              <Option key={i} value={k}>{k}</Option>
            ))
          }
        </Select>
        <Button
          type="primary"
          block={true}
          onClick={()=>{
            if (props.onExport) {
              props.onExport(
                size,
                {
                  border: {pt:1},
                  fontSize: 8
                }
              );
            }
          }}>
          Export
        </Button>
      </Space>
    </Space>
  )

  useEffect(()=>{
    const arr = [];
    [...(Array(props.total).keys())].map((i)=>{
      if (props.total%3 !== 0 || (i+1)%3 === 0) {
        arr.push(i+1);
      }
    });
    setOptions(arr);
  },[props.total])
  if (!props.total) {
    return null;
  }
  return (
    <Popover
      open={open}
      onOpenChange={setOpen}
      placement="leftTop"
      title={'Export as PPT'}
      content={content}
      trigger="click">
      <FilePptFilled
        style={{
          fontSize: 36,
          color: '#C13B1B'
        }} />
    </Popover>
  )
}

export default ExportPPT;
