import {notification} from "antd";
import {ResultSet} from "@recoinsights/types";
import API_SERVICE from "../services/api-service";
import GroupInterface from "../../../shared/types/Group";
import FundInterface from "../../../shared/types/Fund";
import CompanyInterface from "../../../shared/types/Company";
import FundCompanyInterface from "../../../shared/types/FundCompany";
import {useQuery} from "react-query";

export function useGroups() {
    const { data: groups, isLoading: isLoadingGroups } = useQuery<GroupInterface[]>({
        queryKey: "groups",
        queryFn: async () => {
            const response = await API_SERVICE.getGroups({page: -1});

            return response.data.data.items;
        },
        onError: err => notification.error({message: API_SERVICE.handleErrors(err)})
    });

    return { groups: groups ?? [], isLoadingGroups };
}

export function useFunds() {
    const { data: funds, isLoading: isLoadingFunds } = useQuery<FundInterface[]>({
        queryKey: "funds",
        queryFn: async () => {
            const response = await API_SERVICE.getFunds({page: -1});

            return response.data.data.items;
        },
        onError: err => notification.error({message: API_SERVICE.handleErrors(err)})
    });

    const fundCompanies: FundCompanyInterface[] = [];
    const companies: CompanyInterface[] = [];

    funds?.forEach((fund) => {
        fund.fundCompanies?.forEach(map => {
            fundCompanies.push(map);

            if (map.company) {
                companies.push(map.company);
            }
        })
    });

    return { funds: funds ?? [], fundCompanies, companies, isLoadingFunds };
}

export function sortGroupsByName(a: GroupInterface, b: GroupInterface) {
    if (a.name < b.name) {
        return -1;
    }

    if (a.name > b.name) {
        return 1;
    }

    return 0;
}

export function sortFundsByName(a: FundInterface, b: FundInterface) {
    if (a.name < b.name) {
        return -1;
    }

    if (a.name > b.name) {
        return 1;
    }

    return 0;
}

export function sortResultsByCompany(companies: CompanyInterface[]) {
    return (a: ResultSet, b: ResultSet) => {
        const companyA = getCompany(companies, a.company)?.name ?? '-';
        const companyB = getCompany(companies, b.company)?.name ?? '-';

        if (companyA < companyB) {
            return -1;
        }

        if (companyA > companyB) {
            return 1;
        }

        return 0;
    };
}

export function getCompany(companies: CompanyInterface[], companyUid: string) {
    return companies.find((company) => company.uuid === companyUid);
}
