import {Alert} from "antd";
import React from "react";

function AlertUnauthorized() {
    return (
        <Alert
            data-testid="unauthorized-alert"
            message="You do not have the permissions to access this page."
            description={
                <p className="mt-2 mb-0">
                    If you believe this is an error, or if you require any further assistance, please contact us at:<br />
                    <a href="mailto:help@re.co.com">help@re.co.com</a>
                </p>
            }
            type="error"
            showIcon
        />
    );
}

export default AlertUnauthorized;
