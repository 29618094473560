import React, {useEffect, useState} from 'react';
import './index.scss';
import {Button, Col, Form, Input, Modal, notification, Select, Space, Spin, Typography} from 'antd';
import {CloseOutlined, PlusOutlined} from '@ant-design/icons';
import API_SERVICE from '../../services/api-service';
import AccessControl from 'components/AccessControl';

const { Text } = Typography;

export default function CompanyCreateOrEditPage({ onClose, details }: any) {
    const [form] = Form.useForm();
    const [groups, setGroups] = useState([] as any[]);
    const [sectors, setSectors] = useState([] as any[]);
    const [industries, setIndustries] = useState([] as any[]);
    const [creating, setCreating] = useState(false);
    const [loading, setLoading] = useState(true);

    const getGroups = async () => {
        try {
            const {data: {data}} = await API_SERVICE.getGroups();
            setGroups(data.items);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };

    const getSectors = async () => {
        try {
            const {data: {data}} = await API_SERVICE.getSectors();
            setSectors(data.items);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };

    const handleSectorChange = (value: any, setAll = false) => {
        setAll && form.setFieldsValue({industryId: ''});
        let selectedSector = sectors.filter((sector: any) => {
            return sector.id === value;
        });
        //@ts-ignore
        selectedSector.length > 0 && setIndustries(selectedSector[0].industries);
    }

    useEffect(() => {
        details && handleSectorChange(details.sectorId)
    }, [sectors]);

    useEffect(() => {
        Promise.all([getGroups(), getSectors()]).then(() => {}).catch((e) => {
            notification.error({message: "Something went wrong"});
        }).finally(()=> {setLoading(false)});
    }, [])

    return (
        <Modal title={
            (<Space>
                <Text>{details ? 'Edit Company' : 'Add New Company'}</Text>
            </Space>)
        }
               width={570}
               footer={null}
               open={true}
               maskClosable={false}
               cancelText="Cancel"
               okText={details ? "Update" : "+ Add"}
               okType="primary"
               cancelButtonProps={{ ghost: true}}
               onOk={
                   () => {
                       form.submit();
                   }
               }
               onCancel={onClose}>
            <AccessControl id = {7}>     
                {
                    loading ? <div className="modal-spin-container"><Spin/></div> : <Form
                        form={form}
                        initialValues={details || {}}
                        fields={[]}
                        labelCol={{ span: 10 }}
                        wrapperCol={{ span: 14 }}
                        onFinish={(value) => {
                            const reqObj = { ...value };
                            setCreating(true);
                            if (details) {
                                API_SERVICE.companyUpdate(details.id, reqObj).then((d) => {
                                    notification.success({ message: 'Company Updated' });
                                    onClose(true);
                                    setCreating(false);
                                }).catch((e) => {
                                    notification.error({ message: API_SERVICE.handleErrors(e) });
                                    setCreating(false);
                                })
                            } else {
                                API_SERVICE.companyCreate(reqObj).then((d) => {
                                    notification.success({ message: 'Company Created' });
                                    onClose(true);
                                    setCreating(false);
                                }).catch((e) => {
                                    notification.error({ message: API_SERVICE.handleErrors(e) });
                                    setCreating(false);
                                })

                            }
                        }}
                        layout='horizontal'
                        requiredMark={true}
                    >
                        <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label={'Company Name:'}
                                name={'name'}
                                rules={[
                                    { required: true, message: 'Company Name required' },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label={'Select Group:'}
                                name={'pefId'}
                                rules={[
                                    { required: true, message: 'Group required' },
                                ]}
                            >
                                <Select placeholder={'Select Group'}>
                                    {
                                        groups.map((group: any) => {
                                            return <Select.Option
                                                value={group.id}>{group.name}</Select.Option>;
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label={'Select Sector of Operations:'}
                                name={'sectorId'}
                                rules={[
                                    { required: true, message: 'Sector Sector' },
                                ]}
                            >
                                <Select placeholder={'Select Sector'} onChange={(value) => {handleSectorChange(value, true)}}>
                                    {
                                        sectors.map((sector: any) => {
                                            return <Select.Option
                                                value={sector.id}>{sector.name}</Select.Option>;
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label={'Select Industry of Operations: '}
                                name={'industryId'}
                                rules={[
                                    { required: true, message: 'Industry required' },
                                ]}
                            >
                                <Select placeholder={'Select Industry'}>
                                    {
                                        industries && industries.map((industry: any) => {
                                            return <Select.Option
                                                value={industry.id}>{industry.name}</Select.Option>;
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <div className="text-right mt-3">
                            <Space>
                                <Button
                                    type='primary'
                                    ghost
                                    htmlType='button'
                                    icon={<CloseOutlined />}
                                    className='theme-button primary full-width'
                                    onClick={onClose}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    type='primary'
                                    htmlType='submit'
                                    icon={!details && <PlusOutlined />}
                                    loading={creating}
                                    className='theme-button primary full-width '
                                >
                                    {details ? 'Update' : 'Add'}
                                </Button>
                            </Space>
                        </div>
                    </Form>
                }
            </AccessControl>
        </Modal>
 );
}
