import React, {useEffect, useState} from "react";
//import './index.scss';
import {Button, Input, notification, Pagination, Space, Table, Typography} from "antd";
import AdminListLayout from "@recoinsights/shared/components/layout/AdminListLayout/AdminListLayout";
import API_SERVICE from "../../services/api-service";
import FileCreateOrEditPage from "./FileCreateOrEditPage";
//import AccessControl from 'client/src/components/AccessControl';
import {ConfirmationModal} from "../../components";

import {DownloadOutlined, PlusOutlined, SearchOutlined} from "@ant-design/icons";
import {DeleteOutlined, EditOutlined,} from "@material-ui/icons";
import eyeIcon from "@recoinsights/shared/assets/icons/Eye_Icon.svg";
import AccessControl from "components/AccessControl";

const {Text} = Typography;
const {Search} = Input;
export default function UsersFile(props: any) {
    const [files, setFiles] = useState([] as any[]);
    const [fileTypes, setFileTypes] = useState([] as any[]);
    const [search, setSearch] = useState("" as string);
    const [addNew, setAddNew] = useState(false);
    const [editFile, setEditFile] = useState(null as any);
    const [fetching, setFetching] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        perpage: "10",
    } as any);
    const [total, setTotal] = useState(10);
    const [confirmationModal, setConfirmationModal] = useState({} as any);
    const [filters, setFilters] = useState({} as any);

    // @ts-ignore
    const columns = [
        {
            title: "S.No",
            key: "sNO",
            width: 80,
            render: (a: any, v: any, index: number) => (
                <Text>{(pagination.page - 1) * pagination.perpage + index + 1}</Text>
            ),
        },
        {
            title: "Thumbnail",
            key: "thumbnail",
            width: 130,
            render: (a: any) => <div className="linear">
                <img src={a.thumbnail ? a.thumbnail.url : ''} className="thumbnail" alt="thumbnail"/>
            </div>,
        },
        {
            title: "File Display Name",
            dataIndex: "name",
            width: 170,
            render: (a: any) => (
                <Space size={0}>
                    <Text>{a}</Text>
                </Space>
            ),
        },
        {
            title: "File Type",
            dataIndex: "type",
            width: 200,
            filters: fileTypes.map((fileType: any) => {
                return {text: fileType, value: fileType}
            }),
            render: (a: any) => (
                <Space size={0}>
                    <Text>{a}</Text>
                </Space>
            ),
        },
        {
            title: "Show Under",
            key: 'showUnder',
            width: 160,
            filters: [
                {text: 'Support', value: 'isUnderSupport'},
                {text: 'Insights', value: 'isUnderInsights'}
            ],
            render: (a: any) => <>
                {
                    a.isUnderSupport && <Text>Support</Text>
                }
                {
                    a.isUnderInsights && <Text>{a.isUnderSupport && ', '}Insights</Text>
                }
                {
                    (!a.isUnderSupport && !a.isUnderInsights) && <Text>-</Text>
                }
            </>
        },
        {
            title: "Applicable Companies",
            dataIndex: "companyFiles",
            ellipsis: true,
            render: (a: any) => <div className="pr-5" style={{
                wordWrap: 'break-word',
                wordBreak: 'break-word',
                width: '200px',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }}>
                {(a && a.length > 0) ?
                    <img src={eyeIcon} alt="icon" className="table-list-icon" title={namesList(a)}/> : ''}
                {(a && a.length > 0) ? namesList(a) : '-'}
            </div>
        },
        {
            title: "Is Visible?",
            key: "isVisible",
            width: 160,
            filters: [
                {text: 'Visible', value: 1},
                {text: 'Hidden', value: 0}
            ],
            render: (value: any) => (
                <Button
                    className={value.isVisible ? "active-button" : "inactive-button"}
                    onClick={() => {
                        const reqObj = {
                            isVisible: !value.isVisible,
                        };
                        setConfirmationModal({
                            message: `Change status to ${
                                value.isVisible ? "Hidden" : "Visible"
                            }`,
                            action: API_SERVICE.fileStatus,
                            params: [value.id, reqObj],
                        });
                    }}
                >
                    {value.isVisible ? "Visible" : "Hidden"}
                </Button>
            ),
        },
        {
            title: "Actions",
            key: "action",
            width: 120,
            render: (value: any) => (
                <Space>
                    <Button
                        type="text"
                        icon={<DownloadOutlined/>}
                        onClick={() => {
                            downloadFile(value);
                        }}
                    />
                    <Button
                        type="text"
                        icon={<EditOutlined/>}
                        onClick={() => {
                            setEditFile(value);
                        }}
                    />
                    <Button
                        type="text"
                        icon={<DeleteOutlined/>}
                        onClick={() => {
                            setConfirmationModal({
                                message: `Delete File?`,
                                action: API_SERVICE.fileDelete,
                                params: [value.id],
                            });
                        }}
                    />
                </Space>
            ),
        },
    ];

    const downloadFile = (data: any) => {
        if (data.file && data.file.url) {
            window.open(data.file.url);
            /*window.setTimeout(function(){
              popout && popout.close();
            }, 1000);*/
        }
    }

    const namesList = (reqObj: any) => {
        let name = reqObj.map((item: any, i: number) => {
            return item.company?.name;
        });

        let locationString = name.join(`, \n`);
        return locationString;
    }

    const handleTableChange = (pagination: any, filterValues: any, sorter: any) => {
        setFilters({...filters, ...filterValues, page: 1})
    };

    const getFileTypes = async () => {
        try {
            const {data: {data}} = await API_SERVICE.getFileTypes();
            setFileTypes(data.items || []);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };

    const refresh = async (page?: number, perPage?: number) => {
        setFetching(true);
        const params = {...pagination, ...filters};
        if (search) {
            params["q"] = search;
        }
        if (page) {
            params['page'] = page;
        }
        if (perPage) {
            params['perpage'] = perPage;
        }
        setFetching(true);
        try {
            const {
                data: {data},
            } = await API_SERVICE.getFilesList(params);
            setFiles(data.items);
            setTotal(data.total);
            setPagination({page: data.page, perpage: data.perpage});
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        refresh();
        getFileTypes();
    }, [props.location, search, filters]);

    return (
        <div className={"franchise-wrapper page-wrapper"}>
            {/*@ts-ignore*/}
            <AdminListLayout
                title={"Files"}
                titleSearch={<AccessControl id={18}>
                    <div className="search-bar">
                        <Search
                            enterButton={<SearchOutlined/>}
                            placeholder="Search for File Display Name"
                            onSearch={
                                (e) => {
                                    setSearch(e);
                                }
                            }
                        />
                    </div>
                </AccessControl>}
                titleAction={<AccessControl id={19}>
                    <Button
                        type={"primary"}
                        className="theme-button primary"
                        onClick={() => setAddNew(true)}
                        icon={<PlusOutlined/>}
                    >
                        Add New
                    </Button>
                </AccessControl>}

                pagination={<AccessControl id={22}>
                    <Pagination
                        current={pagination.page} pageSize={pagination.perpage} pageSizeOptions={[2, 10, 20, 50]}
                        showSizeChanger
                        showQuickJumper
                        total={total}
                        onChange={(page, size) => {
                            setPagination({page: page, perpage: size})
                            refresh(page, size);
                        }}
                    />
                </AccessControl>}
            >
                {addNew && (
                    <FileCreateOrEditPage
                        onClose={(e: boolean) => {
                            if (e) {
                                refresh();
                            }
                            setAddNew(false);
                        }}
                    />
                )}
                {editFile && (
                    <FileCreateOrEditPage
                        details={editFile}
                        onClose={(e: boolean) => {
                            if (e) {
                                refresh();
                            }
                            setEditFile(null);
                        }}
                    />
                )}
                <AccessControl id={22}>
                    <Table
                        loading={fetching}
                        scroll={{x: true, y: "calc(100vh - 280px)"}}
                        pagination={false}
                        dataSource={files}
                        columns={columns}
                        onChange={handleTableChange}
                        //@ts-ignore
                        rowClassName={record => !record.isVisible && "disabled-row"}
                    /></AccessControl>
            </AdminListLayout>

            <ConfirmationModal
                {...confirmationModal}
                callback={(a: boolean) => {
                    setConfirmationModal({});
                    if (a) {
                        refresh();
                    }
                }}
            />
        </div>
    );
}
