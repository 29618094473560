import React, {useState} from "react";
import {Col, Form, FormInstance, Popconfirm, Row, Select} from "antd";
import {DeleteOutlined, PlusSquareFilled} from "@ant-design/icons";
import ContentMetricsInterface from "../../../../../../shared/types/ContentMetrics.types";
import QuestionInterface from "../../../../../../shared/types/Question.types";
import QuestionContentMetricsInterface from "../../../../../../shared/types/QuestionContentMetrics.types";
import DataPointsInterface from "../../../../../../shared/types/DataPoints.types";

const {Option} = Select;

interface BaseInterface {
    question: QuestionInterface,
    isModifiable: boolean,
    contentMetrics: Array<ContentMetricsInterface>,
    deleteQuestionMetric: Function,
    handleContentMetricChange: Function,
    form: FormInstance
}

interface PropsInterface extends BaseInterface{
    addQuestionMetric: Function
}

interface QuestionMetricRowInterface extends BaseInterface {
    questionMetric: QuestionContentMetricsInterface,
    questionMetricIndex: number
}

export default function AddQuestionMetricComponent ({question, isModifiable, contentMetrics, deleteQuestionMetric, handleContentMetricChange, form, addQuestionMetric}: PropsInterface) {
    return <>
        <p className="mb-1">Provides:</p>
        {
            question.questionMetrics && question.questionMetrics.length > 0 && question.questionMetrics.map((questionMetric: QuestionContentMetricsInterface, questionMetricIndex: number) => {
                return <QuestionMetricRow
                    key={`add${question.uuid}`}
                    question={question}
                    isModifiable={isModifiable}
                    contentMetrics={contentMetrics}
                    deleteQuestionMetric={deleteQuestionMetric}
                    questionMetric={questionMetric}
                    questionMetricIndex={questionMetricIndex}
                    handleContentMetricChange={handleContentMetricChange}
                    form={form}
                />
            })
        }
        <span className={`${isModifiable ? 'cursor-pointer' : 'cursor-disabled'} add-new-option d-inline-block`} onClick={()=> {addQuestionMetric(question.uuid)}} data-testid="content-metric-add-btn"><PlusSquareFilled /></span>
    </>
}

function QuestionMetricRow ({question, isModifiable, contentMetrics, deleteQuestionMetric, handleContentMetricChange, form, questionMetric, questionMetricIndex}: QuestionMetricRowInterface) {
    const [dataPoints, setDataPoints] = useState<Array<DataPointsInterface>>(contentMetrics.find((contentMetric: ContentMetricsInterface) => contentMetric.id === questionMetric?.contentMetricId)?.dataPoints ?? []);

    const handleContentMetricIdChange = (questionUuid: string, type: string, questionMetricIndex: number, value: number) => {
        const dataPoints = contentMetrics.find((contentMetric: ContentMetricsInterface) => contentMetric.id === value)!.dataPoints ?? [];
        setDataPoints(dataPoints);

        handleContentMetricChange(questionUuid, type, questionMetricIndex, value, true);

        const fieldName: Record<string, string | null> = {};
        fieldName[`contentMetricDataPointId_${questionMetricIndex}`] = null
        form.setFieldsValue(fieldName);
    }

    const handleContentMetricsDataPointChange = (questionUuid: string, type: string, questionMetricIndex: number, value: number) => {
        handleContentMetricChange(questionUuid, type, questionMetricIndex, value);
    }

    return <>
        <Row>
            <Col xs={11}>
                <Form.Item name={`contentMetricId_${questionMetricIndex}`} initialValue={questionMetric.contentMetricId}>
                    <Select  key={`option1${question.uuid}`} placeholder="Metric" onChange={(value) => {handleContentMetricIdChange(question.uuid, 'contentMetricId', questionMetricIndex, value)}} data-testid="content-metric-dropdown">
                        <Option value={null}>None</Option>
                        {
                            contentMetrics && contentMetrics.length > 0 && contentMetrics.map((metric: ContentMetricsInterface) => {
                                return <Option key={`ContentMetricOption+${metric.id}+${question.uuid}`} value={metric.id}>{metric.name}</Option>
                            })
                        }
                    </Select>
                </Form.Item>
            </Col>

            <Col xs={11}>
                <Form.Item className="ml-2" name={`contentMetricDataPointId_${questionMetricIndex}`} initialValue={questionMetric.contentMetricDataPointId}>
                    <Select key={`option2${question.uuid}`} placeholder="Data Point" onChange={(value) => {handleContentMetricsDataPointChange(question.uuid, 'contentMetricDataPointId', questionMetricIndex, value)}} data-testid="content-metric-data-point-dropdown">
                        <Option value={null}>None</Option>
                        {
                            dataPoints && dataPoints.length > 0 && dataPoints.map((dataPoint: DataPointsInterface) => {
                                return <Option key={`dataPointOption+${dataPoint.id}+${question.uuid}`} value={dataPoint.id}>{dataPoint.name}</Option>
                            })
                        }
                    </Select>
                </Form.Item>
            </Col>
            <Col xs={2} className="d-flex align-items-center justify-content-center">
                {
                    isModifiable &&
                    <span className="d-inline-block mb-1 ml-1">
                        <Popconfirm title="Sure to delete?" onConfirm={() => deleteQuestionMetric(question.uuid, questionMetricIndex)}><DeleteOutlined data-testid="content-metric-delete-btn"/></Popconfirm>
                    </span>
                }
            </Col>
        </Row>
    </>
}
