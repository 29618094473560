import React, {useEffect, useState} from "react";
import {Button, Form, Input, notification, Radio, Select} from "antd";

import "./index.scss";
import API_SERVICE from '../../../services/api-service';

const formTypes = [
  { name: 'Exact Match', value: 'EXACT_MATCH'},
  { name: 'Value', value: 'VALUE'}
];

export default function FundsBenchMarkingForm(props: any) {

  const [loading, setLoading] = useState(false);
  const [formType, setFormType] = useState <string> (formTypes[0].value);
  const [form] = Form.useForm()

  useEffect(()=>{
    const formData = props.formData;
    const [i, j] = props.cellId.split(':');
    const data_index: number = i;
    const dkpi_index: number = j;
    const fields: any = {
      //@ts-ignore
      ind_index: `${parseInt(data_index/3)}`,
      level_index: `${data_index%3}`,
      dkpi_index: dkpi_index,
    }
    form.resetFields();
    setFormType(formTypes[0].value)
    try {
        const dkpi = formData.mappings['kpis'][dkpi_index];
        const metric = dkpi.metric['name'];
        const cell: any = props.benchmarkData[data_index][metric][dkpi.subindex]['benchmark'];
        if (cell.type) {
          setFormType(cell.type)
        }
        [
          'type',
          'string',
          'startValue',
          'endValue',
          'unitValue'
        ].map((i)=>{
          if (cell[i] || cell[i] === 0) {
            fields[i] = cell[i];
          }
        });
    }
    catch (e){
      //console.log(e)
    }
    form.setFieldsValue(fields)
  },[props.cellId, props.formData])

  return (
      <div className="form1-wrapper">
        <Form
          requiredMark={false}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 22 }}
          form={form}
          initialValues={{type: formTypes[0].value}}
          onValuesChange={(obj: any, data: any) => {
            if (obj.type) {
              setFormType(obj.type)
            }
            const ind_index = parseInt(obj.ind_index || data.ind_index);
            const level_index = parseInt(obj.level_index || data.level_index);
            const data_index = (ind_index*3 + level_index);
            const dkpi_index = (data.dkpi_index);
            props.onChange(`${data_index}:${dkpi_index}`)
          }}
          onFinish={async (obj: any) => {
            const formData = props.formData;
            const mappings = formData['mappings'];
            const reqObj: any = {
              fundId: formData.fundId,
              year: formData.year,
              dkpiId: mappings['kpis'][obj.dkpi_index]['id'],
              metricId: mappings['kpis'][obj.dkpi_index]['metric']['id'],
              levelId: mappings['levels'][obj.level_index]['id'],
              industryId: props.getIndustryId(),
              ...obj
            };
            let isValid = true
            if (formType === formTypes[0].value) {
              reqObj.startValue = '';
              reqObj.endValue = '';
            }
            else {
              try {
                if (!(parseFloat(reqObj.endValue) > parseFloat(reqObj.startValue))) {
                  notification.error({message: "End Value must be greater than Start Value"});
                  isValid = false
                }
              } catch (e) {
                notification.error({message: "Invalid entries"});
                isValid = false
              }
              reqObj.string = '';
            }
            delete reqObj.ind_index;
            delete reqObj.level_index;
            delete reqObj.dkpi_index;
            if (isValid) {
              try {
                  setLoading(true);
                  await API_SERVICE.setBenchmark(reqObj);
                  //form.resetFields();
                  props.onUpdate();
              } catch (e) {
                  notification.error({message: API_SERVICE.handleErrors(e)});
              }
              finally {
                setLoading(false);
              }
            }
          }}>
          <Selector
            label="Industry"
            data={props.formData['industries']}
            name="ind_index" />
          <Selector
            label="Level"
            data={props.formData['levels']}
            name="level_index" />
          <Selector
            label="KPI"
            data={props.formData['kpis']}
            name="dkpi_index" />
          <Form.Item
            className="InputWeight"
            name="type"
            label="Type"
            rules={[{ required: true }]}>
            <Radio.Group>
              {
                formTypes.map((obj: any, i: number) =>
                  <Radio key={i} value={obj.value}>{obj.name}</Radio>
                )
              }
            </Radio.Group>
          </Form.Item>
          {
            (formType === formTypes[0].value)?
              <Form.Item
                className="InputWeight"
                name="string"
                label="String:"
                rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              :
              <div>
                <Form.Item
                  className="InputWeight"
                  name="startValue"
                  label="Start Value:"
                  rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                <Form.Item
                  className="InputWeight"
                  name="endValue" label="End Value:"
                  rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </div>

          }
          <Form.Item
            className="InputWeight"
            name="unitValue"
            label="Unit Value:">
            <Input />
          </Form.Item>
          <Form.Item className="d-flex justify-content-center">
            <Button
              type="primary"
              className="mt-5"
              htmlType="submit"
              block
              disabled={loading}
            >
              +Add/Update Data
            </Button>
          </Form.Item>
        </Form>
      </div>
  );
}

function Selector(props: any) {
  if (props.data) {
    return (
      <Form.Item
        className="InputWeight"
        name={props.name}
        label={`${props.label}:`}
        rules={[{ required: true }]}>
        <Select placeholder={`Select ${props.label}`}>
          {Object.keys(props.data).map((id, i) => {
            return (
              <Select.Option key={i} value={id}>{props.data[id]}</Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    )
  }
  else return <div />;
}
