export const DASHBOARD = "/admin";
export const USER_LIST = '/admin/users';
export const COMPANY_LIST = '/admin/companies';
export const GROUPS_LIST = '/admin/groups';
export const USER_PROFILE = '/admin/profile';
export const OTP = '/otp';
export const FORGOT_PASSWORD = '/reset-password';
export const ANALYTICS = '/admin/analytics'
export const CLAIMS = '/admin/claims'
export const MASTER_MANAGEMENT = '/admin/master-management'
export const PERMISSION = '/admin/permission'
export const CLAIMS_OVERVIEW = `/admin/claims/overview`
export const FINANCIALS = '/admin/financials'
export const FILES = '/admin/files'
export const CONTENT = '/admin/content'

export const HOME = '/admin/analyst/homepage'
export const DATA = '/admin/analyst/datacollection'
export const SUPPORT = '/admin/analyst/support'
export const CONTACTUS = '/admin/analyst/contactus'

export const TARGETS = '/admin/analyst/targets'
export const RESULT = '/admin/analyst/result'
export const EMISSIONS = '/admin/emissions';

export const PORTFOLIO = '/admin/fundmanager/portfolio'
export const COMPANIES = '/admin/fundmanager/companies'
export const INSIGHTS = '/admin/fundmanager/insights'


//admin
export const ADMIN_FUNDS = '/admin/funds'
export const PORTFOLIO_BENCHMARKS = '/admin/portfolio-benchmarks'
export const ADMIN_FUNDS_CONFIGURATION = '/admin/funds/configuration'
export const ADMIN_SURVEYS = '/admin/surveys'
export const ADMIN_SURVEY_QUESTIONNAIRE = '/admin/surveys/questionnaire'
export const ADMIN_DATA_COLLECTION = '/admin/data-collection'
export const ADMIN_QUESTIONS_MASTER = '/admin/questions-master'

export const ADMIN_FUNDS_BENCHMARKING='/admin/funds/benchmarking';
export const KPI_RESULT_CALCULATION='/admin/funds/kpi-results/calculate';
export const KPI_RESULTS = '/admin/funds/kpi-results';
export const ESG_WEIGHTAGES = '/admin/funds/esg-weightages'
export const KPI_SUMMARY = '/admin/funds/kpi/summary';
export const ESG_SCORES = '/admin/funds/esg-scores';
export const COMPANY_RESULT_AND_TARGETS = '/admin/funds/result-targets';
export const REPORTS  = '/admin/funds/reports';
export const COMPANY_PERFORMANCE  = '/admin/company-performance';

// Surveys
export const SURVEYS_LIST = '/admin/surveys-list'

// Company results
export const COMPANY_RESULT_LIST = '/admin/results/companies';
export const COMPANY_RESULT_DASHBOARD = '/admin/results/companies/:companyId';

// Portfolio results
export const PORTFOLIO_RESULTS = '/admin/results/portfolio';
export const PORTFOLIO_RESULTS_DASHBOARD = '/admin/results/portfolio/:groupId';

//new route for fund configuration for PM
export const PM_FUNDS_CONFIGURATION = '/admin/funds/companies-configuration'

