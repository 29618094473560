import * as React from "react";
import {Route, Switch, useHistory, useLocation} from "react-router-dom";

import CompaniesPage from "./Companies/CompaniesPage";
import GroupPage from "./Groups/GroupsPage";
import DesktopAdminLayout from "@recoinsights/shared/components/layout/DesktopAdminLayout";
import UserProfile from "./UserProfile";
import UsersPage from "./Users/UsersPage";
import UsersFile from "./Files/FilesPage";
import ClaimsMaster from "./MasterManagement";
import Permissions from "./Permission/Permission";

import {
  ADMIN_DATA_COLLECTION,
  ADMIN_FUNDS,
  ADMIN_FUNDS_BENCHMARKING,
  ADMIN_FUNDS_CONFIGURATION,
  ADMIN_QUESTIONS_MASTER,
  ADMIN_SURVEY_QUESTIONNAIRE,
  ADMIN_SURVEYS,
  COMPANIES,
  COMPANY_LIST,
  COMPANY_PERFORMANCE,
  COMPANY_RESULT_AND_TARGETS,
  CONTACTUS,
  CONTENT,
  DATA,
  ESG_SCORES,
  ESG_WEIGHTAGES,
  FILES,
  GROUPS_LIST,
  HOME,
  INSIGHTS,
  KPI_RESULT_CALCULATION,
  KPI_RESULTS,
  KPI_SUMMARY,
  MASTER_MANAGEMENT,
  PERMISSION,
  PORTFOLIO,
  PORTFOLIO_BENCHMARKS,
  REPORTS,
  RESULT,
  SUPPORT,
  COMPANY_RESULT_LIST,
  TARGETS,
  USER_LIST,
  USER_PROFILE,
  SURVEYS_LIST,
  PORTFOLIO_RESULTS,
  PM_FUNDS_CONFIGURATION,
  COMPANY_RESULT_DASHBOARD,
  EMISSIONS,
  PORTFOLIO_RESULTS_DASHBOARD
} from "@recoinsights/shared/constants/RouteConstants";
import Content from "./Content";
import Home from "./Analyst/Homepage/Homepage";
import DataCollection from "./Analyst/DataCollection/DataCollection";
import Targets from "./Analyst/Targets/Targets";
import Result from "./Analyst/Result/Result";
import Support from "./Analyst/Support/Support";
import ContactUs from "./Analyst/ContactUs/Contactus";
import Companies from "./FundManager/Companies/Companies";
import Insights from "./FundManager/Insights/Insights";
import Portfolio from "./FundManager/Portfolio/Portfolio";
import Funds from "./RAdmin/Funds/Funds";
import PortfolioBenchmarks from './RAdmin/Funds/PortfolioBenchmarks';
import Surveys from "./RAdmin/Surveys/Surveys";
import DCollection from "./RAdmin/DataCollection/DataCollection";
import FundsConfigure from "./RAdmin/Funds/Configure";
import SurveyQuestionnaire from "./RAdmin/Surveys/SurveyQuestionnaire";
import FundsBenchMarkingCrud from "./FundsBenchMarking/FundBenchMarkingCrud/FundsBenchMarkingCrud";
import KpiResultCalculation from "./FundsBenchMarking/KpiResultCalculation/KpiResultCalculation";
import KpiResultsTable from "./FundsBenchMarking/KpiResultCalculation/KpiResultsTable";
import KpiSummary from "./FundsBenchMarking/KPISummary/KpiSummary";
import EsgScores from "./FundsBenchMarking/EsgScores/EsgScores";
import EsgWeightages from "./FundsBenchMarking/ESGWeightages/EsgWeightages";
import CompanyResult from './FundsBenchMarking/CompnayResultAndTargets/CompanyResult';
import Reports from './FundsBenchMarking/Reports/Reports';
import CompanyPerformance from './FundsBenchMarking/CompanyPerformance/CompanyPerformance';
import QuestionsMasterPage from './QuestionsMasterPage/QuestionsMasterPage';

import ReactGA from 'react-ga';
import SurveysListPage from "./Surveys/SurveysListPage";
import PortfolioResultList from "../components/pages/PortfolioResultList";
import FundCompaniesConfigure from "./RAdmin/Funds/CompaniesConfigure";
import CompanyResultDashboard from "../components/pages/CompanyResultDashboard";
import {isEmissionsEnabled, isResultsEnabled} from "../services/util";
import Emissions from "./Analyst/DataCollection/Emissions";
import PortfolioResultDashboard from "../components/pages/PortfolioResultDashboard";
import CompanyResultList from "../components/pages/CompanyResultList";

// ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID ?? "");

type Props = {};

const ClosedLayout: React.FunctionComponent<Props> = () => {
  const { pathname } = useLocation();
  const history = useHistory();

  const selectedMenu = pathname;

  function handleMenuClick(route: string) {
    if (route !== pathname) {
      history.push(route);
    }
  }

  const AppLayout = DesktopAdminLayout;

  React.useEffect(() => {
    document.title = "Re:Co Platform";
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <AppLayout selectedMenu={selectedMenu} onMenuClick={handleMenuClick}>
      <Switch>
        <Route exact path={COMPANY_LIST} component={CompaniesPage} />
        <Route
          exact
          path={COMPANY_LIST + "/:groupId"}
          component={CompaniesPage}
        />
        <Route exact path={GROUPS_LIST} component={GroupPage} />
        <Route exact path={USER_PROFILE} component={UserProfile} />
        <Route exact path={USER_LIST} component={UsersPage} />
        <Route exact path={MASTER_MANAGEMENT} component={ClaimsMaster} />
        <Route exact path={FILES} component={UsersFile} />
        <Route exact path={CONTENT} component={Content} />
        <Route exact path={HOME} component={Home} />
        <Route exact path={DATA} component={DataCollection} />
        <Route exact path={DATA + "/:surveyId"} component={DataCollection} />
        <Route exact path={TARGETS} component={Targets} />
        <Route exact path={RESULT} component={Result} />
        <Route exact path={SUPPORT} component={Support} />
        <Route exact path={CONTACTUS} component={ContactUs} />
        <Route exact path={INSIGHTS} component={Insights} />
        <Route exact path={PORTFOLIO} component={Portfolio} />
        <Route exact path={COMPANIES} component={Companies} />
        <Route exact path={ADMIN_FUNDS} component={Funds} />
        <Route exact path={PORTFOLIO_BENCHMARKS} component={PortfolioBenchmarks} />
        {/*<Route exact path={ADMIN_FUNDS_CONFIGURATION} component={FundsConfigure} />*/}
        <Route
          exact
          path={`${ADMIN_FUNDS_CONFIGURATION}/:fundId`}
          component={FundsConfigure}
        />
        <Route
          exact
          path={`${ADMIN_FUNDS_BENCHMARKING}/:fundId`}
          component={FundsBenchMarkingCrud}
        />
        <Route
          exact
          path={`${KPI_RESULTS}/:surveyId`}
          component={KpiResultsTable}
        />
        <Route exact path={`${KPI_RESULT_CALCULATION}/:surveyId/:kpiId`} component={KpiResultCalculation} />
        <Route exact path={ADMIN_SURVEYS} component={Surveys} />
        <Route
          exact
          path={`${ADMIN_SURVEY_QUESTIONNAIRE}/:surveyId`}
          component={SurveyQuestionnaire}
        />
        <Route exact path={ADMIN_DATA_COLLECTION} component={DCollection} />
        <Route exact path={`${KPI_SUMMARY}/:fundId`} component={KpiSummary} />
        <Route exact path={`${ESG_SCORES}/:fundId`} component={EsgScores} />
        <Route exact path={`${ESG_WEIGHTAGES}/:fundId`} component={EsgWeightages} />
        <Route exact path={`${COMPANY_RESULT_AND_TARGETS}/:surveyId`} component={CompanyResult} />
        <Route exact path={REPORTS} component={Reports} />
        <Route exact path={`${COMPANY_PERFORMANCE}/:surveyId`} component={CompanyPerformance} />

        {/* //@ts-ignore */}
        <Route exact path={PERMISSION} component={Permissions} />
        <Route exact path={ADMIN_QUESTIONS_MASTER} component={QuestionsMasterPage} />

        <Route exact path={SURVEYS_LIST} component={SurveysListPage} />

        {isResultsEnabled() && <Route exact path={COMPANY_RESULT_LIST} component={CompanyResultList} />}
        {isResultsEnabled() && <Route exact path={COMPANY_RESULT_DASHBOARD} component={CompanyResultDashboard} />}
        {isResultsEnabled() && <Route exact path={PORTFOLIO_RESULTS} component={PortfolioResultList}/>}
        {isResultsEnabled() && <Route exact path={PORTFOLIO_RESULTS_DASHBOARD} component={PortfolioResultDashboard} />}
        <Route
            exact
            path={`${PM_FUNDS_CONFIGURATION}/:fundId`}
            component={FundCompaniesConfigure}
        />
        {isEmissionsEnabled() && <Route exact path={EMISSIONS} component={Emissions}/>}
      </Switch>
    </AppLayout>
  );
};

export default ClosedLayout;
