import {Alert} from "antd";
import React from "react";

function AlertNoResults() {
    return (
        <Alert
            data-testid="no-results-alert"
            message="You do not have any results yet."
            description={
                <>
                    <p className="mt-2">
                        Your results will begin to appear here once you complete your survey(s).
                    </p>
                    <p className="mb-0">
                        If you believe this is an error, or if you require any further assistance, please contact us at:<br />
                        <a href="mailto:help@re.co.com">help@re.co.com</a>
                    </p>
                </>
            }
            type="info"
            showIcon
        />
    )
}

export default AlertNoResults;
