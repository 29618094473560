import React, {useEffect, useState} from "react";
import {Button, Col, notification, Row, Table, Typography} from "antd";
import API_SERVICE from '../../../services/api-service';
import Header from '../../../components/Header';
import {useHistory} from "react-router-dom";
import {KPI_RESULT_CALCULATION} from "@recoinsights/shared/constants/RouteConstants";

const { Text } = Typography;

const KpiResultsTable = (props: any) => {
  const history = useHistory();
  const {match: {params: { surveyId }}} = props;
  var rowIndex = 0;
  const columns: any = [
    {
      title: "S.NO",
      render: () => {
        return (
          <Text>{rowIndex+1}</Text>
        )
      }
    },
    {
      title: "KPI",
      dataIndex: "name",
    },
    {
      title: "Result Preview",
      render: (a: string) => <Text>
          {/*@ts-ignore*/}
          {a.resultPreview.replace(/(<([^>]+)>)/ig, '').toFloat(2)} {a.unit}
        </Text>
    },
    {
      title: "Action",
      render: (data: any) => {
        return (
          <Button
            type={"primary"}
            disabled={!data.dkpiId}
            onClick={()=>{history.push(`${KPI_RESULT_CALCULATION}/${surveyId}/${data.dkpiId}`)}}>
            Calculate
          </Button>
        )
      }
    },
  ];

  const [kpiResults, setKpiResults] = useState <any[]> ([]);
  const [surveyDetails, setSurveyDetails] = useState <any> ({});
  const [loading, setLoading] = useState(true);

  const getSurveyDetails = async () => {
      try {
          setLoading(true);
          const {data: { data }} = await API_SERVICE.getDataCollection({id: surveyId});
          if (data.items.length) {
            setSurveyDetails(data.items[0]);
          }
      } catch (e) {
          notification.error({message: API_SERVICE.handleErrors(e)});
      } finally {
        setLoading(false)
      }
  };

  const getKpiResults = async () => {
      try {
          setLoading(true);
          const {data: { data }} = await API_SERVICE.getKpiResults(surveyId, surveyDetails.year);
          setKpiResults(data);
      } catch (e) {
          notification.error({message: API_SERVICE.handleErrors(e)});
      } finally {
        setLoading(false)
      }
  };

  useEffect(()=>{
    getSurveyDetails();
  },[])

  useEffect(()=>{
    if (surveyDetails.id) {
      getKpiResults();
    }
  },[surveyDetails])

  return (
    <div className="page-wrapper Benchmarking-role-wrapper">
      <Header
        headings={[
          "KPI Result Calculation",
          surveyDetails.funds && !!surveyDetails.funds.length && surveyDetails.funds[0].name,
          surveyDetails.company && surveyDetails.company.name,
          surveyDetails.year
        ]} />
      <Row style={{margin: 24}}>
        <Col>
          <Table
              loading={loading}
            columns={columns}
            dataSource={kpiResults}
            bordered
            pagination={false}
            onRow={(record, index) => {
              if (index) {
                rowIndex = index;
              }
              return {};
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default KpiResultsTable;
