import React, {useEffect, useState} from 'react';
import {Button, Col, DatePicker, notification, Row, Spin} from 'antd';

import API_SERVICE from '../../../services/api-service';
import moment from "moment";
import AccessControl from 'components/AccessControl';

export default function Timeline(props: any) {
    const [stages, setStages] = useState([] as any[]);
    const [formReqObj, setFormReqObj] = useState([] as any[]);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const dateFormat = 'DD/MM/YYYY';

    function dateBeforePreviousStageDisabled(current: any, stage: any, index: number) {
        let startDate = moment().subtract(100, "years").startOf('day'), endDate = moment().add(100, "years").startOf('day');

        const prevStage = stages[index - 1];
        const nextStage = stages[index + 1];
        if(prevStage && moment(prevStage.date).isValid()){
            startDate = moment(prevStage.date).startOf('day')
        }

        if(nextStage && moment(nextStage.date).isValid()){
            endDate = moment(nextStage.date).startOf('day')
        }

        return !(current >= startDate && current <= endDate)
    }

    function handleDateChange(data: any) {
        data.date = (data?.date)?.format('YYYY-MM-DD');
        let reqObj = formReqObj;
        reqObj.push(data);
        setFormReqObj(reqObj);
        stages[data.index].date = data.date;
        setStages(JSON.parse(JSON.stringify(stages)));
    }

    const getCompanyTimelines = async () => {
        try {
            if (props.companyId) {
                setLoading(true);
                const {data: {data}} = await API_SERVICE.getCompanyTimelines(props.companyId);
                setStages(data);
            }
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        } finally {
            setLoading(false);
        }
    };

    const updateCompanyTimelines = async () => {
        setSaving(true);
        try {
            await API_SERVICE.updateCompanyTimelines(props.companyId, {stages: formReqObj});
            notification.success({message: 'Content Updated'});
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        } finally {
            setSaving(false);
        }
    };

    useEffect(() => {
        getCompanyTimelines();
    }, [props])

    return ( 
        <AccessControl id = {29}>

        <>
            <div>

                {loading ? <Spin /> : <ol>
                    {
                        stages.map((stage: any, index: number) => {
                            return (
                                <>
                                    <li>
                                        <Row className="mb-2" align="middle">
                                            <Col xs={6}>
                                                {stage.name}
                                            </Col>
                                            <Col xs={6}>
                                                <DatePicker
                                                    allowClear={false}
                                                    name={'stage_' + stage.id}
                                                    disabledDate={(current) => {return dateBeforePreviousStageDisabled(current, stage, index)}}
                                                    format={dateFormat}
                                                    onChange={(newDate) => {
                                                        handleDateChange({
                                                            stageId: stage.id,
                                                            date: newDate,
                                                            index: index
                                                        })
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </li>
                                </>
                            )
                        })
                    }
                </ol>
                }
            </div>
            <div className="text-center mb-1 mr-2">
            <AccessControl id = {29}>
                <Button
                    type='primary'
                    loading={saving}
                    className='theme-button primary'
                    onClick={updateCompanyTimelines}
                    disabled={!props.companyId}
                >
                    Save
                </Button>
                </AccessControl>
            </div>
        </>
    </AccessControl>)
}
