import React from "react";
import {DataPoints, Metric as MetricData} from "@recoinsights/metrics";
import {Column} from "@ant-design/charts";

enum Labels {
    Year = "Year",
    EnergyConsumption = "Energy Consumption (kWh)",
    Type = "Type",
    Facility = "Facility",
    Vehicle = "Vehicle",
    Refrigerant = "Refrigerant",
    Electricity = "Electricity",
    Total = "Total",
}

interface Props {
    result: MetricData,
}

function EnergyConsumptionByTypeChart({ result }: Props) {
    const data: {[Labels.EnergyConsumption]?: number, [Labels.Type]: string, }[] = [];

    const facilityEnergyConsumption = result.data[DataPoints.TotalFacilityEnergyConsumption];
    const vehicleEnergyConsumption = result.data[DataPoints.TotalVehicleEnergyConsumption];
    const refrigerantEnergyConsumption = result.data[DataPoints.TotalRefrigerantEnergyConsumption];
    const electricityEnergyConsumption = result.data[DataPoints.TotalElectricityEnergyConsumption];


    data.push({
        [Labels.EnergyConsumption]: facilityEnergyConsumption !== undefined ? Math.round(facilityEnergyConsumption ?? 0 / 1000) : 0,
        [Labels.Type]: Labels.Facility,
    });

    data.push({
        [Labels.EnergyConsumption]: vehicleEnergyConsumption !== undefined ? Math.round(vehicleEnergyConsumption ?? 0 / 1000) : 0,
        [Labels.Type]: Labels.Vehicle,
    });

    data.push({
        [Labels.EnergyConsumption]: refrigerantEnergyConsumption !== undefined ? Math.round(refrigerantEnergyConsumption ?? 0 / 1000) : 0,
        [Labels.Type]: Labels.Refrigerant,
    });

    data.push({
        [Labels.EnergyConsumption]: electricityEnergyConsumption !== undefined ? Math.round(electricityEnergyConsumption ?? 0 / 1000) : 0,
        [Labels.Type]: Labels.Electricity,
    });

    return (
        <Column
            data={data}
            isStack={true}
            xField={Labels.Type}
            yField={Labels.EnergyConsumption}
            seriesField={Labels.Type}
            legend={{ position: "right-bottom", maxWidthRatio: 0.33, padding: [0, 0, 0, 30], itemHeight: 10, }}
            height={200}
        />
    );
}

export default EnergyConsumptionByTypeChart;
