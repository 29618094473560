import React from 'react';
import './index.scss';
import {Collapse} from 'antd';
import Industries from "./Industries/Industries";
import AccessControl from 'components/AccessControl';
import MetricKPI from './Metric&KPI/Metric&KPI';
import SurveyUnits from './SurveyUnits/SurveyUnits';
import SurveyGHG from './SurveyGHG/SurveyGHG';
import BenchMarkingColors from './BenchMarkingColors/BenchMarkingColors';
import QuestionsMaster from './QuestionsMaster/QuestionsMaster';

const { Panel } = Collapse;

export default function ClaimsMaster() {
    return (<AccessControl id = {26}>
        <>
            <div className={'role-wrapper page-wrapper'} style={{flex: 1, padding: '30px'}}>
                {/*@ts-ignore*/}
                <div className="profile_section mb-3">
                    <h1>Master Data Management</h1>
                </div>
                <Collapse defaultActiveKey={['3']} accordion>
                    <Panel header="Industries" key="3">
                        <Industries />
                    </Panel>
                    <Panel header="Dashboard Metrics" key="4">
                        <MetricKPI />
                    </Panel>
                    <Panel header="Survey Units" key="5">
                       <SurveyUnits />
                    </Panel>
                    <Panel header="Survey GHG" key="6">
                        <SurveyGHG />
                    </Panel>
                    <Panel header="Benchmarking Colors" key="7">
                        <BenchMarkingColors/>
                    </Panel>
                    <Panel header="Questions Master - Categories & Sub-Categories" key="8">
                        <QuestionsMaster/>
                    </Panel>
                </Collapse>
            </div>
        </>
    </AccessControl>);
}
