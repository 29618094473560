import React, {useEffect, useRef, useState} from "react";
import "./index.scss";
import {
    Checkbox,
    Col,
    Collapse,
    Dropdown,
    Form,
    Input,
    Menu,
    message,
    Modal,
    notification,
    Popconfirm,
    Popover,
    Row,
    Space,
    Spin,
    Steps,
    Tabs,
    Tooltip,
    TreeSelect
} from "antd";
import {
    AlignLeftOutlined,
    BarsOutlined,
    DeleteOutlined,
    FilterFilled,
    LoadingOutlined,
    MenuOutlined,
    PlusCircleFilled,
    PlusOutlined,
    ProfileOutlined,
    SaveOutlined,
    SendOutlined
} from '@ant-design/icons';
import {useHistory} from "react-router-dom";
import API_SERVICE from "../../../services/api-service";
import AdminListLayout from "@recoinsights/shared/components/layout/AdminListLayout/AdminListLayout";
import SimpleQuestionCard from "./QuestionCards/SimpleQuestion";
import SingleSelectQuestionCard from "./QuestionCards/SingleSelectQuestion";
import RTEQuestionCard from "./QuestionCards/RTEQuestion";
import ContentQuestionCard from "./QuestionCards/ContentQuestion";
import MultipleSelectQuestionCard from "./QuestionCards/MultipleSelectQuestion";
import LocationQuestionCard from "./QuestionCards/LocationQuestion";
import {getUser} from "@recoinsights/client/src/services/local-storage";
import AppConstants from "@recoinsights/shared/constants/Constants";
import {DATA} from "@recoinsights/shared/constants/RouteConstants";
//@ts-ignore
import {v4 as uuidv4} from 'uuid';
import ScopeQuestionCard from "./QuestionCards/ScopeQuestion";
import QuestionPageInterface from "../../../../../shared/types/QuestionPage.types";
import QuestionSectionInterface from "../../../../../shared/types/QuestionSection.types";
import QuestionInterface from "../../../../../shared/types/Question.types";
import QuestionContentMetricsInterface from "../../../../../shared/types/QuestionContentMetrics.types";

const {Step} = Steps;
const {TabPane} = Tabs;
const {Panel} = Collapse;
const {Search} = Input;
const antIcon = <LoadingOutlined style={{fontSize: 24}} spin/>;

let questionType: any = [];
let paramMasterQuestion = {
    isMaster: 1,
    page: -1
}
const {SHOW_CHILD} = TreeSelect;

export default function SurveyQuestionnaire(props: any) {
    const history = useHistory();
    const [form] = Form.useForm();
    const roleId = getUser()?.user?.role?.id;
    const [addingPage, setAddingPage] = useState(false)
    const [saving, setSaving] = useState(false);
    const [addingSection, setAddingSection] = useState(false)
    const [addingQuestion, setAddingQuestion] = useState(false)
    const [newPageName, setNewPageName] = useState('')
    const [needScopePages, setNeedScopePages] = useState(false);
    const [metrics, setMetrics] = useState([] as any[]);
    const [surveyDetails, setSurveyDetails] = useState({} as any);
    const [metricData, setMetricData] = useState({pages: [] as any []});
    const [unitCategories, setUnitCategories] = useState([] as any []);
    const [activeMetricsIndex, setActiveMetricsIndex] = useState(0 as number);
    const [activeMetricsId, setActiveMetricsId] = useState(0 as number);
    const [masterQuestions, setMasterQuestionList] = useState([] as any[]);
    const [surveyId] = useState<number>(props.match.params.surveyId || null);
    const [fetchingMetricData, setFetchingMetricData] = useState(false);
    const [activeTab, setActiveTab] = useState('0' as any);
    const [activeSection, setActiveSection] = useState(0 as any);
    const [allQuestionUUIDs, setAllQuestionUUIDs] = useState([] as any[]);
    const [fetchingMasterQuestion, setFetchingMasterQuestion] = useState(false);
    const [contentMetrics, setContentMetrics] = useState([] as any[]);
    const questionContainerRef = useRef<HTMLElement>();
    const tooltipdata = <span>Save as Draft</span>;
    let valueChangeTimer: any = undefined;
    const validationTypeAndCondition = [
        {
            name: 'Number',
            value: 'Number',
            conditions: [
                {name: 'Less Than', value: 'less_than', input: [1]},
                {name: 'Greater Than', value: 'greater_than', input: [1]},
                {name: 'Equals To', value: 'equals_to', input: [1]},
                {name: 'Not Equals To', value: 'not_equals_to', input: [1]},
                {name: 'Between', value: 'between', input: [1, 2]},
                {name: 'Not Between', value: 'not_between', input: [1, 2]}
            ]
        },
        {
            name: 'Text',
            value: 'Text',
            conditions: [
                {name: 'Contains', value: 'contains', input: [1]},
                {name: "Doesn't Contains", value: 'doest_contain', input: [1]},
                {name: 'Email', value: 'email', input: []},
                {name: 'URL', value: 'url', input: []}
            ]
        },
        {
            name: 'Length',
            value: 'Length',
            conditions: [
                {name: 'Max. Character Count', value: 'max_character_count', input: [1]},
                {name: "Min. Character Count", value: 'min_character_count', input: [1]}
            ]
        },
        {
            name: 'Regular Expression',
            value: 'regular_expression',
            conditions: [
                {name: 'Contains', value: 'contains', input: [1]},
                {name: "Doesn't Contains", value: 'doest_contain', input: [1]},
                {name: 'Matches', value: 'matches', input: [1]},
                {name: "Doesn't Match", value: 'doesnt_match', input: [1]}
            ]
        },
    ];


    // initial APIS call
    const getContentMetrics = async () => {
        try {
            const {data: {data}} = await API_SERVICE.getContentMetrics();
            setContentMetrics(data.items);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };

    const getMetrics = async () => {
        try {
            const {data: {data}} = await API_SERVICE.getSurveyMetricsById(surveyId);
            setMetrics(data.items);
            setActiveMetricsId(data.items[0].id);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };

    const getSurveyDetails = async () => {
        try {
            const {data: {data}} = await API_SERVICE.getSurveyDetails(surveyId);
            setSurveyDetails(data);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };

    const getUnitCategories = async () => {
        let params = {year: surveyDetails.year, page: -1};
        try {
            const {data: {data}} = await API_SERVICE.getUnitCategories(params);
            setUnitCategories(data);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };

    const searchMasterQuestion = (value: any) => {
        //@ts-ignore
        paramMasterQuestion.q = value;
        getQuestionsMaster(paramMasterQuestion);
    }

    // master question list on right panel
    const getQuestionsMaster = async (params?: any) => {
        try {
            setFetchingMasterQuestion(true);
            const {data: {data}} = await API_SERVICE.getMasterQuestions(params);
            setMasterQuestionList(data.items);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        } finally {
            setFetchingMasterQuestion(false);
        }
    };

    const deleteMasterQuestion = async (questionId: number) => {
        try {
            await API_SERVICE.deleteMasterQuestion(surveyId, questionId);
            notification.success({message: 'Question deleted successfully'});
            getQuestionsMaster();
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    }

    const filterQuestionMaster = (value: any) => {
        let avail = questionType.indexOf(value);
        avail === -1 ? questionType.push(value) : questionType.splice(avail, 1);

        //@ts-ignore
        paramMasterQuestion.type = questionType;
        getQuestionsMaster(paramMasterQuestion);
    }

    const getDataByMetrics = async (keepCurrentPage: boolean = false) => {
        if (activeMetricsId !== 0) {
            setFetchingMetricData(true);
            try {
                const {data: {data}} = await API_SERVICE.getSurveyQuestionsByMetricsId(surveyId, activeMetricsId);
                setMetricData(data);
                setNeedScopePages(false);
                if (!keepCurrentPage) {
                    data.pages.length > 0 && setActiveTab(data.pages[0].id + '');
                    data.pages.length > 0 && data.pages[0].sections.length > 0 && setActiveSection(data.pages[0].sections[0].id);
                }
            } catch (e) {
                notification.error({message: API_SERVICE.handleErrors(e)});
                setMetricData({pages: []});
            } finally {
                setFetchingMetricData(false);
            }
        }
    };

    const updateALlQuestionUUIDs = (data: any) => {
        let newMetricData = data;
        let questionUUIds: any = [];
        newMetricData.pages.forEach((page: any, pageIndex: number) => {
            page.sections.forEach((section: any, sectionIndex: number) => {
                let currentPageQuestionUUIds = section.questions.map((question: any, questionIndex: number) => {
                    return {
                        name: (pageIndex + 1) + '' + (sectionIndex + 1) + '' + (questionIndex + 1),
                        value: question.uuid
                    };
                })
                questionUUIds = [...questionUUIds, ...currentPageQuestionUUIds];
            })
        });
        setAllQuestionUUIDs(questionUUIds);
    }

    /*useEffect(() => {
        const {match: {params}} = props;
        setSurveyId(params.surveyId);
    }, []);*/

    useEffect(() => {
        surveyId && getContentMetrics();
        surveyId && getMetrics();
        surveyId && getQuestionsMaster();
        surveyId && getSurveyDetails();
    }, [surveyId]);

    useEffect(() => {
        activeMetricsId && getDataByMetrics();
    }, [activeMetricsId]);

    useEffect(() => {
        surveyDetails && surveyDetails.year && getUnitCategories();
    }, [surveyDetails]);

    useEffect(() => {
        metricData && updateALlQuestionUUIDs(metricData);
    }, [metricData])

    // Auto save feature
    /*useEffect(() => {
        if(click===false){
            const myTimeout = setTimeout(saveData, 5000);
        }
        if(click===true){
            function myStopFunction() {
                clearTimeout(myTimeout);
            }
        }
        saveData();
    }, []);

    const saveData = async () => {
        try {
            const {data: {data}} = await API_SERVICE.saveFormData();

        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };*/

    function handleSectionChange(key: any) {
        setActiveSection(key);
    }

    function handlePageChange(key: any) {
        setActiveTab(key + '');
        // to set current active section as page changes for question insertion
        metricData.pages.forEach((page: any) => {
            if (page.id + '' === key && page.sections && page.sections.length > 0) {
                setActiveSection(page.sections[0].id);
            }
        })
    }

    const handleMetricsClick = (index: number, metricId: number) => {
        setActiveTab('0');
        setActiveMetricsIndex(index);
        setActiveMetricsId(metricId);
    }

    //adding/deleting a page
    const handleAddPageClick = () => {
        //@ts-ignore
        /*if(metricData.name.toLowerCase() != 'background') {
            setAddingPage(true);
        }else{
            notification.error({message: 'Can not add page to current metrics'});
        }*/

        //@ts-ignore
        if (metricData.isModifiable) {
            setAddingPage(true);
        } else {
            notification.error({message: 'Can not add page to current metrics'});
        }
    }
    const addPage = (name: string) => {
        const newPageDummyData = {
            "id": 'static_' + Math.random(),
            "name": name,
            "instruction": null,
            // "surveyMetricMapId":161,
            "isModifiable": true,
            "sections": [],
            "surveyMetricMap": {},
            orderNo: 1
        }

        let newMetricData = metricData;
        const currentPageIndex = newMetricData.pages.findIndex((page: any) => {
            return page.id + '' === activeTab;
        });

        for (let i = currentPageIndex + 1; i < newMetricData.pages.length; i++) {
            newMetricData.pages[i].orderNo = i + 2;
        }

        if (currentPageIndex >= 0) {
            newPageDummyData.orderNo = currentPageIndex + 2;
            metricData.pages.splice(currentPageIndex + 1, 0, newPageDummyData);
        } else {
            metricData.pages.push(newPageDummyData);
        }
        setMetricData(JSON.parse(JSON.stringify(newMetricData)));
    }

    //check for a new static page or already existing page
    const deletePageFromAPI = async (pageId: any) => {
        if ((pageId + '').startsWith('static_')) {
            return true;
        } else {
            try {
                await API_SERVICE.deleteSurveyPage(pageId);
                return true;
            } catch (e) {
                notification.error({message: API_SERVICE.handleErrors(e)});
                return false;
            }
        }
    }

    const deletePage = async (pageId: any) => {
        const isDeleted = await deletePageFromAPI(pageId);
        if (isDeleted) {
            /*let newMetricData = metricData;
            const currentPageIndex = newMetricData.pages.findIndex((page: any) => {
                return page.id == activeTab;
            });

            if(currentPageIndex >= 0){
                metricData.pages.splice(currentPageIndex, 1);
            }

            newMetricData.pages.length > 0 ? setActiveTab(newMetricData.pages[0].id+'') : setActiveTab(undefined);
            // newMetricData.pages.length > 0 && newMetricData.pages[0].sections.length > 0 ? setActiveSection(newMetricData.pages[0].sections[0].id) : setActiveSection(undefined);
            setActiveSection(undefined);
            setMetricData(JSON.parse(JSON.stringify(newMetricData)));*/
            getDataByMetrics();
        }
    }

    //adding/deleting a section
    const handleAddSectionClick = () => {
        const currentPage = metricData.pages.find((page: any) => {
            return page.id + '' === activeTab;
        })
        if (currentPage?.isModifiable) {
            setAddingSection(true);
        } else {
            notification.error({message: 'Can not add section to current page'});
        }
    }
    const addSection = (name: string) => {
        const newId = 'static_' + Math.random();
        const newSectionDummyData = {
            "id": newId,
            "name": name,
            "questions": []
        }

        setActiveSection(newId);

        let newMetricData = metricData;
        newMetricData.pages.forEach((page: any) => {
            if (page.id + '' === activeTab) {
                const currentSectionIndex = page.sections.findIndex((section: any) => {
                    return section.id === activeSection;
                });

                if (currentSectionIndex >= 0) {
                    page.sections.splice(currentSectionIndex + 1, 0, newSectionDummyData);
                } else {
                    page.sections.push(newSectionDummyData);
                }
            }
        })
        setMetricData(JSON.parse(JSON.stringify(newMetricData)));
    }

    //check for a new static section or already existing section
    const deleteSectionFromAPI = async (sectionId: any) => {
        if ((sectionId + '').startsWith('static_')) {
            return true;
        } else {
            try {
                await API_SERVICE.deleteSurveySection(sectionId);
                return true;
            } catch (e) {
                notification.error({message: API_SERVICE.handleErrors(e)});
                return false;
            }
        }
    }

    const deleteSection = async (sectionId: any) => {
        const isDeleted = await deleteSectionFromAPI(sectionId);
        if (isDeleted) {
            let newMetricData = metricData;
            newMetricData.pages.forEach((page: any) => {
                if (page.id + '' === activeTab) {
                    const currentSectionIndex = page.sections.findIndex((section: any) => {
                        return section.id === sectionId;
                    });

                    if (currentSectionIndex >= 0) {
                        page.sections.splice(currentSectionIndex, 1);
                    }
                }
            })
            if (sectionId + '' === activeSection) {
                setActiveSection(undefined);
            }
            setMetricData(JSON.parse(JSON.stringify(newMetricData)));
        }
    }

    //adding a master question in survey questions
    const handleMasterQuestionAdd = (masterQuestion: any) => {
        addQuestion(masterQuestion.type, masterQuestion);
    }


    //add/delete a question
    const addQuestion = (type: string, prefilledData?: any) => {
        const currentPage = metricData.pages.find((page: any) => {
            return page.id + '' === activeTab;
        })

        if (currentPage.isModifiable && activeSection) {
            const simpleQuestionDummyData = {
                "uuid": uuidv4(),
                "question": prefilledData ? prefilledData.question : '',
                "type": prefilledData ? prefilledData.type : "simple",
                "description": prefilledData ? prefilledData.description : '',
                "toolTipText": prefilledData ? prefilledData.toolTipText : '',
                "attachmentId": prefilledData ? prefilledData.attachmentId : null,
                "unitCategory": prefilledData ? prefilledData.unitCategory : undefined,
                "parentQuestionUuid": prefilledData ? prefilledData.parentQuestionUuid : null,
                "nextQuestionUuid": prefilledData ? prefilledData.nextQuestionUuid : null,
                "unitId": prefilledData ? prefilledData.unitId : null,
                "isAttachmentAllowed": prefilledData ? prefilledData.isAttachmentAllowed : false,
                "isValidationRequired": prefilledData ? prefilledData.isValidationRequired : false,
                "isRequired": prefilledData ? prefilledData.isRequired : false,
                "isMaster": prefilledData ? prefilledData.isMaster : false,
                "scope": prefilledData ? prefilledData.scope : null,
                "table": prefilledData ? prefilledData.table : null,
                "file": prefilledData ? prefilledData.file : null,
                "options": prefilledData ? prefilledData.options : [],
                "validations": prefilledData ? prefilledData.validations : [],
                "child": prefilledData ? prefilledData.child : null,
                "questionMetrics": [
                    {
                        "contentMetricId": null,
                        "contentMetricDataPointId": null
                    }
                ]
            }
            const singleSelectQuestionDummyData = {
                "uuid": uuidv4(),
                "question": prefilledData ? prefilledData.question : '',
                "type": prefilledData ? prefilledData.type : "single",
                "description": prefilledData ? prefilledData.description : '',
                "toolTipText": prefilledData ? prefilledData.toolTipText : '',
                "attachmentId": prefilledData ? prefilledData.attachmentId : null,
                "unitCategory": prefilledData ? prefilledData.unitCategory : undefined,
                "parentQuestionUuid": prefilledData ? prefilledData.parentQuestionUuid : null,
                "nextQuestionUuid": prefilledData ? prefilledData.nextQuestionUuid : null,
                "unitId": prefilledData ? prefilledData.unitId : null,
                "isAttachmentAllowed": prefilledData ? prefilledData.isAttachmentAllowed : false,
                "isValidationRequired": prefilledData ? prefilledData.isValidationRequired : false,
                "isRequired": prefilledData ? prefilledData.isRequired : false,
                "isMaster": prefilledData ? prefilledData.isMaster : false,
                "scope": prefilledData ? prefilledData.scope : null,
                "table": prefilledData ? prefilledData.table : null,
                "file": prefilledData ? prefilledData.file : null,
                "options": prefilledData ? prefilledData.options : [],
                "validations": prefilledData ? prefilledData.validations : [],
                "child": prefilledData ? prefilledData.child : null,
                "questionMetrics": [
                    {
                        "contentMetricId": null,
                        "contentMetricDataPointId": null
                    }
                ]
            }
            const multipleSelectQuestionDummyData = {
                "uuid": uuidv4(),
                "question": prefilledData ? prefilledData.question : '',
                "type": prefilledData ? prefilledData.type : "multiple",
                "description": prefilledData ? prefilledData.description : '',
                "toolTipText": prefilledData ? prefilledData.toolTipText : '',
                "attachmentId": prefilledData ? prefilledData.attachmentId : null,
                "unitCategory": prefilledData ? prefilledData.unitCategory : undefined,
                "parentQuestionUuid": prefilledData ? prefilledData.parentQuestionUuid : null,
                "nextQuestionUuid": prefilledData ? prefilledData.nextQuestionUuid : null,
                "unitId": prefilledData ? prefilledData.unitId : null,
                "isAttachmentAllowed": prefilledData ? prefilledData.isAttachmentAllowed : false,
                "isValidationRequired": prefilledData ? prefilledData.isValidationRequired : false,
                "isRequired": prefilledData ? prefilledData.isRequired : false,
                "isMaster": prefilledData ? prefilledData.isMaster : false,
                "scope": prefilledData ? prefilledData.scope : null,
                "table": prefilledData ? prefilledData.table : null,
                "file": prefilledData ? prefilledData.file : null,
                "options": prefilledData ? prefilledData.options : [],
                "validations": prefilledData ? prefilledData.validations : [],
                "child": prefilledData ? prefilledData.child : null,
                "questionMetrics": [
                    {
                        "contentMetricId": null,
                        "contentMetricDataPointId": null
                    }
                ]
            }
            const contentQuestionDummyData = {
                "uuid": uuidv4(),
                "question": prefilledData ? prefilledData.question : '',
                "type": prefilledData ? prefilledData.type : "content",
                "description": prefilledData ? prefilledData.description : '',
                "toolTipText": prefilledData ? prefilledData.toolTipText : '',
                "attachmentId": prefilledData ? prefilledData.attachmentId : null,
                "unitCategory": prefilledData ? prefilledData.unitCategory : "undefined",
                "parentQuestionUuid": prefilledData ? prefilledData.parentQuestionUuid : null,
                "nextQuestionUuid": prefilledData ? prefilledData.nextQuestionUuid : null,
                "unitId": prefilledData ? prefilledData.unitId : null,
                "isAttachmentAllowed": prefilledData ? prefilledData.isAttachmentAllowed : false,
                "isValidationRequired": prefilledData ? prefilledData.isValidationRequired : false,
                "isRequired": prefilledData ? prefilledData.isRequired : false,
                "isMaster": prefilledData ? prefilledData.isMaster : false,
                "scope": prefilledData ? prefilledData.scope : null,
                "table": prefilledData ? prefilledData.table : null,
                "file": prefilledData ? prefilledData.file : null,
                "options": prefilledData ? prefilledData.options : [],
                "validations": prefilledData ? prefilledData.validations : [],
                "child": prefilledData ? prefilledData.child : null,
            }
            const RTEQuestionDummyData = {
                "uuid": uuidv4(),
                "question": prefilledData ? prefilledData.question : '',
                "type": prefilledData ? prefilledData.type : "rich_text",
                "description": prefilledData ? prefilledData.description : '',
                "toolTipText": prefilledData ? prefilledData.toolTipText : '',
                "attachmentId": prefilledData ? prefilledData.attachmentId : null,
                "unitCategory": prefilledData ? prefilledData.unitCategory : undefined,
                "parentQuestionUuid": prefilledData ? prefilledData.parentQuestionUuid : null,
                "nextQuestionUuid": prefilledData ? prefilledData.nextQuestionUuid : null,
                "unitId": prefilledData ? prefilledData.unitId : null,
                "isAttachmentAllowed": prefilledData ? prefilledData.isAttachmentAllowed : false,
                "isValidationRequired": prefilledData ? prefilledData.isValidationRequired : false,
                "isRequired": prefilledData ? prefilledData.isRequired : false,
                "isMaster": prefilledData ? prefilledData.isMaster : false,
                "scope": prefilledData ? prefilledData.scope : null,
                "table": prefilledData ? prefilledData.table : null,
                "file": prefilledData ? prefilledData.file : null,
                "options": prefilledData ? prefilledData.options : [],
                "validations": prefilledData ? prefilledData.validations : [],
                "child": prefilledData ? prefilledData.child : null,
                "questionMetrics": [
                    {
                        "contentMetricId": null,
                        "contentMetricDataPointId": null
                    }
                ]
            }

            let newMetricData = metricData;
            newMetricData.pages.forEach((page: any) => {
                if (page.id + '' === activeTab) {
                    page.sections.forEach((section: any) => {
                        if (section.id === activeSection) {
                            if (type === 'simple') {
                                section.questions.push(simpleQuestionDummyData);
                            } else if (type === 'single') {
                                section.questions.push(singleSelectQuestionDummyData);
                            } else if (type === 'multiple') {
                                section.questions.push(multipleSelectQuestionDummyData);
                            } else if (type === 'content') {
                                section.questions.push(contentQuestionDummyData);
                            } else if (type === 'rich_text') {
                                section.questions.push(RTEQuestionDummyData);
                            }
                            message.success("Question added");
                        }
                    })
                }
            })

            setMetricData(JSON.parse(JSON.stringify(newMetricData)));
            setTimeout(() => {
                addQuestionScroll()
            }, 300)
        } else {
            if (!currentPage.isModifiable) {
                notification.error({message: 'Can not add question to this page/section'});
            } else {
                notification.error({message: 'No section currently selected'});
            }
        }
    }
    const deleteQuestionFromAPI = async (questionId: any) => {
        if (!questionId) {
            return true;
        } else {
            try {
                await API_SERVICE.deleteQuestion(surveyId, questionId);
                return true;
            } catch (e) {
                notification.error({message: API_SERVICE.handleErrors(e)});
                return false;
            }
        }

    }

    const deleteQuestion = async (questionUuid: any, questionId: any) => {
        const currentPage = metricData.pages.find((page: any) => {
            return page.id + '' === activeTab;
        })

        if (currentPage.isModifiable && activeSection) {
            // window.setTimeout(() => {setAddingQuestion(false);}, 200)
            setAddingQuestion(true);
            const isDeleted = await deleteQuestionFromAPI(questionId);
            if (isDeleted) {
                let newMetricData = metricData;
                for (const page of newMetricData.pages) {
                    if (page.id + '' === activeTab) {
                        for (const section of page.sections) {
                            if (section.id === activeSection) {
                                const currentQuestionIndex = section.questions.findIndex((question: any) => {
                                    return question.uuid === questionUuid;
                                });
                                section.questions.splice(currentQuestionIndex, 1);
                                break;
                            }
                        }
                        break;
                    }
                }
                setMetricData(JSON.parse(JSON.stringify(newMetricData)));
            }
        } else {
            if (!currentPage.isModifiable) {
                notification.error({message: 'Can not delete question from this page'});
            } else {
                notification.error({message: 'No section currently selected'});
            }
        }
        setAddingQuestion(false);
    }

    //add/delete a option
    const addOption = (questionUuid: string) => {
        const newOptionData = {
            "id": 'static_' + Math.random(),
            "option": "",
            "score": null,
        }
        setAddingQuestion(true);
        let newMetricData = metricData;
        newMetricData.pages.forEach((page: any) => {
            if (page.id + '' === activeTab) {
                page.sections.forEach((section: any) => {
                    if (section.id === activeSection) {
                        section.questions.forEach((question: any) => {
                            if (question.uuid === questionUuid) {
                                question.options.push(newOptionData);
                            }
                        })
                    }
                })
            }
        });

        setMetricData(JSON.parse(JSON.stringify(newMetricData)));
        window.setTimeout(() => {
            setAddingQuestion(false)
        }, 200);
        window.setTimeout(() => {
            optionAddDeleteScroll(questionUuid)
        }, 400);
    }
    const deleteOption = (questionUuid: string, optionId: number) => {
        const currentPage = metricData.pages.find((page: any) => {
            return page.id + '' === activeTab;
        })
        setAddingQuestion(true);

        if (currentPage.isModifiable && activeSection) {
            let newMetricData = metricData;
            newMetricData.pages.forEach((page: any) => {
                if (page.id + '' === activeTab) {
                    page.sections.forEach((section: any) => {
                        if (section.id === activeSection) {
                            section.questions.forEach((question: any) => {
                                if (question.uuid === questionUuid) {
                                    const currentOptionIndex = question.options.findIndex((option: any) => {
                                        return option.id === optionId;
                                    });
                                    question.options.splice(currentOptionIndex, 1);
                                }
                            });
                        }
                    })
                }
            })
            setMetricData(JSON.parse(JSON.stringify(newMetricData)));
            window.setTimeout(() => {
                setAddingQuestion(false);
            }, 200);
            window.setTimeout(() => {
                optionAddDeleteScroll(questionUuid)
            }, 400);

        } else {
            if (!currentPage.isModifiable) {
                notification.error({message: 'Can not delete option from this page'});
            } else {
                notification.error({message: 'No section currently selected'});
            }
        }
    }

    //add/delete a questionMetric
    const addQuestionMetric = (questionUuid: string) => {
        const newQuestionMetricData = {
            id: 'static_' + Math.random(),
            contentMetricId: null,
            contentMetricDataPointId: null
        }
        setAddingQuestion(true);
        let newMetricData = {...metricData};

        let question = newMetricData.pages.find((page: QuestionPageInterface) => {
            return (page.id + '' === activeTab);
        }).sections.find((section: QuestionSectionInterface) => {
            return (section.id === activeSection);
        }).questions.find((question: QuestionInterface) => {
            return (question.uuid === questionUuid);
        });

        question.questionMetrics.push(newQuestionMetricData);

        setMetricData(newMetricData);

        window.setTimeout(() => {
            setAddingQuestion(false)
        }, 200);
        window.setTimeout(() => {
            optionAddDeleteScroll(questionUuid)
        }, 400);
    }

    const deleteQuestionMetric = (questionUuid: string, questionMetricIndex: number) => {
        const currentPage = metricData.pages.find((page: QuestionPageInterface) => {
            return page.id + '' === activeTab;
        })
        setAddingQuestion(true);

        if (currentPage.isModifiable && activeSection) {
            let newMetricData = {...metricData};

            let questionMetrics = newMetricData.pages.find((page: QuestionPageInterface) => {
                return (page.id + '' === activeTab);
            }).sections.find((section: QuestionSectionInterface) => {
                return (section.id === activeSection);
            }).questions.find((question: QuestionInterface) => {
                return (question.uuid === questionUuid);
            }).questionMetrics;

            questionMetrics.splice(questionMetricIndex, 1);

            setMetricData(newMetricData);

            window.setTimeout(() => {
                setAddingQuestion(false);
            }, 200);
            window.setTimeout(() => {
                optionAddDeleteScroll(questionUuid)
            }, 400);
        } else {
            if (!currentPage.isModifiable) {
                notification.error({message: 'Can not delete provides from this page'});
            } else {
                notification.error({message: 'No section currently selected'});
            }
        }
    }

    const optionAddDeleteScroll = (questionUuid: string | number) => {
        let currentQuestion: any;
        //@ts-ignore
        let ques = questionContainerRef.current.getElementsByClassName("question-wrapper");
        //@ts-ignore
        for (let item of ques) {
            if (item.id === questionUuid) {
                currentQuestion = item
            }
        }
        currentQuestion.scrollIntoView({
            behavior: 'smooth'
        })
    }

    const addQuestionScroll = () => {
        //@ts-ignore
        let ques = questionContainerRef.current.getElementsByClassName("question-wrapper");
        ques[ques.length - 1].scrollIntoView({
            behavior: 'smooth'
        });
    }

    const publishSurvey = async () => {
        const proceed = await Promise.resolve(saveAsDraft());
        if (!proceed) {
            return;
        }
        setSaving(true);
        API_SERVICE.publishSurveyByAdmin(surveyId).then((d) => {
            notification.success({message: 'Survey Published'});
            getDataByMetrics();
        }).catch((e) => {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }).finally(() => {
            setSaving(false);
        });
    }

    const saveAsDraft = async () => {
        const reqObj = formulateReqObj();

        let isSuccess = false;

        setSaving(true);
        try {
            await API_SERVICE.createSurveyQuestion(surveyId, reqObj).then((d) => {
                notification.success({message: 'Survey Updated'});
                isSuccess = true;
                getDataByMetrics(true);
                getQuestionsMaster();
            })
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
            isSuccess = false;
        } finally {
            setSaving(false);
            return isSuccess;
        }
    }

    //formulate reqObj
    const formulateReqObj = () => {
        const reqObj = {
            //@ts-ignore
            "metricId": metricData.id,
            "needPages": needScopePages,
            "pages": metricData.pages.map((page: any) => {
                return {
                    name: page.name,
                    orderNo: page.orderNo,
                    sections: page.sections.map((section: any) => {
                        return {
                            name: section.name,
                            questions: section.questions.map((question: any) => {
                                if (question.type === 'simple') {
                                    let reqObj: any = {
                                        "orderNo": question.orderNo,
                                        "uuid": question.uuid,
                                        "type": question.type,
                                        "question": question.question,
                                        "description": question.description,
                                        "toolTipText": question.toolTipText,
                                        "is_required": question.isRequired ? question.isRequired : false,
                                        "nextQuestionUuid": question.nextQuestionUuid,
                                        "unitCategory": question.unitCategory ? question.unitCategory : null,
                                        "isValidationRequired": question.isValidationRequired ? question.isValidationRequired : false,
                                        "isAttachmentAllowed": question.isAttachmentAllowed ? question.isAttachmentAllowed : false,
                                        'isMaster': question.isMaster ? question.isMaster : false,
                                        "validations": {
                                            "input1": (question.validations && question.validations.length > 0 && question.validations[0].input1) || (question.input1 && question.input1),
                                            "input2": (question.validations && question.validations.length > 0 && question.validations[0].input2) || (question.input2 ? question.input2 : null),
                                            "type": (question.validations && question.validations.length > 0 && question.validations[0].type) || (question.validationType),
                                            "condition": (question.validations && question.validations.length > 0 && question.validations[0].condition) || (question.validationCondition)
                                        },
                                        "questionMetrics": question.questionMetrics.map((questionMetric: QuestionContentMetricsInterface) => {
                                            return {
                                                "id": questionMetric.id,
                                                "contentMetricId": questionMetric.contentMetricId,
                                                "contentMetricDataPointId": questionMetric.contentMetricDataPointId,
                                            }
                                        })
                                    }

                                    if (question.id) {
                                        reqObj['id'] = question.id;
                                    }
                                    return reqObj;
                                } else if (question.type === 'single' || question.type === 'multiple') {
                                    let reqObj: any = {
                                        "orderNo": question.orderNo,
                                        "uuid": question.uuid,
                                        "type": question.type,
                                        "question": question.question,
                                        "description": question.description,
                                        "toolTipText": question.toolTipText,
                                        "is_required": question.isRequired,
                                        "nextQuestionUuid": question.nextQuestionUuid,
                                        "isAttachmentAllowed": question.isAttachmentAllowed ? question.isAttachmentAllowed : false,
                                        'isMaster': question.isMaster ? question.isMaster : false,
                                        "options": question.options.map((option: any) => {
                                            return {
                                                "id": option.id,
                                                "option": option.option,
                                                "score": option.score,
                                                "nextQuestionUuid": option.nextQuestionUuid
                                            }
                                        }),
                                        "questionMetrics": question.questionMetrics.map((questionMetric: QuestionContentMetricsInterface) => {
                                            return {
                                                "id": questionMetric.id,
                                                "contentMetricId": questionMetric.contentMetricId,
                                                "contentMetricDataPointId": questionMetric.contentMetricDataPointId,
                                            }
                                        })
                                    }

                                    if (question.id) {
                                        reqObj['id'] = question.id;
                                    }
                                    return reqObj;

                                } else if (question.type === 'scope1_table1_table1a' || question.type === 'scope1_table2' || question.type === 'scope1_table3' || question.type === 'scope2_table1' || question.type === 'scope3_table1') {
                                    let reqObj: any = {
                                        "orderNo": question.orderNo,
                                        "uuid": question.uuid,
                                        "type": question.type,
                                        "question": question.question,
                                        "description": question.description,
                                        "toolTipText": question.toolTipText,
                                        "is_required": question.isRequired,
                                        "nextQuestionUuid": question.nextQuestionUuid,
                                        "questionMetrics": question.questionMetrics.map((questionMetric: QuestionContentMetricsInterface) => {
                                            return {
                                                "id": questionMetric.id,
                                                "contentMetricId": questionMetric.contentMetricId,
                                                "contentMetricDataPointId": questionMetric.contentMetricDataPointId,
                                            }
                                        })
                                    }

                                    if (question.id) {
                                        reqObj['id'] = question.id;
                                    }
                                    return reqObj;
                                } else if(question.type === 'rich_text') {
                                    let reqObj: any = {
                                        "orderNo": question.orderNo,
                                        "uuid": question.uuid,
                                        "type": question.type,
                                        "question": question.question,
                                        "description": question.description,
                                        "toolTipText": question.toolTipText,
                                        "is_required": question.isRequired,
                                        "nextQuestionUuid": question.nextQuestionUuid,
                                        "isAttachmentAllowed": question.isAttachmentAllowed ? question.isAttachmentAllowed : false,
                                        'isMaster': question.isMaster ? question.isMaster : false,
                                        "questionMetrics": question.questionMetrics.map((questionMetric: QuestionContentMetricsInterface) => {
                                            return {
                                                "id": questionMetric.id,
                                                "contentMetricId": questionMetric.contentMetricId,
                                                "contentMetricDataPointId": questionMetric.contentMetricDataPointId,
                                            }
                                        })
                                    }

                                    if (question.id) {
                                        reqObj['id'] = question.id;
                                    }
                                    return reqObj;
                                } else {
                                    let reqObj: any = {
                                        "orderNo": question.orderNo,
                                        "uuid": question.uuid,
                                        "type": question.type,
                                        "question": question.question,
                                        "description": question.description,
                                        "toolTipText": question.toolTipText,
                                        "is_required": question.isRequired,
                                        "nextQuestionUuid": question.nextQuestionUuid,
                                        "isAttachmentAllowed": question.isAttachmentAllowed ? question.isAttachmentAllowed : false,
                                        'isMaster': question.isMaster ? question.isMaster : false
                                    }

                                    if (question.id) {
                                        reqObj['id'] = question.id;
                                    }
                                    return reqObj;
                                }
                            })
                        }
                    })
                }
            })
        }

        return JSON.parse(JSON.stringify(reqObj));
    } // formulate function ends

    const handleFormValueChange = (questionId: any, type: string, value: any) => {
        clearTimeout(valueChangeTimer);
        valueChangeTimer = setTimeout(() => {
            setFormValues(questionId, type, value)
        }, 1000);
    }

    const setFormValues = (questionId: any, type: string, value: any) => {
        let newMetricData = metricData;
        for (const page of newMetricData.pages) {
            if (page.id + '' === activeTab) {
                for (const section of page.sections) {
                    if (section.id === activeSection) {
                        for (const question of section.questions) {
                            if (question.uuid === questionId) {
                                question[type] = value;
                                break;
                            }
                        }
                        break;
                    }
                }
                break;
            }
        }

        setMetricData(JSON.parse(JSON.stringify(newMetricData)));
    }

    const handleOptionChange = (questionUuid: any, type: string, optionIndex: number, value: any) => {
        let newMetricData = metricData;
        for (const page of newMetricData.pages) {
            if (page.id + '' === activeTab) {
                for (const section of page.sections) {
                    if (section.id === activeSection) {
                        for (const question of section.questions) {
                            if (question.uuid === questionUuid) {
                                for (const [index, option] of question.options.entries()) {
                                    if (optionIndex === index) {
                                        option[type] = value;
                                        break;
                                    }
                                }
                                break;
                            }
                        }
                        break;
                    }
                }
                break;
            }
        }

        setMetricData(JSON.parse(JSON.stringify(newMetricData)));
    }

    const handleContentMetricChange = (questionUuid: string, type: string, questionMetricIndex: number, value: number, reset: boolean = false) => {
        let newMetricData = {...metricData};

        let questionMetric = newMetricData.pages.find((page: QuestionPageInterface) => {
            return (page.id + '' === activeTab);
        }).sections.find((section: QuestionSectionInterface) => {
            return (section.id === activeSection);
        }).questions.find((question: QuestionInterface) => {
            return (question.uuid === questionUuid);
        }).questionMetrics.find((_questionMetric: QuestionContentMetricsInterface, index: number) => {
            return (questionMetricIndex === index);
        });

        questionMetric[type] = value;
        reset && (questionMetric["contentMetricDataPointId"] = null);

        setMetricData(newMetricData);
    }

    const renderDeleteIconForSection = (isModifiable: boolean, sectionId: number | string) => {
        if (isModifiable) {
            return <Popconfirm title="Sure to delete section?"
                               onConfirm={() => deleteSection(sectionId)}><DeleteOutlined/></Popconfirm>
        }
        return '';
    }

    const cloneQuestion = (question: any, questionIndex: number) => {
        let clonedQuestion = {...JSON.parse(JSON.stringify(question))};
        clonedQuestion.uuid = uuidv4();
        clonedQuestion.id = '';

        if (clonedQuestion.options && clonedQuestion.options.length) {
            clonedQuestion.options.forEach((option: any) => {
                option.id = 'static_' + Math.random()
            })
        }

        setAddingQuestion(true);
        let newMetricData = metricData;
        for (const page of newMetricData.pages) {
            if (page.id + '' === activeTab) {
                for (const section of page.sections) {
                    if (section.id === activeSection) {
                        section.questions.splice(questionIndex + 1, 0, clonedQuestion);
                        break;
                    }
                }
                break;
            }
        }
        setMetricData(JSON.parse(JSON.stringify(newMetricData)));
        window.setTimeout(() => {
            setAddingQuestion(false);
        }, 200)
        window.setTimeout(() => {
            optionAddDeleteScroll(clonedQuestion.uuid)
        }, 400);
        message.success("Question Copied");
    }

    const content = (
        <div>
            {/*<p className="cursor-pointer"><TableOutlined /> Q:Master Based</p>
			<p className="cursor-pointer"><DatabaseOutlined /> Q:Rank Order</p>*/}
            <p className="cursor-pointer" onClick={() => {
                addQuestion('rich_text')
            }}><AlignLeftOutlined/> Q:Rich Text</p>
            <p className="cursor-pointer" onClick={() => {
                addQuestion('multiple')
            }}><BarsOutlined/> Q:Multiple Select</p>
            <p className="cursor-pointer" onClick={() => {
                addQuestion('single')
            }}><MenuOutlined/> Q:Single Select</p>
            <p className="cursor-pointer" onClick={() => {
                addQuestion('simple')
            }}><MenuOutlined/> Q:Simple Ans</p>
            <p className="cursor-pointer" onClick={() => {
                addQuestion('content')
            }}><MenuOutlined/> Content</p>
            <p className="cursor-pointer" onClick={handleAddSectionClick}><MenuOutlined/> Section</p>
            <p className="cursor-pointer" onClick={handleAddPageClick}><ProfileOutlined/> Page</p>
        </div>
    );

    const menu = (
        <Menu>
            <Menu.Item>
                <div><Checkbox onClick={() => filterQuestionMaster("rich_text")}>Rich Text</Checkbox></div>
            </Menu.Item>
            <Menu.Item>
                <div><Checkbox onClick={() => filterQuestionMaster("multiple")}>Multiple Select</Checkbox></div>
            </Menu.Item>
            <Menu.Item>
                <div><Checkbox onClick={() => filterQuestionMaster("single")}>Single Select</Checkbox></div>
            </Menu.Item>
            <Menu.Item>
                <div><Checkbox onClick={() => filterQuestionMaster("simple")}>Simple Select</Checkbox></div>
            </Menu.Item>
            <Menu.Item>
                <div><Checkbox onClick={() => filterQuestionMaster("content")}>Content</Checkbox></div>
            </Menu.Item>
        </Menu>
    );

    const [categories, setCategories] = useState<any[]>([]);
    const [subcategoryFilter, setSubcategoryFilter] = useState<any[]>([]);
    const getCategories = async () => {
        try {
            const {data: {data}} = await API_SERVICE.quesCategoryGet();
            setCategories(data.items);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };
    const subcategory_menu = (
        <TreeSelect
            style={{width: 240, backgroundColor: '#fff'}}
            treeData={[
                ...categories.filter((cat: any) => !!(cat.subCategories.length)).map(
                    (category: any, index: number) => {
                        const children: any[] = [
                            ...category.subCategories.map((sub: any, i: number) => {
                                return {
                                    key: sub.id,
                                    value: sub.id,
                                    title: sub.name,
                                }
                            })
                        ];
                        return {
                            key: `cat-${index}`,
                            value: `cat-${index}`,
                            title: category.name,
                            children,
                        }
                    }
                )
            ]}
            onChange={setSubcategoryFilter}
            treeCheckable={true}
            showCheckedStrategy={SHOW_CHILD}
            placeholder={<div><FilterFilled/> Subcategories</div>}
        />
    );
    useEffect(() => {
        getCategories();
    }, [])

    useEffect(() => {
        if (![AppConstants.roles.SUPER_ADMIN_ID, AppConstants.roles.ADMIN_ID].includes(roleId)) {
            history.push(`${DATA}/${surveyId}#read`);
        }
    }, [])

    return (
        <div className={'questionnaire-component-wrapper page-wrapper'}>
            {/*@ts-ignore*/}

            <AdminListLayout
                title={
                    metrics && metrics.length > 0 &&
                    <Row align={'middle'}>
                        <Col xs={24}>
                            <Steps size="small" labelPlacement="vertical" progressDot={false}
                                   current={activeMetricsIndex}>
                                {
                                    metrics.map((item: any, index: number) => {
                                        return <Step title={item.name} key={index} className="cursor-pointer"
                                                     onClick={() => {
                                                         handleMetricsClick(index, item.id)
                                                     }}/>
                                    })
                                }
                            </Steps>
                        </Col>
                    </Row>
                }
            >

                <Row>
                    <Col xs={18} className="questions-column-wrapper" id="questions-container"
                         ref={questionContainerRef as React.RefObject<HTMLDivElement>}>
                        <div className="p-2">
                            {
                                fetchingMetricData || saving || addingQuestion ?
                                    <div className="text-center"><Spin indicator={antIcon}/></div> :
                                    <Tabs
                                        defaultActiveKey={metricData && metricData.pages.length > 0 ? metricData.pages[0].id + '' : '0'}
                                        activeKey={activeTab} size={'large'}
                                        className="bg-white-tab"
                                        destroyInactiveTabPane={false} onChange={handlePageChange}>
                                        {
                                            metricData && metricData.pages && metricData.pages.length > 0 && metricData.pages.map((page: any, pageIndex: number) => {
                                                return <TabPane tab={page.name} key={page.id}
                                                                className="questionnaire-page">
                                                    <>
                                                        {
                                                            page.sections && page.sections.length > 0 &&
                                                            <Collapse defaultActiveKey={[page.sections[0].id + '']}
                                                                      onChange={handleSectionChange} accordion={true}>
                                                                {
                                                                    page.sections.map((section: any, sectionIndex: number) => {
                                                                        return <Panel header={section.name}
                                                                                      key={section.id}
                                                                                      extra={
                                                                                          <div onClick={event => {
                                                                                              event.stopPropagation()
                                                                                          }}>
                                                                                              {renderDeleteIconForSection(page.isModifiable, section.id)}
                                                                                          </div>
                                                                                      }>
                                                                            {
                                                                                section.questions && section.questions.length > 0 && section.questions.map((question: any, questionIndex: number) => {
                                                                                    if (question.type === 'simple')
                                                                                        return <SimpleQuestionCard
                                                                                            key={questionIndex}
                                                                                            questionIndex={questionIndex}
                                                                                            question={question}
                                                                                            unitCategories={unitCategories}
                                                                                            validationTypeAndCondition={validationTypeAndCondition}
                                                                                            isModifiable={page.isModifiable}
                                                                                            isGHGDefaultQuestion={page.ghgQuestionDefaultPage}
                                                                                            questionNumber={(pageIndex + 1) + '' + (sectionIndex + 1) + '' + (questionIndex + 1)}
                                                                                            deleteQuestion={deleteQuestion}
                                                                                            handleFormValueChange={handleFormValueChange}
                                                                                            allQuestionUUIDs={allQuestionUUIDs}
                                                                                            cloneQuestion={cloneQuestion}
                                                                                            contentMetrics={contentMetrics}
                                                                                            deleteQuestionMetric={deleteQuestionMetric}
                                                                                            addQuestionMetric={addQuestionMetric}
                                                                                            handleContentMetricChange={handleContentMetricChange}/>
                                                                                    else if (question.type === 'single')
                                                                                        return <SingleSelectQuestionCard
                                                                                            key={questionIndex}
                                                                                            questionIndex={questionIndex}
                                                                                            question={question}
                                                                                            unitCategories={unitCategories}
                                                                                            validationTypeAndCondition={validationTypeAndCondition}
                                                                                            isModifiable={page.isModifiable}
                                                                                            isGHGDefaultQuestion={page.ghgQuestionDefaultPage}
                                                                                            questionNumber={(pageIndex + 1) + '' + (sectionIndex + 1) + '' + (questionIndex + 1)}
                                                                                            addOption={addOption}
                                                                                            deleteQuestion={deleteQuestion}
                                                                                            deleteOption={deleteOption}
                                                                                            addingQuestion={addingQuestion}
                                                                                            handleFormValueChange={handleFormValueChange}
                                                                                            handleOptionChange={handleOptionChange}
                                                                                            allQuestionUUIDs={allQuestionUUIDs}
                                                                                            cloneQuestion={cloneQuestion}
                                                                                            contentMetrics={contentMetrics}
                                                                                            deleteQuestionMetric={deleteQuestionMetric}
                                                                                            addQuestionMetric={addQuestionMetric}
                                                                                            handleContentMetricChange={handleContentMetricChange}/>
                                                                                    else if (question.type === 'multiple')
                                                                                        return <MultipleSelectQuestionCard
                                                                                            key={questionIndex}
                                                                                            questionIndex={questionIndex}
                                                                                            question={question}
                                                                                            unitCategories={unitCategories}
                                                                                            validationTypeAndCondition={validationTypeAndCondition}
                                                                                            isModifiable={page.isModifiable}
                                                                                            isGHGDefaultQuestion={page.ghgQuestionDefaultPage}
                                                                                            questionNumber={(pageIndex + 1) + '' + (sectionIndex + 1) + '' + (questionIndex + 1)}
                                                                                            addOption={addOption}
                                                                                            deleteQuestion={deleteQuestion}
                                                                                            deleteOption={deleteOption}
                                                                                            handleFormValueChange={handleFormValueChange}
                                                                                            handleOptionChange={handleOptionChange}
                                                                                            allQuestionUUIDs={allQuestionUUIDs}
                                                                                            cloneQuestion={cloneQuestion}
                                                                                            contentMetrics={contentMetrics}
                                                                                            deleteQuestionMetric={deleteQuestionMetric}
                                                                                            addQuestionMetric={addQuestionMetric}
                                                                                            handleContentMetricChange={handleContentMetricChange}/>
                                                                                    else if (question.type === 'rich_text')
                                                                                        return <RTEQuestionCard
                                                                                            key={questionIndex}
                                                                                            questionIndex={questionIndex}
                                                                                            question={question}
                                                                                            unitCategories={unitCategories}
                                                                                            validationTypeAndCondition={validationTypeAndCondition}
                                                                                            isModifiable={page.isModifiable}
                                                                                            isGHGDefaultQuestion={page.ghgQuestionDefaultPage}
                                                                                            questionNumber={(pageIndex + 1) + '' + (sectionIndex + 1) + '' + (questionIndex + 1)}
                                                                                            deleteQuestion={deleteQuestion}
                                                                                            handleFormValueChange={handleFormValueChange}
                                                                                            allQuestionUUIDs={allQuestionUUIDs}
                                                                                            cloneQuestion={cloneQuestion}
                                                                                            contentMetrics={contentMetrics}
                                                                                            deleteQuestionMetric={deleteQuestionMetric}
                                                                                            addQuestionMetric={addQuestionMetric}
                                                                                            handleContentMetricChange={handleContentMetricChange}/>
                                                                                    else if (question.type === 'content')
                                                                                        return <ContentQuestionCard
                                                                                            key={questionIndex}
                                                                                            questionIndex={questionIndex}
                                                                                            question={question}
                                                                                            isModifiable={page.isModifiable}
                                                                                            isGHGDefaultQuestion={page.ghgQuestionDefaultPage}
                                                                                            questionNumber={(pageIndex + 1) + '' + (sectionIndex + 1) + '' + (questionIndex + 1)}
                                                                                            deleteQuestion={deleteQuestion}
                                                                                            handleFormValueChange={handleFormValueChange}
                                                                                            allQuestionUUIDs={allQuestionUUIDs}
                                                                                            cloneQuestion={cloneQuestion}/>
                                                                                    else if (question.type === 'location_dropdown')
                                                                                        return <LocationQuestionCard
                                                                                            key={questionIndex}
                                                                                            questionIndex={questionIndex}
                                                                                            question={question}
                                                                                            isModifiable={page.isModifiable}
                                                                                            isGHGDefaultQuestion={page.ghgQuestionDefaultPage}
                                                                                            questionNumber={(pageIndex + 1) + '' + (sectionIndex + 1) + '' + (questionIndex + 1)}
                                                                                            handleFormValueChange={handleFormValueChange}
                                                                                            allQuestionUUIDs={allQuestionUUIDs}
                                                                                            contentMetrics={contentMetrics}
                                                                                            deleteQuestionMetric={deleteQuestionMetric}
                                                                                            addQuestionMetric={addQuestionMetric}
                                                                                            handleContentMetricChange={handleContentMetricChange}/>
                                                                                    else if (question.type === 'scope1_table1_table1a' || question.type === 'scope1_table2' || question.type === 'scope1_table3' || question.type === 'scope2_table1' || question.type === 'scope3_table1')
                                                                                        return <ScopeQuestionCard
                                                                                            key={questionIndex}
                                                                                            questionIndex={questionIndex}
                                                                                            question={question}
                                                                                            isModifiable={page.isModifiable}
                                                                                            isGHGDefaultQuestion={page.ghgQuestionDefaultPage}
                                                                                            questionNumber={(pageIndex + 1) + '' + (sectionIndex + 1) + '' + (questionIndex + 1)}
                                                                                            deleteQuestion={deleteQuestion}
                                                                                            handleFormValueChange={handleFormValueChange}
                                                                                            allQuestionUUIDs={allQuestionUUIDs}
                                                                                            contentMetrics={contentMetrics}
                                                                                            deleteQuestionMetric={deleteQuestionMetric}
                                                                                            addQuestionMetric={addQuestionMetric}
                                                                                            handleContentMetricChange={handleContentMetricChange}/>
                                                                                    else
                                                                                        return <div>question type to be
                                                                                            created</div>
                                                                                })
                                                                            }
                                                                        </Panel>
                                                                    })
                                                                }
                                                            </Collapse>
                                                        }
                                                        <div className="text-right mt-1 pr-2">
                                                            {page.isModifiable &&
                                                            <Popconfirm title="Sure to delete page?" onConfirm={() => {
                                                                deletePage(page.id)
                                                            }}><DeleteOutlined/></Popconfirm>}
                                                        </div>
                                                    </>
                                                </TabPane>
                                            })

                                        }
                                    </Tabs>
                            }

                        </div>
                    </Col>
                    <Col xs={6}>
                        <div className="pr-3 questionnaire-right-panel">
                            <div className="question-master-wrapper">
                                <h2 className="mt-3 text-center">Questions Master</h2>
                                <Search placeholder="Search for a question here" className="mr-3 search" size="large"
                                        type="search" onSearch={(value: any) => {
                                    searchMasterQuestion(value)
                                }}/>
                                <Space size="middle" className="mt-1">
                                    {subcategory_menu}
                                    <Dropdown overlay={menu} trigger={['click']}>
                                        <div className="cursor-pointer ssize"><FilterFilled/>Type</div>
                                    </Dropdown>
                                </Space>
                                <div className="masterQuestion-list-wrapper">
                                    {
                                        fetchingMasterQuestion ?
                                            <div className="text-center"><Spin indicator={antIcon}/></div> :
                                            masterQuestions && masterQuestions.length > 0 && masterQuestions.map((item: any, index: number) => {
                                                if (
                                                    (subcategoryFilter.length && item.subCategories) &&
                                                    !(item.subCategories.filter((a: any) => subcategoryFilter.includes(a.subCategory.id)).length)
                                                ) {
                                                    return [];
                                                }
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div className="ques-master">
												<span className="d-flex">
													<span>Q:</span><span
                                                    dangerouslySetInnerHTML={{__html: item.question}}></span>
												</span>
                                                            <p className="mb-0 pr-4">Type:{item.type}</p>
                                                            <div className="d-flex justify-content-end align-items-center">
													<span>
														<Popconfirm title="Sure to delete question?"
                                                                    onConfirm={() => deleteMasterQuestion(item.id)}><DeleteOutlined/></Popconfirm>
													</span>
                                                                <span className="cursor-pointer question-icon"
                                                                      onClick={() => {
                                                                          handleMasterQuestionAdd(item)
                                                                      }}><PlusCircleFilled/></span>
                                                            </div>

                                                        </div>
                                                    </React.Fragment>
                                                );
                                            })
                                    }
                                </div>
                            </div>


                            <div className="questionnaire-action-wrapper pt-1 mt-1">
                                <Space>
                                    <Popover content={content} placement="top">
                                        <span className="questionnaire-action-btn icon-plus"><PlusOutlined/></span>
                                    </Popover>
                                    <span className="questionnaire-action-btn icon-save" onClick={saveAsDraft}><Tooltip
                                        placement="top" title={tooltipdata}><SaveOutlined/></Tooltip></span>
                                    <span className="questionnaire-action-btn icon-publish"
                                          onClick={publishSurvey}><SendOutlined/></span>
                                </Space>
                            </div>
                        </div>
                    </Col>
                </Row>


                {/*modal for adding page*/}
                <Modal title={<div>Add New Page</div>}
                       open={addingPage}
                       maskClosable={false}
                       cancelText="Cancel"
                       okText="+ Add"
                       okType="primary"
                       cancelButtonProps={{ghost: true}}
                       onOk={() => {
                           form.submit();
                       }}
                       destroyOnClose={true}
                       afterClose={() => {
                           form.resetFields();
                           setNewPageName('');
                       }}
                    /*footer={
                        <>
                            <Button onClick={()=> {setAddingPage(false)}} className="ant-btn ant-btn-default ant-btn-background-ghost"><span>Cancel</span></Button>
                            <Button className="ant-btn ant-btn-primary" onClick={() => {form.submit();}} ><span>+ Add</span></Button>
                        </>
                    }*/
                       onCancel={() => {
                           setAddingPage(false)
                       }}>
                    <Form
                        form={form}
                        fields={[]}
                        onFinish={(value) => {
                            setAddingPage(false);
                            addPage(value.name);
                        }}
                        layout='horizontal'
                        requiredMark={true}
                    >
                        <Form.Item
                            label={'Page Name:'}
                            name={'name'}
                            rules={[
                                {required: true, message: 'Page Name required'},
                            ]}
                        >
                            <Input autoFocus={true} onChange={(event) => {
                                setNewPageName(event.target.value)
                            }}/>
                        </Form.Item>

                        {
                            //@ts-ignore
                            metricData && metricData.name && metricData.name.toLowerCase().trim() === 'environmental' && newPageName && newPageName.toLowerCase().trim() === 'summary' &&
                            <Form.Item>
                                <Checkbox checked={needScopePages} onChange={(e: any) => {
                                    setNeedScopePages(e.target.checked);
                                }}>Need GHG Emission Questions?</Checkbox>
                            </Form.Item>
                        }
                    </Form>
                </Modal>

                {/*modal for adding section*/}
                <Modal title={<div>Add New Section</div>}
                       open={addingSection}
                       maskClosable={false}
                       cancelText="Cancel"
                       okText="+ Add"
                       okType="primary"
                       cancelButtonProps={{ghost: true}}
                       destroyOnClose={true}
                       afterClose={() => {
                           form.resetFields();
                       }}
                       onOk={() => {
                           form.submit();
                       }}
                       onCancel={() => {
                           setAddingSection(false)
                       }}>
                    <Form
                        form={form}
                        fields={[]}
                        onFinish={(value) => {
                            addSection(value.name);
                            setAddingSection(false);
                        }}
                        layout='horizontal'
                        requiredMark={true}
                    >
                        <Form.Item
                            label={'Section Name:'}
                            name={'name'}
                            rules={[
                                {required: true, message: 'Section Name required'},
                            ]}
                        >
                            <Input autoFocus={true}/>
                        </Form.Item>
                    </Form>
                </Modal>
            </AdminListLayout>
        </div>
    );
}
