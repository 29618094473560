import React, {useEffect, useState} from 'react';
import ReactQuill from "react-quill";
import {Checkbox, Divider, Form, Input, Popconfirm, Select, Space} from "antd";
import {CopyOutlined, DeleteOutlined, PlusSquareFilled} from "@ant-design/icons";
import AddQuestionMetricComponent from "./AddQuestionMetricComponent";

const {Option} = Select;

export default function MultipleSelectQuestionCard({question, isModifiable, isGHGDefaultQuestion, questionNumber, addOption, deleteQuestion, deleteOption, handleFormValueChange, handleOptionChange, allQuestionUUIDs, cloneQuestion, questionIndex, contentMetrics, deleteQuestionMetric, addQuestionMetric, handleContentMetricChange}: any) {
    const [form] = Form.useForm();
    const [isAttachmentAllowed, setIsAttachmentAllowed] = useState(false as boolean);
    const [isRequired, setIsRequired] = useState(false as boolean);
    const [isMaster, setIsMaster] = useState(false as boolean);
    const [questionTitle, setQuestionTitle] = useState('' as string);

    useEffect(() => {
        setIsAttachmentAllowed(question.isAttachmentAllowed);
        setIsRequired(question.isRequired);
        setIsMaster(question.isMaster);
        setQuestionTitle(question.question);
    }, []);

    return (
        <Form form={form} className="question-wrapper" initialValues={question} id={question.uuid}>
            <div className="d-flex align-items-center justify-content-between">
                <h4 className="quesid">#ques-id-{questionNumber} - multiple</h4>
                <div>
                    <div className='d-flex'>
                        <span className='text-primary mr-1'>*</span><Form.Item name="orderNo"><Input placeholder="Question order no" disabled={isGHGDefaultQuestion ? false : !isModifiable}  onChange={(event) => {handleFormValueChange(question.uuid, 'orderNo', event.target.value)}} /></Form.Item>
                    </div>
                </div>
            </div>
            <span className='text-primary'>*</span>
            <div className="quill-container">
                <ReactQuill className={`survey-quill ${(isGHGDefaultQuestion ? '' : !isModifiable && 'disabled')}`} theme="snow" placeholder="type question title" value={questionTitle}
                            onChange={(value) => {setQuestionTitle(value); handleFormValueChange(question.uuid, 'question', value);}}
                            readOnly={isGHGDefaultQuestion ? false : !isModifiable}
                            modules={{
                                toolbar: {
                                    container: [
                                        [{'header': [1, 2, 3, 4, 5, 6, false]}],
                                        ['bold', 'italic', 'underline'],
                                        [{'list': 'ordered'}, {'list': 'bullet'}],
                                        [{'align': []}],
                                        ['link', 'image']
                                    ]
                                }
                            }}
                />
            </div>

            <Form.Item className="mt-1" name='description'>
                <Input placeholder="type question description" disabled={isGHGDefaultQuestion ? false : !isModifiable} onChange={(event) => {handleFormValueChange(question.uuid, 'description', event.target.value)}} />
            </Form.Item>
            <Form.Item name='toolTipText'>
                <Input placeholder="type question hint/tooltip text" disabled={isGHGDefaultQuestion ? false : !isModifiable} onChange={(event) => {handleFormValueChange(question.uuid, 'toolTipText', event.target.value)}} />
            </Form.Item>

            <div>
                <p className="mb-1">Options:</p>
                {
                    question.options && question.options.length > 0 && question.options.map((option: any, optionIndex: number) => {
                        return <div className="d-flex align-items-center justify-content-between" key={optionIndex}>
                            <Space size={15}>
                                <Form.Item name={`option_${optionIndex}`} initialValue={option.option}>
                                    <Input disabled={isGHGDefaultQuestion ? false : !isModifiable} placeholder="Insert Text" onChange={(event) => {handleOptionChange(question.uuid, 'option', optionIndex, event.target.value)}} />
                                </Form.Item>
                                {/* <Form.Item name={`nextQuestionUuid_${optionIndex}`} initialValue={option.nextQuestionUuid}>
                                    <Select defaultValue="next question" disabled={isGHGDefaultQuestion ? false : !isModifiable} style={{minWidth: 150}} onChange={(value) => {handleOptionChange(question.uuid, 'nextQuestionUuid', optionIndex, value)}} >

                                        {
                                            allQuestionUUIDs && allQuestionUUIDs.length> 0 && allQuestionUUIDs.map((questionUUID: any, i: number) => {
                                                return <Option value={questionUUID.value} key={i}>{questionUUID.name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item> */}
                                <Form.Item name={`score_${optionIndex}`} initialValue={option.score}>
                                    <Input placeholder="score" disabled={isGHGDefaultQuestion ? false : !isModifiable} onChange={(event) => {handleOptionChange(question.uuid, 'score', optionIndex, event.target.value)}} />
                                </Form.Item>
                                {
                                    isModifiable &&
                                    <span className="d-inline-block mb-1">
                                        <Popconfirm title="Sure to delete option?" onConfirm={() => deleteOption(question.uuid, option.id)}><DeleteOutlined/></Popconfirm>
                                    </span>
                                }
                            </Space>
                        </div>
                    })
                }
                <span className={`${isModifiable ? 'cursor-pointer' : 'cursor-disabled'} add-new-option d-inline-block`}  onClick={()=> {addOption(question.uuid)}}><PlusSquareFilled /></span>
            </div>

            <div className="mt-3">
                <AddQuestionMetricComponent
                    key={`add${question.uuid}`}
                    question={question}
                    isModifiable={isModifiable}
                    contentMetrics={contentMetrics}
                    deleteQuestionMetric={deleteQuestionMetric}
                    handleContentMetricChange={handleContentMetricChange}
                    form={form}
                    addQuestionMetric={addQuestionMetric}
                />
            </div>

            <Divider className="mt-1 mb-3" />

            <div className="d-flex align-items-center justify-content-between">
                <Space size={15}>
                    <Form.Item name={'isAttachmentAllowed'}>
                        <Checkbox disabled={isGHGDefaultQuestion ? false : !isModifiable} value={true} checked={isAttachmentAllowed} onChange={(e: any)=> {setIsAttachmentAllowed(e.target.checked); handleFormValueChange(question.uuid, 'isAttachmentAllowed', e.target.checked);}}>Allow Attachment?</Checkbox>
                    </Form.Item>
                    <Form.Item name={'isRequired'}>
                        <Checkbox disabled={isGHGDefaultQuestion ? false : !isModifiable} value={true} checked={isRequired} onChange={(e: any)=> {setIsRequired(e.target.checked); handleFormValueChange(question.uuid, 'isRequired', e.target.checked);}}>Required</Checkbox>
                    </Form.Item>
                    <Form.Item name={'nextQuestionUuid'} className="mb-2">
                        <Select disabled={isGHGDefaultQuestion ? false : !isModifiable} placeholder="Next Question" onChange={(value) => {handleFormValueChange(question.uuid, 'nextQuestionUuid', value);}}>
                        <Option value={null}>None</Option>
                            {
                                allQuestionUUIDs && allQuestionUUIDs.length> 0 && allQuestionUUIDs.map((questionUUID: any, i: number) => {
                                    return <Option value={questionUUID.value} key={i}>{questionUUID.name}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Space>

                <Space size={15}>
                    <Form.Item name={'isMaster'}>
                        <Checkbox disabled={isGHGDefaultQuestion ? false : !isModifiable} value={true} checked={isMaster} onChange={(e: any)=> {setIsMaster(e.target.checked);  handleFormValueChange(question.uuid, 'isMaster', e.target.checked);}}>Add to master</Checkbox>
                    </Form.Item>
                    {
                        isModifiable &&
                        <>
                            <span className="cursor-pointer d-inline-block mb-2" onClick={()=> {cloneQuestion(question, questionIndex)}}><CopyOutlined/></span>
                            <span className="d-inline-block mb-2">
                                    <Popconfirm title="Sure to delete question?" onConfirm={() => deleteQuestion(question.uuid, question.id || null)}><DeleteOutlined /></Popconfirm>
                                </span>
                        </>
                    }
                </Space>
            </div>
        </Form>
    )
}
