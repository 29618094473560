import React from 'react';
import {Space, Typography} from 'antd';

const { Title } = Typography;


export default function Header(props: any) {
    return (
        <div style={{padding: 16}}>
          <Space>
            {
                (props.headings || []).map((title: string, i: number) => {
                  return (
                    <div key={i}>
                      <Title level={props.level || 5}>
                        {title}
                        {
                          (
                            (i+1 < props.headings.length) &&
                            (props.headings[i+1])
                          )? ' |': ''
                        }
                      </Title>
                    </div>
                  )
                })
            }
          </Space>
        </div>
    );
}
