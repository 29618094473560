import React, {useEffect, useState} from "react";
import {Button, notification, Pagination, Space, Table, Typography, Input} from "antd";
import AdminListLayout from "@recoinsights/shared/components/layout/AdminListLayout/AdminListLayout";
import API_SERVICE from "../../services/api-service";
import UserCreateOrEditPage from "./UserCreateOrEditPage";
import {ConfirmationModal} from "../../components";
import {PlusOutlined, SearchOutlined} from "@ant-design/icons";
import {DeleteOutlined, EditOutlined,} from "@material-ui/icons";
import eyeIcon from "@recoinsights/shared/assets/icons/Eye_Icon.svg";
import AccessControl, {accessIncludes} from "components/AccessControl";


const {Text} = Typography;
const {Search} = Input;
export default function UsersPage(props: any) {
    const [roles, setRoles] = useState([] as any[]);
    const [users, setUsers] = useState([] as any[]);
    const [search, setSearch] = useState("" as string);
    const [addNew, setAddNew] = useState(false);
    const [editUser, setEditUser] = useState(null as any);
    const [fetching, setFetching] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        perpage: "10",
    } as any);
    const [total, setTotal] = useState(10);
    const [confirmationModal, setConfirmationModal] = useState({} as any);
    const [filters, setFilters] = useState({} as any);
    const columns = [
        {
            title: "S.No",
            key: "sNO",
            width: 80,
            render: (a: any, v: any, index: number) => (
                <Text>{(pagination.page - 1) * pagination.perpage + index + 1}</Text>
            ),
        },
        {
            title: "Full Name",
            key: "name",
            width: 200,
            render: (a: any) => (
                <Space size={0}>
                    <Text>{a.name}</Text>
                </Space>
            ),
        },
        {
            title: "Email ID",
            key: "email",
            width: 200,
            render: (a: any) => (
                <Space direction={"vertical"} size={0}>
                    <Text>{a.email || "NA"}</Text>
                </Space>
            ),
        },
        {
            title: "Contact No.",
            key: "contactDetails",
            width: 200,
            render: (a: any) => (
                <Space direction={"vertical"} size={0}>
                    <Text>{a.phoneNumber || "NA"}</Text>
                </Space>
            ),
        },
        {
            title: "Role",
            dataIndex: "role",
            width: 200,
            filters: roles.map((role: any) => {
                return {text: role.name, value: role.id};
            }),
            render: (a: any) => <Text>{a.name ? a.name : "-"}</Text>,
        },
        {
            title: "Groups & Companies",
            key: "companies",
            width: 200,
            render: (a: any) => {
                const companies: any[] = [];

                (a.groups || []).map((group: any) => {
                    companies.push(group.name);
                    return true;
                });

                (a.companies || []).map((company: any) => {
                    companies.push(company.name);
                    return true
                });

        return (
          <div
            className="pr-5"
            style={{
              wordWrap: "break-word",
              wordBreak: "break-word",
              width: "200px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {a.companies && a.companies.length > 0 ? (
              <img
                src={eyeIcon}
                alt="icon"
                className="table-list-icon"
                title={companies.join(",\n")}
              />
            ) : (
              ""
            )}
            {a.companies && a.companies.length > 0
              ? companies.join(", ")
              : "N/A"}
          </div>
        );
      },
    },
    {
      title: "Is Active?",
      key: "isActive",
      width: 200,
      filters: [
        { text: "Active", value: true },
        { text: "InActive", value: false },
      ],
      render: (value: any) => (
        <Button
          className={value.isActive ? "active-button" : "inactive-button"}
          disabled={!accessIncludes([17])}
          onClick={() => {
            const reqObj = {
              isActive: !value.isActive,
            };
            setConfirmationModal({
              message: `Change status to ${
                value.isActive ? "InActive" : "Active"
              }`,
              action: API_SERVICE.userStatus,
              params: [value.id, reqObj],
            });
          }}
        >
          {value.isActive ? "Active" : "InActive"}
        </Button>
      ),
    },
    {
      title: "Actions",
      key: "action",
      width: 200,
      render: (value: any) => (
        <>
          {value.role?.id !== 1 && (
            <>
              {
                accessIncludes([14,15]) ?
                    <Space>
                      <AccessControl id={14}>
                        <Button
                            type="text"
                            icon={<EditOutlined />}
                            onClick={() => {
                              setEditUser(value);
                            }}
                        />
                      </AccessControl>
                      <AccessControl id={15}>
                        <Button
                            type="text"
                            icon={<DeleteOutlined />}
                            onClick={() => {
                              setConfirmationModal({
                                message: `Delete User?`,
                                action: API_SERVICE.userDelete,
                                params: [value.id],
                              });
                            }}
                        />
                      </AccessControl>
                    </Space>
                    :
                    <div style={{width: 100}}>-</div>
              }
            </>
          )}
        </>
      ),
    },
  ];

    const handleTableChange = (
        pagination: any,
        filterValues: any,
        sorter: any
    ) => {
        setFilters({...filters, ...filterValues, page: 1});
    };

    const fetchRoles = async () => {
        try {
            const {
                data: {data},
            } = await API_SERVICE.getRoles({perpage: 1000});
            setRoles(data.items);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };

    const refresh = async (page?: number, perPage?: number) => {
        const params = {...pagination, ...filters};
        if (search) {
            params["q"] = search;
            params['page'] = 1;
        }
        if (page) {
            params['page'] = page;
        }
        if (perPage) {
            params['perpage'] = perPage;
        }
        setFetching(true);
        try {
            const {
                data: {data},
            } = await API_SERVICE.users(params);
            setUsers(data.items);
            setTotal(data.total);
            setPagination({page: data.page, perpage: data.perpage});
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        refresh();
    }, [props.location, search, filters]);
    useEffect(() => {
        fetchRoles();
    }, []);

    return (
        <div className={"franchise-wrapper page-wrapper"}>
            {/*@ts-ignore*/}
            <AdminListLayout
                title={"Users"}
                titleSearch={
                    <AccessControl id={12}>
                        <div className="search-bar">
                            <Search
                                enterButton={<SearchOutlined/>}
                                placeholder="Search for Users here"
                                onSearch={(e) => {
                                    setSearch(e);
                                }}
                            />
                        </div>
                    </AccessControl>
                }
                titleAction={
                    <AccessControl id={13}>
                        <Button
                            type={"primary"}
                            className="theme-button primary"
                            onClick={() => setAddNew(true)}
                            icon={<PlusOutlined/>}
                        >
                            Add New
                        </Button>
                    </AccessControl>
                }
                pagination={
                    <AccessControl id={16}>
                        <Pagination
                            current={pagination.page} pageSize={pagination.perpage} pageSizeOptions={[10, 20, 50]}
                            showSizeChanger
                            showQuickJumper
                            total={total}
                            onChange={(page, size) => {
                                setPagination({page: page, perpage: size});
                                refresh(page, size);
                            }}
                        />
                    </AccessControl>
                }
            >
                {addNew && (
                    <UserCreateOrEditPage
                        onClose={(e: boolean) => {
                            if (e) {
                                refresh();
                            }
                            setAddNew(false);
                        }}
                    />
                )}
                {editUser && (
                    <UserCreateOrEditPage
                        details={editUser}
                        onClose={(e: boolean) => {
                            if (e) {
                                refresh();
                            }
                            setEditUser(null);
                        }}
                    />
                )}
                <AccessControl id={16}>
                    <Table
                        //@ts-ignore
                        rowClassName={(record) => !record.isActive && "disabled-row"}
                        loading={fetching}
                        scroll={{x: true, y: "calc(100vh - 280px)"}}
                        pagination={false}
                        dataSource={users}
                        columns={columns}
                        onChange={handleTableChange}
                    />
                </AccessControl>
            </AdminListLayout>

            <ConfirmationModal
                {...confirmationModal}
                callback={(a: boolean) => {
                    setConfirmationModal({});
                    if (a) {
                        refresh();
                    }
                }}
            />
        </div>
    );
}
