import React, {useEffect, useState} from 'react';
import {Button, notification} from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import API_SERVICE from '../../../services/api-service';
import AccessControl from 'components/AccessControl';

export default function Homepage(props: any) {
    const [value, setValue] = useState('');
    const [saving, setSaving] = useState(false);


    const handleUpdateContent = async () => {
        setSaving(true);
        try {
            await API_SERVICE.updateContent(props.companyId, {homepage : value});
            notification.success({ message: 'Content Updated'});
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        } finally {
            setSaving(false);
        }
    };



    useEffect(() => {
        props.data?.homepage ? setValue(props.data.homepage) : setValue('');
    }, [props])

    return (
        <>
            <ReactQuill theme="snow" value={value} onChange={setValue}/>
            <div className="text-right mt-2">
                <AccessControl id={56}>
                <Button
                    type='primary'
                    loading={saving}
                    className='theme-button primary'
                    onClick={handleUpdateContent}
                    disabled={!props.companyId}
                >
                    Save
                </Button>
                </AccessControl>
            </div>
        </>
    )
}
