import {Column} from "@ant-design/charts";
import {DataPoints, GenderDiversity} from "@recoinsights/metrics";
import React from "react";

enum Labels {
    Management = "Management",
    Board = "Board",
    Total = "Total",
}

interface Props {
    result: GenderDiversity,
}

function GenderDiversityByLevelChart({ result }: Props) {
    const data: { userType: string, value?: number, managementType: string }[] = [];

    const femaleBoardMembers = result.data[DataPoints.FemaleBoardMembers];
    const femaleManagementMembers = result.data[DataPoints.FemaleManagementMembers];
    const totalBoardMembers = result.data[DataPoints.TotalBoardMembers];
    const totalManagementMembers = result.data[DataPoints.TotalManagementMembers];

    data.push({
        userType: 'Male',
        value: totalManagementMembers ? totalManagementMembers - (femaleManagementMembers ?? 0) : undefined,
        managementType: Labels.Management,
    });

    data.push({
        userType: 'Male',
        value: totalBoardMembers ? totalBoardMembers - (femaleBoardMembers ?? 0) : undefined,
        managementType: Labels.Board,
    });

    data.push({
        userType: 'Female',
        value: femaleManagementMembers,
        managementType: Labels.Management,
    });
    data.push({
        userType: 'Female',
        value: femaleBoardMembers,
        managementType: Labels.Board,
    });

    const config = {
        data,
        xField: "managementType",
        yField: "value",
        isStack: true,
        seriesField: "userType",
        radius: 1,
        label: {
            offset: -15,
        },
        interactions: [
            {
                type: 'element-active',
            },
        ],
        legend: {
            position: 'bottom' as const
        },
        height: 200,
    };

    return (
        <Column {...config} />
    );
}

export default GenderDiversityByLevelChart;
