import {Button, notification} from "antd";
import React, {useState} from "react";
import apiService, {DownloadResultsRequest} from "../../services/api-service";
import API_SERVICE from "../../services/api-service";

interface Props {
    companyId?: number,
    groupId?: number,
    year?: number,
}

function ResultsDownloadButton({ companyId, groupId, year }: Props) {
    const [isLoading, setIsLoading] = useState(false);

    async function downloadResults() {
        setIsLoading(true);

        const params: DownloadResultsRequest = {};
        if (companyId) params.company_id = companyId;
        if (groupId) params.group_id = groupId;
        if (year) params.year = year;

        console.log(params);

        try {
            await apiService.downloadResults(params);
        } catch (error) {
            notification.error({message: API_SERVICE.handleErrors(error)})
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Button
            onClick={() => {
                downloadResults();
            }}
            loading={isLoading}
            type="ghost"
        >
            <i className={"bi bi-download mr-1"}></i>
            {"Download"}
        </Button>
    )
}

export default ResultsDownloadButton;
