import React, {useEffect, useState} from "react";
import {Col, notification, Row, Slider, Table, Tooltip, Typography} from "antd";
import type {SliderMarks} from 'antd/es/slider';
import "./index.scss";
import API_SERVICE from '../../../services/api-service';
import Header from '../../../components/Header';
import './index.scss';

const first = 0;

export default function EsgScores(props: any) {
  const { Text} = Typography;
  const {match: {params: { surveyId }}} = props;
  const [loading, setLoading] = useState(true);
  const [dataSource] = useState <any[]> ([
    {initiative: 'Test Initiative A', kpi:{ name: 'Happiness Index'} }
  ]);

  const sliderMarks: SliderMarks = {
    0: 'fund-bottom',
    50: 'fund-average',
    100: 'fund-top'
  };
  const columns: any[] = [

        {
          title: "Initiative",
          key: "initiative",
          width: 200,
          render: (obj: any) => {
              return (
                <Tooltip placement="top" title={(obj.initiative) || ' '}>
                  <div style={{overflow: 'hidden'}}>
                    <Text>{obj.initiative}</Text>
                  </div>
                </Tooltip>
              )
            }
        },
        {
          title: "KPI",
          key: "kpi",
          width: 200,
          render: (obj: any) => {
              return (
                <Tooltip placement="top" title={(obj.kpi && obj.kpi.name) || ' '}>
                  <div style={{overflow: 'hidden'}}>
                    <Text>{obj.kpi && obj.kpi.name}</Text>
                  </div>
                </Tooltip>
              )
            }
        },
        {
          title: "Company Performance",
          key: "performance",
          render: (obj: any) => {
              return (
                <div className="mx-4 px-4">
                  <Slider
                    marks={sliderMarks}
                    included={false}
                    defaultValue={37}
                    />
                </div>
              )
            }
        }

  ];


  const getCompanyPerformance = async () => {
      try {
          setLoading(true);
          //const {data: {data}} = await API_SERVICE.getEsgScores(fundId, fundYear);
          //setDataSource(data);
      } catch (e) {
          notification.error({message: API_SERVICE.handleErrors(e)});
      } finally {
        setLoading(false)
      }
  };

  useEffect(()=>{
    getCompanyPerformance()
  },[])

  return (
    <div className="page-wrapper Benchmarking-role-wrapper">
      <InputHeader
        surveyId={surveyId}
        />
      <Row justify="start">
        <Col className="p-2">
          <Table
            id="test-table"
            columns={columns}
            dataSource={dataSource}
            size="small"
            bordered={true}
            scroll={{ x: "calc(700px + 50%)", y: 500 }}
            pagination={false}
            loading={loading}
          />
        </Col>
      </Row>
    </div>
  );
}



const InputHeader = (props: any) => {
  const [surveyDetails, setSurveyDetails] = useState <any> ({});
  const [fundDetails, setFundDetails] = useState <any> ({});
  const [industry, setIndustry] = useState <any> ({});


  const getSurveyDetails = async () => {
      try {
          const {data: { data }} = await API_SERVICE.getDataCollection({id: props.surveyId});
          if (data.items.length) {
            setSurveyDetails(data.items[first]);
            if (data.items[first]['funds'].length) {
              setFundDetails(data.items[first]['funds'][first]);
            }
          }
      } catch (e) {
          notification.error({message: API_SERVICE.handleErrors(e)});
      }
  };

  const getIndustry = async () => {
    try {
      const {data: {data}} = await API_SERVICE.getIndustry(props.surveyId);
      if (data) {
        setIndustry(data)
      }
    } catch (e) {
      notification.error({message: API_SERVICE.handleErrors(e)});
    }
  };

  useEffect(()=>{
    getSurveyDetails();
    getIndustry();
  },[])

  return (
    <div>
      <Row justify="space-between" align="middle" className="p-2 bottomLine">
        <Col>
          <Header headings={[
              `Performance. vs. ${fundDetails.name}`,
              surveyDetails.company && surveyDetails.company.name,
              surveyDetails.year,
              industry.name
            ]} />
        </Col>
        <Col>
          <Row className="px-1" align="middle" gutter={10}>
            <Col>
              <Slider className="slider-label" trackStyle={{width: 0}} />
            </Col>
            <Col>- Company Performance</Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}
