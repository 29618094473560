import React, {useEffect, useState} from 'react';
import './index.scss';
import {Button, DatePicker, Form, Input, Modal, notification, Radio, Select, Space, Typography} from 'antd';
import {CloseOutlined, PlusOutlined} from '@ant-design/icons';
import API_SERVICE from 'services/api-service';
import moment from 'moment';

const {Text} = Typography;
const {Option} = Select;

export default function SurveyCreateOrEditPage({onClose, details, isCopy}: any) {
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);
    const [groups, setGroups] = useState([] as any[]);
    const [selectedGroup, setSelectedGroup] = useState(0);
    const [funds, setFunds] = useState([] as any[]);
    const [companies, setCompanies] = useState([] as any);
    const [yearList, setYearList] = useState([] as any);
    const maxYear = moment().year() + 11;
    const minYear = 2016;

    const YearOptions = () => {
        let years = []
        for (let i = minYear; i < maxYear; i++) {
            years.push(i);
        }
        setYearList(years);
    }

    const getGroups = async () => {
        try {
            const {data: {data}} = await API_SERVICE.getGroups();
            setGroups(data.items);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };
    const getFunds = async () => {
        try {
            const {data: {data}} = await API_SERVICE.getFunds({pef: [selectedGroup]});
            setFunds(data.items);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };
    const fetchCompanies = async () => {
        try {
            const {data: {data}} = await API_SERVICE.getCompanies({pef: [selectedGroup], page: -1});
            setCompanies(data.items);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };

    useEffect(() => {
        YearOptions();
        getGroups();
        if (details && details.pefId) {
            setSelectedGroup(details.pefId);
        }
    }, []);

    useEffect(() => {
        fetchCompanies();
        getFunds();
    }, [selectedGroup])

    return (
        <Modal title={
            (<Space>
                <Text>{details ? (isCopy ? 'Copy Survey' : 'Edit Survey') : 'Add New Survey '}</Text>
            </Space>)
        }
               width={550}
               footer={null}
               open={true}
               maskClosable={false}
               cancelText="Cancel"
               okText={details ? (isCopy ? 'Copy Survey' : 'Update') : "Add"}
               okType="primary"
               cancelButtonProps={{ghost: true}}
               onOk={
                   () => {
                       form.submit();
                   }
               }
               onCancel={onClose}>
            <Form
                form={form}
                initialValues={details || {}}
                fields={[]}
                labelCol={{
                    style: {
                        width: 200,
                        textAlign: 'left'
                    }
                }}
                onFinish={(value) => {
                    const reqObj = {...value};

                    if (reqObj.reportingPeriod) {
                        const {reportingPeriod} = reqObj;
                        reqObj['startDate'] = reportingPeriod[0];
                        reqObj['endDate'] = reportingPeriod[1];
                        delete reqObj.reportingPeriod;
                        delete reqObj.year;
                    }

                    if (reqObj.year) {
                        delete reqObj.reportingPeriod;
                    }

                    if (reqObj.companyStructure === 'isPrivate') {
                        reqObj['isPrivate'] = true;
                        reqObj['isPublic'] = false;
                    } else if (reqObj.companyStructure === 'isPublic') {
                        reqObj['isPrivate'] = false;
                        reqObj['isPublic'] = true;
                    }

                    if (reqObj.growthStage === 'isVenture') {
                        reqObj['isVenture'] = true;
                        reqObj['isGrowth'] = false;
                        reqObj['isPrivateEquity'] = false;
                    } else if (reqObj.growthStage === 'isGrowth') {
                        reqObj['isVenture'] = false;
                        reqObj['isGrowth'] = true;
                        reqObj['isPrivateEquity'] = false;
                    } else if (reqObj.growthStage === 'isPrivateEquity') {
                        reqObj['isVenture'] = false;
                        reqObj['isGrowth'] = false;
                        reqObj['isPrivateEquity'] = true;
                    }

                    reqObj.fundIds = [reqObj.fundIds];
                    reqObj['percentageOwnership'] = Number(reqObj['percentageOwnership']);
                    setSaving(true);
                    if (details && !isCopy) {
                        API_SERVICE.updateSurvey(details.id, reqObj).then((d) => {
                            notification.success({message: 'Survey Updated'});
                            onClose(true);
                        }).catch((e) => {
                            notification.error({message: API_SERVICE.handleErrors(e)});
                        }).finally(() => {
                            setSaving(false);
                        });
                    } else if (details && isCopy) {
                        delete reqObj.fundIds;
                        // delete reqObj.companyId;
                        delete reqObj.pefId;
                        API_SERVICE.copySurvey(details.id, reqObj).then((d) => {
                            notification.success({message: 'Survey Copied'});
                            onClose(true);
                        }).catch((e) => {
                            notification.error({message: API_SERVICE.handleErrors(e)});
                        }).finally(() => {
                            setSaving(false);
                        });

                    } else {
                        API_SERVICE.createSurvey(reqObj).then((d) => {
                            notification.success({message: 'Survey Created'});
                            onClose(true);
                        }).catch((e) => {
                            notification.error({message: API_SERVICE.handleErrors(e)});
                        }).finally(() => {
                            setSaving(false);
                        });

                    }
                }}
                layout='horizontal'
                requiredMark={true}
            >

                <Form.Item
                    label={'Data Year:'}
                    name={'year'}
                    rules={[
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!getFieldValue('reportingPeriod') && !value) {
                                    return Promise.reject(new Error('Please enter year or Reporting period'));
                                } else if (getFieldValue('reportingPeriod') && value) {
                                    return Promise.reject(new Error('Please enter only one field Year/Reporting period'));
                                }
                                return Promise.resolve();
                            },
                        }),
                    ]}
                >
                    <Select placeholder="Select Data year" disabled={!!details && !isCopy} allowClear={true}>
                        {
                            yearList.map((year: any) => {
                                return <Option value={year}>{year}</Option>
                            })
                        }
                    </Select>
                </Form.Item>

                <div className="text-center my-1">OR</div>

                <Form.Item
                    label={'Reporting Period:'}
                    name={'reportingPeriod'}
                    rules={[
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!getFieldValue('year') && !value) {
                                    return Promise.reject(new Error('Please enter year or Reporting period'));
                                } else if (getFieldValue('year') && value) {
                                    return Promise.reject(new Error('Please enter only one field Year/Reporting period'));
                                }
                                return Promise.resolve();
                            },
                        }),
                    ]}
                >
                    <DatePicker.RangePicker/>
                </Form.Item>

                <Form.Item
                    label={'Select Group:'}
                    name={'pefId'}
                    rules={[
                        {required: true, message: 'Group required'},
                    ]}
                >
                    <Select placeholder={'Group Name'} onSelect={(value: number) => {
                        setSelectedGroup(value)
                    }} disabled={!!details}>
                        {
                            groups.map((group: any) => {
                                return <Select.Option
                                    value={group.id}>{group.name}</Select.Option>;
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label={'Company:'}
                    name={'companyId'}
                    rules={[
                        {required: true, message: 'Company required'},
                    ]}
                >
                    <Select placeholder={'Company Name'} disabled={!!details && !isCopy}>
                        {
                            companies.map((company: any) => {
                                return <Select.Option
                                    value={company.id}>{company.name}</Select.Option>;
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label={'Display On Funds:'}
                    name={'fundIds'}
                    rules={[
                        {required: true, message: 'Fund required'},
                    ]}
                >
                    <Select placeholder={'Display On Funds'} disabled={!!details}>
                        {
                            funds.map((fund: any) => {
                                return <Select.Option
                                    value={fund.id}>{fund.name}</Select.Option>;
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label={'Freeze Date:'}
                    name={'freezeDate'}
                    rules={[
                        {required: true, message: 'Date required'},
                    ]}
                >
                    <DatePicker/>
                </Form.Item>

                <Form.Item
                    label={'Company Structure?'}
                    name={'companyStructure'}
                >
                    <Radio.Group>
                        <Radio value={'isPrivate'}>Private</Radio>
                        <Radio value={'isPublic'}>Public</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    label={'Growth Stage?'}
                    name={'growthStage'}
                >
                    <Radio.Group>
                        <Radio value={'isVenture'}>Venture</Radio>
                        <Radio value={'isGrowth'}>Growth</Radio>
                        <Radio value={'isPrivateEquity'}>Private Equity</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    label={'% Ownership of the Group?'}
                    name={'percentageOwnership'}
                    rules={[
                        {required: true, message: 'Percentage required'},
                    ]}
                >
                    <Input addonAfter={'%'}/>
                </Form.Item>

                <div className="text-right mt-3">
                    <Space>
                        <Button
                            type='primary'
                            ghost
                            htmlType='button'
                            icon={<CloseOutlined/>}
                            className='theme-button default full-width'
                            onClick={onClose}
                        >
                            Cancel
                        </Button>

                        <Button
                            type='primary'
                            htmlType='submit'
                            icon={!details && <PlusOutlined/>}
                            loading={saving}
                            className='theme-button primary full-width '
                        >
                            {details ? (isCopy ? 'Copy Survey' : 'Update') : 'Create'}
                        </Button>
                    </Space>
                </div>

            </Form>
        </Modal>
    );
}

const styles = {
    label: {
        width: 192,
        color: 'rgba(0, 0, 0, 0.65)'
    }
}
