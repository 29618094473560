import React from 'react';
import OTP from '@recoinsights/shared/view/otp/OTP';

interface Props {
    location: any;
}

export default function OTPPage(props: Props) {
    return (
        <OTP {...props}/>
    );
}
