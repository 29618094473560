import React, {useEffect, useState} from "react";
import {Button, Form, notification, Space, Typography,} from "antd";
import {DownloadOutlined, UploadOutlined} from "@ant-design/icons";
import API_SERVICE from "../../../services/api-service";
import GHGUpload from "./UploadGHGFile";
import moment from "moment";
import AccessControl from "../../../components/AccessControl";
const { Text} = Typography;


export default function SurveyGHG(props: any) {
    const [fetching, setFetching] = useState(false);
    const [GHGFileDetail, setGHGFileDetail] = useState({} as any);

    const downloadGHGExcel = async () => {
        setFetching(true);
        try {
            const {data: { data }} = await API_SERVICE.downloadGHGFile();
            if(data.url) window.location.href = data.url;
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }finally {
            setFetching(false)
        }
    }


    const getGHGFileDetails = async () => {
        try {
            const {data: { data }} = await API_SERVICE.getGHGFileDetails();
            setGHGFileDetail(data);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }finally {
            setFetching(false)
        }
    }

    useEffect(() => {
        getGHGFileDetails();
    }, [])

  return (
    <>
        <Space>
            <AccessControl id = {26}>
            <Button loading={fetching} type={'primary'} htmlType='button' className='theme-button primary' icon={<DownloadOutlined />} onClick={downloadGHGExcel}>
                Download Existing Excel
            </Button>
            </AccessControl>

            <AccessControl id = {25}>
            <Form>
                <Form.Item className="m-0" name="">
                    <GHGUpload type={'DOCUMENT'}>
                        <Button  type={'primary'} htmlType='button' className='theme-button primary' icon={<UploadOutlined />}>
                            Upload Fresh Table
                        </Button>
                    </GHGUpload>

                </Form.Item>
            </Form>
            </AccessControl>
        </Space>
        <div className="mt-3">
            <Text >Total { (GHGFileDetail && GHGFileDetail.totalRows) ? GHGFileDetail.totalRows : '0'} rows in the GHG Master Table - Last Upload { (GHGFileDetail && GHGFileDetail.lastUpdated) ? moment(GHGFileDetail.lastUpdated).format('DD-MMM-YYYY') : '-'}</Text>
        </div>

    </>
  );
}


 
  
