import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, notification, Popconfirm, Row, Select, Space, Table, Tooltip, Typography} from "antd";
import {MinusCircleOutlined} from "@ant-design/icons";
import "./index.scss";
import API_SERVICE from '../../../services/api-service';
import {getUser} from '../../../services/local-storage';
import Header from '../../../components/Header';
import ExportPPT from '../../../components/ExportPPT';

import FundsBenchMarkingForm from './FundsBenchMarkingForm';
import PptxGenJS from "pptxgenjs";

export default function FundsBenchMarkingCrud(props: any) {
  const { Text} = Typography;
  const {match: {params: { fundId }}} = props;
  const [fundYear, setFundYear] = useState <number> (2022);

  const [loading, setLoading] = useState(true);
  const [benchmarkData, setBenchmarkData] = useState <any> ({
    header: [],
    benchmarks: []
  });
  const [activeCell, setActiveCell] = useState <string>('0:0');
  const [benchmarkFormData, setBenchmarkFormData] = useState <any> ({});
  const [benchmarkTableData, setBenchmarkTableData] = useState <any> ({});
  const header_index = 0;

  const roleId = getUser()?.user?.role?.id;
  const [editMode] = useState([1,2].includes(roleId));

  const formData: any = {
    fundId: fundId,
    year: fundYear,
    industries: [],
    levels: [],
    kpis: [],
    mappings: {
      levels: [],
      kpis: []
    }
  }

  const tableData: any = {
    headers: [
      [
        { text: "Benchmarks", options: {colspan: 2} }
      ],
      [
        { text: "Industries", options: {} },
        { text: "Levels", options: {} }
      ]
    ],
    rows: [],
    visited: []
  }

  const industryHeaders: any[] = [
        {
          width: editMode? 60: 0,
          render: (obj: any, data: any, index: number) => (
            editMode?
              <Popconfirm title="Sure to delete?"
                onConfirm={async () => {
                  try {
                    setLoading(true);
                    await API_SERVICE.deleteIndustries([
                      benchmarkData.benchmarks[index]['id'],
                      benchmarkData.benchmarks[index+1]['id'],
                      benchmarkData.benchmarks[index+2]['id']
                    ]);
                    fetchBenchmarkData();
                  } catch (e) {
                      notification.error({message: API_SERVICE.handleErrors(e)});
                      setLoading(false);
                  }
                }}>
                <div className="text-primary cursor-pointer text-center">
                  <MinusCircleOutlined />
                </div>
              </Popconfirm>
              :<div />
          ),
          onCell: (_: any, index: number) => {
             if (index%3 === 0) {
               return { rowSpan: 3 };
             }
             return { rowSpan: 0 };
          }
        },
        {
          title: "Benchmarks",
          children: [
            {
              title: "Industries",
              key: "industry",
              className: "pt-0 pb-0 pl-2 pr-2",
              width: 120,
              render: (obj: any) => {
                if (!formData.industries.includes(obj.name)) {
                  formData.industries.push(obj.name);
                }
                const level = obj.levelName.titlecase();
                if (!formData.levels.includes(level)) {
                  formData.levels.push(level);
                  formData.mappings.levels.push({
                    id: obj.levelId,
                    name: obj.levelName
                  });
                }
                return (
                  <div style={{overflow: 'hidden'}}>
                    <Tooltip placement="top" title={obj.name}>
                      <Text>{obj.name}</Text>
                    </Tooltip>
                  </div>
                )
              }
            },
            {
              title: "Levels",
              key: "level",
              className: "pt-0 pb-0 pl-2 pr-2",
              width: 120,
              render: (obj: any) =>
                <Text>{obj.levelName.titlecase()}</Text>
            },
          ]
        }
  ];
  const getKpiHeaders = () => {
    let data_headers: any[] = [];
    if (benchmarkData.header.length) {
      const [s,g,e] = benchmarkData.header;
      data_headers = [e,s,g];
    }
    return data_headers.map((metric: any, i: number) => {
        if (metric['dkpis'].length) {
          tableData.headers[header_index].push(
            { text: metric.name, options: {colspan: metric['dkpis'].length} }
          );
        }
        return {
            title: metric.name,
            align: "center",
            children: metric['dkpis'].length?
              metric['dkpis'].map((kpi: any, index: number)=>{
                if (!formData.kpis.includes(kpi.name)) {
                  kpi['metric'] = {
                    id: metric.id,
                    name: metric.name
                  }
                  kpi['index'] = formData.kpis.length;
                  kpi['subindex'] = index;
                  formData.kpis.push(kpi.name);
                  formData.mappings.kpis.push(kpi);
                }
                return {
                    title: (
                      editMode?
                        <Popconfirm title="Sure to delete?"
                          onConfirm={async () => {
                              try {
                                setLoading(true);
                                await API_SERVICE.deleteDkpi(kpi.id);
                                fetchBenchmarkData();
                              } catch (e) {
                                  notification.error({message: API_SERVICE.handleErrors(e)});
                                  setLoading(false);
                              }
                          }}>
                          <div className="text-primary cursor-pointer text-center p-2">
                            <MinusCircleOutlined />
                          </div>
                        </Popconfirm>
                        :<div/>
                    ),
                    className: 'p-0',
                    children: [
                        {
                          title: (
                            <div className="p-2">
                              {kpi.name}<p className="unitClass">({kpi.description})</p>
                            </div>
                          ),
                          key: kpi.id,
                          className: "p-0",
                          width: 180,
                          render: (obj: any, data: any, data_index: number) => {
                              const cellId = `${data_index}:${kpi['index']}`;
                              const cellStyle: any ={
                                borderColor: '#3297FD',
                                borderWidth: (activeCell === cellId)? 2:0,
                                backgroundColor: data.levelColorCode,
                                overflow: 'hidden',
                                display: 'flex'
                              };
                              let value = ' ';
                              try {
                                const c = data[metric.name][index]['benchmark'];
                                if (!tableData.visited.includes(cellId)) {
                                  if (data_index > (tableData.rows.length-1)) {
                                    tableData.rows.push([
                                      { text: data.name, options: {} },
                                      { text: data.levelName.titlecase(), options: {} }
                                    ]);
                                  }
                                  tableData.rows[data_index].push(
                                    { text: value, options: {fill: data.levelColorCode} }
                                  )
                                  tableData.visited.push(cellId)
                                }
                                switch (c.type) {
                                  case 'VALUE':
                                    value = `${c.startValue}-${c.endValue} ${c.unitValue || ''}`;
                                    break;
                                  case 'EXACT_MATCH':
                                    value = `${c.string} ${c.unitValue || ''}`;
                                    break;
                                  default:
                                    value = ' '
                                    break;
                                }
                                const length = tableData.rows[data_index].length;
                                tableData.rows[data_index][length-1].text = value;
                              }
                              catch (e){
                              }
                              return (
                                <Button onClick={()=>setActiveCell(cellId)} style={cellStyle} ghost block>
                                  <Text className="p-8">
                                    {value}
                                  </Text>
                                </Button>
                              )
                            }
                        },
                    ],

                }
            })
            :
            [{
              title: '',
              children: [
                {
                  title: '',
                  className: "p-0",
                  render: (data: any) =>
                    <Button
                      style={{
                        border: 0,
                        backgroundColor: data.levelColorCode
                      }}
                      block>
                      {' '}
                    </Button>
                }
              ]
            }]

          }
      })
  }

  const fetchBenchmarkData = async () => {
      try {
          setLoading(true);
          const {data: {data}} = await API_SERVICE.getBenchmarkData(fundId, fundYear);
          setBenchmarkData(data);
      } catch (e) {
          notification.error({message: API_SERVICE.handleErrors(e)});
      } finally {
        setLoading(false)
      }
  };

  const export_table = async (page_size = 3, slide_options={}) => {
    const headers = [...benchmarkTableData.headers];
    const rows = [...benchmarkTableData.rows];
    benchmarkFormData.mappings.kpis.map((obj: any, i: number)=>{
      headers[header_index+1].push({ text: `${obj.name}\n(${obj.description})`, options: {} });
    });
    const pres = new PptxGenJS();
    let slide: any = pres.addSlide();
    let table: any[] = [];
    rows.map((r,i)=>{
      if (i!==0 && i%page_size===0) {
          slide.addTable(headers.concat(table), { ...slide_options });
          slide = pres.addSlide();
          table = [];
      }
      else if (i+1 === rows.length) {
          table.push(r)
          slide.addTable(headers.concat(table), { ...slide_options });
      }
      table.push(r)
    });
    pres.writeFile({ fileName: `benchmarks Id_${fundId} ${fundYear}.pptx` });
  }

  useEffect(()=>{
    formData['year'] = fundYear;
    fetchBenchmarkData();
  },[fundYear])

  useEffect(()=>{
    setBenchmarkFormData(formData);
    setBenchmarkTableData(tableData);
  },[benchmarkData])

  return (
    <div className="page-wrapper Benchmarking-role-wrapper">
      <InputHeader
        editable={editMode}
        year={fundYear}
        onYearChange={setFundYear}
        fundId={fundId}
        onUpdate={fetchBenchmarkData}
        />
      <Row justify="space-between" className="p-3">
        {
          editMode &&
            <Col sm={5} style={{minWidth: 280}}>
              <Space direction={"vertical"} align="center">
                <br/>
                <b>Add/Update Benchmarking Data:</b>
                <div className="pt-2" />
                <FundsBenchMarkingForm
                  cellId={activeCell}
                  onChange={setActiveCell}
                  benchmarkData={benchmarkData.benchmarks}
                  getIndustryId={()=>{
                    const [i] = activeCell.split(':');
                    const index = parseInt(i);
                    return (benchmarkData.benchmarks[index]['id']);
                  }}
                  formData={benchmarkFormData}
                  onUpdate={fetchBenchmarkData}
                  />
              </Space>
            </Col>
        }
        <Col sm={editMode? 18:24}>
          <Table
            columns={industryHeaders.concat(getKpiHeaders())}
            dataSource={benchmarkData.benchmarks}
            size="small"
            bordered={true}
            scroll={{ x: "calc(700px + 50%)", y: 500 }}
            pagination={false}
            loading={loading}
          />
          {
            !loading &&
            <Row justify="end" style={{paddingTop: 12}}>
              <Col>
                <ExportPPT
                  total={benchmarkTableData.rows.length}
                  onExport={export_table} />
              </Col>
            </Row>
          }
        </Col>
      </Row>
    </div>
  );
}



const InputHeader = (props: any) => {
  const arrYear = [2020, 2021, 2022];
  const arrHeadKpi = [
    { id: 5, name: "Environmental" },
    { id: 3, name: "Social" },
    { id: 4, name: "Governance" }
  ];
  const [years] = useState(arrYear);
  const [headKpi] = useState(arrHeadKpi);
  const [loading, setLoading] = useState(false);
  const [industryForm] = Form.useForm()
  const [kpiForm] = Form.useForm()
  const [fundDetails, setFundDetails] = useState <any> ({})
  const [levels, setLevels] = useState <any[]> ([]);

  const getFundDetails = async () => {
      try {
          setLoading(true);
          const {data: {data}} = await API_SERVICE.getFunds({id: props.fundId});
          if (data.items.length) {
            setFundDetails(data.items[0]);
          }
      } catch (e) {
          notification.error({message: API_SERVICE.handleErrors(e)});
      } finally {
        setLoading(false)
      }
  };

  const getLevels = async () => {
      try {
          const {data: {data}} = await API_SERVICE.getColorLevels();
          if (data.items.length) {
            const arr: any[] = data.items.sort( (a: any,b: any) => (a.id < b.id)? -1 : 1);
            setLevels(arr);
          }
      } catch (e) {
      }
  };

  useEffect(()=>{
    getLevels();
    getFundDetails();
  },[])

  return (
    <div>
      <Row justify="space-between" align="middle" className="p-2 bottomLine">
        <Col>
          <Header
            headings={[
              "Fund Benchmarking",
              fundDetails.name,
              fundDetails.pef && fundDetails.pef.name,
              props.year
            ]} />
        </Col>
        <Col>
          <div className="d-flex">
            <Form layout="horizontal">
              <Form.Item className="mb-0" label="Select Year:">
                <Select placeholder="year" value={props.year} onChange={props.onYearChange}>
                  {years.map((obj: any, i: number) => {
                    return <Select.Option key={i} value={obj}>{obj}</Select.Option>;
                  })}
                </Select>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
      {
        props.editable &&
          <Row justify="start" align="middle" className="p-2 bottomLine">
            <Col>
              <div className="d-flex">
                <Form layout="inline" form={industryForm}
                  onFinish={async (form: any) => {
                    try {
                        setLoading(true);
                        await API_SERVICE.pushIndustries({
                          name: form.name,
                          fundId: props.fundId,
                          year: props.year
                        });
                        industryForm.resetFields();
                        props.onUpdate();
                    } catch (e) {
                        notification.error({message: API_SERVICE.handleErrors(e)});
                    }
                    finally {
                      setLoading(false);
                    }
                  }}>
                  <Form.Item name="name" label="Industry:" className="mb-0">
                    <Input
                      placeholder="Enter Industry"
                      style={{ width: 150 }}
                    ></Input>
                  </Form.Item>
                  <Button type="primary"
                    htmlType="submit" disabled={loading}>
                    +Add
                  </Button>
                </Form>

                <Form className="ml-3" layout="inline"
                  form={kpiForm}
                  onFinish={async (form: any) => {
                    try {
                        setLoading(true);
                        await API_SERVICE.pushDkpi({
                          ...form,
                          fundId: props.fundId,
                          year: props.year
                        });
                        kpiForm.resetFields();
                        props.onUpdate()
                    } catch (e) {
                        notification.error({message: API_SERVICE.handleErrors(e)});
                    }
                    finally {
                      setLoading(false);
                    }
                  }}>
                  <Form.Item name="metricId" label="KPI:" className="mb-0">
                    <Select placeholder="Select KPI">
                      {headKpi.map((obj: any, i: number) => {
                        return (
                          <Select.Option key={i} value={obj.id}>
                            {obj.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item className="mb-0" name="name">
                    <Input
                      placeholder="Enter KPI Title"
                      style={{ width: 150 }}
                    ></Input>
                  </Form.Item>
                  <Form.Item className="mb-0" name="description">
                    <Input
                      placeholder="Enter KPI description"
                      style={{ width: 150 }}
                    ></Input>
                  </Form.Item>
                  <Button type="primary"
                    htmlType="submit" disabled={loading}>
                    +Add
                  </Button>
                </Form>
              </div>
            </Col>
            <div style={{marginLeft: 'auto'}}>
              <div className="d-flex align-items-center">
              {
                levels.map((obj,i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        width: 100,
                        backgroundColor: obj.colorCode,
                        padding: 2,
                        textAlign: 'center'
                      }}>
                      {obj.name.titlecase()}
                    </div>
                  )
                })
              }
              </div>
            </div>
          </Row>
      }
    </div>
  )
}
