import {useState} from "react";
import {useQuery} from "react-query";
import {notification} from "antd";
import {Metrics, resolveMetricResults} from "@recoinsights/metrics";
import {Metric, ResultSet} from "@recoinsights/types";
import API_SERVICE from "../services/api-service";

export function resolveMetric(metricUid: Metrics, metrics: Metric[]) {
    return metrics.find((metric) => metric.uid === metricUid) as Metric;
}

export function useResults({ companyId, groupId }: { companyId?: number, groupId?: number }) {
    const [isAuthorised, setIsAuthorised] = useState<boolean>(true);

    const { data: results, isLoading: isLoadingResults } = useQuery<ResultSet[]>({
        queryKey: companyId ? `results/company/${companyId}` : groupId ? `results/portfolio/${groupId}` : "results",
        queryFn: async () => {
            const response = await API_SERVICE.getResults({company_id: companyId, group_id: groupId});

            return response.data.data.sort((a: ResultSet, b: ResultSet) => a.year - b.year);
        },
        onError: (error: any) => {
            if (error?.response?.status === 403) {
                setIsAuthorised(false);
            }

            notification.error({message: API_SERVICE.handleErrors(error)})
        }
    });

    const years = results?.map((result: ResultSet) => result.year).filter((value, index, array) => array.indexOf(value) === index) ?? [];

    return {
        results: results ?? [],
        years,
        isLoadingResults,
        isAuthorised,
    };
}

export function useMetrics() {
    const { data: metrics, isLoading: isLoadingMetrics } = useQuery<Metric[]>({
        queryKey: "metrics",
        queryFn: async () => {
            const response = await API_SERVICE.getContentMetrics();

            return response.data.data.items;
        },
        onError: err => notification.error({message: API_SERVICE.handleErrors(err)})
    });

    return {
        metrics: metrics ?? [],
        isLoadingMetrics,
    };
}

export function useMetricsWithResults(results: ResultSet[]) {
    const metricScores: Partial<Record<Metrics, boolean>> = {};

    results.forEach((resultSet) => {
        Object.keys(resultSet.metrics).forEach((key) => {
            const metricUid = key as Metrics;
            const metric = resolveMetricResults(metricUid as Metrics, resultSet);

            if (metric.hasScore()) {
                metricScores[metricUid] = true;
            }
        });
    });

    function hasMetricScore(metricUid: Metrics) {
        return !!metricScores[metricUid];
    }

    const hasEnvironmentalMetrics = hasMetricScore(Metrics.RenewableEnergyConsumption) || hasMetricScore(Metrics.EmissionsIntensity);
    const hasSocialMetrics = hasMetricScore(Metrics.CommunityEngagement) || hasMetricScore(Metrics.CustomerSatisfaction) || hasMetricScore(Metrics.EmployeeSatisfaction) || hasMetricScore(Metrics.GenderDiversity) || hasMetricScore(Metrics.IncidentFrequency);
    const hasGovernanceMetrics = hasMetricScore(Metrics.BoardMaturity) || hasMetricScore(Metrics.CyberSecurityMaturity);

    return {
        hasMetricScore,
        hasEnvironmentalMetrics,
        hasSocialMetrics,
        hasGovernanceMetrics,
    };
}
