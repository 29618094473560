import React from 'react';
import './index.scss';
import {Button, Col, Row, Steps} from 'antd';
import AdminListLayout from '@recoinsights/shared/components/layout/AdminListLayout/AdminListLayout';
import {getCompanyContentDetails, getCompanyTimelineDetails} from "../../../services/local-storage";
import moment from 'moment';
import {SURVEYS_LIST} from '@recoinsights/shared/constants/RouteConstants';
import {useHistory} from "react-router-dom";
import useWindowDimensions from '@recoinsights/shared/hooks/WindowsDimension'
import {getUser} from '@recoinsights/client/src/services/local-storage';

const { Step } = Steps;

export default function Home() {
    const user = getUser().user;
    const companyContent = getCompanyContentDetails();
    const companyTimeline = getCompanyTimelineDetails();
    const history = useHistory();
    const windowDimensions = useWindowDimensions();
    return (

        <div className={'group-wrapper page-wrapper'}>
            {/*@ts-ignore*/}
            <AdminListLayout title={`${(user.groups && user.groups.length)? user.groups[0].name : ''} Data Collection`}>
                <Row>
                    <Col sm={24}>
                        <div className="p-2">
                            <div dangerouslySetInnerHTML={{__html: companyContent?.homepage}} />
                            {
                                companyTimeline && companyTimeline.length > 0 &&
                                <Steps direction={
                               		(windowDimensions.width < 768) ? 'vertical' : 'horizontal'
                            } progressDot current={companyTimeline.length} className="homepage-stepper">
                                    {
                                        companyTimeline.map((item: any, index: number) => {
                                            return <Step key={index} title={item.name} description={item.date && moment(item.date).utc().format('DD/MM/YYYY')} />
                                        })
                                    }
                                </Steps>
                            }
                            <Button className='theme-button primary mt-2'
                                    onClick={() => history.push(SURVEYS_LIST)}
                            >
                                View Surveys
                            </Button>
                        </div>
                    </Col>
                </Row>

            </AdminListLayout>
        </div>
    );
}
