import React from 'react';
import './index.scss';
import {Col, Row} from 'antd';
import AdminListLayout from '@recoinsights/shared/components/layout/AdminListLayout/AdminListLayout';
import {getCompanyContentDetails} from 'services/local-storage';

export default function ContactUs() {
    const companyContent = getCompanyContentDetails();

    return (
        <div className={'group-wrapper page-wrapper'}>
            {/*@ts-ignore*/}
            <AdminListLayout title={'Contact us'}>
            <Row>
                    <Col sm={24}>
                        <div className="p-2">
                            <div dangerouslySetInnerHTML={{__html: companyContent?.contactUs}} />
                        </div>
                    </Col>
                </Row>
            </AdminListLayout>
        </div>
    );
}
