import React, {useEffect, useState} from 'react';
import {Col, notification, Row, Typography} from 'antd';

import API_SERVICE from '../../../services/api-service';
import AccessControl from 'components/AccessControl';

const { Text} = Typography;


export default function Industries() {
    const [sectors, setSectors] = useState([] as any[]);

    const getSectors = async () => {
        try {
            const {data: {data}} = await API_SERVICE.getSectors();
            setSectors(data.items);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };

    useEffect(() => {
        getSectors();
    }, [])

    return (<AccessControl id = {26}>
        <>
            <Row>
                {
                    sectors.map((sector: any) => {
                        return (
                            <Col xs={24} sm={24} md={12} lg={8} className="mb-4">
                                <div>
                                    <Text strong><h4 className="mb-0">{sector.name}</h4></Text>
                                    <ul className="pl-2">
                                        {
                                            (sector.industries && sector.industries.length > 0) && sector.industries.map((industry: any) => {
                                                return <li>{industry.name}</li>;
                                            })
                                        }
                                    </ul>
                                </div>
                            </Col>
                        )
                    })
                }
            </Row>
        </>
   </AccessControl> )
}
