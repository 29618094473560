import React, {useEffect, useState} from "react";
import {Button, Col, Divider, Input, notification, Popconfirm, Row, Select, Space, Spin} from 'antd';
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import '../index.scss';
import API_SERVICE from '../../../services/api-service';

export default function QuestionsMaster(){
    const [categories, setCategories] = useState([]);
    const [category_name, set_category_name] = useState('')
    const [subcategory_name, set_subcategory_name] = useState('')
    const [categoryId, setCategoryId] = useState(-1);
    const [loading, setLoading] = useState <boolean> (true);
    const [error, setError ] = useState <string> ('');

    const getCategories = async () => {
        try {
            const {data: {data}} = await API_SERVICE.quesCategoryGet();
            setCategories(data.items);
        } catch (e) {
            setError(API_SERVICE.handleErrors(e))
        }
        finally {
          setLoading(false)
        }
    };

    const addCategory = async () => {
        try {
            setLoading(true);
            await API_SERVICE.quesCategoryCreate({ name: category_name });
            getCategories();
        } catch (e) {
            setError(API_SERVICE.handleErrors(e))
        }
        finally {
          setLoading(false)
        }
    };

    const addSubCategory = async () => {
        try {
            if (categoryId === -1) {
              return notification.error({message: 'Select a category first'})
            }
            setLoading(true);
            await API_SERVICE.quesSubCategoryCreate({ name: subcategory_name, categoryId });
            getCategories();
        } catch (e) {
            setError(API_SERVICE.handleErrors(e))
        }
        finally {
          setLoading(false)
        }
    };

    useEffect(()=>{
      getCategories();
    },[])

    if (loading) {
      return <Spin />
    }

    return(
            <div>
              {
                error &&
                  <div style={{color: 'red', marginBottom: 24}}>
                    {error}
                  </div>
              }
              <Row gutter={48}>
                <Col>
                  <Space>
                    <Input placeholder="Enter Category"
                      onChange={(e)=>set_category_name(e.target.value)} />
                    <Button type="primary" icon={<PlusOutlined />} onClick={addCategory}>
                      Add New
                    </Button>
                  </Space>
                </Col>
                <Col>
                  <Space>
                    <Select
                      placeholder="Select Category"
                      onChange={setCategoryId}
                      options={[
                        ...categories.map((obj: any)=>{
                          return {
                            value: obj.id,
                            label: obj.name
                          }
                        })
                      ]} />
                    <Input placeholder="Enter Sub-Category"
                      onChange={(e)=>set_subcategory_name(e.target.value)} />
                    <Button type="primary" icon={<PlusOutlined />} onClick={addSubCategory}>
                      Add New
                    </Button>
                  </Space>
                </Col>
              </Row>
              <Divider />

              {
                categories.map((cat: any,i) => (
                  <div key={i}>
                    <Row align="middle">
                      <Col style={{width: 140}}>
                        <Space>
                          <Popconfirm title="Sure to delete?"
                            onConfirm={async () => {
                              try {
                                  setLoading(true);
                                  await API_SERVICE.quesCategoryDelete(cat.id);
                                  getCategories();
                              } catch (e) {
                                  setError(API_SERVICE.handleErrors(e))
                              }
                              finally {
                                setLoading(false)
                              }
                            }}>
                            <div className="text-primary cursor-pointer text-center">
                              <MinusCircleOutlined />
                            </div>
                          </Popconfirm>
                          <div>{cat.name}</div>
                        </Space>
                      </Col>
                      <Col>
                        <Select mode="multiple"
                          style={{minWidth: 140}}
                          value={[
                            ...cat.subCategories.map((sub: any) => {
                              return {
                                label: sub.name,
                                value: sub.id
                              }
                            })
                          ]}
                          onDeselect={async (id: any)=>{
                            try {
                                await API_SERVICE.quesSubCategoryDelete(id);
                                getCategories();
                            } catch (e) {
                                setError(API_SERVICE.handleErrors(e))
                            }
                          }} />
                      </Col>
                    </Row>
                    {
                      (i+1 < categories.length) && <Divider />
                    }
                  </div>
                ))
              }
            </div>
    )
}
