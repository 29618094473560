import React from 'react';
import ForgotPassword from '@recoinsights/shared/view/forgotPassword/forgotPassword';

interface Props {
    location: any;
}

export default function forgotPasswordPage(props: Props) {
    return (
        <ForgotPassword {...props}/>
    );
}
