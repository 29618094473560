import React, { useState } from 'react';
import {notification, Upload, UploadProps} from 'antd';
import API_SERVICE from '../services/api-service';
import {useTranslation} from "react-i18next";

export default function FileUpload(props: any) {
    const { t } = useTranslation();

    const a = props.defaultFiles ? [
        {
            uid: props.defaultFiles?.id,
            name: props.defaultFiles?.name,
            status: 'done',
            response: 'Server Error 500',
            url: props.defaultFiles?.url,
        }
    ] : []

    const uploadProps = {
        defaultFileList: a
    }

    async function handleUpload(file: any) {
        try {
            const response = await API_SERVICE.fileUpload(props.type, file);
            const { data: { id, url } } = response.data;
            props.onChange(id);
            if (props.callback) {
                props.callback({ id, url });
            }
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    }

    const handleChange: UploadProps['onChange'] = (info) => {
        let newFileList = [...info.fileList];

        newFileList = newFileList.slice(-1);

        newFileList = newFileList.map((file) => {
            if (file.response) {
                file.url = file.response.url;
            }
            return file;
        });

        props.setFileList(newFileList);

        if(newFileList.length === 0) {
            props.setFileId('');
        }
    };

    return (
            //@ts-ignore
        <Upload
            fileList={props.fileList}
            onChange={handleChange}
            {...uploadProps}
            multiple={false}
            showUploadList={true}
            beforeUpload={(file) => {
                if(props.type === "THUMBNAIL"){
                    const isFileAllowed = (file.type.includes('png') || file.type.includes('jpg') || file.type.includes('jpeg'));
                    if(!isFileAllowed){
                        notification.error({message: t(`${file.name} is not Allowed, please select appropriate file`)});
                        //@ts-ignore
                        return Upload.LIST_IGNORE;
                    }
                }
                handleUpload(file);
                return false;
            }}
        >
            {props.children}
        </Upload>
    );
}
