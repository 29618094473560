import React, {useEffect, useState} from "react";
import "./index.scss";
import {Button, Checkbox, Col, Form, Input, Modal, notification, Row, Select, Space, Typography,} from "antd";
import {CloseOutlined, UploadOutlined} from "@ant-design/icons";
import API_SERVICE from "../../services/api-service";

import AccessControl from "@recoinsights/client/src/components/AccessControl";
import FileUpload from "../../components/FileUpload";

const { Text } = Typography;
export default function FileCreateOrEditPage({ onClose, details }: any) {
  const [form] = Form.useForm();
  const [isUnderSupport, setIsUnderSupport] = useState(false as boolean);
  const [isUnderInsights, setIsUnderInsights] = useState(false as boolean);
  const [groups, setGroups] = useState([] as any);
  const [companies, setCompanies] = useState([] as any);

  const handleGroupsChange = (value: any, setAll = false) => {
    let companies: any = [];
    groups.map((group: any) => {
      if(value.includes(group.id)){
        return group.companies;
      }
    }).filter((item: any) => {
      if(item && item.length > 0){
        return item;
      }
    }).map((item: any) => {
      item.map((a: any) => {
        companies.push(a);
      })
    });
    let companyIds = companies.map((item: any) => {
      return item.id;
    })
    setAll && form.setFieldsValue({companyIds: companyIds})
    setCompanies(companies);
  }

  const getGroups = async () => {
    try {
      const {data: {data}} = await API_SERVICE.getGroups();
      setGroups(data.items);
    } catch (e) {
      notification.error({message: API_SERVICE.handleErrors(e)});
    }
  };

  const getInitialValues = (details: any) => {
    if (details) {
      const obj = { ...details };
      const {companyFiles, groups} = obj;
      if (companyFiles.length) {
        obj.companyIds = companyFiles.map((item: any, i: number) => {
          return item.company?.id;
        });
      }

      if (groups.length) {
        let groupsArray = groups.map((item: any, i: number) => {
          return item.id;
        });
        obj.groups = groupsArray;
      }
      return obj;
    } else return {};
  };

  useEffect(() => {
    // getCompanies();
    getGroups();
    if(details){
      setIsUnderSupport(details.isUnderSupport);
      setIsUnderInsights(details.isUnderInsights);
    }
  }, []);

  useEffect(()=> {
    if (details && details.groups.length) {
      let groupsArray = details.groups.map((item: any, i: number) => {
        return item.id;
      });
      handleGroupsChange(groupsArray);
    }
  }, [groups])
  return (<AccessControl id = {19}>
    <Modal
      title={<Text>{details ? "Edit File" : "Add File"}</Text>}
      width={600}
               footer={null}
               open={true}
               maskClosable={false}
               cancelText="Cancel"
               okText={details ? "Update" : "+ Add"}
               okType="primary"
               cancelButtonProps={{ ghost: true}}
               onOk={
                   () => {
                       form.submit();
                   }
               }
               onCancel={onClose}
    >
      <Form
          form={form}
          initialValues={getInitialValues(details) || {}}
          fields={[]}
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
          onFinish={(value) => {
            const reqObj: any = value;
            reqObj.isUnderSupport = isUnderSupport;
            reqObj.isUnderInsights = isUnderInsights;
            if (details) {
              API_SERVICE.fileUpdate(details.id, reqObj)
                  .then((d) => {
                    notification.success({ message: 'File Updated Successfully'});
                    onClose(true);
                  })
                  .catch((e) => {
                    notification.error({ message: API_SERVICE.handleErrors(e) });
                  })
            } else {
              API_SERVICE.fileCreate(reqObj)
                  .then((d) => {
                    notification.success({ message: 'File Created Successfully'});
                    onClose(true);
                  })
                  .catch((e) => {
                    console.log(e);
                    notification.error({ message: API_SERVICE.handleErrors(e) });
                  })
            }
          }}
          layout="horizontal"
          requiredMark={true}
      >
        <Col md={24} sm={24} xs={24}>
          <Form.Item
              label={"Upload File"}
              name={"fileId"}
              rules={[{ required: false }]}
          >
            <FileUpload
                type={'DOCUMENT'}
                fileType="pdf"
                defaultFiles={details && details.file}
            >
              <Button htmlType="button" icon={<UploadOutlined />}>Upload</Button>
            </FileUpload>
          </Form.Item>
        </Col>

        <Col md={24} sm={24} xs={24}>
          <Form.Item
              label={"Upload Thumbnail"}
              name={"thumbnailId"}
              rules={[{ required: false }]}
          >
            <FileUpload type={'THUMBNAIL'} fileType="image" defaultFiles={details && details.thumbnail}>
              <Button htmlType="button" icon={<UploadOutlined />}>Upload</Button>
            </FileUpload>
          </Form.Item>
        </Col>

        <Col md={24} sm={24} xs={24}>
          <Form.Item
              label={"File Display Name"}
              name={"name"}
              rules={[{ required: false, message: "File Display Name required" }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Row>
          <Col md={10} sm={10} xs={10} className="text-right pr-1">
            <label>Show Under:</label>
          </Col>
          <Col md={14} sm={14} xs={14}>
            <div className="d-flex align-items-center">
              <Form.Item
                  className="grow-1"
                  name={"isUnderSupport"}
                  rules={[{ required: false }]}
              >
                <Checkbox value={true} checked={isUnderSupport} onChange={(e: any)=> {setIsUnderSupport(e.target.checked)}}>Support</Checkbox>
              </Form.Item>
              <Form.Item
                  className="grow-1"
                  name={"isUnderInsights"}
                  rules={[{ required: false }]}
              >
                <Checkbox value={true} checked={isUnderInsights} onChange={(e: any)=> {setIsUnderInsights(e.target.checked)}}>Insight</Checkbox>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Col md={24} sm={24} xs={24}>
          <Form.Item
              label={"Select Group"}
              name={"groups"}
              rules={[{ required: false }]}
          >
            <Select mode="multiple"
                    filterOption={(input, option:any) =>
                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    allowClear
                    placeholder={'Select Group'}
                    onChange={(value) => {handleGroupsChange(value, true)}}
            >
              {
                groups.map((group: any) => {
                  return <Select.Option
                      value={group.id}>{group.name}</Select.Option>;
                })
              }
            </Select>
          </Form.Item>
        </Col>
        <Col md={24} sm={24} xs={24}>
          <Form.Item
              label={"Companies Under Selected Group"}
              name={"companyIds"}
              rules={[{ required: false }]}
          >
            <Select mode="multiple"
                    filterOption={(input, option:any) =>
                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    allowClear
                    placeholder={'Select Companies'}>
              {
                companies.map((company: any) => {
                  return <Select.Option
                      value={company.id}>{company.name}</Select.Option>;
                })
              }
            </Select>
          </Form.Item>
        </Col>
        <div className="text-right mt-3">
          <Space>
            <Button
                type="primary"
                ghost
                htmlType="button"
                icon={<CloseOutlined />}
                className="theme-button primary full-width"
                onClick={onClose}
            >
              Cancel
            </Button>

            <Button
                type="primary"
                htmlType="submit"
                // loading={loading}
                className="theme-button primary full-width "
            >
              {details ? "Update" : "+ Add"}
            </Button>
          </Space>
        </div>
      </Form>
    </Modal>
    </AccessControl>
  );
}

