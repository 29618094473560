import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, notification, Row, Space, Spin,} from "antd";
import {MinusCircleOutlined} from "@ant-design/icons";
import API_SERVICE from "../../../services/api-service";
import {useTranslation} from "react-i18next";
import AccessControl, {accessIncludes} from "../../../components/AccessControl";
import "antd/lib/style/index.css";
import "antd/dist/antd.css";
import 'antd/lib/spin/style/index.css';

export default function MetricKPI() {
  return (
    <>
      <Row>
        <Col xs={12}>
          <Metric />
        </Col>
      </Row>
    </>
  );
}

function Metric() {
  const { t } = useTranslation();
  const [metricForm] = Form.useForm();
  const [addNewMetric, setAddNewMetric] = useState(false);
  const [fetchingMetrics, setFetchingMetrics] = useState(false);
  const [metric, setMetrics] = useState([] as any[]);

  const getMetrics = async () => {
    setFetchingMetrics(true);
    try {
      const {data: { data }} = await API_SERVICE.getMetric();
      setMetrics(data.items);
    } catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    } finally {
      setFetchingMetrics(false);
    }
  };

  const deleteMetric = async (id: number) => {
    try {
      await API_SERVICE.deleteMetric(id);
      notification.success({ message: 'Deleted Successfully' });
      getMetrics();
    } catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    }
  };


  useEffect(() => {
    getMetrics();
  }, []);

  return (
    <div>
      <AccessControl id = {25}>
      <Form
        form={metricForm}
        fields={[]}
        layout="inline"
        onFinish={async (value: string) => {
          setAddNewMetric(true);
          try {
            // @ts-ignore
            await API_SERVICE.createMetric(value);
            notification.success({
              message: "Metric Created Successfully",
            });
            metricForm.resetFields();
            getMetrics();
          } catch (e) {
            notification.error({
              message: t(API_SERVICE.handleErrors(e)),
            });
          } finally {
            setAddNewMetric(false);
          }
        }}
        requiredMark={false}
      >
        <Form.Item
          name="name"
          rules={[{ required: true, message: "Metric name required" }]}
        >
          <Input placeholder="Enter a New Metric" style={{ width: 200 }} />
        </Form.Item>

        <Form.Item>
          <Button
            loading={addNewMetric}
            type={"primary"}
            htmlType="submit"
            className="theme-button primary"
          >
            Add New
          </Button>
        </Form.Item>
      </Form>
      </AccessControl>

      <AccessControl id = {26}>
      <br />
      <div>
        {
          fetchingMetrics ? <div><Spin size='small' spinning /></div> : <ol>
            {metric.map((metric: any) => {
              return (
                  <div className="d-flex align-items-center mb-1">
                    {accessIncludes([25]) && <MinusCircleOutlined onClick={() => {deleteMetric(metric.id)}} />}
                    <Space>
                      <li className="ml-3"> {metric.name}</li>
                    </Space>
                  </div>
              );
            })}
          </ol>
        }

      </div>
      </AccessControl>
    </div>
  );
}

