import React, {useEffect, useState} from 'react';
import {notification, Select} from 'antd';
import API_SERVICE from "../../../services/api-service";
import {getUser} from '@recoinsights/client/src/services/local-storage';

export default function UnitSelect(props: any) {
    const {Option} = Select;
    const [units, setUnits] = useState([] as any [])
    const [selectedValue, setSelectedValue] = useState(props.innitialValue);
    const roleId=getUser()?.user?.role?.id;

    const getUnits = async () => {
        const params = {
            year: props.surveyYear,
            category: props.questionItem.unitCategory
        }
        try {
            const {data: {data}} = await API_SERVICE.getUnits({params});
            setUnits(data.items);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };

    useEffect(() => {
        getUnits();
    }, [])

    return (
        <>
            {
                units && units.length > 0 &&
                <Select value={selectedValue} placeholder={"Select Unit"} 
                disabled={roleId===4 ? false : true}
                onChange={(id) => {
                    props.onChange(id);
                    setSelectedValue(id)
                }}>
                    {
                        units.map((item: any, index: number) => {
                            return <Option value={item.id} key={index}>{item.unit}</Option>
                        })
                    }
                </Select>
            }
        </>
    );
}
