import {MouseEvent} from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";

type Params<T> = { [K in keyof T]?: string };

export function useRouteParams<T extends Params<T>>() {
    return useParams<T>();
}

export function useSearchParams<T extends Params<T>>() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const params: Record<string, string | undefined> = {};

    searchParams.forEach((value, key) => {
        params[key] = value;
    })

    return params as T;
}

export function useRouter<R extends Params<R>, S extends Params<S>>() {
    const history = useHistory();
    const location = useLocation();
    const routeParams = useRouteParams<R>();
    const searchParams = useSearchParams<S>();

    function route({ route, routeParams, searchParams }: { route?: string, routeParams?: Record<string, any>, searchParams?: Record<string, any> }) {
        let pathname = route ?? location.pathname;
        const search = new URLSearchParams(location.search);

        if (route && routeParams) {
            Object.keys(routeParams).forEach((key: string) => {
                const value = routeParams[key];

                pathname = route.replace(`:${key}`, value);
            });
        }

        if (searchParams) {
            Object.keys(searchParams).forEach((key: string) => {
                const value = searchParams[key];

                if (value) {
                    search.set(key, value);
                } else {
                    search.delete(key);
                }
            });
        }

        return {
            pathname: pathname,
            search: search.toString(),
        };
    }

    function href(location: Partial<Location>) {
        return `${location.pathname}?${location.search}`;
    }

    function navigate(location: Partial<Location>) {
        history.push(location)
    }

    function handleButtonClick(event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) {
        event.preventDefault();

        const href = event.currentTarget.attributes.getNamedItem("href");

        if (href) {
            history.push(href.value);
        }
    }

    return {
        handleButtonClick,
        history,
        href,
        location,
        navigate,
        route,
        routeParams,
        searchParams,
    }
}
