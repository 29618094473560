import React, {useEffect, useState} from 'react';
import './index.scss';
import {Button, Col, Divider, Dropdown, Input, Menu, Row, Tabs, Tag, Typography} from 'antd';
import {CommentOutlined, DownloadOutlined, DownOutlined, FallOutlined, RiseOutlined} from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import Paragraph from 'antd/lib/typography/Paragraph';
import TableData from 'views/Analyst/Targets/tabledata';

const { TextArea } = Input;
const { TabPane } = Tabs;
const { Text } = Typography;

export default function Companies(props: any) {
    const [search, ] = useState('' as string);
    const [pagination] = useState({page: 1, perpage: '10'} as any);
    const [filters] = useState({} as any);
    
    const refresh = async () => {
        const params = {...pagination, ...filters};
        if (search) {
            params['q'] = search;
            params['page'] = 1;
        }
    };
    useEffect(() => {
        refresh();
    }, [props.location, pagination, search, filters]);
    const menu = (
        <Menu>
          <Menu.Item>
            <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
            Horizon Co.
            </a>
          </Menu.Item>
          <Menu.Item icon={<DownOutlined />} disabled>
          <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
            Magility Co.
            </a>
          </Menu.Item>
          <Menu.Item >
          <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
            Plance Co.
            </a>
          </Menu.Item>
          <Menu.Item >
          <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
              Heade Co.
              </a></Menu.Item>
              <Menu.Item >
          <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
            Dreality Co.
              </a></Menu.Item>
        </Menu>
      );
      function handleTabChange(key: any) {
      }
      return (
        <>
            <div className={'role-wrapper page-wrapper'} style={{flex: 1, padding: '30px'}}>
                {/*@ts-ignore*/}
                <Dropdown overlay={menu}>
                <Button>
                    Horizon Co. <DownOutlined />
                </Button>
                </Dropdown>
                <Tabs defaultActiveKey="1" size={'small'} className="bg-white-tab" destroyInactiveTabPane={true} onChange={handleTabChange}>
                    <TabPane tab="Overview" key="1">
                    <Title level={5}>Investment thesis  <Text type="danger">    Edit</Text></Title> 
                    <Paragraph>
                        <ul>
                            <li>Expansion into new markets and product lines via acquisitions</li>
                            <li>Professionalize sales, account management, client services to grow revenue organically</li>
                            <li>Increase repeat business with existing customers</li>
                        </ul>
                    </Paragraph>
                    <p></p>
                    <Title level={5}>ESG Dimensions of Value Creation plan  <Text type="danger">    Edit</Text></Title> 
                    <Paragraph> 
                        <ul>
                            <li><strong>Risk:</strong>  Incorporate climate risk into acquisition due diligence process and quarterly review of top priority risks at Board level</li>
                            <li><strong>Strategy:</strong> Strengthen sales and account management through increased recruitment and retention of female top talent in middle management to senior sales roles</li>
                            <li><strong>Governance:</strong> Professionalize oversight of risk management system and strategy at board level</li>
                            <li><strong>Metrics:</strong> Net Promoter Score to track customer satisfaction</li>
                        </ul>
                    </Paragraph>
                    </TabPane>
                    <TabPane tab="Data Collection" key="2">
                        <Title level={5}>Company Parameters</Title>      
                        <Text>Please describe the scope of your company’s physical operations in the past year (e.g., the number of sites, subsidiaries, etc.)</Text> 
                        <div>
                        <TextArea size='small' value="At Horizon Co. we have 8 plants and 3 offices in the United States. We also have 2 plants and 1 office in Canada. We're looking to acquire a company in the UK which would have 3 plants and one office but this acquisition won't be finalized until late 2022." />   <CommentOutlined />      
                        </div>   

                        <Divider />
                        <Title level={5}>Revenue</Title>
                        <Input width={10} size='small' value="377" /> 
                        <Text type="secondary">$M Revenue in 2021</Text> <CommentOutlined />
                    </TabPane>
                    <TabPane tab="Targets" key="3">
                    <TableData />
                    
                    </TabPane>

                    <TabPane tab="Results" key="4">
                    <Row>
                                        <Col sm={24}>
                                            <div className="p-2">
                                            <Divider orientation="left"></Divider>
                                                <div>
                                                <Tag color="error">Week</Tag>
                                                <Tag color="default">Neutral</Tag>
                                                <Tag color="success">Strong</Tag>
                                                <Tag color="default"><RiseOutlined />Improvement</Tag>
                                                <Tag color="default"><FallOutlined />Digression</Tag>
                                                <div className="text-right mt-3">
                                                    <Text type="danger" className="text-right mt-3">Edit</Text>
                                                    <DownloadOutlined />
                                                </div>
                                                <Divider />

                                                <TableData />

                                                </div>
                                            </div>
                                        </Col>
                                    </Row>     
                    </TabPane>
                </Tabs>
            </div>

        </>
    );
}
