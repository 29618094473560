import React, {useEffect, useState} from "react";
import "./index.scss";
import {Button, Col, Form, Input, notification, Popconfirm, Row, Select, Table,} from "antd";
import {MinusCircleOutlined,} from "@ant-design/icons";
import API_SERVICE from "../../../services/api-service";
import Header from "../../../components/Header";
import ExportPPT from "../../../components/ExportPPT";
import PptxGenJS from "pptxgenjs";
import {getUser} from "../../../services/local-storage";
import AppConstants from "@recoinsights/shared/constants/Constants";

const CompanyResult = (props: any) => {
  const {
    match: {
      params: { surveyId },
    },
  } = props;
  const roleId = getUser()?.user?.role?.id;
  const [editMode] = useState(
    [AppConstants.roles.SUPER_ADMIN_ID, AppConstants.roles.ADMIN_ID].includes(
      roleId
    )
  );
  const [surveyDetails, setSurveyDetails] = useState<any>({});
  const [fundDetails, setFundDetails] = useState<any>({});
  const [industry, setIndustry] = useState<any>({});
  const [initiatives, setInitiatives] = useState([]);
  const [dkpis, setDkpis] = useState([]);
  const [targets, setTargets] = useState<any[]>([]);
  const [levels, setLevels] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const [initiativeForm] = Form.useForm();
  const [dkpiForm] = Form.useForm();

  const response_data: any = {
    visited: [],
    targets: [],
    rows: [],
    headers: [
      { text: "Initiative", options: {} },
      { text: "KPI", options: {} },
      { text: "Result", options: {} },
      { text: "Internal Benchmark", options: {} },
      { text: "Internal Benchmark", options: {} },
      { text: "Target", options: {} },
      { text: "KPI Driver", options: {} },
    ],
  };
  const [response, setResponse] = useState<any>(response_data);

  const columns: any = [
    ...(![
      AppConstants.roles.COMPANY_MANAGER_ID,
      AppConstants.roles.COMPANY_ANALYST_ID,
      AppConstants.roles.FUND_MANAGER_ID,
    ].includes(roleId)
      ? [
          {
            title: "",
            key: "1",
            align: "center",
            render: (obj: any) => {
              return (
                <Popconfirm
                  title="Sure to delete?"
                  onConfirm={async () => {
                    try {
                      await API_SERVICE.deleteInitiative(obj.initiativeId);
                      getTargets();
                    } catch (e) {
                      notification.error({
                        message: API_SERVICE.handleErrors(e),
                      });
                    }
                  }}
                >
                  <div className="text-primary cursor-pointer text-center">
                    <MinusCircleOutlined />
                  </div>
                </Popconfirm>
              );
            },
            onCell: (_: any, index: number) => ({
              rowSpan: targets[index].initiativeSpan,
            }),
          },
        ]
      : []),
    {
      title: "Initiative",
      key: "initiative",
      align: "center",
      dataIndex: "initiativeName",
      onCell: (_: any, index: number) => ({
        rowSpan: targets[index].initiativeSpan,
      }),
    },
    ...(![
      AppConstants.roles.COMPANY_MANAGER_ID,
      AppConstants.roles.COMPANY_ANALYST_ID,
      AppConstants.roles.FUND_MANAGER_ID,
    ].includes(roleId)
      ? [
          {
            title: "",
            key: "1",
            align: "center",
            render: (obj: any) => {
              return (
                <Popconfirm
                  title="Sure to delete?"
                  onConfirm={async () => {
                    try {
                      await API_SERVICE.deleteInitiativeDkpiMap(
                        obj.initiativeDkpiMapId
                      );
                      getTargets();
                    } catch (e) {
                      notification.error({
                        message: API_SERVICE.handleErrors(e),
                      });
                    }
                  }}
                >
                  <div className="text-primary cursor-pointer text-center">
                    <MinusCircleOutlined />
                  </div>
                </Popconfirm>
              );
            },
          },
        ]
      : []),
    {
      title: "KPI",
      key: "kpi",
      align: "center",
      dataIndex: "dkpiName",
    },
    {
      title: `Result ${surveyDetails.year}`,
      key: "result",
      align: "center",
      render(obj: any) {
        const d = parseFloat(`${obj.result}`).toFixed(0);

        return {
          props: {
            style: {
              background:
                (obj.cellColor && obj.cellColor.colorCode) || "#F4F4F4",
            },
          },
          children: (
            <div>
              {
                //@ts-ignore
                isNaN(d) ? obj.result : d
              }
            </div>
          ),
        };
      },
    },
    {
      title: "Internal Benchmark",
      key: "Itb",
      dataIndex: "internalBenchmark",
      align: "center",
      render: (value: any, data: any, index: number) =>
        editMode ? (
          <Input
            style={{ width: 110 }}
            defaultValue={value}
            placeholder="write here"
            onChange={(e) => {
              const obj = { ...response };
              obj.targets[index]["internalBenchmark"] = e.target.value;
              setResponse(obj);
            }}
          />
        ) : (
          <div>{value}</div>
        ),
    },
    {
      title: "External benchmark",
      key: "5",
      align: "center",
      dataIndex: "externalBenchmark",
      render: (value: any, data: any, index: number) =>
        editMode ? (
          <Input
            style={{ width: 110 }}
            defaultValue={value}
            placeholder="write here"
            onChange={(e) => {
              const obj = { ...response };
              obj.targets[index]["externalBenchmark"] = e.target.value;
              setResponse(obj);
            }}
          />
        ) : (
          <div>{value}</div>
        ),
    },
    {
      title: "Target",
      key: "6",
      align: "center",
      dataIndex: "target",
      render: (value: any, data: any, index: number) =>
        ![AppConstants.roles.FUND_MANAGER_ID].includes(roleId) ? (
          <Input
            style={{ width: 110 }}
            defaultValue={value}
            placeholder="write here"
            onChange={(e) => {
              const obj = { ...response };
              obj.targets[index]["target"] = e.target.value;
              setResponse(obj);
            }}
          />
        ) : (
          <div>{value}</div>
        ),
    },
    {
      title: "KPI Driver",
      key: "7",
      align: "center",
      width: 260,
      render: (obj: any, data: any, index: number) => {
        if (!response_data.visited.includes(index)) {
          response_data.visited.push(index);
          response_data.targets.push({
            initiativeId: obj.initiativeId,
            dkpiId: obj.dkpiId,
            industryId: industry.id,
            companyId: surveyDetails.company.id,
            fundId: surveyDetails.funds[first]["id"],
            year: surveyDetails.year,
            internalBencfhmark: obj.internalBencfhmark,
            externalBenchmark: obj.externalBenchmark,
            target: obj.target,
            kpiDriver: obj.kpiDriver,
          });
          response_data.rows.push([
            { text: obj.initiativeName || "", options: {} },
            { text: obj.dkpiName || "", options: {} },
            {
              text: obj.result || "",
              options: {
                fill: (obj.cellColor && obj.cellColor.colorCode) || "#F4F4F4",
              },
            },
            { text: obj.internalBenchmark || "", options: {} },
            { text: obj.externalBenchmark || "", options: {} },
            { text: obj.target || "", options: {} },
            { text: obj.kpiDriver || "", options: {} },
          ]);
        }
        if ([AppConstants.roles.FUND_MANAGER_ID].includes(roleId))
          return <div>{obj.kpiDriver}</div>;
        return (
          <Input
            style={{ width: 260 }}
            defaultValue={obj.kpiDriver}
            placeholder="write here"
            onChange={(e) => {
              const obj = { ...response };
              obj.targets[index]["kpiDriver"] = e.target.value;
              setResponse(obj);
            }}
          />
        );
      },
    },
  ];

  const getLevels = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.getColorLevels();
      if (data.items.length) {
        const arr: any[] = data.items.sort((a: any, b: any) =>
          a.id < b.id ? -1 : 1
        );
        setLevels(arr);
      }
    } catch (e) {}
  };

  const getIndustry = async () => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await API_SERVICE.getIndustry(surveyId);
      if (data) {
        setIndustry(data);
      }
    } catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    } finally {
      setLoading(false);
    }
  };

  const getSurveyDetails = async () => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await API_SERVICE.getDataCollection({ id: surveyId });
      if (data.items.length) {
        setSurveyDetails(data.items[first]);
        if (data.items[first]["funds"].length) {
          setFundDetails(data.items[first]["funds"][first]);
        }
      }
    } catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    } finally {
      setLoading(false);
    }
  };

  const getTargets = async () => {
    try {
      setLoading(true);
      response_data.targets = [];
      response_data.visited = [];
      const {
        data: { data },
      } = await API_SERVICE.getCompanyResultTargets(surveyId);
      const arr: any[] = [];
      const visited: any[] = [];
      data.items.map((initiative: any) => {
        initiative.kpi.map((obj: any) => {
          let span = 0;
          if (!visited.includes(initiative.initiativeId)) {
            span = initiative.kpi.length;
            visited.push(initiative.initiativeId);
          }
          arr.push({
            initiativeId: initiative.initiativeId,
            initiativeName: initiative.initiativeName,
            initiativeSpan: span,
            ...obj,
          });
        });
      });
      setTargets(arr);
    } catch (e) {
      console.log(e);
      notification.error({ message: API_SERVICE.handleErrors(e) });
    } finally {
      setLoading(false);
    }
  };

  const getCompanyInitiatives = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.getCompanyInitiatives({
        year: surveyDetails.year,
        fundId: fundDetails.id,
        industryId: industry.id,
      });
      setInitiatives(data.items);
    } catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    }
  };

  async function setCompanyInitiatives(name: string) {
    try {
      setLoading(true);
      initiativeForm.resetFields();
      await API_SERVICE.setCompanyInitiatives({
        name: name,
        year: surveyDetails.year,
        fundId: fundDetails.id,
        industryId: industry.id,
      });
      await getCompanyInitiatives();
    } catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    } finally {
      setLoading(false);
    }
  }

  const getDkpis = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.getDkpi({
        fundId: fundDetails.id,
        companyId: surveyDetails.company.id,
        year: surveyDetails.year,
      });
      setDkpis(data.items);
    } catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    }
  };

  const pushInitiativeDkpiMap = async (form: any) => {
    try {
      dkpiForm.resetFields();
      await API_SERVICE.pushInitiativeDkpiMap({
        ...form,
        industryId: industry.id,
        companyId: surveyDetails.company.id,
        fundId: fundDetails.id,
        year: surveyDetails.year,
      });
      await getTargets();
    } catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    }
  };

  const saveTargets = async () => {
    try {
      await API_SERVICE.saveTargets(response.targets);
      notification.success({ message: "Saved Successfully" });
    } catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    }
  };

  const export_table = async (page_size = 3, slide_options = {}) => {
    const headers = [[...response.headers]];
    const rows = [...response.rows];
    const pres = new PptxGenJS();
    let slide: any = pres.addSlide();
    let table: any[] = [];
    rows.map((r, i) => {
      if (i !== 0 && i % page_size === 0) {
        slide.addTable(headers.concat(table), { ...slide_options });
        slide = pres.addSlide();
        table = [];
      } else if (i + 1 === rows.length) {
        table.push(r);
        slide.addTable(headers.concat(table), { ...slide_options });
      }
      table.push(r);
    });
    pres.writeFile({
      fileName: `targets id_${surveyId} ${surveyDetails.year}.pptx`,
    });
  };

  useEffect(() => {
    getIndustry();
    getSurveyDetails();
    getLevels();
  }, []);

  useEffect(() => {
    if (surveyDetails.id && fundDetails.id) {
      getDkpis();
    }
  }, [surveyDetails, fundDetails]);

  useEffect(() => {
    if (surveyDetails.id && industry.id) {
      getTargets();
      getCompanyInitiatives();
    }
  }, [surveyDetails, industry]);

  useEffect(() => {
    if (targets.length) {
      setResponse(response_data);
    }
  }, [targets]);

  const first = 0;
  return (
    <div style={{ backgroundColor: "#fff" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid black",
          padding: 12,
          margin: 24,
        }}
      >
        <Header
          headings={[
            "Company Results & Targets",
            fundDetails.name,
            surveyDetails.company && surveyDetails.company.name,
            surveyDetails.year,
            industry.name,
          ]}
        />
        {![AppConstants.roles.FUND_MANAGER_ID].includes(roleId) && (
          <Button type="primary" onClick={saveTargets}>
            Save
          </Button>
        )}
      </div>
      <div className="d-flex pb-2 bottomLine ml-4 mr-4">
        {![
          AppConstants.roles.COMPANY_MANAGER_ID,
          AppConstants.roles.COMPANY_ANALYST_ID,
          AppConstants.roles.FUND_MANAGER_ID,
        ].includes(roleId) && (
          <Form
            layout="inline"
            form={initiativeForm}
            onFinish={(f) => setCompanyInitiatives(f.name)}
          >
            <Form.Item name="name" label="Initiative:">
              <Input placeholder="enter initiative" style={{ width: 150 }} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                +Add
              </Button>
            </Form.Item>
          </Form>
        )}
        {![
          AppConstants.roles.COMPANY_MANAGER_ID,
          AppConstants.roles.COMPANY_ANALYST_ID,
          AppConstants.roles.FUND_MANAGER_ID,
        ].includes(roleId) && (
          <Form
            layout="inline"
            form={dkpiForm}
            onFinish={pushInitiativeDkpiMap}
          >
            <Form.Item name="initiativeId" label="KPI">
              <Select placeholder="Select Initiative">
                {initiatives.map((obj: any, i: number) => {
                  return (
                    <Select.Option key={i} value={obj.id}>
                      {obj.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name="dkpiId" className="mb-0">
              <Select placeholder="Select KPI">
                {dkpis.map((obj: any, i: number) => {
                  return (
                    <Select.Option key={i} value={obj.id}>
                      {obj.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                +Add
              </Button>
            </Form.Item>
          </Form>
        )}
        <div style={{ marginLeft: "auto" }}>
          <div className="d-flex align-items-center">
            {levels.map((obj, i) => {
              return (
                <div
                  style={{
                    width: 100,
                    backgroundColor: obj.colorCode,
                    padding: 2,
                    textAlign: "center",
                  }}
                >
                  {obj.name.titlecase()}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <Row align="middle" className="p-4 pt-0 bottomLine">
        {!loading && (
          <Col>
            <Table
              style={targets.length ? {} : { width: 710 }}
              columns={columns}
              dataSource={targets}
              loading={loading}
              bordered
              size="small"
              pagination={false}
              scroll={{ x: "max-content" }}
            />
          </Col>
        )}
      </Row>
      {!loading && (
        <Row justify="end" style={{ margin: 12, marginTop: 12 }}>
          <Col>
            <ExportPPT total={response.rows.length} onExport={export_table} />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default CompanyResult;
