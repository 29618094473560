import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "react-query";
import {ConfigProvider} from "antd";
import './i18n';
import enUS from 'antd/lib/locale-provider/en_US';

import ProtectedRoute from '@recoinsights/shared/components/ProtectedRoute';
import {AuthProvider} from "@recoinsights/shared/contexts/AuthContext";

import Layout from "views/Layout";
import Login from "views/login/Login";
import OTP from 'views/otp/OTP';
import ForgotPassword from 'views/forgotPassword/forgotPassword';
import "antd/dist/antd.css";
import "./App.scss";
import ClosedLayout from './views/ClosedLayout';

function App() {
    const queryClient = new QueryClient()

    return (
        <ConfigProvider locale={enUS}>
            <QueryClientProvider client={queryClient}>
                <AuthProvider>
                    <Router
                        getUserConfirmation={() => {
                            /* Empty callback to block the default browser prompt */
                        }}
                    >
                        <Switch>
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/otp" component={OTP} />
                            <Route exact path="/reset-password" component={ForgotPassword} />
                            <Route exact path="/reset-password/:token" component={ForgotPassword} />
                            {/*<Route exact path="/confirm-email" component={ConfirmEmail} />*/}
                            {/*<Route exact path="/reset-password-request" component={ResetPasswordRequest} />*/}
                            <ProtectedRoute path="/admin" component={ClosedLayout} />
                            <ProtectedRoute path="/" component={Layout} />
                        </Switch>
                    </Router>
                </AuthProvider>
            </QueryClientProvider>
        </ConfigProvider>
    );
}

export default App;
