import React, {useEffect, useState} from "react";
import "./index.scss";
import {Button, Form, Input, notification, Pagination, Select, Space, Table, Typography} from "antd";
import {useHistory} from "react-router-dom";
import AdminListLayout from "@recoinsights/shared/components/layout/AdminListLayout/AdminListLayout";
import {PlusOutlined, SearchOutlined,} from "@ant-design/icons";
import {ConfirmationModal} from "components";
import API_SERVICE from "../../../services/api-service";
import {ADMIN_FUNDS_BENCHMARKING} from "@recoinsights/shared/constants/RouteConstants";
import AccessControl, {accessIncludes} from "../../../components/AccessControl";

const {Text} = Typography;
const {Search} = Input;

export default function PortfolioBenchmarks(props: any) {
    const [form] = Form.useForm();
    const history = useHistory();
    const [groups, setGroups] = useState([] as any[]);
    const [funds, setFunds] = useState([] as any[]);
    const [search, setSearch] = useState("" as string);
    const [saving, setSaving] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [pagination, setPagination] = useState({page: 1, perpage: "10"} as any);
    const [total, setTotal] = useState(10);
    const [filters, setFilters] = useState({} as any);
    const [confirmationModal, setConfirmationModal] = useState({} as any);
    const [editFund, setEditFund] = useState(null as any);

    const columns = [
        {
            title: "S.No",
            key: "sno",
            render: (a: any, v: any, index: number) => (
                <Text>{(pagination.page - 1) * pagination.perpage + index + 1}</Text>
            ),
        },
        {
            title: "Fund  Name",
            key: 'name',
            dataIndex: "name",
            ellipses: true,
            render: (a: any) => <Text>{a}</Text>,
        },
        {
            title: "Group Name",
            key: 'group',
            dataIndex: "pef",
            ellipses: true,
            render: (a: any) => <Text>{a.name}</Text>,
        },
        {
            title: "Configure",
            key: "configure",
            width: 300,
            render: (value: any) => (
                <Space>
                    <Button
                        loading={saving}
                        type={"primary"}
                        htmlType="submit"
                        className="theme-button primary"
                        onClick={() => {
                            history.push(`${ADMIN_FUNDS_BENCHMARKING}/${value.id}`);
                        }}>
                        Benchmarks
                    </Button>
                </Space>
            ),
        }
    ];

    const getGroupsList = async () => {
        try {
            const {
                data: {data},
            } = await API_SERVICE.getGroups();
            setGroups(data.items);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };
    const handleTableChange = (pagination: any, filterValues: any, sorter: any) => {
        setFilters({...filters, ...filterValues, page: 1})
    };

    const refresh = async (page?: number, perPage?: number) => {
        setFetching(true);
        const params = {...pagination, ...filters};
        if (search) {
            params["q"] = search;
            params["page"] = 1;
        }
        if (page) {
            params['page'] = page;
        }
        if (perPage) {
            params['perpage'] = perPage;
        }
        setFetching(true);
        try {
            const {data: {data}} = await API_SERVICE.getFunds(params);
            setFunds(data.items);
            setTotal(data.total);
            setPagination({page: data.page, perpage: data.perpage});
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        } finally {
            setFetching(false);
        }
    };
    useEffect(() => {
        getGroupsList();
    }, []);

    useEffect(() => {
        refresh();
    }, [props.location, search, filters]);
    return (
        <div className={"group-wrapper page-wrapper"}>
            {/*@ts-ignore*/}
            <AdminListLayout
                title={"Portfolio Benchmarks"}
                titleSearch={
                    <div className="search-bar">
                        {
                            accessIncludes([40]) && <Search
                                enterButton={<SearchOutlined/>}
                                placeholder="Search a Fund by its Name"
                                onSearch={(e) => {
                                    setSearch(e);
                                }}
                            />
                        }
                    </div>
                }
                titleAction={
                    accessIncludes([37, 38]) && <Form
                        form={form}
                        fields={[]}
                        onFinish={(value) => {
                            setSaving(true);
                            if (editFund && editFund.id) {
                                API_SERVICE.updateFund(editFund.id, value)
                                    .then((d) => {
                                        notification.success({
                                            message: "Fund updated successfully",
                                        });
                                        form.resetFields();
                                        setEditFund(null);
                                        refresh();
                                    })
                                    .catch((e) => {
                                        notification.error({
                                            message: API_SERVICE.handleErrors(e),
                                        });
                                    })
                                    .finally(() => {
                                        setSaving(false);
                                    });
                            } else {
                                API_SERVICE.createFund(value)
                                    .then((d) => {
                                        notification.success({
                                            message: "Fund created successfully",
                                        });
                                        form.resetFields();
                                        refresh();
                                    })
                                    .catch((e) => {
                                        notification.error({
                                            message: API_SERVICE.handleErrors(e),
                                        });
                                    })
                                    .finally(() => {
                                        setSaving(false);
                                    });
                            }
                        }}
                        layout="inline"
                        requiredMark={true}
                    >
                        <Form.Item
                            name="pefId"
                            rules={[{required: true, message: "Group required"}]}
                        >
                            <Select placeholder="Select Group" disabled={!!editFund}>
                                {groups.map((group: any, index: number) => {
                                    return (
                                        <Select.Option value={group.id} key={index}>
                                            {group.name}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            style={{flex: 1}}
                            name="name"
                            rules={[{required: true, message: "Fund Name required"}]}
                        >
                            <Input autoComplete={"off"} placeholder="Enter a new Fund Name"/>
                        </Form.Item>
                        <Button
                            loading={saving}
                            type={"primary"}
                            htmlType="submit"
                            className="theme-button primary"
                            icon={!editFund && <PlusOutlined/>}
                        >
                            {editFund ? "Update" : "Add New"}
                        </Button>
                    </Form>
                }
                pagination={
                    accessIncludes([54]) && <Pagination
                        current={pagination.page} pageSize={pagination.perpage} pageSizeOptions={[10, 20, 50]}
                        showSizeChanger
                        showQuickJumper
                        defaultCurrent={1}
                        total={total}
                        onChange={(page, size) => {
                            setPagination({page: page, perpage: size});
                            refresh(page, size);
                        }}
                    />
                }
            >
                <AccessControl id={54}>
                    <Table
                        loading={fetching}
                        scroll={{x: true, y: "calc(100vh - 340px)"}}
                        pagination={false}
                        dataSource={funds}
                        columns={columns}
                        onChange={handleTableChange}
                    />
                </AccessControl>


                <ConfirmationModal
                    {...confirmationModal}
                    callback={(a: boolean) => {
                        setConfirmationModal({});
                        if (a) {
                            refresh();
                        }
                    }}
                />
            </AdminListLayout>
        </div>
    );
}
