import React, {useState} from "react";
import "./index.scss";
import {Button, Col, Form, Input, Modal, notification, Space, Typography} from "antd";
import {CloseOutlined} from "@ant-design/icons";
import API_SERVICE from "@recoinsights/client/src/services/api-service";
import FundCompanyInterface from "@recoinsights/shared/types/FundCompany";

const {Text} = Typography;

interface FundPropsInterface {
    onClose: any;
    details?: FundCompanyInterface;
}

export default function FundCompaniesEditPage({onClose, details}: FundPropsInterface) {
    const [form] = Form.useForm();
    const [saving, setSaving] = useState<boolean>(false);

    const handleFundCompanyEdit = (value: any) => {
        if (!details) {
            notification.error({message: 'No id found'});
            return false;
        }

        setSaving(true);

        API_SERVICE.updateFundCompany(details!.fundId, details.companyId, value)
            .then((d) => {
                notification.success({message: 'Data Updated Successfully'});
                onClose(true);
            })
            .catch((e) => {
                notification.error({message: API_SERVICE.handleErrors(e)});
            })
            .finally(() => {
                setSaving(false);
            })
    }

    return (
        <Modal
            title={<Text>Edit Company</Text>}
            width={600}
            footer={null}
            open={true}
            maskClosable={false}
            cancelText="Cancel"
            okText="Update"
            okType="primary"
            cancelButtonProps={{ghost: true}}
            onOk={() => {
                    form.submit();
                }}
            onCancel={onClose}
        >
            {
                <Form
                    form={form}
                    initialValues={details || {}}
                    fields={[]}
                    labelCol={{span: 8}}
                    wrapperCol={{span: 16}}
                    onFinish={(value) => {
                        handleFundCompanyEdit(value);
                    }}
                    layout="horizontal"
                    requiredMark={true}
                >
                    <Col md={24} sm={24} xs={24}>
                        <Form.Item
                            label={"Ownership (%)"}
                            name={"ownership"}
                            rules={[
                                {required: true, message: "Please enter Ownership %"},
                                {
                                    validator: (_, value) =>
                                        value <= 100 ? Promise.resolve() : Promise.reject(new Error('Ownership percentage can not exceed 100%')),
                                }
                                ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col md={24} sm={24} xs={24}>
                        <Form.Item
                            label={"Initial investment year"}
                            name={"initialInvestmentYear"}
                            rules={[
                                {required: true, message: "Please enter Initial investment year"},
                                {
                                    validator: (_, value) =>
                                        (value+'').length === 4 ? Promise.resolve() : Promise.reject(new Error('Year should be 4 digits')),
                                },
                                {
                                    validator: (_, value) =>
                                        Number(value) < (new Date().getFullYear() + 1) ? Promise.resolve() : Promise.reject(new Error('Initial Investment year should be before current year')),
                                }
                                ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <div className="text-right mt-3">
                        <Space>
                            <Button
                                type="primary"
                                ghost
                                htmlType="button"
                                icon={<CloseOutlined/>}
                                className="theme-button primary full-width"
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                loading={saving}
                                type="primary"
                                htmlType="submit"
                                className="theme-button primary full-width "
                            >
                                Update
                            </Button>
                        </Space>
                    </div>
                </Form>
            }
        </Modal>
    );
}

