import React, {useEffect, useState} from "react";
import "./index.scss";
import {Button, notification, Table, Typography,} from "antd";
import AdminListLayout from "@recoinsights/shared/components/layout/AdminListLayout/AdminListLayout";
import {EditOutlined} from "@material-ui/icons";
import {ConfirmationModal} from "components";
import API_SERVICE from "services/api-service";
import FundCompaniesEditPage from "./FundCompaniesEditPage";
import {useParams} from "react-router-dom";

const {Text} = Typography;

interface ParamInterface {
    fundId: string
}

export default function FundCompaniesConfigure() {
    const params: ParamInterface = useParams();
    const fundId = Number(params.fundId);
    const [fundDetail, setFundDetail] = useState([] as any);
    const [fetching, setFetching] = useState(true);
    const [confirmationModal, setConfirmationModal] = useState({} as any);
    const [editCompany, setEditCompany] = useState(null as any);

    const columns = [
        {
            title: "Company",
            sector: "company",
            dataIndex: "company",
            width: "100%",
            render: (company: any) => <Text>{company?.name}</Text>
        },
        {
            title: "Sector",
            key: "sector",
            dataIndex: "company",
            render: (company: any) => <Text>{company?.industry?.sector?.name}</Text>
        },
        {
            title: "Industry",
            key: "industry",
            dataIndex: "company",
            render: (company: any) => <Text>{company?.industry?.name}</Text>
        },
        {
            title: "Ownership",
            dataIndex: "ownership",
            render: (ownership: any) => <Text>{ownership ? `${ownership} %` : '-'}</Text>
        },
        {
            title: "Initial Investment year",
            dataIndex: "initialInvestmentYear",
            render: (initialInvestmentYear: any) => <Text>{initialInvestmentYear ? initialInvestmentYear : '-'}</Text>
        },
        {
            title: "",
            key: "action",
            width: 120,
            render: (company: any) => (
                <Button
                    type="text"
                    icon={<EditOutlined/>}
                    onClick={() => {
                        setEditCompany(company);
                    }}
                />
            )
        }
    ];

    const getFundDetail = async (fundId: number) => {
        try {
            const {data: {data}} = await API_SERVICE.getFunds({id: fundId});
            setFundDetail(data.items[0]);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        getFundDetail(fundId)
    }, []);

    return (
        <div className={"group-wrapper page-wrapper"}>
            <AdminListLayout
                title={fundDetail?.name}
            >
                <Table
                    loading={fetching}
                    pagination={false}
                    dataSource={fundDetail.fundCompanies}
                    columns={columns}
                    className="table-responsive"
                    data-testid="funds-configure-table"
                />

                <ConfirmationModal
                    {...confirmationModal}
                    callback={(a: boolean) => {
                        setConfirmationModal({});
                        if (a) {
                            getFundDetail(fundId);
                        }
                    }}
                />

                {editCompany && (
                    <FundCompaniesEditPage
                        details={editCompany}
                        onClose={(e: boolean) => {
                            if (e) {
                                getFundDetail(fundId);
                            }
                            setEditCompany(null);
                        }}
                        data-testid="edit-fund-company-modal"
                    />
                )}
            </AdminListLayout>
        </div>
    );
}
