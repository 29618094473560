import React, {useEffect, useState} from 'react';
import './index.scss';
import {notification, Pagination, Progress, Space, Table, Typography} from 'antd';
import AdminListLayout from '@recoinsights/shared/components/layout/AdminListLayout/AdminListLayout';
import {ConfirmationModal} from 'components';
import API_SERVICE from "../../../services/api-service";
import AccessControl from 'components/AccessControl';
import eyeIcon from "@recoinsights/shared/assets/icons/Eye_Icon.svg";
import moment from "moment";

const {Text} = Typography;

export default function DCollection(props: any) {
    const [groups, setGroups] = useState([] as any[]);
    const [search] = useState("" as string);
    const [fetching, setFetching] = useState(false);
    const [pagination, setPagination] = useState({page: 1, perpage: '10'} as any);
    const [total, setTotal] = useState(10);
    const [filters, setFilters] = useState({} as any);
    const [confirmationModal, setConfirmationModal] = useState({} as any);
    
    const [surveys, setSurveys] = useState([] as any[]);

    const [funds, setFunds] = useState([] as any[]);

    const columns = [
         {
      title: "S.No",
      key: "sNO",
      width: "80px",
      render: (a: any, v: any, index: number) => (
          <Text>{(pagination.page - 1) * pagination.perpage + index + 1}</Text>
      ),
    },
    {
      title: "Company Name",
      key: "name",
      width: 200,
      ellipsis: true,
      render: (a: any) => <Text>{a?.company?.name}</Text>,
    },
    {
      title: "Funds",
      dataIndex: "funds",
      width: 180,
      ellipsis: true,
      filters: funds.map((fund: any) => {
        return {text: fund.name, value: fund.id}
      }),
      render: (a: any) => <div className="pr-5" style={{ wordWrap: 'break-word', wordBreak: 'break-word', width: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {(a && a.length>0) ? <img src={eyeIcon} alt="icon" className="table-list-icon" title={namesList(a)} /> : ''}
        {(a && a.length>0) ? namesList(a) : '-'}
      </div>
    },
    {
      title: "Group Name",
      dataIndex: "pef",
      filters: groups.map((group: any) => {
        return { text: group.name, value: group.id };
      }),
      width: 150,
      ellipsis: true,
      render: (a: any) => <Text>{a && a.name ? a.name : "-"}</Text>,
    },
    {
      title: "Data Year",
      key: "year",
      width: 120,
      ellipsis: true,
      render: (a: any) => <Text>{a.year}</Text>,
    },
    {
      title: "Freeze Date",
      dataIndex: "freezeDate",
      render: (a: any) => <Text>{a ? moment(a).format('DD-MM-YYYY') : "-"}</Text>,
    },
        {
        title: 'Collection Progress',
        dataIndex: 'percentageComplete',
        width: 200,
        ellipsis: true,
        render: (value: any) => <Space>
                <div style={{ width: 170 }}> <Progress  percent={parseInt(value)} status="active" strokeWidth={5} size="small" strokeColor="#E30613" trailColor="#1A1A1A"/></div>
            </Space>,
         }
    ];
    
   const namesList = (dataObj: any) => {
    let name = dataObj.map((item: any, i: number) => {
      return item.name;
    });

    let dataString = name.join(`, \n`);
    return dataString;
  }

  const getFunds = async () => {
    try {
      const {data: {data}} = await API_SERVICE.getFunds();
      setFunds(data.items);
    } catch (e) {
      notification.error({message: API_SERVICE.handleErrors(e)});
    }
  };

  const getGroups = async () => {
    try {
      const {data: {data}} = await API_SERVICE.getGroups();
      setGroups(data.items);
    } catch (e) {
      notification.error({message: API_SERVICE.handleErrors(e)});
    }
  };



  const handleTableChange = (
    pagination: any,
    filterValues: any,
  ) => {
    setFilters({ ...filters, ...filterValues ,page: 1 });
  };
  
  
  
  const refresh = async (page?: number, perPage? : number) => {
    const reqParams = { ...pagination, ...filters };
    if (search) {
      reqParams["q"] = search;
      reqParams["page"] = 1;
    }
     if(page){
            reqParams['page'] = page;
        }
        if(perPage){
            reqParams['perpage'] = perPage;
        }

        setFetching(true);
    try {
      const {data: { data }} = await API_SERVICE.getDataCollection(reqParams);
      setSurveys(data.items);
      setTotal(data.total);
      setPagination({page: data.page, perpage: data.perpage});	
    } catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    refresh();
    getFunds();
    getGroups();
  }, [props.location, search, filters]);

 
   return (
    <div className={"company-wrapper page-wrapper"}>
      {/*@ts-ignore*/}
      <AdminListLayout
        title={"Companies Data Collection Details "}
        pagination={<AccessControl id={10}>
                                 <Pagination current={pagination.page} pageSize={pagination.perpage} pageSizeOptions={[10,20,50]} showSizeChanger showQuickJumper total={total}
                                             onChange={(page, size) => {
                                                 setPagination({page: page, perpage: size});
                                                 refresh(page, size);
                                             }}/>
                             </AccessControl>}
        
      >
        <Table
          loading={fetching}
          scroll={{ x: true, y: "calc(100vh - 340px)" }}
          pagination={false}
          dataSource={surveys}
          columns={columns}
          onChange={handleTableChange}
        />

        <ConfirmationModal
          {...confirmationModal}
          callback={(a: boolean) => {
            setConfirmationModal({});
            if (a) {
              refresh();
            }
          }}
        />
      </AdminListLayout>
   </div>
  );
}
