import React, {useEffect, useState} from "react";
import "./index.scss";
import {Button, notification, Pagination, Space, Table, Typography, Input} from "antd";

import AdminListLayout from "@recoinsights/shared/components/layout/AdminListLayout/AdminListLayout";
import SurveyCreateOrEditPage from "./SurveysCreateOrEditPage";
import {CopyOutlined, DownloadOutlined, PlusOutlined, SearchOutlined} from "@ant-design/icons";
import {ADMIN_SURVEY_QUESTIONNAIRE} from "@recoinsights/shared/constants/RouteConstants";

import {DeleteOutlined, EditOutlined,} from "@material-ui/icons";
import {ConfirmationModal} from "components";
import API_SERVICE from "../../../services/api-service";
import moment from "moment";
import {useHistory} from "react-router-dom";
import eyeIcon from "@recoinsights/shared/assets/icons/Eye_Icon.svg";
import AccessControl, {accessIncludes} from "../../../components/AccessControl";

const {Text} = Typography;
const {Search} = Input;

export default function Survey(props: any) {
    const history = useHistory();
    const [surveys, setSurveys] = useState([] as any[]);
    const [search, setSearch] = useState("" as string);
    const [addNew, setAddNew] = useState(false);
    const [editSurvey, setEditSurvey] = useState(null as any);
    const [copySurvey, setCopySurvey] = useState(null as any);
    const [funds, setFunds] = useState([] as any[]);
    const [fetching, setFetching] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        perpage: "10",
    } as any);
    const [filters, setFilters] = useState({} as any);
    const [total, setTotal] = useState(10);
    const [confirmationModal, setConfirmationModal] = useState({} as any);
    const [groups, setGroups] = useState([] as any[]);

    const columns = [
        {
            title: "S.No",
            key: "sNO",
            width: "80px",
            render: (a: any, v: any, index: number) => (
                <Text>{(pagination.page - 1) * pagination.perpage + index + 1}</Text>
            ),
        },
        {
            title: "Company Name",
            key: "name",
            width: 200,
            ellipsis: true,
            render: (a: any) => <Text>{a.company.name}</Text>,
        },
        {
            title: "Funds",
            dataIndex: "funds",
            ellipsis: true,
            filters: funds.map((fund: any) => {
                return {text: fund.name, value: fund.id}
            }),
            render: (a: any) => <div className="pr-5" style={{
                wordWrap: 'break-word',
                wordBreak: 'break-word',
                width: '200px',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }}>
                {(a && a.length > 0) ?
                    <img src={eyeIcon} alt="icon" className="table-list-icon" title={namesList(a)}/> : ''}
                {(a && a.length > 0) ? namesList(a) : '-'}
            </div>
        },
        {
            title: "Group Name",
            dataIndex: "pef",
            filters: groups.map((group: any) => {
                return {text: group.name, value: group.id};
            }),
            width: 200,
            ellipsis: true,
            render: (a: any) => <Text>{a && a.name ? a.name : "-"}</Text>,
        },
        {
            title: "Reporting Period",
            key: "year",
            width: 280,
            ellipsis: true,
            render: (a: any) => <Text>{a.year ? a.year : (moment(a.startDate).format('YYYY-MM-DD') + ' - ' + moment(a.endDate).format('YYYY-MM-DD'))}</Text>,
        },
        {
            title: "Freeze Date",
            dataIndex: "freezeDate",
            render: (a: any) => <Text>{a ? moment(a).format('DD-MM-YYYY') : "-"}</Text>,
        },
        {
            title: "Actions",
            key: "action",
            width: 120,
            render: (value: any) => (
                <Space>
                    {
                        accessIncludes([49, 50]) ?
                            <>
                                <AccessControl id={49}>
                                    <Button type={"primary"}
                                            className="theme-button primary text-capitalize"
                                            style={{minWidth: 100}}
                                            onClick={() => {
                                                history.push(`${ADMIN_SURVEY_QUESTIONNAIRE}/${value.id}`);
                                            }}
                                    >
                                        {value.type ? (value.type.toLowerCase() === 'publish' ? 'published' : value.type.toLowerCase()) : '-'}
                                    </Button>
                                </AccessControl>
                                <AccessControl id={49}>
                                    <Button
                                        type="text"
                                        icon={<EditOutlined/>}
                                        onClick={() => {
                                            setEditSurvey(value);
                                        }}
                                    />
                                </AccessControl>

                                <AccessControl id={49}>
                                    <Button
                                        type="text"
                                        icon={<DownloadOutlined/>}
                                        onClick={() => {
                                            handleDownloadSurvey(value.id);
                                        }}
                                    />
                                </AccessControl>

                                <AccessControl id={50}>
                                    <Button
                                        type="text"
                                        icon={<CopyOutlined/>}
                                        onClick={() => {
                                            setCopySurvey(value);
                                        }}
                                    />
                                </AccessControl>

                                <AccessControl id={50}>
                                    <Button
                                        type="text"
                                        icon={<DeleteOutlined/>}
                                        onClick={() => {
                                            setConfirmationModal({
                                                message: `Delete Survey?`,
                                                action: API_SERVICE.deleteSurvey,
                                                params: [value.id],
                                            });
                                        }}
                                    />
                                </AccessControl>
                            </>
                            :
                            <div style={{width: 100}}>-</div>
                    }
                </Space>
            ),
        },
    ];

    const namesList = (dataObj: any) => {
        let name = dataObj.map((item: any, i: number) => {
            return item.name;
        });

        let dataString = name.join(`, \n`);
        return dataString;
    }


    const getFunds = async () => {
        try {
            const {data: {data}} = await API_SERVICE.getFunds();
            setFunds(data.items);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };

    const getGroups = async () => {
        try {
            const {data: {data}} = await API_SERVICE.getGroups();
            setGroups(data.items);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };


    const setFormInitialValues = () => {
        let reqObj = {
            id: editSurvey && editSurvey.id,
            year: editSurvey && editSurvey?.year,
            reportingPeriod: editSurvey && editSurvey.startDate && [moment(editSurvey.startDate), moment(editSurvey.endDate)],
            pefId: editSurvey && editSurvey.pef && editSurvey.pef.id,
            companyId: editSurvey && editSurvey.company && editSurvey.company.id,
            freezeDate: editSurvey && moment(editSurvey.freezeDate),
            fundIds:
                editSurvey &&
                editSurvey.funds &&
                editSurvey.funds.length > 0 &&
                editSurvey.funds.map((item: any) => {
                    return item.id;
                }),
            companyStructure: '',
            growthStage: '',
            percentageOwnership: editSurvey && editSurvey.percentageOwnership
        };

        if (editSurvey && editSurvey.isPrivate) {
            reqObj['companyStructure'] = 'isPrivate'
        } else if (editSurvey && editSurvey.isPublic) {
            reqObj['companyStructure'] = 'isPublic'
        }

        if (editSurvey && editSurvey.isVenture) {
            reqObj['growthStage'] = 'isVenture'
        } else if (editSurvey && editSurvey.isGrowth) {
            reqObj['growthStage'] = 'isGrowth'
        } else if (editSurvey && editSurvey.isPrivateEquity) {
            reqObj['growthStage'] = 'isPrivateEquity'
        }

        return reqObj;
    };

    const setFormInitialValues1 = () => {
        let reqObj = {
            id: copySurvey && copySurvey.id,
            year: copySurvey && copySurvey?.year,
            reportingPeriod: copySurvey && copySurvey.startDate && [moment(copySurvey.startDate), moment(copySurvey.endDate)],
            pefId: copySurvey && copySurvey.pef && copySurvey.pef.id,
            companyId: copySurvey && copySurvey.company && copySurvey.company.id,
            freezeDate: copySurvey && moment(copySurvey.freezeDate),
            fundIds:
                copySurvey &&
                copySurvey.funds &&
                copySurvey.funds.length > 0 &&
                copySurvey.funds.map((item: any) => {
                    return item.id;
                }),

            companyStructure: '',
            growthStage: '',
            percentageOwnership: copySurvey && copySurvey.percentageOwnership
        };

        if (copySurvey && copySurvey.isPrivate) {
            reqObj['companyStructure'] = 'isPrivate'
        } else if (copySurvey && copySurvey.isPublic) {
            reqObj['companyStructure'] = 'isPublic'
        }

        if (copySurvey && copySurvey.isVenture) {
            reqObj['growthStage'] = 'isVenture'
        } else if (copySurvey && copySurvey.isGrowth) {
            reqObj['growthStage'] = 'isGrowth'
        } else if (copySurvey && copySurvey.isPrivateEquity) {
            reqObj['growthStage'] = 'isPrivateEquity'
        }

        return reqObj;
    };

    const handleDownloadSurvey = async (id: number) => {
        try {
            const {data: {data}} = await API_SERVICE.downloadSurveyResponses(id);
            window.open(data);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };


    const handleTableChange = (
        pagination: any,
        filterValues: any,
        sorter: any
    ) => {
        setFilters({...filters, ...filterValues, page: 1});
    };

    const refresh = async (page?: number, perPage?: number) => {
        const reqParams = {...pagination, ...filters};
        if (search) {
            reqParams["q"] = search;
            reqParams["page"] = 1;
        }
        if (page) {
            reqParams['page'] = page;
        }
        if (perPage) {
            reqParams['perpage'] = perPage;
        }
        setFetching(true);
        try {
            const {
                data: {data},
            } = await API_SERVICE.getSurveys(reqParams);
            setSurveys(data.items);
            setTotal(data.total);
            setPagination({page: data.page, perpage: data.perpage});
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        refresh();
        getFunds();
        getGroups();
        // getDataYears();
    }, [props.location, search, filters]);

    return (
        <div className={"company-wrapper page-wrapper"}>
            {/*@ts-ignore*/}
            <AdminListLayout
                title={"All Surveys"}
                titleSearch={
                    <AccessControl id={51}>
                        <div className="search-bar">
                            <Search
                                placeholder="Search a Survey"
                                allowClear
                                enterButton={<SearchOutlined/>}
                                onSearch={(e) => {
                                    setSearch(e);
                                }}
                            />
                        </div>
                    </AccessControl>
                }
                titleAction={
                    <AccessControl id={48}>
                        <Button
                            type={"primary"}
                            className="theme-button primary"
                            onClick={() => setAddNew(true)}
                            icon={<PlusOutlined/>}
                        >
                            Create New
                        </Button>
                    </AccessControl>
                }

                pagination={
                    accessIncludes([54]) && <Pagination
                        current={pagination.page} pageSize={pagination.perpage} pageSizeOptions={[10, 20, 50]}
                        showSizeChanger
                        showQuickJumper
                        total={total}
                        onChange={(page, size) => {
                            setPagination({page: page, perpage: size});
                            refresh(page, size);
                        }}
                    />
                }
            >
                {addNew && (
                    <SurveyCreateOrEditPage
                        onClose={(e: boolean) => {
                            if (e) {
                                refresh();
                            }
                            setAddNew(false);
                        }}
                    />
                )}
                {editSurvey && (
                    <SurveyCreateOrEditPage
                        details={setFormInitialValues()}
                        onClose={(e: boolean) => {
                            if (e) {
                                refresh();
                            }
                            setEditSurvey(null);
                        }}
                    />
                )}
                {copySurvey && (
                    <SurveyCreateOrEditPage
                        details={setFormInitialValues1()}
                        isCopy={true}
                        onClose={(e: boolean) => {
                            if (e) {
                                refresh();
                            }
                            setCopySurvey(null);
                        }}
                    />
                )}
                <AccessControl id={47}>
                    <Table
                        loading={fetching}
                        scroll={{x: true, y: "calc(100vh - 340px)"}}
                        pagination={false}
                        dataSource={surveys}
                        columns={columns}
                        onChange={handleTableChange}
                    />
                </AccessControl>

                <ConfirmationModal
                    {...confirmationModal}
                    callback={(a: boolean) => {
                        setConfirmationModal({});
                        if (a) {
                            refresh();
                        }
                    }}
                />
            </AdminListLayout>
        </div>
    );
}
