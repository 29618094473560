import moment from 'moment';

export function ISOtoDateString(ISODate){
    return moment.utc(ISODate).format('DD-MM-YYYY')
}

export function isResultsEnabled() {
    return process.env.REACT_APP_RESULTS_ENABLED === '1';
}

export function downloadFile(data, name, fileType = 'text/CSV') {
    const blob = new Blob([data], { type: fileType });
    const url = window.URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.setAttribute('href', url)
    a.setAttribute('download', name);
    a.click()
}

export function isEmissionsEnabled() {
    return process.env.REACT_APP_EMISSIONS_ENABLED === '1';
}
