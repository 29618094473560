import {ResultSet} from "@recoinsights/types";
import {BoardMaturity, Metrics, resolveMetricResults} from "@recoinsights/metrics";
import {DualAxes} from "@ant-design/charts";

enum Labels {
    Year = "Year",
    Score = "Score",
    Type = "Type",
    Board = "Composition, independence & effectiveness",
    Management = "Management skills",
    TalentCompensation = "Talent & compensation",
    PoliciesProcedures = "Policies & procedures",
    ControlRisk = "Control & risk management",
    AuditDisclosure = "Audit & disclosure",
    ESG = "Other ESG factors",
    Total = "Total",
}

interface Props {
    results: ResultSet[];
}

function BoardMaturityChart({ results }: Props) {
    const barData: { [Labels.Year]: number, [Labels.Score]?: number, [Labels.Type]: string, }[] = [];
    const lineData: { [Labels.Year]: number, [Labels.Total]?: number }[] = [];

    results.forEach((result) => {
        const metric = resolveMetricResults(Metrics.BoardMaturity, result) as BoardMaturity;
        const { board, management, policiesProcedures, talentCompensation, controlRisk, auditDisclosure, esg,  } = metric.getScoreBreakdown();

        barData.push({
            [Labels.Year]: result.year,
            [Labels.Score]: board.score !== undefined ? Math.round(board.score * 100) : undefined,
            [Labels.Type]: Labels.Board,
        });

        barData.push({
            [Labels.Year]: result.year,
            [Labels.Score]: management.score !== undefined ? Math.round(management.score * 100) : undefined,
            [Labels.Type]: Labels.Management,
        });

        barData.push({
            [Labels.Year]: result.year,
            [Labels.Score]: policiesProcedures.score !== undefined ? Math.round(policiesProcedures.score * 100) : undefined,
            [Labels.Type]: Labels.PoliciesProcedures,
        });

        barData.push({
            [Labels.Year]: result.year,
            [Labels.Score]: talentCompensation.score !== undefined ? Math.round(talentCompensation.score * 100) : undefined,
            [Labels.Type]: Labels.TalentCompensation,
        });

        barData.push({
            [Labels.Year]: result.year,
            [Labels.Score]: controlRisk.score !== undefined ? Math.round(controlRisk.score * 100) : undefined,
            [Labels.Type]: Labels.ControlRisk,
        });

        barData.push({
            [Labels.Year]: result.year,
            [Labels.Score]: auditDisclosure.score !== undefined ? Math.round(auditDisclosure.score * 100) : undefined,
            [Labels.Type]: Labels.AuditDisclosure,
        });

        barData.push({
            [Labels.Year]: result.year,
            [Labels.Score]: esg.score !== undefined ? Math.round(esg.score * 100) : undefined,
            [Labels.Type]: Labels.ESG,
        });

        lineData.push({
            [Labels.Year]: result.year,
            [Labels.Total]: metric.score !== undefined ? Math.round(metric.score * 100) : undefined,
        });
    });

    return (
        <DualAxes
            data={[barData, lineData]}
            xField={Labels.Year}
            yField={[Labels.Score, Labels.Total]}
            yAxis={[{min: 0, max: 100}, {min: 0, max: 100}]}
            legend={{ position: "right-bottom", maxWidthRatio: 0.33, padding: [0, 0, 0, 30], itemHeight: 10, }}
            geometryOptions={[
                {
                    geometry: 'column',
                    isGroup: true,
                    seriesField: Labels.Type,
                },
                {
                    geometry: 'line',
                    lineStyle: {
                        lineWidth: 2,
                    },
                },
            ]}
            height={200} />
    );
}

export default BoardMaturityChart;
