import React from "react";
import {Link} from "react-router-dom";
import {Table} from "antd";
import {Metric, ResultSet} from "@recoinsights/types";
import {Metric as MetricResult, Metrics, resolveMetricResults} from "@recoinsights/metrics";
import CompanyInterface from "@recoinsights/shared/types/Company";
import {COMPANY_RESULT_DASHBOARD} from "@recoinsights/shared/constants/RouteConstants";
import {useRouter} from "../../helpers/location";
import {sortResultsByCompany} from "../../helpers/portfolio";
import ScoreComparisonIndicator from "./ScoreComparisonIndicator";

interface Props {
    companies: CompanyInterface[];
    metrics: Metric[];
    metricUid: Metrics;
    results: ResultSet[];
    years: number[];
}

interface MetricScore {
    company: string;
    years: {year: number, result: MetricResult}[];
}

function MetricScorecard({ companies, metrics, metricUid, results, years }: Props) {
    const { href, route } = useRouter();

    const scores: MetricScore[] = [];

    results.sort(sortResultsByCompany(companies)).forEach((result) => {
        let score = scores.find((score) => score.company === result.company);

        if (!score) {
            score = {
                company: result.company,
                years: [],
            };

            scores.push(score);
        }

        const metricResult = resolveMetricResults(metricUid, result);

        score.years.push({
            year: result.year,
            result: metricResult,
        });
    });

    function getCompany(companyUid: string) {
        return companies.find((company) => company.uuid === companyUid);
    }

    const tableHeaders: any[] = [
        {
            title: (
                <h4 className="mb-0">Company</h4>
            ),
            dataIndex: "company",
            render: (companyUid: string) => {
                const company = getCompany(companyUid);

                if (!company) {
                    return '-';
                }

                return (
                    <Link
                        to={href(route({
                            route: COMPANY_RESULT_DASHBOARD,
                            routeParams: {companyId: company.id},
                        }))}>
                        {company.name}
                    </Link>
                );
            },
        },
        ...years.map((year) => ({
            title: (
                <p className="text-center mb-0">{year}</p>
            ),
            render: (data: MetricScore) => {
                const score = data.years.find((dataYear) => dataYear.year === year);
                const previousScore = data.years.find((dataYear) => dataYear.year === year - 1);

                return (
                    <p className="text-center mb-0">
                        {score && previousScore && (
                            <>
                                <ScoreComparisonIndicator metricResultA={score.result} metricResultB={previousScore.result} />
                                &nbsp;
                            </>
                        )}
                        {score?.result.renderScore()}
                    </p>
                )
            },
        }))
    ].filter((value) => value);

    return (
        <section data-testid="metric-scorecard" className="table-responsive" style={{ height: 400, overflowX: "hidden" }}>
            <Table
                columns={tableHeaders}
                dataSource={scores}
                pagination={false}
            />
        </section>
    );
}

export default MetricScorecard;
