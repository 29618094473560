import {ResultSet} from "@recoinsights/types";
import {CustomerSatisfaction, Metrics, resolveMetricResults} from "@recoinsights/metrics";
import {Line} from "@ant-design/charts";

enum Labels {
    Year = "Year",
    NPS = "NPS",
}

interface Props {
    results: ResultSet[];
}

function CustomerSatisfactionChart({ results }: Props) {
    const data = results.map((result) => {
        const metric = resolveMetricResults(Metrics.CustomerSatisfaction, result) as CustomerSatisfaction;

        return {
            [Labels.Year]: result.year,
            [Labels.NPS]: metric.score,
        }
    });

    return (
        <Line
            data={data}
            xField={Labels.Year}
            yField={Labels.NPS}
            legend={{ position: "right-bottom", maxWidthRatio: 0.33, padding: [0, 0, 0, 30], itemHeight: 10, }}
            height={200} />
    );
}

export default CustomerSatisfactionChart;
