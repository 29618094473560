import {ResultSet} from "@recoinsights/types";
import {CyberSecurityMaturity, Metrics, resolveMetricResults} from "@recoinsights/metrics";
import {Column} from "@ant-design/charts";

enum Labels {
    Year = "Year",
    Score = "Score",
    Type = "Type",
    CyberSecurity = "Cyber security",
}

interface Props {
    results: ResultSet[];
}

function CyberSecurityMaturityChart({ results }: Props) {
    const data = results.map((result) => {
        const metric = resolveMetricResults(Metrics.CyberSecurityMaturity, result) as CyberSecurityMaturity;

        return {
            [Labels.Year]: result.year,
            [Labels.Score]: metric.score !== undefined ? Math.round(metric.score * 100) : undefined,
            [Labels.Type]: Labels.CyberSecurity,
        }
    });

    return (
        <Column
            data={data}
            isStack={true}
            seriesField={Labels.Type}
            xField={Labels.Year}
            yField={Labels.Score}
            legend={{ position: "right-bottom", maxWidthRatio: 0.33, padding: [0, 0, 0, 30], itemHeight: 10, }}
            height={200}
        />
    );
}

export default CyberSecurityMaturityChart;
