import React from 'react';
import {notification, Upload} from 'antd';
import API_SERVICE from "../../../services/api-service";


export default function GHGUpload(props: any) {

    async function handleUpload(file: any) {
        let bodyFormData = new FormData();
        bodyFormData.append('file', file);
        try {
            await API_SERVICE.uploadGHGFile(bodyFormData);
            notification.success({message: 'File Uploaded successfully'});
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    }

    return (
        <Upload
            showUploadList={false}
            beforeUpload={(file) => {
                console.log(file);
                if (file && (file.name.endsWith('xlsx') || file.name.endsWith('xlx'))) {
                    handleUpload(file);
                    return true;
                }
                else{
                    notification.error({message: 'Please upload Excel file'});
                    return false;
                }
            }}
        >
            {props.children}
        </Upload>
    );
}
