import React from 'react';
import 'antd/dist/antd.css';
import './index.scss';
import {Table} from 'antd';

export default function TableData(props: any) {
  const columns = [
    {
      title: 'Initiative',
      dataIndex: 'init',
      onCell: (_: any, index: number) => {
        if (index === 0) {
          return { rowspan: 2 };
        }
        if(index === 1){
          return { className: 'd-none' };
        }
      },
    },
    {
      title: 'KPI',
      dataIndex: 'kpi',
    },
    {
      title: 'Target(2021)',
      dataIndex: 't21',
    },
    {
      title: 'Target(2022)',
      dataIndex: 't22',
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
    },
  ];

  const data = [
    {
      key: '1',
      init: 'Climate',
      kpi: ' % Renewable Energy Consumed',
      t21: '>60%',
      t22: '>80%',
      notes: 'Plan to increase target to >80% in 2022 after exceeding target in 2021',
    },
    {
      key: '2',
      init: 'Climate',
      t21: '<150 t CO2e/ $M Rev.',
      t22: '<150 t CO2e/ $M Rev.',
      kpi: 'GHG Intensity (Scope 1 & 2 emissions/ Revenue)',
      notes: 'Maintaining current target of <150 t CO2 e/ $M Rev. for 2022 ',
    },
    {
      key: '3',
      init: 'Diversity',
      kpi: 'Gender Diversity (% Board + Management)',
      t21: '>40%',
      t22: '>45%',
      notes: 'Plan to increase target to >45% in 2022 after exceeding target in 2021',
    },
    {
      key: '4',
      init: 'Employee Engagement',
      kpi: 'Employee Net Promoter Score (% eNPS)',
      t21: '>30%',
      t22: '>30%',
      notes: 'Significant effort required to meet eNPS target in 2022 if left unchanged',
    },
    {
      key: '5',
      init: 'Board Maturity',
      kpi: 'Average Board Maturity Score (%)',
      t21: '>80%',
      t22: '>80%',
      notes: 'Missed 2021 target but plan in place to exceed in 80% score in 2022',
    },
  ];

  //@ts-ignore
  return <Table pagination={false} scroll={{ x: true}} columns={columns} dataSource={data} bordered />
}
