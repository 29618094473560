import {ResultSet} from "@recoinsights/types";
import {GenderDiversity, Metrics, resolveMetricResults} from "@recoinsights/metrics";
import {DualAxes} from "@ant-design/charts";

enum Labels {
    Year = "Year",
    Female = "Female %",
    Type = "Type",
    Management = "Management",
    Board = "Board",
    Total = "Total",
}

interface Props {
    results: ResultSet[];
}

function GenderDiversityChart({ results }: Props) {
    const barData: { [Labels.Year]: number, [Labels.Female]?: number, [Labels.Type]: string, }[] = [];
    const lineData: { [Labels.Year]: number, [Labels.Total]?: number }[] = [];

    results.forEach((result) => {
        const metric = resolveMetricResults(Metrics.GenderDiversity, result) as GenderDiversity;
        const managementDiversity = metric.getManagementDiversity();
        const boardDiversity = metric.getBoardDiversity();

        lineData.push({
            [Labels.Year]: result.year,
            [Labels.Total]: metric.score !== undefined ? Math.round(metric.score * 100) : undefined,
        });

        barData.push({
            [Labels.Year]: result.year,
            [Labels.Female]: managementDiversity !== undefined ? Math.round(managementDiversity * 100) : undefined,
            [Labels.Type]: Labels.Management,
        });

        barData.push({
            [Labels.Year]: result.year,
            [Labels.Female]: boardDiversity !== undefined ? Math.round(boardDiversity * 100) : undefined,
            [Labels.Type]: Labels.Board,
        });
    });

    let yAxisMax = barData.reduce((max, data) => {
        const value = data[Labels.Female];

        return value !== undefined && value > max ? value : max;
    }, 0);

    if (yAxisMax < 50) {
        yAxisMax = 50;
    }

    return (
        <DualAxes
            data={[barData, lineData]}
            xField={Labels.Year}
            yField={[Labels.Female, Labels.Total]}
            yAxis={[{ min: 0, max: yAxisMax }, { min: 0, max: yAxisMax}]}
            legend={{ position: "right-bottom", maxWidthRatio: 0.33, padding: [0, 0, 0, 30], itemHeight: 10, }}
            geometryOptions={[
                {
                    geometry: 'column',
                    isGroup: true,
                    seriesField: Labels.Type,
                },
                {
                    geometry: 'line',
                    lineStyle: {
                        lineWidth: 2,
                    },
                },
            ]}
            height={200}
        />
    );
}

export default GenderDiversityChart;
