import React, {useEffect, useState} from "react";
import './kpiResultCalculation.css';

import {Button, Checkbox, Col, Form, Input, notification, Radio, Row, Space, Table, Typography} from "antd";
import {MinusCircleOutlined, SearchOutlined} from "@ant-design/icons";
import API_SERVICE from '../../../services/api-service';
import Header from '../../../components/Header';

const { Text, Title } = Typography

const valueTypes = [
  {name: 'Value', range: 'Value', type: 'VALUE', outofrangeText: 'GE Not Applicable'},
  {name: 'Text', range: 'Length', type: 'TEXT', outofrangeText: 'Low'},
  {name: 'Score', range: 'Score', type: 'SCORE', outofrangeText: 'N/A'}
];

export default function KpiResultCalculation(props: any) {
  const {match: {params: { surveyId, kpiId }}} = props;
  const { TextArea } = Input;
  const columns: any = [
    {
      title: "Q.ID",
      dataIndex: "id",
    },
    {
      title: "Question Title",
      dataIndex: "question",
      render: (value: string) => {
        return (
          <TextArea
            className={'question-title'}
            value={value.replace(/(<([^>]+)>)/ig, '')}
            autoSize={true} />
        )
      },
      width: 400
    },
    {
      title: "Response",
      render: (data: any) => {
        let value: string = '';
        if (data.scopeTableData && data.scopeTableData.length) {
          let kgco2eGhgEmission_total = 0;
          data.scopeTableData.map((obj: any)=>{
            kgco2eGhgEmission_total += obj.kgco2eGhgEmission;
          })
          //@ts-ignore
          value = `${kgco2eGhgEmission_total}`.toFloat(2);
        }
        else {
          data.responses.map((resp: any) => {
            if (resp.optionId) {
              value += (resp.option? resp.option['option']: '')+'\n';
            }
            else {
              //@ts-ignore
              let text = `${resp.text}`.toFloat(2);
              value += `${text} ${resp.unit? resp.unit.unit:''}\n`;
            }
          })
        }
        return (
          <TextArea
            className={'question-response'}
            value={value.replace(/(<([^>]+)>)/ig, '')}
            autoSize={true} />
        )
      },
      width: 200
    },
  ];

  const [valueType, setValueType] = useState(0);
  const [defaultValueType, setDefaultValueType] = useState <string> ('');
  const [formula, setFormula] = useState <string> ('');
  const [unit, setUnit] = useState <string> ('');
  const [checked, setChecked] = useState <boolean> (false);
  const [defaultRangeArr, setDefaultRangeArr] = useState <any[]> ([]);
  const [rangeArr, setRangeArr] = useState <any[]> ([]);
  const [response, setResponse] = useState('');

  const [search, setSearch] = useState <string> ('');
  const [surveyDetails, setSurveyDetails] = useState <any> ({});
  const [fundDetails, setFundDetails] = useState <any> ({});
  const [kpiName, setKpiName] = useState <string> ('');
  const [questions, setQuestions] = useState <any[]> ([]);
  const [loading, setLoading] = useState(true);

  const calculateKpiResult = async () => {
      try {
          setLoading(true);
          const reqObj = {
            fundId: fundDetails.id,
            companyId: surveyDetails.companyId,
            dkpiId: kpiId,
            formula: formula,
            unit: unit,
            year: surveyDetails.year,
            isRangeBased: checked,
            type: valueTypes[valueType]['type'],
            dkpiResultsRanges: checked? rangeArr : []
          }
          //return console.log(reqObj)
          const {data: { data }} = await API_SERVICE.calculateKpiResult(kpiId, reqObj);
          setResponse(data.responsePreview)
          notification.success({message: "Result updated"});
      } catch (e) {
          notification.error({message: API_SERVICE.handleErrors(e)});
      } finally {
        setLoading(false)
      }
  };

  const getSurveyQuestions = async (params: any = {}) => {
      try {
          setLoading(true);
          const {data: { data }} = await API_SERVICE.getSurveyQuestions(
            surveyId, { page: -1, order: 'asc', ...params}
          );
          setQuestions(data.items);
      } catch (e) {
          notification.error({message: API_SERVICE.handleErrors(e)});
      } finally {
        setLoading(false)
      }
  };

  const getFundDetails = async () => {
      try {
          setLoading(true);
          const {data: { data }} = await API_SERVICE.getDataCollection({id: surveyId});
          if (data.items.length) {
            setSurveyDetails(data.items[0]);
            //get dkpi name
            const {data: {data: {name}}} = await API_SERVICE.getDkpi({id: kpiId});
            setKpiName(name);
          }
      } catch (e) {
          notification.error({message: API_SERVICE.handleErrors(e)});
      } finally {
        setLoading(false)
      }
  };

  const getKpiResultDetails = async () => {
    const {data: { data }} = await API_SERVICE.getKpiResultDetails(
      kpiId,
      fundDetails.id,
      surveyDetails.year,
      surveyDetails.companyId
    );
    const keys = ['unit', 'formula', 'resultPreview', 'isRangeBased', 'type', 'dkpiResultsRanges'];
    const setters = [setUnit, setFormula, setResponse, setChecked, setDefaultValueType, setDefaultRangeArr];
    keys.map((key,i) => {
      if (data[key]) {
        setters[i](data[key])
      }
    })
    if (data.type) {
      valueTypes.map((obj,i)=>{
        if (obj.type === data.type) {
          setValueType(i)
        }
      })
    }
  }

  useEffect(()=>{
    getFundDetails();
  },[])

  useEffect(()=>{
    if (surveyDetails.id) {
      if (surveyDetails.funds.length) {
        setFundDetails(surveyDetails.funds[0]);
      }
      getSurveyQuestions()
    }
  },[surveyDetails])

  useEffect(()=>{
    if (fundDetails.id) {
      getKpiResultDetails();
    }
  },[fundDetails])

  return (
    <div className="page-wrapper Benchmarking-role-wrapper">
      <div>
        <Row justify="space-between" align="middle" className="p-2 bottomLine">
          <Col>
            <Header headings={[
              "KPI Result Calculation",
              fundDetails.name,
              surveyDetails.company && surveyDetails.company.name,
              surveyDetails.year,
              kpiName
              ]} />
          </Col>
          <Col>
            <div>
              <Button
                  loading={loading}
                type="primary"
                htmlType="submit"
                onClick={calculateKpiResult}>
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        <Col sm={16}>
          <div className="p-2">
            <div className="d-flex mt-2 mb-2">
              <Form>
                <Form.Item>
                  <Input
                    placeholder="Search a Question by its title"
                    onChange={({target: {value}}) => {setSearch(value)}}
                  />
                </Form.Item>
              </Form>
              <Button
                type="primary"
                htmlType="submit"
                onClick={()=>getSurveyQuestions({question: search})}>
                <SearchOutlined />
                Search
              </Button>
            </div>

            <Table
              columns={columns}
              dataSource={questions}
              bordered
              size="small"
            />
          </div>
        </Col>

        <Col sm={8} className="bottomLeft">
          <Row
            align="middle"
            className="bottomLine pb-1 d-flex justify-content-center mt-1"
          >
            <Col sm={4}>
              <b>Response preview</b>
            </Col>
            <Col sm={10}>
              {/*@ts-ignore*/}
              <Input value={`${response}`.toFloat(2)} disabled className="inputkpi" />
            </Col>
            <Col sm={6}>
              <Input
                placeholder="unit"
                className="inputkpi ml-1"
                value={unit}
                onChange={({target: {value}}) => {setUnit(value)}} />
            </Col>
          </Row>
          <div className="p-2">
            <div>
              <TextArea
                rows={6}
                className="paraKpi"
                value={formula}
                onChange={({target: {value}}) => setFormula(value)} />
            </div>
            <div className="p-2">
              <Row align="middle" justify="space-between">
                <Col>
                  <Radio.Group value={valueType} onChange={({target: {value}})=> setValueType(value)}>
                    {valueTypes.map((obj: any, i: number) => <Radio value={i} key={i}>{obj.name}</Radio>)}
                  </Radio.Group>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={calculateKpiResult}>
                    Calculate
                  </Button>
                </Col>
              </Row>
              <p>
                {/* @ts-ignore */}
                <Checkbox checked={checked} onClick={({target: {checked}}) => setChecked(checked)}>
                  Range based
                </Checkbox>
              </p>
              {checked &&
                <KpiResultRangeInput
                  type={valueType}
                  defaultType={defaultValueType}
                  defaultValue={defaultRangeArr}
                  onChange={setRangeArr}
                  />
              }
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}


const KpiResultRangeInput = (props: any) => {
  const [rangeArr, setRangeArr] = useState <any[]> ([]);
  const [outofrangeText, setOutofrangeText] = useState('');
  const rangeObj = {
    rangeFrom: 0,
    rangeTo: 0,
    rangeText: "",
    type: "RANGE"
  }
  const getInitialRangeArr = () => {
    const arr = [...props.defaultValue]
    const length = arr.length;
    if (length && valueTypes[props.type]['type'] === props.defaultType) {
      setOutofrangeText(arr[length-1]['rangeText']);
      arr.splice(length-1, 1);
      const value: any[] = [];
      arr.map((obj,i) => {
        value.push({
          rangeFrom: obj.rangeFrom,
          rangeTo: obj.rangeTo,
          rangeText: obj.rangeText,
          type: "RANGE"
        })
      })
      setRangeArr(value);
    }
    else {
      setRangeArr([{...rangeObj}]);
      setOutofrangeText(
        valueTypes[props.type].outofrangeText
      );
    }
  }
  const push = () => {
    const arr = [...rangeArr];
    arr.push({...rangeObj});
    setRangeArr(arr);
  }
  const pop = (index: number) => {
    const arr = [...rangeArr];
    arr.splice(index, 1);
    setRangeArr(arr);
  }
  const update = (index: number, key: string, event: any) => {
    const arr = [...rangeArr];
    arr[index][key] = event.target.value;
    setRangeArr(arr);
  }
  useEffect(()=>{
    if (rangeArr.length) {
      props.onChange(rangeArr.concat({
        "rangeFrom": null,
        "rangeTo": null,
        "rangeText": outofrangeText,
        "type": "DEFAULT"
      }))
    }
  },[rangeArr])
  useEffect(()=>{
    getInitialRangeArr()
  },[props.defaultValue, props.defaultType, props.type])
  return (
    <Space direction={'vertical'}>
      {
        rangeArr.map((obj: any, i: number) => {
          return (
            <div>
              <Title level={5}>
                {`${valueTypes[props.type]['range']} Range ${i+1}`}
              </Title>
              <Row gutter={10} align="middle" justify="space-between">
                <Col>
                  <Row gutter={10} align="middle" justify="space-between">
                    <Col>
                      <Input
                        value={obj.rangeFrom}
                        onChange={(e)=>{update(i, 'rangeFrom', e)}}
                        style={{width: 60}}
                        />
                    </Col>
                    <Col>
                      <Text>to</Text>
                    </Col>
                    <Col>
                      <Input
                        value={obj.rangeTo}
                        onChange={(e)=>{update(i, 'rangeTo', e)}}
                        style={{width: 60}}
                        />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Input
                    value={obj.rangeText}
                    onChange={(e)=>{update(i, 'rangeText', e)}}
                    placeholder="Exact Display Text"
                    style={{width: 160, marginLeft: 12}}
                    />
                </Col>
                <Col>
                  {(rangeArr.length > 1) && (
                    <div className="text-primary cursor-pointer text-center">
                      <MinusCircleOutlined onClick={()=>pop(i)}/>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          )
        })
      }
      <Button onClick={push} type="primary" size="large">
        <Title level={4} style={{color: '#fff'}}>+</Title>
      </Button>
      <Row align="middle" justify="space-between">
        <Col>
          <Title level={5}>
            {`${valueTypes[props.type]['range']} out of Range`}
          </Title>
        </Col>
        <Col>
          <Input
            style={{
              width: 160,
              marginLeft: 12,
              marginRight: 24
            }}
            value={outofrangeText}
            onChange={({target: {value}}) => {setOutofrangeText(value)}} />
        </Col>
      </Row>
    </Space>
  );
}
