import {EmissionsIntensity} from "@recoinsights/metrics";
import {Pie} from "@ant-design/charts";
import React from "react";

enum Labels {
    Emissions = "Emissions (tCO2e)",
    Type = "Type",
    Scope1 = "Scope 1",
    Scope2 = "Scope 2",
    Scope3 = "Scope 3",
    Total = "Total",
}

interface Props {
    result: EmissionsIntensity,
}

function EmissionsByScopeChart({ result }: Props) {
    const data: { [Labels.Emissions]?: number, [Labels.Type]: string, }[] = [];

    const scope1Emissions = result.getScope1Emissions();
    const scope2Emissions = result.getScope2Emissions();
    const scope3Emissions = result.getScope3Emissions();

    data.push({
        [Labels.Emissions]: scope1Emissions ? Math.round(scope1Emissions / 1000) : undefined,
        [Labels.Type]: Labels.Scope1,
    });

    data.push({
        [Labels.Emissions]: scope2Emissions ? Math.round(scope2Emissions / 1000) : undefined,
        [Labels.Type]: Labels.Scope2,
    });

    data.push({
        [Labels.Emissions]: scope3Emissions ? Math.round(scope3Emissions / 1000) : undefined,
        [Labels.Type]: Labels.Scope3,
    });

    const config = {
        appendPadding: 10,
        data,
        angleField: Labels.Emissions,
        colorField: Labels.Type,
        radius: 1,
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
        ],
        legend: {
            position: 'bottom' as const
        },
        height: 200,
    };

    return (
        <Pie {...config} />
    );
}

export default EmissionsByScopeChart;
