import React, {useEffect, useState} from "react";
import 'antd/dist/antd.css';
import '../index.scss';
import './index.scss';
import {notification,} from "antd";
import API_SERVICE from "../../../../services/api-service";


export default function Total(props : any) {
    const surveyId=props.surveyId;
    const [totalCarbonIntensities, setTotalCarbonIntensities] = useState({
        "scope1OperationOfCompanyFacility": 0,
        "scope1CompanyVehicleUse": 0,
        "scope1RefrigerantUsage": 0,
        "scope2PurchasedElectricity": 0,
        "scope3CompanyBusinessTravel": 0,
        "totalGhgEmissions": 0,
        "scope12CarbonIntensity": 0
    } as any);


    const getTotalCarbonIntensities = async () => {
        try {
            const {data: {data}} = await API_SERVICE.getTotalCarbonIntensities(surveyId);
            setTotalCarbonIntensities(data);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };

    const formatter = new Intl.NumberFormat('en-US');



    useEffect(() => {
        getTotalCarbonIntensities();
    }, [])

    return (
        <div className="question-card total-tab">
            <div>
                <div className={'mb-1'}>Scope 1 Operation of Company Facilities = {formatter.format(totalCarbonIntensities.scope1OperationOfCompanyFacility.toFixed(2))} kgCO2e</div>
                <div className={'mb-1'}>Scope 1 Company Vehicle Use = {formatter.format(totalCarbonIntensities.scope1CompanyVehicleUse.toFixed(2))} kgCO2e</div>
                <div className={'mb-1'}>Scope 1 Refrigerant Use = {formatter.format(totalCarbonIntensities.scope1RefrigerantUsage.toFixed(2))} kgCO2e</div>
                <div className={'mb-1'}>Scope 2 Purchased Electricity = {formatter.format(totalCarbonIntensities.scope2PurchasedElectricity.toFixed(2))} kgCO2e</div>
                <div className={'mb-1'}>Scope 3 Company Business Travel = {formatter.format(totalCarbonIntensities.scope3CompanyBusinessTravel.toFixed(2))} kgCO2e</div>
                <div className={'mb-1'}><strong>Total GHG Emissions: {formatter.format(totalCarbonIntensities.totalGhgEmissions.toFixed(2))} kgCO2e</strong></div>
                <hr/>
                <div className={'mb-1'}><strong>Scope 1 & 2 Carbon Intensity = {formatter.format(totalCarbonIntensities.scope12CarbonIntensity.toFixed(2))} kgCO2e / $1M</strong></div>
            </div>
        </div>

    );
}
