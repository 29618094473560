import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import {notification, Select} from 'antd';
import API_SERVICE from "../../../services/api-service";
import {getUser} from '@recoinsights/client/src/services/local-storage';


export default function LocationSelect(props: any) {
    const location = useLocation();
    const {Option} = Select;
    const [locations, setLocations] = useState([] as any []);
    const [selectedValue, setSelectedValue] = useState(props.innitialValue);
    const roleId=getUser()?.user?.role?.id;

    const getLocations = async () => {
        try {
            const {data: {data}} = await API_SERVICE.getCountries();
            setLocations(data.items);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };

    useEffect(() => {
        getLocations();
    }, [])

    return (
        <>
            {
                locations && locations.length > 0 &&
                <Select showSearch={true}
                        filterOption={(input, option:any) =>
                            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        disabled={(!(roleId === 4 && (location.hash === '#edit')))}
                        value={selectedValue}
                        placeholder={"Select Location"} onChange={(id) => {
                    props.onChange(id);
                    setSelectedValue(id)
                }}>
                    {
                        locations.map((item: any, index: number) => {
                            return <Option value={item.name} key={index} >{item.name}</Option>
                        })
                    }
                </Select>
            }
        </>
    );
}
