import React, {useEffect, useState} from "react";
import {Button, Col, Form, notification, Popconfirm, Radio, Row, Select, Space, Table, Tooltip, Typography} from "antd";
import PptxGenJS from "pptxgenjs";
import {MinusCircleOutlined} from "@ant-design/icons";
import "./index.scss";
import API_SERVICE from '../../../services/api-service';
import {getUser} from '../../../services/local-storage';
import Header from '../../../components/Header';
import ExportPPT from '../../../components/ExportPPT';
import AppConstants from '@recoinsights/shared/constants/Constants';

export default function KpiSummary(props: any) {
  const { Text } = Typography;
  const {match: {params: { fundId }}} = props;
  const [fundYear, setFundYear] = useState <number> (2022);

  const [loading, setLoading] = useState(true);
  const [kpiSummary, setKpiSummary] = useState <any> ({
    header: [],
    data: []
  });
  const [dataSource, setDataSource] = useState <any[]> ([]);
  const [activeCell, setActiveCell] = useState <string>('0:0');
  const roleId = getUser()?.user?.role?.id;
  const [editMode] = useState(
    [
      AppConstants.roles.SUPER_ADMIN_ID,
      AppConstants.roles.ADMIN_ID
    ].includes(roleId)
  );
  const [kpiTableData, setKpiTableData] = useState <any> ({});
  const header_index = 0;
  const tableDataHeaders = [
    [
      { text: "Kpi Summary", options: {colspan: 2} }
    ],
    [
      { text: "Industry", options: {} },
      { text: "Company", options: {} }
    ],
    [
      { text: "", options: {} },
      { text: "Higher is:", options: {} },
    ]
  ];
  const tableData: any = {
    headers: [...tableDataHeaders],
    rows: [],
    visited: []
  };



  const industryHeaders: any[] = [

        {
          title: "KPI SUMMARY",
          children: [
            {
              title: "Industry",
              key: "industry",
              className: "pt-0 pb-0 pl-2 pr-2",
              width: 120,
              children: [
                {
                  width: 120,
                  render: (obj: any) => {
                    return (
                      <Tooltip placement="top" title={(obj.industry && obj.industry.name) || ' '}>
                        <div style={{overflow: 'hidden'}}>
                          <Text>{obj.industry && obj.industry.name}</Text>
                        </div>
                      </Tooltip>
                    )
                  },
                  onCell: (_: any, index: number) => {
                      return { rowSpan: dataSource[index].industry['span'] };
                    },
                }
              ]
            },
            {
              title: "Company",
              key: "company",
              className: "pt-0 pb-0 pl-2 pr-2",
              width: 120,
              children: [
                {
                  width: 180,
                  render: (obj: any) => {
                    return (
                      <div style={{overflow: 'hidden'}}>
                        <Space>
                          {
                            editMode?
                              <Popconfirm title="Sure to delete?"
                                onConfirm={async () => {
                                  try {
                                    setLoading(true);
                                    await API_SERVICE.popIndustryCompanyMap(fundId, {
                                      year: fundYear,
                                      industryId: obj.industry.id,
                                      companyId: obj.company.id,
                                    });
                                    getKpiSummary();
                                  } catch (e) {
                                      notification.error({message: API_SERVICE.handleErrors(e)});
                                      setLoading(false);
                                  }
                                }}>
                                <div className="text-primary cursor-pointer text-center">
                                  <MinusCircleOutlined />
                                </div>
                              </Popconfirm>
                              :<div />
                          }
                          <Tooltip placement="top" title={(obj.company && obj.company.name) || ' '}>
                            <Text>{obj.company && obj.company.name}</Text>
                          </Tooltip>
                        </Space>
                      </div>
                    )
                  },
                  title: (
                    <div style={{display: 'flex', justifyContent:'flex-end'}}>
                      Higher is:
                    </div>
                  )
                }
              ]
            },
          ]
        }
  ];

  const getKpiHeaders = () => {
    let dkpi_index = 0;
    return kpiSummary.header.map((metric: any, i: number) => {
        tableData.headers[header_index].push(
          { text: metric.name, options: {colspan: metric['dkpis'].length} }
        );
        return {
            title: metric.name,
            align: "center",
            children: metric['dkpis'].length?
              metric['dkpis'].map((kpi: any, index: number)=>{
                kpi['index'] = dkpi_index
                dkpi_index += 1
                tableData.headers[header_index+1].push(
                  { text: `${kpi.name}\n${kpi.description}`, options: {} }
                );
                tableData.headers[header_index+2].push(
                  { text: kpi.isGood? 'Good':'Bad', options: {} }
                )
                return {
                    title: (
                      <div className="p-2">
                        {kpi.name}<p className="unitClass">({kpi.description})</p>
                      </div>
                    ),
                    className: 'p-0',
                    children: [
                        {
                          title: (
                            (editMode)?
                              <div style={{padding: 12, paddingBottom: 4}}>
                                <Radio.Group value={kpi.isGood? 'Good':'Bad'}
                                  defaultValue={kpi.isGood? 'Good':'Bad'}
                                  onChange={async (e)=>{
                                    try {
                                        setLoading(true);
                                        await API_SERVICE.updateGoodBad({
                                          "fundId" : fundId,
                                          "year": fundYear,
                                          "dkpiId": kpi.id,
                                          "isGood": (e.target.value === 'Good')? true : false
                                        });
                                        notification.success({message: "Updated"});
                                    } catch (e) {
                                        notification.error({message: API_SERVICE.handleErrors(e)});
                                    } finally {
                                        getKpiSummary();
                                    }
                                    }}>
                                  <Radio value="Good">Good</Radio>
                                  <Radio value="Bad">Bad</Radio>
                                </Radio.Group>
                              </div>
                              :
                              <div style={{padding: 4, paddingLeft: 12}}>
                                {kpi.isGood? 'Good':'Bad'}
                              </div>
                          ),
                          key: kpi.id,
                          className: "p-0",
                          width: 180,
                          render: (obj: any, data: any, data_index: number) => {
                              let value = ' ';
                              let cellColor = '#FFFFFF';
                              let cellStyle: any = {};
                              const cellId = `${data_index}:${kpi['index']}`;
                              try {
                                const dataObj = data[metric.name][index];
                                cellColor = dataObj.cellColor && dataObj.cellColor.colorCode.slice(0,7);
                                cellStyle = {
                                  borderColor: '#3297FD',
                                  borderWidth: (activeCell === cellId)? 2:0,
                                  backgroundColor: cellColor,
                                  overflow: 'hidden',
                                  display: 'flex'
                                };
                                let suffix = ''
                                switch (dataObj.isGood) {
                                  case 1:
                                    suffix = '⬈'
                                    break;
                                  case 0:
                                    suffix = '⬊'
                                    break;
                                  default:
                                    suffix = ''
                                    break;
                                }
                                //@ts-ignore
                                value = dataObj.resultPreview.replace(/(<([^>]+)>)/ig, '').toFloat(2);                                
                                value = `${value} ${dataObj.unit || ''} ${suffix}`

                              }
                              catch (e){
                              }
                              try {
                                if (!tableData.visited.includes(cellId)) {
                                  if (data_index > (tableData.rows.length-1)) {
                                    tableData.rows.push(
                                      [
                                        {text: obj.industry.name, options:{} },
                                        {text: obj.company.name, options: {} }
                                      ]
                                      /*
                                      obj.industry.span? [
                                        {text: obj.industry.name, options:{rowspan: obj.industry.span}},
                                        { text: obj.company.name, options: {} }
                                      ]
                                      :[{ text: obj.company.name, options: {} }]
                                      */
                                    );
                                  }
                                  tableData.rows[data_index].push(
                                    {
                                      text: value,
                                      options: {fill: cellColor}
                                    }
                                  )
                                  tableData.visited.push(cellId)
                                }
                              }
                              catch (e) {
                                console.log(e)
                              }
                              return (
                                <Button onClick={()=>setActiveCell(cellId)} style={cellStyle} ghost block>
                                  <Text className="p-8">
                                    {value}
                                  </Text>
                                </Button>
                              )
                            }
                        },
                    ],

                }
            })
            :
            [{
              title: '',
              children: [
                {
                  title: '',
                  className: "p-0",
                  render: (data: any) =>
                    <Button
                      style={{
                        border: 0,
                        //backgroundColor: data.level['colorCode']
                      }}
                      block>
                      {' '}
                    </Button>
                }
              ]
            }]

          }
      })
  }

  const getKpiSummary = async () => {
      try {
          setLoading(true);
          tableData.rows = []
          tableData.visited = []
          tableData.headers = [...tableDataHeaders]
          const {data: {data}} = await API_SERVICE.getKpiSummary(fundId, fundYear);
          setDataSource([]);
          setKpiSummary(data);
      } catch (e) {
          notification.error({message: API_SERVICE.handleErrors(e)});
      } finally {
        setLoading(false)
      }
  };

  const export_table = async (page_size = 3, slide_options={}) => {
    const headers = [...kpiTableData.headers];
    const rows = [...kpiTableData.rows];
    const pres = new PptxGenJS();
    let slide: any = pres.addSlide();
    let table: any[] = [];
    rows.map((r,i)=>{
      if (i!==0 && i%page_size===0) {
          slide.addTable(headers.concat(table), { ...slide_options });
          slide = pres.addSlide();
          table = [];
      }
      else if (i+1 === rows.length) {
          table.push(r)
          slide.addTable(headers.concat(table), { ...slide_options });
      }
      table.push(r)
    });
    pres.writeFile({ fileName: `kpi-summary Id_${fundId} ${fundYear}.pptx` });
  }

  useEffect(()=>{
    getKpiSummary();
  },[fundYear])

  useEffect(()=>{
    const obj: any[] = [];
    const industries: any[] = [];
    kpiSummary.data.map((industryObj: any) => (
      industryObj.industryCompaniesMaps.map((industryMap: any)=> {
          obj.push({
            industry: {
              id: industryObj.id,
              name: industryObj.name,
              span: !industries.includes(industryObj.id)?
                industryObj.industryCompaniesMaps.length : 0
            },
            ...industryMap
          })
          industries.push(industryObj.id)
      })
    ));
    setDataSource(obj);
  },[kpiSummary])

  useEffect(()=>{
    setKpiTableData(tableData);
  },[dataSource])

  return (
    <div className="page-wrapper Benchmarking-role-wrapper">
      <InputHeader
        editable={editMode}
        year={fundYear}
        onYearChange={setFundYear}
        fundId={fundId}
        onUpdate={getKpiSummary}
        />
      <Row justify="start">
        <Col className="p-2">
          <Table
            id="test-table"
            columns={industryHeaders.concat(getKpiHeaders())}
            dataSource={dataSource}
            size="small"
            bordered={true}
            scroll={{ x: "calc(700px + 50%)", y: 500 }}
            pagination={false}
            loading={loading}
          />
          {
            !loading &&
            <Row justify="end" style={{paddingTop: 12}}>
              <Col>
                <ExportPPT
                  total={kpiTableData.rows.length}
                  onExport={export_table} />
              </Col>
            </Row>
          }
        </Col>
      </Row>
    </div>
  );
}



const InputHeader = (props: any) => {
  const arrYear = [2020, 2021, 2022];
  const [years] = useState(arrYear);
  const [loading, setLoading] = useState(false);
  const [inputForm] = Form.useForm()
  const [fundDetails, setFundDetails] = useState <any> ({})
  const [companies, setCompanies] = useState <any[]> ([])
  const [industries, setIndustries] = useState <any[]> ([])
  const [levels, setLevels] = useState <any[]> ([]);

  const getFundDetails = async () => {
      try {
          setLoading(true);
          const {data: {data}} = await API_SERVICE.getFunds({id: props.fundId});
          if (data.items.length) {
            setFundDetails(data.items[0]);
          }
      } catch (e) {
          notification.error({message: API_SERVICE.handleErrors(e)});
      } finally {
        setLoading(false)
      }
  };

  const getCompanies = async () => {
      try {
          const {data: {data}} = await API_SERVICE.getCompanies({fundId: props.fundId, page: -1});
          setCompanies(data.items);
      } catch (e) {
          notification.error({message: API_SERVICE.handleErrors(e)});
      }
  };

  const getIndustries = async () => {
      try {
          const {data: {data}} = await API_SERVICE.getEsgIndustries(props.fundId, props.year);
          setIndustries(data.items);
      } catch (e) {
          notification.error({message: API_SERVICE.handleErrors(e)});
      }
  };

  const getLevels = async () => {
      try {
          const {data: {data}} = await API_SERVICE.getColorLevels();
          if (data.items.length) {
            const arr: any[] = data.items.sort( (a: any,b: any) => (a.id < b.id)? -1 : 1);
            setLevels(arr);
          }
      } catch (e) {
      }
  };

  useEffect(()=>{
    getCompanies();
    getIndustries();
    getFundDetails();
    inputForm.resetFields();
  },[props.year])

  useEffect(()=>{
    getLevels();
  },[])

  return (
    <div>
      <Row justify="space-between" align="middle" className="p-2 bottomLine">
        <Col>
          <Header headings={[
              "KPI Summary",
              fundDetails.name,
              fundDetails.pef && fundDetails.pef.name
            ]} />
        </Col>
        <Col>
          <div className="d-flex">
            <Form layout="horizontal">
              <Form.Item className="mb-0" label="Select Year:">
                <Select placeholder="year" value={props.year} onChange={(i)=>props.onYearChange(i)}>
                  {years.map((obj: any, i: number) => {
                    return <Select.Option key={i} value={obj}>{obj}</Select.Option>;
                  })}
                </Select>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
      {
        props.editable &&
          <Row justify="start" align="middle" className="p-2 bottomLine">
            <Col>
              <div className="d-flex">
                <Form layout="inline" form={inputForm}
                  onFinish={async (form: any) => {
                    try {
                        setLoading(true);
                        await API_SERVICE.pushIndustryCompanyMap({
                          ...form,
                          fundId: parseInt(props.fundId),
                          year: props.year.toString()
                        });
                        //inputForm.resetFields();
                        props.onUpdate();
                    } catch (e) {
                        notification.error({message: API_SERVICE.handleErrors(e)});
                    }
                    finally {
                      setLoading(false);
                    }
                  }}>
                  <Form.Item name="industryId"  className="mb-0">
                    <Select placeholder="Select Industry">
                      {industries.map((obj: any, i: number) => {
                        return (
                          <Select.Option key={i} value={obj.id}>
                            {obj.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item name="companyId" className="mb-0">
                    <Select placeholder="Select Company">
                      {companies.map((obj: any, i: number) => {
                        return (
                          <Select.Option key={i} value={obj.id}>
                            {obj.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Button type="primary"
                    htmlType="submit" disabled={loading}>
                    +Add
                  </Button>
                </Form>
              </div>
            </Col>
            <div style={{marginLeft: 'auto'}}>
              <div className="d-flex align-items-center">
              {
                levels.map((obj,i) => {
                  return (
                    <div
                      style={{
                        width: 100,
                        backgroundColor: obj.colorCode,
                        padding: 2,
                        textAlign: 'center'
                      }}>
                      {obj.name.titlecase()}
                    </div>
                  )
                })
              }
              </div>
            </div>
          </Row>
      }
    </div>
  )
}
