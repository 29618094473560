import React, {useState} from "react";
import {Button, Col, notification, Row, Table} from "antd";
import {ArrowDownOutlined} from "@ant-design/icons";
import API_SERVICE from '../../../services/api-service';

const Reports = () => {
    const columns: any = [
        {
          title: "S.NO",
          dataIndex: "id",
       },
        {
          title: "Report Name",
          dataIndex: "reportname",
        },
        {
          title: "Report Description",
          dataIndex: "reportdescription",

        },
        {
            title:'Action',
            render: () => {
                return (
                  <Button type="primary"
                    icon={<ArrowDownOutlined/>}
                    style={{width: 36}}
                    onClick={async ()=>{
                      try {
                        const { data: {data} } = await API_SERVICE.getProgressReport();
                        if (data.url) {
                          window.open(data.url, '_blank');
                        }
                        else {
                          notification.error({message: "This report is currently not avaialable"})
                          console.log(data)
                        }
                      } catch (e) {
                        notification.error({message: API_SERVICE.handleErrors(e)});
                      }
                    }}>
                  </Button>
                );
              },
        }

      ];
      const data: any = [
        {
          key: "1",
          id: "1",
          reportname: 'Login Pending Report',
          reportdescription: "This report show if a user has ever logged in or not. If logged in did the user update any survey in last 3 days",
        }
      ];
      const [tableDatastate] = useState(data);

  return (
    <div className="bg-white pb-4">
      <Row align="middle"
          className="p-2 bottomLine">
            <Col>
          <div className="align-self-center">
            <span className="Benchmarking-heading">
              <span>Reports</span>
            </span>
          </div>
        </Col>
          </Row>
          <Row  align="middle"
          className="mt-4 ml-2">
            <Col>
          <Table
        columns={columns}
        dataSource={tableDatastate}
        pagination={false}
        size='large'
      />
      </Col>
      </Row>
    </div>
  );
};

export default Reports;
