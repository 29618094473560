import React, {useEffect, useState} from 'react';
import './index.scss';
import {Button, Col, Form, Input, Modal, notification, Select, Space, Spin, Typography} from 'antd';
import {CloseOutlined} from '@ant-design/icons';
import API_SERVICE from '../../services/api-service';
import AccessControl from '@recoinsights/client/src/components/AccessControl';
import AppConstants from '@recoinsights/shared/constants/Constants';

const {Text} = Typography;

export default function UserCreateOrEditPage({onClose, details}: any) {
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);
    const [fetchingInitialAPIs, setFetchingInitialAPIs] = useState(true);
    const [roles, setRoles] = useState([] as any);
    const [companies, setCompanies] = useState([] as any);
    const [groups, setGroups] = useState([] as any);
    const [selectedRole, setSelectedRole] = useState(null);
    const [companyIds, setCompanyIds] = useState([] as any);

    const handleRoleChange = async (value: any) => {
        setSelectedRole(value);
        setCompanies([]);
        form.setFieldsValue({groups: undefined, companyIds: undefined})
    }

    const handleGroupChange = async (value: any) => {
        extractCompanies(value, true);
    }

    const getRoles = async () => {
        try {
            const {data: {data}} = await API_SERVICE.getRoles();
            setRoles(data.items);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };

    const getGroups = async () => {
        try {
            const {data: {data}} = await API_SERVICE.getGroups();
            setGroups(data.items);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };

    const extractCompanies = (value: any, setAll = false) => {
        const selectedGroup = groups.find((group: any) => group.id === value);
        let companyIds = selectedGroup?.companies?.map((item: any) => item.id)
        setAll && form.setFieldsValue({companyIds: undefined})
        setCompanies(selectedGroup?.companies || []);
        setCompanyIds(companyIds || []);
    }

    const getInitialValues = (details: any) => {
        if (details) {
            const {groups, companies} = details;

            const obj = {
                "firstName": details.firstName,
                "lastName": details.lastName,
                "email": details.email,
                "phoneNumber": details.phoneNumber,
                "roleId": details.roleId,
                "groups": undefined,
                "companyIds": undefined
            }

            if (groups.length) {
                let a = groups.map((item: any) => {
                    return item.id
                });
                if (AppConstants.adminArray.includes(details.roleId)) {
                    obj.groups = a;
                } else {
                    obj.groups = a[0];
                }
            }

            if (companies.length) {
                let a = companies.map((item: any) => {
                    return item.id
                });
                if (AppConstants.consultantAndAuditor.includes(details.roleId)) {
                    obj.companyIds = a;
                } else {
                    obj.companyIds = a[0];
                }
            }
            return obj;
        } else return {};
    }

    useEffect(() => {
        Promise.all([getRoles(), getGroups()]).then(() => {}).catch(() => {
            notification.error({message: "Something went wrong"});
        }).finally(()=> {
            setFetchingInitialAPIs(false)
        });

        if (details && details.roleId) {
            setSelectedRole(details.roleId);
        }
    }, []);

    useEffect(() => {
        if (details && details.groups && details.groups.length > 0) {
            extractCompanies(details.groups[0].id);
        }
    }, [groups]);

    return (<AccessControl id={13}>
            <Modal title={
                (<Space>
                    <Text>{details ? 'Edit User' : 'Add New user'}</Text>
                </Space>)
            }
                   width={550}
                   footer={null}
                   open={true}
                   maskClosable={false}
                   onOk={
                       () => {
                           form.submit();
                       }
                   }
                   okText={details ? "Update" : "+ Add"}
                   okType="primary"
                   okButtonProps={{shape: 'round'}}
                   cancelButtonProps={{shape: 'round', className: 'btn-dark'}}
                   onCancel={() => onClose()}>
                {
                    fetchingInitialAPIs ? <div className="modal-spin-container"><Spin/></div> : <Form
                        form={form}
                        labelCol={{span: 10}}
                        wrapperCol={{span: 14}}
                        initialValues={
                            getInitialValues(details)
                        }
                        fields={[]}
                        onFinish={(value) => {
                            for (let key in value) {
                                if (value.hasOwnProperty(key) && value[key] === undefined) {
                                    delete value[key];
                                }
                            }
                            if (value && (typeof value.companyIds === 'number')) {
                                value.companyIds = [value.companyIds];
                            }
                            if (value && (typeof value.groups === 'number')) {
                                value.groups = [value.groups];
                            }

                            if (value && !value.phoneNumber) {
                                delete value['phoneNumber']
                            }

                            setSaving(true);
                            if (details) {
                                API_SERVICE.userUpdate(details.id, value).then((d) => {
                                    notification.success({message: 'User successfully updated'});
                                    onClose(true);
                                }).catch((e) => {
                                    notification.error({message: API_SERVICE.handleErrors(e)});
                                }).finally(() => {
                                    setSaving(false);
                                });
                            } else {
                                API_SERVICE.userCreate(value).then((d) => {
                                    notification.success({message: 'User successfully created'});
                                    onClose(true);
                                }).catch((e) => {
                                    console.log(e)
                                    notification.error({message: API_SERVICE.handleErrors(e)});
                                }).finally(() => {
                                    setSaving(false);
                                });
                            }
                        }}
                        layout='horizontal'
                        requiredMark={true}
                    >
                        <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label={'First Name'}
                                name={'firstName'}
                                rules={[
                                    {required: true, message: 'Name required'},
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label={'Last Name'}
                                name={'lastName'}
                                rules={[
                                    {required: true, message: 'Name required'},
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label={'Email ID'}
                                name={'email'}
                                rules={[
                                    {required: true, type: 'email', message: 'Email required'},
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label={'Contact No.'}
                                name={'phoneNumber'}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>

                        <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label={'Select Role'}
                                name={'roleId'}
                                rules={[
                                    {required: true, message: 'Atleast one role is required.'},
                                ]}
                            >
                                <Select
                                    onSelect={handleRoleChange}
                                >
                                    {
                                        roles.length > 0 && roles.map((role: any) => {
                                            return <Select.Option
                                                value={role.id}>{role.name}</Select.Option>;
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label={(selectedRole && AppConstants.adminArray.includes(selectedRole)) ? 'Select Groups' : 'Select Group'}
                                name={`groups`}
                                rules={[
                                    {required: true, message: 'Group required.'},
                                ]}
                            >
                                <Select
                                    //@ts-ignore
                                    mode={(selectedRole && AppConstants.adminArray.includes(selectedRole)) && 'multiple'}
                                    showArrow={true}
                                    maxTagCount={2}
                                    showSearch={true}
                                    filterOption={(input, option: any) =>
                                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={handleGroupChange}
                                >
                                    {
                                        groups.length > 0 && groups.map((group: any) => {
                                            return <Select.Option
                                                value={group.id}>{group.name}</Select.Option>;
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label={(selectedRole && AppConstants.consultantAndAuditor.includes(selectedRole)) ? 'Select Companies' : 'Select Company'}
                                name={'companyIds'}
                                rules={[
                                    {required: false, message: 'Company required'},
                                ]}
                            >
                                <Select
                                    //@ts-ignore
                                    disabled={selectedRole ? AppConstants.adminArray.includes(selectedRole) : false}
                                    //@ts-ignore
                                    mode={(selectedRole && AppConstants.consultantAndAuditor.includes(selectedRole)) && 'multiple'}
                                    showArrow={true}
                                    maxTagCount={2}
                                    showSearch={true}
                                    filterOption={(input, option: any) =>
                                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    defaultValue={companyIds}
                                >
                                    {
                                        companies.length > 0 && companies.map((company: any) => {
                                            return <Select.Option
                                                value={company.id}>{company.name}</Select.Option>;
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <div className="text-right mt-3">
                            <Space>
                                <Button
                                    type='primary'
                                    ghost
                                    htmlType='button'
                                    icon={<CloseOutlined/>}
                                    className='theme-button primary full-width'
                                    onClick={onClose}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    type='primary'
                                    htmlType='submit'
                                    loading={saving}
                                    className='theme-button primary full-width '
                                >
                                    {details ? "Update" : "+ Add"}
                                </Button>
                            </Space>
                        </div>
                    </Form>
                }
            </Modal>
        </AccessControl>
    );
}
