import React, {useEffect, useState} from "react";
import "./index.scss";
import {Button, Input, notification, Space, Table, Typography} from "antd";
import {useHistory} from "react-router-dom";
import AdminListLayout from "@recoinsights/shared/components/layout/AdminListLayout/AdminListLayout";
import {PlusOutlined, SearchOutlined,} from "@ant-design/icons";
import {DeleteOutlined, EditOutlined} from "@material-ui/icons";
import {ConfirmationModal} from "components";
import API_SERVICE from "../../../services/api-service";
import {
    ADMIN_FUNDS_BENCHMARKING,
    ADMIN_FUNDS_CONFIGURATION,
    ESG_SCORES,
    ESG_WEIGHTAGES,
    KPI_SUMMARY, PM_FUNDS_CONFIGURATION
} from "@recoinsights/shared/constants/RouteConstants";
import AccessControl, {accessIncludes} from "../../../components/AccessControl";
import {getUser} from '../../../services/local-storage';
import AppConstants from '@recoinsights/shared/constants/Constants';
import FundCreateOrEditPage from "./FundCreateOrEditPage";

const {Text} = Typography;
const {Search} = Input;

export default function Funds(props: any) {
    const history = useHistory();
    const roleId = getUser()?.user?.role?.id;
    const [funds, setFunds] = useState([] as any[]);
    const [search, setSearch] = useState("" as string);
    const [fetching, setFetching] = useState(false);
    const [filters, setFilters] = useState({} as any);
    const [confirmationModal, setConfirmationModal] = useState({} as any);
    const [editFund, setEditFund] = useState(null as any);
    const [addNew, setAddNew] = useState(false);
    const isFundManager = (roleId === AppConstants.roles.FUND_MANAGER_ID);
    const columns = [
        {
            title: "Fund",
            dataIndex: "name",
            width: "100%",
            render: (a: any) => <Text>{a}</Text>,
        },
        isFundManager ? {
            title: "Companies",
            dataIndex: "fundCompanies",
            render: (fundCompanies: any) => <Text>{fundCompanies?.length}</Text>,
        } : {},
        !isFundManager ? {
            title: "Group",
            dataIndex: "pef",
            render: (group: any) => <Text>{group.name}</Text>,
        } : {},
        !isFundManager ?
            {
                title: "Configure",
                key: "configure",
                width: 300,
                render: (value: any) => (
                    <Space>
                        <Button
                            type={"primary"}
                            htmlType="submit"
                            className="theme-button primary"
                            onClick={() => {
                                history.push(`${ESG_WEIGHTAGES}/${value.id}`);
                            }}
                        >
                            Weightages
                        </Button>
                        <Button
                            type={"primary"}
                            htmlType="submit"
                            className="theme-button primary"
                            onClick={() => {
                                history.push(`${ADMIN_FUNDS_BENCHMARKING}/${value.id}`);
                            }}
                        >
                            Benchmarks
                        </Button>
                    </Space>
                ),
            } : {},
        !isFundManager ? {
            title: "Fund Dashboard",
            key: "fund-dashboard",
            width: 200,
            render: (value: any) => (
                <Space>
                    <Button
                        type={"primary"}
                        htmlType="submit"
                        className="theme-button primary"
                        onClick={() => {
                            history.push(`${KPI_SUMMARY}/${value.id}`);
                        }}
                    >
                        KPIs
                    </Button>
                    {
                        !(roleId === AppConstants.roles.FUND_MANAGER_ID) &&
                        <Button
                            type={"primary"}
                            htmlType="submit"
                            className="theme-button primary"
                            onClick={() => {
                                history.push(`${ESG_SCORES}/${value.id}`);
                            }}>
                            ESG
                        </Button>
                    }
                </Space>
            ),
        } : {},
        ...(
            !isFundManager ?
                [{
                    title: "Actions",
                    key: "action",
                    width: 120,
                    render: (value: any) => (
                        <Space>
                            <AccessControl id={41}>
                                <Button
                                    type={"primary"}
                                    htmlType="submit"
                                    className="theme-button primary"
                                    onClick={() => {
                                        history.push(`${ADMIN_FUNDS_CONFIGURATION}/${value.id}`);
                                    }}
                                >
                                    Configure
                                </Button>
                            </AccessControl>

                            <AccessControl id={38}>
                                <Button
                                    type="text"
                                    icon={<EditOutlined/>}
                                    onClick={() => {
                                        setEditFund(value);
                                    }}
                                />
                            </AccessControl>
                            <AccessControl id={39}>
                                <Button
                                    type="text"
                                    icon={<DeleteOutlined/>}
                                    onClick={() => {
                                        setConfirmationModal({
                                            message: `Delete Fund?`,
                                            action: API_SERVICE.deleteFund,
                                            params: [value.id],
                                        });
                                    }}
                                />
                            </AccessControl>
                        </Space>
                    ),
                }]
                : [
                    {
                        title: "",
                        key: "action",
                        width: 120,
                        render: (fund: any) => (
                            <Space>
                                <Button
                                    type={"primary"}
                                    htmlType="submit"
                                    className="theme-button primary"
                                    onClick={() => {
                                        history.push(`${PM_FUNDS_CONFIGURATION}/${fund.id}`, {fundCompanies: fund.fundCompanies});
                                    }}
                                >
                                    Manage
                                </Button>
                                <Button
                                    type="text"
                                    icon={<EditOutlined/>}
                                    onClick={() => {
                                        setEditFund(fund);
                                    }}
                                />
                            </Space>
                        )
                    }
                ]
        ),
    ];

    const handleTableChange = (pagination: any, filterValues: any) => {
        setFilters({...filters, ...filterValues})
    };

    const refresh = async () => {
        setFetching(true);
        const params = {...filters};
        if (search) {
            params["q"] = search;
        }
        setFetching(true);
        try {
            const {data: {data}} = await API_SERVICE.getFunds(params);
            setFunds(data.items);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        refresh();
    }, [props.location, search, filters]);

    return (
        <div className={"group-wrapper page-wrapper"}>
            <AdminListLayout
                title="Funds"
                titleSearch={
                            accessIncludes([40]) && <div className="search-bar"><Search
                                enterButton={<SearchOutlined/>}
                                placeholder="Search a Fund by its Name"
                                onSearch={(e) => {
                                    setSearch(e);
                                }}
                                data-testid="fund-searchbar"
                            />
                        </div>
                }
                titleAction={
                        accessIncludes([37]) && <Button
                            type={"primary"}
                            className="theme-button primary"
                            onClick={() => setAddNew(true)}
                            icon={<PlusOutlined/>}
                            data-testid="add-fund-button"
                        >
                            Add New
                        </Button>
                }
            >
                {addNew && (
                    <FundCreateOrEditPage
                        onClose={(e: boolean) => {
                            if (e) {
                                refresh();
                            }
                            setAddNew(false);
                        }}
                        data-testid="add-edit-fund-modal"
                    />
                )}
                {editFund && (
                    <FundCreateOrEditPage
                        details={editFund}
                        onClose={(e: boolean) => {
                            if (e) {
                                refresh();
                            }
                            setEditFund(null);
                        }}
                        data-testid="add-edit-fund-modal"
                    />
                )}
                <AccessControl id={54}>
                    <Table
                        loading={fetching}
                        className="table-responsive"
                        pagination={false}
                        dataSource={funds}
                        columns={columns.filter(value => JSON.stringify(value) !== '{}')}
                        onChange={handleTableChange}
                        data-testid="funds-table"
                    />
                </AccessControl>

                <ConfirmationModal
                    {...confirmationModal}
                    callback={(a: boolean) => {
                        setConfirmationModal({});
                        if (a) {
                            refresh();
                        }
                    }}
                />
            </AdminListLayout>
        </div>
    );
}
