import React, {useEffect, useState} from 'react';
import './index.scss';
import {Space} from 'antd';
import AdminListLayout from '@recoinsights/shared/components/layout/AdminListLayout/AdminListLayout';
import TableData from './tabledata';
import {DownloadOutlined} from '@ant-design/icons';

export default function Targets(props: any) {
    const [search, ] = useState('' as string);
    const [pagination] = useState({page: 1, perpage: '10'} as any);
    const [filters] = useState({} as any);
    useEffect(() => {
    }, [props.location, pagination, search, filters]);
    return (
        
        <div className={'group-wrapper page-wrapper'}>
            {/*@ts-ignore*/}
            <AdminListLayout>
                <div className="p-2">
                    <div className="text-right mt-3">
                        <h3>
                            <Space size={20}>
                                <div className="text-primary cursor-pointer" >Edit</div>
                                <span className="cursor-pointer"><DownloadOutlined /></span>
                            </Space>
                        </h3>
                    </div>
                    <TableData />
                </div>
            </AdminListLayout>
        </div>
    );
}
