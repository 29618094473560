import React from "react";
import {Metrics} from "@recoinsights/metrics";
import {Metric, ResultSet} from "@recoinsights/types";
import {Col, Divider, Row} from "antd";
import Chart from "./Chart";
import MetricComponent from "./Metric";
import ScoreTimeline from "./ScoreTimeline";
import {resolveMetric, useMetricsWithResults} from "../../helpers/metrics";
import EmissionsChart from "../charts/EmissionsChart";
import EnergyConsumptionChart from "../charts/EnergyConsumptionChart";
import CustomerSatisfactionChart from "../charts/CustomerSatisfactionChart";
import EmployeeSatisfactionChart from "../charts/EmployeeSatisfactionChart";
import GenderDiversityChart from "../charts/GenderDiversityChart";
import BoardMaturityChart from "../charts/BoardMaturityChart";
import CyberSecurityMaturityChart from "../charts/CyberSecurityMaturityChart";

interface Props {
    results: ResultSet[];
    metrics: Metric[];
}

function CompanyMultiYearResults({results, metrics}: Props) {
    const { hasMetricScore, hasEnvironmentalMetrics, hasSocialMetrics, hasGovernanceMetrics } = useMetricsWithResults(results);
    
    return (
        <section data-testid="charts-dashboard">
            {hasEnvironmentalMetrics && (
                <Row>
                    <Col flex={1} className="bg-white mb-4">
                        <Row className="p-2" style={{ backgroundColor: "#fafafa" }}>
                            <Col>
                                <h3 className="mb-0"><i className="bi bi-globe mr-1"/>Environmental</h3>
                            </Col>
                        </Row>

                        <Divider className="my-0"/>

                        {hasMetricScore(Metrics.RenewableEnergyConsumption) && (
                            <Row align={"middle"} justify={"center"}>
                                <MetricComponent metric={resolveMetric(Metrics.RenewableEnergyConsumption, metrics)} style={{ width: "50%" }}>
                                    <ScoreTimeline metricUid={Metrics.RenewableEnergyConsumption} results={results} />
                                </MetricComponent>

                                <Chart title={"Total Energy Consumption"} description={"kWh"} style={{ width: "50%" }}>
                                    <EnergyConsumptionChart results={results} />
                                </Chart>
                            </Row>
                        )}

                        {hasMetricScore(Metrics.RenewableEnergyConsumption) && hasMetricScore(Metrics.EmissionsIntensity) && (
                            <Divider className="m-2" style={{ width: "auto", minWidth: "auto" }} />
                        )}

                        {hasMetricScore(Metrics.EmissionsIntensity) && (
                            <Row align={"middle"} justify={"center"}>
                                <MetricComponent metric={resolveMetric(Metrics.EmissionsIntensity, metrics)} style={{ width: "50%" }}>
                                    <ScoreTimeline metricUid={Metrics.EmissionsIntensity} results={results} />
                                </MetricComponent>

                                <Chart title={"GHG Emissions"} description={"tCO2e"} style={{ width: "50%" }}>
                                    <EmissionsChart results={results} />
                                </Chart>
                            </Row>
                        )}
                    </Col>
                </Row>
            )}

            {hasSocialMetrics && (
                <Row>
                    <Col flex={1} className="bg-white mb-4">
                        <Row className="p-2" style={{ backgroundColor: "#fafafa" }}>
                            <Col>
                                <h3 className="mb-0"><i className="bi bi-people mr-1" />Social</h3>
                            </Col>
                        </Row>

                        <Divider className="my-0"/>

                        {hasMetricScore(Metrics.CustomerSatisfaction) && (
                            <Row align={"middle"} justify={"center"}>
                                <MetricComponent metric={resolveMetric(Metrics.CustomerSatisfaction, metrics)} style={{ width: "50%" }}>
                                    <ScoreTimeline metricUid={Metrics.CustomerSatisfaction} results={results} />
                                </MetricComponent>

                                <Chart style={{ width: "50%" }}>
                                    <CustomerSatisfactionChart results={results} />
                                </Chart>
                            </Row>
                        )}

                        {hasMetricScore(Metrics.RenewableEnergyConsumption) && hasMetricScore(Metrics.EmissionsIntensity) && (
                            <Divider className="m-2" style={{ width: "auto", minWidth: "auto" }} />
                        )}

                        {hasMetricScore(Metrics.EmployeeSatisfaction) && (
                            <Row align={"middle"} justify={"center"}>
                                <MetricComponent metric={resolveMetric(Metrics.EmployeeSatisfaction, metrics)} style={{ width: "50%" }}>
                                    <ScoreTimeline metricUid={Metrics.EmployeeSatisfaction} results={results} />
                                </MetricComponent>

                                <Chart style={{ width: "50%" }}>
                                    <EmployeeSatisfactionChart results={results} />
                                </Chart>
                            </Row>
                        )}

                        {(hasMetricScore(Metrics.CustomerSatisfaction) || hasMetricScore(Metrics.EmployeeSatisfaction)) && hasMetricScore(Metrics.GenderDiversity) && (
                            <Divider className="m-2" style={{ width: "auto", minWidth: "auto" }} />
                        )}
                        
                        {hasMetricScore(Metrics.GenderDiversity) && (
                            <Row align={"middle"} justify={"center"}>
                                <MetricComponent metric={resolveMetric(Metrics.GenderDiversity, metrics)} style={{ width: "50%" }}>
                                    <ScoreTimeline metricUid={Metrics.GenderDiversity} results={results} />
                                </MetricComponent>

                                <Chart style={{ width: "50%" }}>
                                    <GenderDiversityChart results={results} />
                                </Chart>
                            </Row>
                        )}
                    </Col>
                </Row>
            )}

            {hasGovernanceMetrics && (
                <Row>
                    <Col flex={1} className="bg-white mb-4">
                        <Row className="p-2"  style={{ backgroundColor: "#fafafa" }}>
                            <Col>
                                <h3 className="mb-0"><i className="bi bi-bank mr-1"/>Governance</h3>
                            </Col>
                        </Row>

                        <Divider className="my-0"/>

                        {hasMetricScore(Metrics.BoardMaturity) && (
                            <Row align={"middle"} justify={"center"}>
                                <MetricComponent metric={resolveMetric(Metrics.BoardMaturity, metrics)} style={{ width: "50%" }}>
                                    <ScoreTimeline metricUid={Metrics.BoardMaturity} results={results} />
                                </MetricComponent>

                                <Chart style={{ width: "50%" }}>
                                    <BoardMaturityChart results={results} />
                                </Chart>
                            </Row>
                        )}

                        {hasMetricScore(Metrics.BoardMaturity) && hasMetricScore(Metrics.CyberSecurityMaturity) && (
                            <Divider className="m-2" style={{ width: "auto", minWidth: "auto" }} />
                        )}

                        {hasMetricScore(Metrics.CyberSecurityMaturity) && (
                            <Row align={"middle"} justify={"center"}>
                                <MetricComponent metric={resolveMetric(Metrics.CyberSecurityMaturity, metrics)} style={{ width: "50%" }}>
                                    <ScoreTimeline metricUid={Metrics.CyberSecurityMaturity} results={results} />
                                </MetricComponent>

                                <Chart style={{ width: "50%" }}>
                                    <CyberSecurityMaturityChart results={results} />
                                </Chart>
                            </Row>
                        )}
                    </Col>
                </Row>
            )}
        </section>
    );
}

export default CompanyMultiYearResults;
