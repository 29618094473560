import React, {useEffect} from 'react';
import './index.scss';
import {Col, Row, Space, Tag} from 'antd';
import AdminListLayout from '@recoinsights/shared/components/layout/AdminListLayout/AdminListLayout';

import {DownloadOutlined, FallOutlined, RiseOutlined,} from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';


export default function Result() {

    useEffect(() => {
    });
    return (
        
        <div className={'group-wrapper page-wrapper'}>
            {/*@ts-ignore*/}
            <AdminListLayout title={'Results'}>
                <Row>
                    <Col sm={24}>
                        <div className="p-2">
                            <Title level={4}>KPI Summary</Title>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <Tag color="error">Week</Tag>
                                    <Tag color="default">Neutral</Tag>
                                    <Tag color="success">Strong</Tag>
                                    <Tag color="default"><RiseOutlined />Improvement</Tag>
                                    <Tag color="default"><FallOutlined />Digression</Tag>
                                </div>
                                <h3 className="mb-0">
                                    <Space size={20}>
                                        <div className="text-primary cursor-pointer" >Edit</div>
                                        <span className="cursor-pointer"><DownloadOutlined /></span>
                                    </Space>
                                </h3>
                            </div>
                        </div>
                    </Col>
                </Row>

            </AdminListLayout>
        </div>
    );
}
