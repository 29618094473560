import React from "react";
import {
    BoardMaturity,
    CommunityEngagement,
    CustomerSatisfaction,
    CyberSecurityMaturity,
    EmissionsIntensity,
    EmployeeSatisfaction,
    GenderDiversity,
    Metrics,
    RenewableEnergyConsumption,
    resolveMetricResults,
} from "@recoinsights/metrics";
import {Metric, ResultSet} from "@recoinsights/types";
import {Col, Divider, Row} from "antd";
import {default as MetricComponent} from "./Metric";
import AlertNoResults from "./AlertNoResults";
import {resolveMetric, useMetricsWithResults} from "../../helpers/metrics";
import EnergyConsumptionByTypeChart from "../charts/EnergyConsumptionByTypeChart";
import EmissionsByScopeChart from "../charts/EmissionsByScopeChart";
import GenderDiversityByLevelChart from "../charts/GenderDiversityByLevelChart";
import BoardMaturityByCategoryChart from "../charts/BoardMaturityByCategoryChart";
import CyberSecurityMaturityByCategoryChart from "../charts/CyberSecurityMaturityByCategoryChart";
import Chart from "./Chart";
import Score from "./Score";

interface Props {
    results: ResultSet[];
    metrics: Metric[];
    year: number;
}

function CompanySingleYearResults({results, metrics, year}: Props) {
    const currentYearResults = results.find((result: ResultSet) => {
        return result.year === year;
    });
    const previousYearResults = results.find((result: ResultSet) => {
        return result.year === year - 1;
    });

    const { hasEnvironmentalMetrics, hasSocialMetrics, hasGovernanceMetrics } = useMetricsWithResults(results);

    if (!currentYearResults) {
        return <AlertNoResults />
    }

    const currentMetricResults = {
        renewableEnergyConsumption: resolveMetricResults(Metrics.RenewableEnergyConsumption, currentYearResults) as RenewableEnergyConsumption,
        emissionsIntensity: resolveMetricResults(Metrics.EmissionsIntensity, currentYearResults) as EmissionsIntensity,
        communityEngagement: resolveMetricResults(Metrics.CommunityEngagement, currentYearResults) as CommunityEngagement,
        customerSatisfaction: resolveMetricResults(Metrics.CustomerSatisfaction, currentYearResults) as CustomerSatisfaction,
        employeeSatisfaction: resolveMetricResults(Metrics.EmployeeSatisfaction, currentYearResults) as EmployeeSatisfaction,
        genderDiversity: resolveMetricResults(Metrics.GenderDiversity, currentYearResults) as GenderDiversity,
        boardMaturity: resolveMetricResults(Metrics.BoardMaturity, currentYearResults) as BoardMaturity,
        cyberSecurityMaturity: resolveMetricResults(Metrics.CyberSecurityMaturity, currentYearResults) as CyberSecurityMaturity,
    }

    const previousMetricResults = previousYearResults ? {
        renewableEnergyConsumption: resolveMetricResults(Metrics.RenewableEnergyConsumption, previousYearResults) as RenewableEnergyConsumption,
        emissionsIntensity: resolveMetricResults(Metrics.EmissionsIntensity, previousYearResults) as EmissionsIntensity,
        communityEngagement: resolveMetricResults(Metrics.CommunityEngagement, previousYearResults) as CommunityEngagement,
        customerSatisfaction: resolveMetricResults(Metrics.CustomerSatisfaction, previousYearResults) as CustomerSatisfaction,
        employeeSatisfaction: resolveMetricResults(Metrics.EmployeeSatisfaction, previousYearResults) as EmployeeSatisfaction,
        genderDiversity: resolveMetricResults(Metrics.GenderDiversity, previousYearResults) as GenderDiversity,
        boardMaturity: resolveMetricResults(Metrics.BoardMaturity, previousYearResults) as BoardMaturity,
        cyberSecurityMaturity: resolveMetricResults(Metrics.CyberSecurityMaturity, previousYearResults) as CyberSecurityMaturity,
    } : {};

    return (
        <section data-testid="metrics-dashboard">
            {hasEnvironmentalMetrics && (
                <Row>
                    <Col flex={1} className="bg-white mb-4">
                        <Row className="p-2" style={{ backgroundColor: "#fafafa" }}>
                            <Col>
                                <h3 className="mb-0"><i className="bi bi-globe mr-1" />Environmental</h3>
                            </Col>
                        </Row>

                        <Divider className="my-0" />

                        {currentMetricResults.renewableEnergyConsumption.hasScore() && (
                            <Row justify="center" align="middle">
                                <MetricComponent metric={resolveMetric(Metrics.RenewableEnergyConsumption, metrics)} style={{ width: "50%" }}>
                                    <Score currentResult={currentMetricResults.renewableEnergyConsumption} previousResult={previousMetricResults.renewableEnergyConsumption} />
                                </MetricComponent>
                                <Chart style={{ width: "50%" }} title={"Total Energy Consumption"} description={"kWh"}>
                                    <EnergyConsumptionByTypeChart result={currentMetricResults.renewableEnergyConsumption} />
                                </Chart>
                            </Row>
                        )}

                        {currentMetricResults.renewableEnergyConsumption.hasScore() && currentMetricResults.emissionsIntensity.hasScore() && (
                            <Divider className="m-2" style={{ width: "auto", minWidth: "auto" }} />
                        )}

                        {currentMetricResults.emissionsIntensity.hasScore() && (
                            <Row justify="center" align="middle">
                                <MetricComponent metric={resolveMetric(Metrics.EmissionsIntensity, metrics)} style={{ width: "50%" }}>
                                    <Score currentResult={currentMetricResults.emissionsIntensity} previousResult={previousMetricResults.emissionsIntensity} />
                                </MetricComponent>
                                <Chart style={{ width: "50%" }} title={"GHG Emissions"} description={"tCO2e"}>
                                    <EmissionsByScopeChart result={currentMetricResults.emissionsIntensity} />
                                </Chart>
                            </Row>
                        )}
                    </Col>
                </Row>
            )}

            {hasSocialMetrics && (
                <Row>
                    <Col flex={1} className="bg-white mb-4">
                        <Row className="p-2" style={{ backgroundColor: "#fafafa" }}>
                            <Col>
                                <h3 className="mb-0"><i className="bi bi-people mr-1" />Social</h3>
                            </Col>
                        </Row>

                        <Divider className="my-0" />

                        {(currentMetricResults.communityEngagement.hasScore() || currentMetricResults.customerSatisfaction.hasScore() || currentMetricResults.employeeSatisfaction.hasScore()) && (
                            <Row wrap={false}>
                                {currentMetricResults.communityEngagement.hasScore() && (
                                    <MetricComponent metric={resolveMetric(Metrics.CommunityEngagement, metrics)} flex={1}>
                                        <Score currentResult={currentMetricResults.communityEngagement} previousResult={previousMetricResults.communityEngagement} />
                                    </MetricComponent>
                                )}
                                {currentMetricResults.communityEngagement.hasScore() && (currentMetricResults.customerSatisfaction.hasScore() || currentMetricResults.employeeSatisfaction.hasScore()) && (
                                    <Divider type="vertical" className="m-2 h-auto" />
                                )}
                                {currentMetricResults.customerSatisfaction.hasScore() && (
                                    <MetricComponent metric={resolveMetric(Metrics.CustomerSatisfaction, metrics)} flex={1}>
                                        <Score currentResult={currentMetricResults.customerSatisfaction} previousResult={previousMetricResults.customerSatisfaction} />
                                    </MetricComponent>
                                )}
                                {currentMetricResults.employeeSatisfaction.hasScore() && (currentMetricResults.communityEngagement.hasScore() || currentMetricResults.customerSatisfaction.hasScore()) && (
                                    <Divider type="vertical" className="m-2 h-auto" />
                                )}
                                {currentMetricResults.employeeSatisfaction.hasScore() && (
                                    <MetricComponent metric={resolveMetric(Metrics.EmployeeSatisfaction, metrics)} flex={1}>
                                        <Score currentResult={currentMetricResults.employeeSatisfaction} previousResult={previousMetricResults.employeeSatisfaction} />
                                    </MetricComponent>
                                )}
                            </Row>
                        )}

                        {(currentMetricResults.communityEngagement.hasScore() || currentMetricResults.customerSatisfaction.hasScore() || currentMetricResults.employeeSatisfaction.hasScore()) && (currentMetricResults.genderDiversity.hasScore()) && (
                            <Divider className="m-2" style={{ width: "auto", minWidth: "auto" }} />
                        )}

                        {currentMetricResults.genderDiversity.hasScore() && (
                            <Row justify="center" align="middle">
                                <MetricComponent metric={resolveMetric(Metrics.GenderDiversity, metrics)} style={{ width: "50%" }}>
                                    <Score currentResult={currentMetricResults.genderDiversity} previousResult={previousMetricResults.genderDiversity} />
                                </MetricComponent>
                                <Chart style={{ width: "50%" }}>
                                    <GenderDiversityByLevelChart result={currentMetricResults.genderDiversity} />
                                </Chart>
                            </Row>
                        )}
                    </Col>
                </Row>
            )}

            {hasGovernanceMetrics && (
                <Row>
                    <Col flex={1} className="bg-white mb-4">
                        <Row className="p-2" style={{ backgroundColor: "#fafafa" }}>
                            <Col>
                                <h3 className="mb-0"><i className="bi bi-bank mr-1" />Governance</h3>
                            </Col>
                        </Row>

                        <Divider className="my-0" />

                        {currentMetricResults.boardMaturity.hasScore() && (
                            <Row justify="center" align="middle">
                                <MetricComponent metric={resolveMetric(Metrics.BoardMaturity, metrics)} style={{ width: "50%" }}>
                                    <Score currentResult={currentMetricResults.boardMaturity} previousResult={previousMetricResults.boardMaturity} />
                                </MetricComponent>
                                <Chart style={{ width: "50%" }}>
                                    <BoardMaturityByCategoryChart result={currentMetricResults.boardMaturity} year={year} />
                                </Chart>
                            </Row>
                        )}

                        {currentMetricResults.boardMaturity.hasScore() && currentMetricResults.cyberSecurityMaturity.hasScore() && (
                            <Divider className="m-2" style={{ width: "auto", minWidth: "auto" }} />
                        )}

                        {currentMetricResults.cyberSecurityMaturity.hasScore() && (
                            <Row justify="center" align="middle">
                                <MetricComponent metric={resolveMetric(Metrics.CyberSecurityMaturity, metrics)} style={{ width: "50%" }}>
                                    <Score currentResult={currentMetricResults.cyberSecurityMaturity} previousResult={previousMetricResults.cyberSecurityMaturity} />
                                </MetricComponent>
                                <Chart style={{ width: "50%" }}>
                                    <CyberSecurityMaturityByCategoryChart result={currentMetricResults.cyberSecurityMaturity} year={year} />
                                </Chart>
                            </Row>
                        )}
                    </Col>
                </Row>
            )}
        </section>
    );
}

export default CompanySingleYearResults;
