import React, {useEffect, useState} from 'react';
import './index.scss';
import {Button, Col, Form, Input, notification, Row, Select} from 'antd';
import {EyeInvisibleOutlined, EyeTwoTone, ReloadOutlined} from '@ant-design/icons';
import API_SERVICE from '../../services/api-service';
import AppConstants from "@recoinsights/shared/constants/Constants";

export default function EditProfile(props: any) {
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);
    const [profile, setProfile] = useState({} as any);
    const [fetching, setFetching] = useState(false);
    const [roles, setRoles] = useState([] as any);
    const [companies, setCompanies] = useState([] as any);
    const [groups, setGroups] = useState([] as any);
    const [selectedRole, setSelectedRole] = useState(null);
    const [resetPassFlag, setResetPassFlag] = useState(false);

    const getProfile = async () => {
        setFetching(true);
        try {
            const {data: {data}} = await API_SERVICE.getProfile();
            const profileObj = {
                "id": data.id,
                "firstName": data.firstName,
                "lastName": data.lastName,
                "email": data.email,
                "phoneNumber": data.phoneNumber,
                "roleIds": data.roleId,
                groups: undefined,
                companyIds: undefined,
            }

            if (data.groups?.length) {
                let a = data.groups.map((item: any) => {
                    return item.id
                });
                if (AppConstants.adminArray.includes(data.role.id)) {
                    profileObj.groups = a;
                } else {
                    profileObj.groups = a[0];
                }
            }

            if (data.companies?.length) {
                let a = data.companies.map((item: any) => {
                    return item.id
                });
                if (AppConstants.consultantAndAuditor.includes(data.role.id)) {
                    profileObj.companyIds = a;
                } else {
                    profileObj.companyIds = a[0];
                }
            }
            setProfile(profileObj);
            setSelectedRole(data.role.id);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        } finally {
            setFetching(false);
        }
    };

    const getCompanies = async () => {
        try {
            const {data: {data}} = await API_SERVICE.getCompanies({perpage: 1000});
            setCompanies(data.items);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };
    const getRoles = async () => {
        try {
            const {data: {data}} = await API_SERVICE.getRoles();
            setRoles(data.items);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };

    const getGroups = async () => {
        try {
            const {data: {data}} = await API_SERVICE.getGroups();
            setGroups(data.items);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };

    useEffect(() => {
        getProfile();
        getRoles();
        getCompanies();
        getGroups();
    }, []);

    if (fetching)
        return <div> Getting profile details... </div>
    return (
        <Row className="p-4">
            <Col xs={13}>
                <div>
                    <Form
                        form={form}
                        initialValues={profile || {}}
                        fields={[]}
                        onFinish={(value) => {
                            if (resetPassFlag) {
                                if (value['password'] !== value['confirm_pass']) {
                                    return notification.warning({message: 'Confirm Password doesnt match your New Password'});
                                } else {
                                    delete value['confirm_pass'];
                                }
                            }
                            const reqObj: any = value;
                            setSaving(true);
                            API_SERVICE.profileUpdate(reqObj).then((d) => {
                                notification.success({message: 'Profile updated successfully'});
                            }).catch((e) => {
                                notification.error({message: API_SERVICE.handleErrors(e)});
                            }).finally(() => {
                                setSaving(false);
                            });
                        }}
                        layout='horizontal'
                        labelCol={{span: 8}}
                        wrapperCol={{span: 12}}
                        requiredMark={true}
                    >
                        <Form.Item
                            label={'First Name:'}
                            name={'firstName'}
                            rules={[
                                {required: true, message: 'This field is required'},
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label={'Last Name:'}
                            name={'lastName'}
                            rules={[
                                {required: true, message: 'This field is required'},
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label={'Email ID:'}
                            name={'email'}

                        >
                            <Input disabled/>
                        </Form.Item>
                        <Form.Item
                            label={'Contact No:'}
                            name={'phoneNumber'}

                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label={'Role'}
                            name={'roleIds'}
                        >
                            <Select
                                disabled={true}
                            >
                                <Select.Option value={1}>Super Admin</Select.Option>
                                {
                                    roles.length > 0 && roles.map((role: any) => {
                                        return <Select.Option
                                            value={role.id}>{role.name}</Select.Option>;
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label={'Group'}
                            name={'groups'}
                        >
                            <Select
                                disabled={true}
                                //@ts-ignore
                                mode={(selectedRole && AppConstants.adminArray.includes(selectedRole)) && 'multiple'}
                                showArrow={true}
                                maxTagCount={2}
                                showSearch={true}
                                filterOption={(input, option: any) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    groups.length > 0 && groups.map((group: any) => {
                                        return <Select.Option
                                            value={group.id}>{group.name}</Select.Option>;
                                    })
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={'Company:'}
                            name={'companyIds'}
                            rules={[
                                {required: false, message: 'Company required'},
                            ]}
                        >
                            <Select
                                disabled={true}
                                //@ts-ignore
                                mode={(selectedRole && AppConstants.consultantAndAuditor.includes(selectedRole)) && 'multiple'}
                                showArrow={true}
                                maxTagCount={2}
                                showSearch={true}
                                filterOption={(input, option: any) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    companies.length > 0 && companies.map((company: any) => {
                                        return <Select.Option
                                            value={company.id}>{company.name}</Select.Option>;
                                    })
                                }
                            </Select>
                        </Form.Item>
                      {
                        resetPassFlag && <>
                          <Form.Item
                              className="mt-3"
                              label={'New Password:'}
                              name={'password'}
                              rules={[
                                  { required: true, message: 'Enter your new password here' },
                              ]}>
                            <Input.Password iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                          </Form.Item>
                          <Form.Item
                              label={'Confirm Password:'}
                              name={'confirm_pass'}
                              rules={[
                                  { required: true, message: 'Confirm your password here' },
                              ]}>
                            <Input.Password iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                          </Form.Item>
                        </>
                      }
                      <Row className="mb-3">
                          <Col xs={8} className={'text-right'}>
                          </Col>
                          <Col xs={12}>
                            <Button
                                type='primary'
                                ghost
                                htmlType='button'
                                icon={!resetPassFlag && <ReloadOutlined />}
                                className='theme-button primary mr-1'
                                onClick={()=>{
                                  setResetPassFlag(!resetPassFlag)
                                }}>
                                {resetPassFlag? 'Cancel':'Reset Password'}
                            </Button>
                          </Col>
                      </Row>
                        <Row>
                            <Col xs={8}></Col>
                            <Col xs={12}>
                                <Button
                                    type='primary'
                                    htmlType='button'
                                    // icon={<CloseOutlined />}
                                    loading={saving}
                                    className='theme-button primary'
                                    onClick={form.submit}
                                >
                                    Save Changes
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Col>
        </Row>
    );
}
