import React, {useEffect, useState} from "react";
import "./index.scss";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  notification,
  Radio,
  Row,
  Space,
  Spin,
  Steps,
  Tabs,
  Tooltip,
} from "antd";
import AdminListLayout from "@recoinsights/shared/components/layout/AdminListLayout/AdminListLayout";
import {InfoCircleOutlined, LeftOutlined, LoadingOutlined, RightOutlined, SaveFilled,} from "@ant-design/icons";
import ReactQuill from "react-quill";
import {useLocation, useParams} from "react-router-dom";
import API_SERVICE from "../../../services/api-service";
import {AttachFile} from "@material-ui/icons";
import FileUpload from "../../../components/FileUpload";
import UnitSelect from "./UnitSelect";
import LocationSelect from "./LocationSelect";
import ScopeOneGHGQuestion from "./GHGQuestion/ScopeOneGHGQuestion";
import ScopeOneTable2GHGQuestion from "./GHGQuestion/ScopeOneTable2GHGQuestion";
import ScopeOneTable3GHGQuestion from "./GHGQuestion/ScopeOneTable3GHGQuestion";
import ScopeTwoTable1GHGQuestion from "./GHGQuestion/ScopeTwoTable1GHGQuestion";
import ScopeThreeTable1GHGQuestion from "./GHGQuestion/ScopeThreeTable1GHGQuestion";
import Total from "./GHGQuestion/Total";
import { RouterPrompt } from "@recoinsights/shared/components/RouterPrompt";
import { roundOfValue } from "@recoinsights/shared/services/Utility";
import QuestionComments from "./QuestionComments";
import { getUser } from "@recoinsights/client/src/services/local-storage";
import { logout } from "@recoinsights/shared/contexts/AuthContext";
import {useInterval} from "react-use";
import CollectionProgress from "./CollectionProgress";

const { TabPane } = Tabs;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface ParamInterface {
  surveyId: string
}

export default function DataCollection(props: any) {
  const params: ParamInterface = useParams();
  useInterval(() => {
    handleSaveAsDraft();
    }, 600000);

  const location = useLocation();
  const { Step } = Steps;

  const [metrics, setMetrics] = useState([] as any[]);
  const [flatMetric, setFlatMetric] = useState([] as any[]);
  const [metricData, setMetricData] = useState({ pages: [] as any[] });
  const [activeMetricsIndex, setActiveMetricsIndex] = useState(0 as number);
  const [activeMetricsId, setActiveMetricsId] = useState(0 as number);
  const [activeTab, setActiveTab] = useState("0" as string);
  const [dependentQuestions, setDependentQuestions] = useState({} as any);

  const [percentage, setPercentage] = useState(0 as number);

  const [fetchingMetricData, setFetchingMetricData] = useState(false);
  const [shouldBlockNavigation] = useState(true);
  const [surveyDetails, setSurveyDetails] = useState({} as any);

  const [surveyId] = useState(params.surveyId) as any;

  const [activeQuestionComments, setActiveQuestionComments] = useState() as any;

  const roleId = getUser()?.user?.role?.id;

  useEffect(() => {
    localStorage.setItem("questions", JSON.stringify({}));
    localStorage.setItem("scopeTableData", JSON.stringify({}));
    getSurveyDetails();
  }, []);


  const getSurveyDetails = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.getSurveyDetails(surveyId);
      setSurveyDetails(data);
    } catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    }
  };
  async function handleTabChange(key: any) {
    if (roleId === 4 ) {
      const proceed = await Promise.resolve(handleSaveAsDraft());
      if (!proceed) {
        return;
      }
    }
    setActiveTab(key + "");
  }

  async function handleMetricChange(key: any) {
    const currentMetric = metrics.find((item: any, index: number) => {
      return index === key;
    });

    if (roleId === 4 ) {
      const proceed = await Promise.resolve(handleSaveAsDraft());
      if (!proceed) {
        return;
      }
    }
    handleMetricsClick(key, currentMetric.id);
  }

  const handleMetricsClick = (index: number, metricId: number) => {
    localStorage.setItem("questions", JSON.stringify({}));
    localStorage.setItem("scopeTableData", JSON.stringify({}));
    setActiveTab("0");
    setActiveMetricsIndex(index);
    setActiveMetricsId(metricId);
  };

  const handlePrevSwitch = async (key: number) => {
    if (roleId === 4) {
      const proceed = await Promise.resolve(handleSaveAsDraft());
      if (!proceed) {
        return;
      }
    }
    if (key !== 0) {
      setActiveTab(key - 1 + "");
    } else {
      setActiveTab("0");
      let prevMetric = metrics.filter((item: any, index: number) => {
        return index === activeMetricsIndex - 1;
      });
      handleMetricsClick(activeMetricsIndex - 1, prevMetric[0].id);
    }
  };

  const handleNextSwitch = async (key: number, threshold: number) => {
    if (roleId === 4 ) {
      const proceed = await Promise.resolve(handleSaveAsDraft());
      if (!proceed) {
        return;
      }
    }
    if (key !== threshold) {
      setActiveTab(key + 1 + "");
    } else {
      setActiveTab("0");
      let nextMetric = metrics.filter((item: any, index: number) => {
        return index === activeMetricsIndex + 1;
      });
      handleMetricsClick(activeMetricsIndex + 1, nextMetric[0].id);
    }
  };

  const isLastMetricPage = () => {
    return (
      metrics.length - 1 === activeMetricsIndex &&
      metricData.pages.length - 1 + "" === activeTab
    );
  };

  const handleSaveAsDraft = (showSuccess: boolean = false) => {
    const reqObj = getVisibleResponses().filter(
      (q: any) =>
        q.optionIds?.length ||
        q.hasOwnProperty("text") ||
        q.hasOwnProperty("scopeTableData")
    );
    /*if(validateResponses(reqObj)){
            saveToDraft(reqObj);
        }*/
    const isSaved = saveToDraft(reqObj, showSuccess);
    return isSaved;
  };

  const getVisibleResponses = () => {
    let answers = getFormattedQuestionResponse();
    let finalResponses: any = [];
    flatMetric.map((data: any) => {
      answers.forEach((item: any) => {
        if (data.id+'' === item.questionId) {
          item.signature = data.signature;
        }
      });
    });

    answers.forEach((item: any) => {
      if (item.scopeTableData || isVisible(item)) {
        finalResponses.push(item);
      }
    });

    return finalResponses;
  };

  const validateResponses = (finalResponses: any) => {
    flatMetric.map((data: any) => {
      finalResponses.forEach((item: any) => {
        if (data.id+'' === item.questionId) {
          item.validations = data.validations;
          item.isRequired = data.isRequired;
        }
      });
    });

    const validated = finalResponses.every((item: any) => {
      return isValid(item);
    });
    return validated;
  };

  const saveToDraft = async (answers: any, showSuccess: boolean) => {
    if (location.hash === '#edit') {
      let isSuccess = false;
      try {
        await API_SERVICE.saveMetricResponsesToDraft(surveyId, activeMetricsId, {
          responses: answers,
        });
        showSuccess && notification.success({ message: "Saved Successfully" });
        isSuccess = true;
      } catch (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
        isSuccess = false;
      } finally {
        return isSuccess;
      }
    }
    else {
      return true;
    }
  };

  const handleFinalizeData = async () => {
    if (roleId === 4 ) {
      const proceed = await Promise.resolve(handleSaveAsDraft(true));
      if (!proceed) {
        return;
      }
    }

    /*try {
      await API_SERVICE.saveAnalystSurveyFinalData(surveyId);
      history.push('/admin/analyst/surveys');
      // notification.success({message: 'Successfully Saved'});
    } catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    }*/
  };

  const getMetrics = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.getSurveyMetrics(surveyId);
      setMetrics(data);
      setActiveMetricsId(data[0].id);
    } catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    }
  };

  const getQuestionSignature = (uuid: string, flatList: any[]) => {
    let signatures: any = [];
    let _sig: any = [];
    flatList.forEach((question: any) => {
      if (question.options?.length) {
        let qSig = "";
        _sig = [];
        question.options.forEach((option: any) => {
          if (option.nextQuestionUuid === uuid) {
            question.signature = getQuestionSignature(question.uuid, flatList);
            if (question.signature) {
              // _sig.push(question.signature);
              qSig = question.signature;
            }
            _sig.push(option.id);
          }
        });
        if (qSig) {
          signatures.push(qSig);
        }
        if (_sig.length) {
          signatures.push(`[${_sig.join(",")}]`);
        }
      } else {
        if (question.nextQuestionUuid === uuid) {
          question.signature = getQuestionSignature(question.uuid, flatList);
          if (question.signature) {
            signatures.push(question.signature);
          }
          signatures.push("q" + question.id);
        }
      }
    });
    return signatures.join("-");
  };
  const evaluateTree = (data?: any) => {
    const flatList: any[] = [];
    data.pages.forEach((page: any) => {
      page.sections.forEach((section: any) => {
        section.questions.forEach((questionItem: any) => {
          switch (questionItem.type) {
            case "simple":
            case "location_dropdown":
            case "rich_text":
              _updateValue(
                questionItem.id,
                questionItem.uuid,
                {
                  text: questionItem.responses[0]?.text,
                },
                questionItem.responses?.[0]?.fileId,
                questionItem.responses?.[0]?.unitId
              );
              break;
            case "multiple":
              _updateValue(
                questionItem.id,
                questionItem.uuid,
                {
                  optionIds: questionItem.responses.map(
                    (response: any) => response.optionId
                  ),
                },
                questionItem.responses?.[0]?.fileId,
                questionItem.responses?.[0]?.unitId
              );
              break;
            case "single":
              _updateValue(
                questionItem.id,
                questionItem.uuid,
                {
                  optionIds: questionItem.responses.map(
                    (response: any) => response.optionId
                  ),
                },
                questionItem.responses?.[0]?.fileId,
                questionItem.responses?.[0]?.unitId
              );
              break;
          }

          if (questionItem?.responses?.[0]?.scopeTableData?.length) {
            const scopeTableData = localStorage.getItem("scopeTableData")
              ? JSON.parse(localStorage.getItem("scopeTableData") as string)
              : {};
            scopeTableData[questionItem.id] =
              questionItem?.responses[0]?.scopeTableData;
            localStorage.setItem(
              "scopeTableData",
              JSON.stringify(scopeTableData)
            );
          }
          flatList.push(questionItem);
        });
      });
    });
    flatList.forEach((question: any) => {
      if (question.parentQuestionUuid) {
        question.signature = getQuestionSignature(question.uuid, flatList);
      }
    });
    setFlatMetric(flatList);
  };

  const getDataByMetrics = async () => {
    if (activeMetricsId !== 0) {
      setFetchingMetricData(true);
      try {
        const {
          data: { data },
        } = await API_SERVICE.getUserMetricsData(surveyId, activeMetricsId);
        data.pages.forEach((page: any) => {
          if (page.name === "GHG Emissions") {
            page.sections.forEach((section: any) => {
              section.scope = null;
              for (const question of section.questions) {
                if (question.scope) {
                  section.scope = question.scope;
                  break;
                }
              }
            });
          }
        });
        setMetricData(data);
        evaluateTree(data);

        // reEvaluateHiddenQuestions(data);
      } catch (e) {
        //@ts-ignore
        if (e.response.status === 404) {
          window.location.replace('/admin/analyst/surveys')
        }
        notification.error({ message: API_SERVICE.handleErrors(e) });
        setMetricData({ pages: [] });
      } finally {
        setFetchingMetricData(false);
      }
    }
  };

  const getPercentage = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.getUserPercentageComplete(surveyId);
      setPercentage(data.percentage);
      return data.percentage;
    } catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    }
  };

  const renderTabBar = (props: any, DefaultTabBar: any) => (
    <DefaultTabBar {...props} className="button-style-tab-bar mt-2" />
  );

  useEffect(() => {
    getMetrics();
    // getPercentage();
    // setAutoSaveTimer();
  }, []);

  useEffect(() => {
    getDataByMetrics();
    // clearAutoSaveTimer();
  }, [activeMetricsId]);


  useEffect(() => {
    if (roleId === 4 && (location.hash === '#edit')) {
      if (shouldBlockNavigation) {
        window.onbeforeunload = () => true;
      } else {
        //@ts-ignore
        window.onbeforeunload = undefined;
      }
    }
  }, [shouldBlockNavigation]);

  const handleOK = () => {
    //@ts-ignore
    window.onbeforeunload = undefined;
    if (localStorage.getItem("fromLogout")) {
      handleSaveAsDraft();
      logout();
      return true;
    } else {
      return true;
    }
  };

  const handleCancel = () => {
    localStorage.removeItem("fromLogout");
    return false;
  };

  return (
    <div className={"group-wrapper page-wrapper"}>
      {roleId === 4 && (location.hash === '#edit') && (
        <RouterPrompt
          when={shouldBlockNavigation}
          title="Leave this page"
          cancelText="Cancel"
          okText="Save & Exit"
          onOK={handleOK}
          onCancel={handleCancel}
          callback={handleSaveAsDraft}
        />
      )}
      {/*@ts-ignore*/}
      <AdminListLayout
        title={
          metrics &&
          metrics.length > 0 && (
            <Row align={"middle"}>
              <Col xs={24}>
                <Steps
                  size="small"
                  labelPlacement="vertical"
                  progressDot={false}
                  current={activeMetricsIndex}
                  onChange={(key) => {
                    handleMetricChange(key);
                  }}
                >
                  {metrics.map((item: any, index: number) => {
                    return (
                      <Step
                        title={item.name}
                        key={index}
                        className="cursor-pointer"
                      />
                    );
                  })}
                </Steps>
              </Col>
              {/*<Col xs={2} className="text-right pr-1">
                <div>
                  <small>{Math.floor(percentage)} %</small>
                </div>
              </Col>*/}
            </Row>
          )
        }
      >
        <Row>
          <Col sm={24}>
            <div className="p-2">
              {/*<Progress percent={0} status="active" />*/}

              {fetchingMetricData ? (
                <div className="text-center">
                  <Spin indicator={antIcon} />
                </div>
              ) : (
                <Tabs
                  defaultActiveKey="0"
                  activeKey={activeTab}
                  size={"large"}
                  className="bg-white-tab"
                  destroyInactiveTabPane={false}
                  onChange={handleTabChange}
                >
                  {
                    // metricData && metricData.pages && metricData.pages.length > 0
                    metricData &&
                      metricData.pages &&
                      metricData.pages.length > 0 &&
                      metricData.pages.map((item: any, index: number) => {
                        if (item.name === "GHG Emissions") {
                          return (
                            <TabPane tab={item.name} key={index}>
                              <div className="section-card p-2" key={index}>
                                <div>{item.instruction}</div>
                              </div>

                              <Tabs
                                defaultActiveKey="0"
                                renderTabBar={renderTabBar}
                                animated={false}
                                destroyInactiveTabPane={true}
                              >
                                <TabPane tab={"Scope 1"} key={0}>
                                  {item.sections &&
                                    item.sections.length > 0 &&
                                    item.sections.map(
                                      (section: any, i: number) => {
                                        if (
                                          section.scope === "scope1" ||
                                          section.scope === null
                                        ) {
                                          return (
                                            <div
                                              className="section-card p-2"
                                              key={i}
                                            >
                                              <div className="section-title">
                                                <h3 className="mb-0">
                                                  {section.name}{" "}
                                                </h3>
                                              </div>
                                              {section.questions &&
                                                section.questions.length > 0 &&
                                                section.questions.map(
                                                  (
                                                    questionItem: any,
                                                    j: number
                                                  ) => {
                                                    if (
                                                      // isVisible(questionItem)
                                                        true
                                                    ) {
                                                      if (
                                                        questionItem.scope ===
                                                        "scope1"
                                                      ) {
                                                        return (
                                                          <div
                                                            key={
                                                              questionItem.id
                                                            }
                                                          >
                                                            {questionItem.table ===
                                                              "table1" && (
                                                              <ScopeOneGHGQuestion
                                                                surveyYear={
                                                                  surveyDetails.year
                                                                }
                                                                surveyId={
                                                                  surveyId
                                                                }
                                                                activeQuestionComments={
                                                                  activeQuestionComments
                                                                }
                                                                setActiveQuestionComments={
                                                                  setActiveQuestionComments
                                                                }
                                                                questionItem={
                                                                  questionItem
                                                                }
                                                                j={j}
                                                                roleId={roleId}
                                                              />
                                                            )}
                                                            {questionItem.table ===
                                                              "table2" && (
                                                              <ScopeOneTable2GHGQuestion
                                                                surveyYear={
                                                                  surveyDetails.year
                                                                }
                                                                surveyId={
                                                                  surveyId
                                                                }
                                                                activeQuestionComments={
                                                                  activeQuestionComments
                                                                }
                                                                setActiveQuestionComments={
                                                                  setActiveQuestionComments
                                                                }
                                                                questionItem={
                                                                  questionItem
                                                                }
                                                                j={j}
                                                                roleId={roleId}
                                                              />
                                                            )}
                                                            {questionItem.table ===
                                                              "table3" && (
                                                              <ScopeOneTable3GHGQuestion
                                                                surveyYear={
                                                                  surveyDetails.year
                                                                }
                                                                surveyId={
                                                                  surveyId
                                                                }
                                                                activeQuestionComments={
                                                                  activeQuestionComments
                                                                }
                                                                setActiveQuestionComments={
                                                                  setActiveQuestionComments
                                                                }
                                                                questionItem={
                                                                  questionItem
                                                                }
                                                                j={j}
                                                                roleId={roleId}
                                                              />
                                                            )}
                                                          </div>
                                                        );
                                                      } else if (
                                                        questionItem.scope ===
                                                        null
                                                      ) {
                                                        return (
                                                          <QuestionCard
                                                            key={
                                                              questionItem.id
                                                            }
                                                            questionItem={
                                                              questionItem
                                                            }
                                                            j={j}
                                                            dependentQuestions={
                                                              dependentQuestions
                                                            }
                                                            setDependentQuestions={
                                                              setDependentQuestions
                                                            }
                                                            metric={item}
                                                            isRenewable={false}
                                                            surveyId={surveyId}
                                                            questionId={
                                                              questionItem.id
                                                            }
                                                            roleId={roleId}
                                                            activeQuestionComments={
                                                              activeQuestionComments
                                                            }
                                                            setActiveQuestionComments={
                                                              setActiveQuestionComments
                                                            }
                                                          />
                                                        );
                                                      }
                                                    }
                                                  }
                                                )}
                                            </div>
                                          );
                                        } // if scope1
                                      }
                                    )}
                                </TabPane>
                                <TabPane tab={"Scope 2"} key={1}>
                                  {item.sections &&
                                    item.sections.length > 0 &&
                                    item.sections.map(
                                      (section: any, i: number) => {
                                        if (section.scope === "scope2") {
                                          return (
                                            <div
                                              className="section-card p-2"
                                              key={i}
                                            >
                                              <div className="section-title">
                                                <h3 className="mb-0">
                                                  {section.name}
                                                </h3>
                                              </div>
                                              {section.questions &&
                                                section.questions.length > 0 &&
                                                section.questions.map(
                                                  (
                                                    questionItem: any,
                                                    j: number
                                                  ) => {
                                                    if (
                                                      // isVisible(questionItem)
                                                        true
                                                    ) {
                                                      if (
                                                        questionItem.scope ===
                                                        "scope2"
                                                      ) {
                                                        return (
                                                          <div
                                                            key={
                                                              questionItem.id
                                                            }
                                                          >
                                                            {questionItem.table ===
                                                              "table1" && (
                                                              <ScopeTwoTable1GHGQuestion
                                                                surveyYear={
                                                                  surveyDetails.year
                                                                }
                                                                questionItem={
                                                                  questionItem
                                                                }
                                                                j={j}
                                                                roleId={roleId}
                                                                activeQuestionComments={
                                                                  activeQuestionComments
                                                                }
                                                                setActiveQuestionComments={
                                                                  setActiveQuestionComments
                                                                }
                                                                surveyId={
                                                                  surveyId
                                                                }
                                                              />
                                                            )}
                                                          </div>
                                                        );
                                                      }
                                                    }
                                                  }
                                                )}
                                            </div>
                                          );
                                        } // if scope2
                                      }
                                    )}
                                </TabPane>
                                <TabPane tab={"Scope 3"} key={2}>
                                  {item.sections &&
                                    item.sections.length > 0 &&
                                    item.sections.map(
                                      (section: any, i: number) => {
                                        if (section.scope === "scope3") {
                                          return (
                                            <div
                                              className="section-card p-2"
                                              key={i}
                                            >
                                              <div className="section-title">
                                                <h3 className="mb-0">
                                                  {section.name}
                                                </h3>
                                              </div>
                                              {section.questions &&
                                                section.questions.length > 0 &&
                                                section.questions.map(
                                                  (
                                                    questionItem: any,
                                                    j: number
                                                  ) => {
                                                    if (
                                                      // isVisible(questionItem)
                                                        true
                                                    ) {
                                                      if (
                                                        questionItem.scope ===
                                                        "scope3"
                                                      ) {
                                                        return (
                                                          <div
                                                            key={
                                                              questionItem.id
                                                            }
                                                          >
                                                            {questionItem.table ===
                                                              "table1" && (
                                                              <ScopeThreeTable1GHGQuestion
                                                                surveyYear={
                                                                  surveyDetails.year
                                                                }
                                                                questionItem={
                                                                  questionItem
                                                                }
                                                                j={j}
                                                                roleId={roleId}
                                                                activeQuestionComments={
                                                                  activeQuestionComments
                                                                }
                                                                setActiveQuestionComments={
                                                                  setActiveQuestionComments
                                                                }
                                                                surveyId={
                                                                  surveyId
                                                                }
                                                              />
                                                            )}
                                                          </div>
                                                        );
                                                      }
                                                    }
                                                  }
                                                )}
                                            </div>
                                          );
                                        } // if scope3
                                      }
                                    )}
                                </TabPane>
                                <TabPane tab={"Total"} key={3}>
                                  <div className="section-card p-2">
                                    <div className="section-title">
                                      <h3 className="mb-0">
                                        Total of Scope 1, 2 and 3 Emissions
                                      </h3>
                                    </div>
                                    <Total surveyId={surveyId} />
                                  </div>
                                </TabPane>
                              </Tabs>

                              <Divider />

                              <div className="d-flex justify-content-between mt-3">
                                <Button
                                  type="default"
                                  htmlType="button"
                                  onClick={() => {
                                    handlePrevSwitch(index);
                                  }}
                                  disabled={
                                    activeMetricsIndex === 0 &&
                                    activeTab === "0"
                                  }
                                  data-testid="previous-button"
                                >
                                  <LeftOutlined /> Previous
                                </Button>

                                {isLastMetricPage() ? (
                                  <Button
                                    type="primary"
                                    htmlType="button"
                                    className="theme-button primary"
                                    onClick={handleFinalizeData}
                                    // onClick={() => {handleNextSwitch(index, metricData.pages.length-1)}}
                                  >
                                    Finalize <RightOutlined />
                                  </Button>
                                ) : (
                                  <Button
                                    type="primary"
                                    htmlType="button"
                                    className="theme-button primary"
                                    onClick={() => {
                                      handleNextSwitch(
                                        index,
                                        metricData.pages.length - 1
                                      );
                                    }}
                                    data-testid="next-button"
                                  >
                                    Next <RightOutlined />
                                  </Button>
                                )}
                              </div>
                            </TabPane>
                          );
                        }
                        // if ghg emission page
                        else if (
                          item.name === "% Renewable Energy Calculation"
                        ) {
                          return (
                            <TabPane tab={item.name} key={index}>
                              {item.sections &&
                                item.sections.length > 0 &&
                                item.sections.map((section: any, i: number) => {
                                  return (
                                    <div className="section-card p-2" key={i}>
                                      <div className="section-title">
                                        <h3 className="mb-0">{section.name}</h3>
                                      </div>
                                      {section.questions &&
                                        section.questions.length > 0 &&
                                        section.questions.map(
                                          (questionItem: any, j: number) => {
                                            if (
                                                // isVisible(questionItem)
                                                true
                                            ) {
                                              return (
                                                <QuestionCard
                                                  key={questionItem.id}
                                                  questionItem={questionItem}
                                                  j={j}
                                                  dependentQuestions={
                                                    dependentQuestions
                                                  }
                                                  setDependentQuestions={
                                                    setDependentQuestions
                                                  }
                                                  metric={item}
                                                  activeTab={activeTab}
                                                  isRenewable={true}
                                                  surveyId={surveyId}
                                                  questionId={questionItem.id}
                                                  roleId={roleId}
                                                  activeQuestionComments={
                                                    activeQuestionComments
                                                  }
                                                  setActiveQuestionComments={
                                                    setActiveQuestionComments
                                                  }
                                                />
                                              );
                                            }
                                          }
                                        )}
                                    </div>
                                  );
                                })}

                              <Divider />

                              <div className="d-flex justify-content-between mt-3">
                                <Button
                                  type="default"
                                  htmlType="button"
                                  onClick={() => {
                                    handlePrevSwitch(index);
                                  }}
                                  disabled={
                                    activeMetricsIndex === 0 &&
                                    activeTab === "0"
                                  }
                                >
                                  <LeftOutlined /> Previous
                                </Button>

                                {isLastMetricPage() ? (
                                  <Button
                                    type="primary"
                                    htmlType="button"
                                    className="theme-button primary"
                                    onClick={handleFinalizeData}
                                    // onClick={() => {handleNextSwitch(index, metricData.pages.length-1)}}
                                  >
                                    Finalize <RightOutlined />
                                  </Button>
                                ) : (
                                  <Button
                                    type="primary"
                                    htmlType="button"
                                    className="theme-button primary"
                                    onClick={() => {
                                      handleNextSwitch(
                                        index,
                                        metricData.pages.length - 1
                                      );
                                    }}
                                    data-testid="next-button"
                                  >
                                    Next <RightOutlined />
                                  </Button>
                                )}
                              </div>
                            </TabPane>
                          );
                        }

                        return (
                          <TabPane tab={item.name} key={index}>
                            {
                              item.instruction && item.instruction !== '' && <div className="section-card p-2">{item.instruction}</div>
                            }
                            {item.sections &&
                              item.sections.length > 0 &&
                              item.sections.map((section: any, i: number) => {
                                return (
                                  <div className="section-card p-2" key={i}>
                                    <div className="section-title">
                                      <h3 className="mb-0">{section.name}</h3>
                                    </div>
                                    {section.questions &&
                                      section.questions.length > 0 &&
                                      section.questions.map(
                                        (questionItem: any, j: number) => {
                                          if (
                                            isVisible(
                                              questionItem,
                                              section.questions
                                            )
                                          ) {
                                            return (
                                              <QuestionCard
                                                key={questionItem.id}
                                                questionItem={questionItem}
                                                j={j}
                                                dependentQuestions={
                                                  dependentQuestions
                                                }
                                                setDependentQuestions={
                                                  setDependentQuestions
                                                }
                                                metric={item}
                                                isRenewable={false}
                                                surveyId={surveyId}
                                                questionId={questionItem.id}
                                                activeQuestionComments={
                                                  activeQuestionComments
                                                }
                                                setActiveQuestionComments={
                                                  setActiveQuestionComments
                                                }
                                                roleId={roleId}
                                                surveyDetails={surveyDetails}
                                              />
                                            );
                                          }
                                        }
                                      )}
                                  </div>
                                );
                              })}

                            <Divider />

                            <div className="d-flex justify-content-between mt-3">
                              <Button
                                type="default"
                                htmlType="button"
                                onClick={() => {
                                  handlePrevSwitch(index);
                                }}
                                disabled={
                                  activeMetricsIndex === 0 && activeTab === "0"
                                }
                              >
                                <LeftOutlined /> Previous
                              </Button>

                              {isLastMetricPage() ? (
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  className="theme-button primary"
                                  onClick={handleFinalizeData}
                                  // onClick={() => {handleNextSwitch(index, metricData.pages.length-1)}}
                                >
                                  Finalize <RightOutlined />
                                </Button>
                              ) : (
                                <Button
                                  type="primary"
                                  htmlType="button"
                                  className="theme-button primary"
                                  onClick={() => {
                                    handleNextSwitch(
                                      index,
                                      metricData.pages.length - 1
                                    );
                                  }}
                                  data-testid="next-button"
                                >
                                  Next <RightOutlined />
                                </Button>
                              )}
                            </div>
                          </TabPane>
                        );
                      })
                  }
                </Tabs>
              )}

              {roleId === 4  && (location.hash === '#edit') && (
                <div className="floating-btn-user" data-testid="draft-button" onClick={() => {handleSaveAsDraft(true)}}>
                  <span className="questionnaire-action-btn icon-save-user">
                    <Tooltip placement="top" title={"Save as draft"}>
                      <SaveFilled />
                    </Tooltip>
                  </span>
                </div>
              )}
              {
                (roleId === 5)  &&
                  <CollectionProgress {...{
                      surveyId,
                      metricData,
                      activeMetricsId,
                      getPercentage
                    }}/>
              }
            </div>
          </Col>
        </Row>
      </AdminListLayout>
    </div>
  );
}

const getSignatureMatrix = (i: any[], result = []) => {
  const r: any[] = [];
  i.forEach((_i: any) => {
    if (_i && _i !== undefined && _i !== "undefined") {
      if (_i.indexOf("[") > -1) {
        r.push(_i.replace("[", "").replace("]", "").split(","));
      } else {
        r.push(_i);
      }
    }
  });
  return r;
};

const isVisible = (question: any, allQuestions = []) => {
  let signature;
  if (question) {
    signature = question.signature;
  }
  if (!signature) {
    return true;
  }
  const signatureItems = signature.split("-");
  const signatureMatrix = getSignatureMatrix(signatureItems);
  const responses = getFormattedQuestionResponse();

  responses.forEach((response: any) => {
    if (response.optionIds) {
      response.optionIds.forEach((optionId: any) => {
        let index = -1;

        signatureMatrix.forEach((s: any, _i: number) => {
          if (typeof s === "string") {
            if (s === optionId.toString()) {
              index = _i;
            }
          } else {
            if (s.indexOf(optionId.toString()) > -1) {
              index = _i;
            }
          }
        });
        if (index > -1) {
          signatureMatrix.splice(index, 1);
        }
      });
    }
  });
  if (signatureMatrix?.length) {
    let temp = JSON.parse(JSON.stringify(signatureMatrix));
    temp.forEach((t: any) => {
      if (t.indexOf("q") > -1) {
        const qId = parseInt(t.replace("q", ""));
        if (
          (isVisible(allQuestions?.find((a: any) => a.id === qId)),
          allQuestions)
        ) {
          signatureMatrix.splice(signatureMatrix.indexOf(t), 1);
        }
      }
    });
  }
  return !signatureMatrix.length;
};

const isValid = (question: any) => {
  const { validations } = question;
  if (!validations || validations.length === 0) {
    return true;
  }

  if (validations[0].type === "Number") {
    if (!isNaN(Number(question.text))) {
      return true;
    } else {
      return false;
    }
  } else if (validations[0].type === "Text") {
  } else if (validations[0].type === "Length") {
  } else if (validations[0].type === "regular_expression") {
  }
};

const _updateValue = (
  questionId: any,
  uuid: string,
  value: any,
  fileId: any,
  unitId?: any
) => {
  if (value && value.text) {
    value.text = value.text || "";
  }

  if (value && value.optionIds) {
    value.optionIds = value.optionIds || [];
  }
  const data = JSON.parse(localStorage.getItem("questions") as string);
  data[questionId] = { ...value, fileId, uuid };
  if (unitId) {
    data[questionId]["unitId"] = unitId;
  }
  localStorage.setItem("questions", JSON.stringify(data));
};
const getFormattedQuestionResponse = () => {
  const data = JSON.parse(localStorage.getItem("questions") as string);
  const response = [];
  for (let i in data) {
    if (data[i].optionIds || data[i].hasOwnProperty('text')) {
      response.push({
        questionId: i,
        ...data[i],
      });
    }
  }

  const tableData = JSON.parse(
    localStorage.getItem("scopeTableData") as string
  );
  for (let i in tableData) {
    response.push({
      questionId: i,
      scopeTableData: tableData[i].length ? [...tableData[i]] : [],
    });
  }

  return response;
};

const QuestionCard = ({
  questionItem,
  j,
  dependentQuestions: dependentQuestionsList,
  setDependentQuestions,
  isRenewable,
  activeTab,
  surveyId,
  roleId,
  activeQuestionComments,
  setActiveQuestionComments,
  surveyDetails,
}: any) => {
  const location = useLocation();
  const [fileId, setFileId] = useState("");
  const [unitId, setUnitId] = useState("");
  const [textValue, setTextValue] = useState(questionItem.responses?.[0]?.text);
  const [currentSimpleValue, setCurrentSimpleValue] = useState("");
  const [percentageRenewableEnergy, setPercentageRenewableEnergy] = useState(
    "" as any
  );

  const getPercentageRenewableEnergy = async (value?: any) => {
    const reqObj = { renewablePercentage: value };
    try {
      const {
        data: { data },
      } = await API_SERVICE.getPercentageRenewableEnergy(surveyId, reqObj);
      setPercentageRenewableEnergy(data);
      /*if(!questionItem.responses?.[0]?.text && !(value || value === 0)) {
        handdleRenewalebleEnergyPercentageChange(data);
      }*/
    } catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    }
  };

  const updateValue = (
    questionId: any,
    uuid: string,
    value: any,
    fileId: any
  ) => {
    _updateValue(questionId, uuid, value, fileId);
  };
  const setRichTextReqData = (value: any) => {
    setTextValue(value);
    updateValue(
      questionItem.id,
      questionItem.uuid,
      { text: value },
      questionItem.isAttachmentAllowed && fileId
    );
  };
  const handleSimpleResponse = (
    questionId: any,
    uuid: string,
    value: any,
    fileId: any,
    unitId?: any
  ) => {
    setCurrentSimpleValue(value);
    if (unitId) {
      _updateValue(questionId, uuid, value, fileId, unitId);
    } else {
      _updateValue(questionId, uuid, value, fileId);
    }
  };
  const handleUnitSelect = (id: any) => {
    setUnitId(id);
    _updateValue(
      questionItem.id,
      questionItem.uuid,
      currentSimpleValue ||
        (questionItem.responses?.[0]?.text && {
          text: questionItem.responses?.[0]?.text,
        }),
      questionItem.isAttachmentAllowed && fileId,
      id
    );
  };

  const handleOptionSelect = (data: any) => {
    const allDependentQuestions = questionItem.options
      .filter((option: any) => !!option.nextQuestionUuid)
      .map((option: any) => {
        return option.nextQuestionUuid;
      });
    allDependentQuestions.forEach((uuid: string) => {
      dependentQuestionsList[uuid] = true;
    });
    for (let dependentQuestion in dependentQuestionsList) {
      dependentQuestionsList[data.target["data-nextQuestion"]] = false;
    }
    setDependentQuestions(JSON.parse(JSON.stringify(dependentQuestionsList)));
    updateValue(
      questionItem.id,
      questionItem.uuid,
      { optionIds: [data.target.value] },
      questionItem.isAttachmentAllowed && fileId
    );
  };

  const handdleRenewalebleEnergyPercentageChange = async (value: any) => {
    getPercentageRenewableEnergy(value);
    handleSimpleResponse(
      questionItem.id,
      questionItem.uuid,
      { text: value },
      questionItem.isAttachmentAllowed && fileId,
      questionItem.unitCategory && unitId
    );
  };

  useEffect(() => {
    if (isRenewable) {
      getPercentageRenewableEnergy();
    }
  }, [activeTab]);

  if (questionItem.type === "multiple") {
    return (
      <div className="question-card" key={j}>
        <div className="d-flex">
          <div
            className="question"
            dangerouslySetInnerHTML={{ __html: questionItem.question }}
          />
          {questionItem.isRequired && (
            <span className="text-primary ml-1"> *</span>
          )}
        </div>
        {questionItem.description && <small>{questionItem.description}</small>}
        {questionItem.toolTipText && (
          <Tooltip
              key={questionItem.id}
              destroyTooltipOnHide={true}
              overlayClassName="large-tooltip"
              className="pl-1 cursor-pointer text-primary"
              title={<div dangerouslySetInnerHTML={{__html: questionItem.toolTipText}} />}
          >
            <InfoCircleOutlined />
          </Tooltip>
        )}

        <Form
          initialValues={{
            optionIds: questionItem.responses.map(
              (response: any) => response.optionId
            ),
          }}
        >
          <Form.Item
            rules={[{ required: questionItem.isRequired }]}
            name={"optionIds"}
          >
            <Checkbox.Group
              onChange={(v) => {
                updateValue(
                  questionItem.id,
                  questionItem.uuid,
                  { optionIds: v },
                  questionItem.isAttachmentAllowed && fileId
                );
              }}
            >
              <Space direction="vertical">
                {questionItem &&
                  questionItem.options &&
                  questionItem.options.length > 0 &&
                  questionItem.options.map((optionItem: any, index: number) => {
                    return (
                      <Checkbox value={optionItem.id} key={index} disabled={(location.hash !== '#edit')}>
                        {optionItem.option}
                      </Checkbox>
                    );
                  })}
              </Space>
            </Checkbox.Group>
          </Form.Item>

          <QuestionComments
            surveyId={surveyId}
            questionId={questionItem.id}
            activeQuestionComments={activeQuestionComments}
            setActiveQuestionComments={setActiveQuestionComments}
          />

          {questionItem.isAttachmentAllowed && (
            <Form.Item label={""} name={"fileId"} rules={[{ required: false }]}>
              <FileUpload
                onChange={(id: any) => setFileId(id)}
                type={"DOCUMENT"}
              >
                <span className="cursor-pointer">
                  <AttachFile />
                </span>
              </FileUpload>
            </Form.Item>
          )}
        </Form>
      </div>
    );
  } else if (questionItem.type === "single") {
    return (
      <div className="question-card">
        <div className="d-flex">
          <div
            className="question"
            dangerouslySetInnerHTML={{ __html: questionItem.question }}
          />
          {questionItem.isRequired && (
            <span className="text-primary ml-1"> *</span>
          )}
        </div>
        {questionItem.description && <small>{questionItem.description}</small>}
        {questionItem.toolTipText && (
          <Tooltip
              key={questionItem.id}
              destroyTooltipOnHide={true}
              overlayClassName="large-tooltip"
              className="pl-1 cursor-pointer text-primary"
              title={<div dangerouslySetInnerHTML={{__html: questionItem.toolTipText}} />}
          >
            <InfoCircleOutlined />
          </Tooltip>
        )}

        <Form
          initialValues={{
            "optionId":
              getFormattedQuestionResponse()?.find(
                (a) => questionItem?.id === parseInt(a.questionId)
              )?.optionIds?.[0] || questionItem.responses?.[0]?.optionId,
          }}
        >
          <Form.Item
            name={"optionId"}
            rules={[{ required: questionItem.isRequired }]}
          >
            <Radio.Group onChange={handleOptionSelect}>
              <Space direction="vertical">
                {questionItem &&
                  questionItem.options &&
                  questionItem.options.length > 0 &&
                  questionItem.options.map((optionItem: any, index: number) => {
                    return (
                      <Radio
                        value={optionItem.id}
                        key={index}
                        disabled={(!(roleId === 4 && location.hash === '#edit'))}
                        data-nextQuestion={optionItem.nextQuestionUuid}
                      >
                        {optionItem.option}
                      </Radio>
                    );
                  })}
              </Space>
            </Radio.Group>
          </Form.Item>

          <QuestionComments
            surveyId={surveyId}
            questionId={questionItem.id}
            activeQuestionComments={activeQuestionComments}
            setActiveQuestionComments={setActiveQuestionComments}
          />

          {questionItem.isAttachmentAllowed && (
            <Form.Item label={""} name={"fileId"} rules={[{ required: false }]}>
              <FileUpload
                onChange={(id: any) => setFileId(id)}
                type={"DOCUMENT"}
                fileType="pdf"
              >
                <span className="cursor-pointer">
                  <AttachFile />
                </span>
              </FileUpload>
            </Form.Item>
          )}
        </Form>
      </div>
    );
  } else if (questionItem.type === "rich_text") {
    return (
      <div className="question-card" key={j}>
        <div className="d-flex">
          <div
            className="question"
            dangerouslySetInnerHTML={{ __html: questionItem.question }}
          />
          {questionItem.isRequired && (
            <span className="text-primary ml-1"> *</span>
          )}
        </div>
        {questionItem.description && <small>{questionItem.description}</small>}
        {questionItem.toolTipText && (
          <Tooltip
              key={questionItem.id}
              destroyTooltipOnHide={true}
              overlayClassName="large-tooltip"
              className="pl-1 cursor-pointer text-primary"
              title={<div dangerouslySetInnerHTML={{__html: questionItem.toolTipText}} />}
          >
            <InfoCircleOutlined />
          </Tooltip>
        )}
        <div>
          <ReactQuill
            className={`survey-quill bg-white ${(roleId === 4 && (location.hash === '#edit')) ? "" : "disabled"}`}
            value={textValue || ""}
            theme="snow"
            key={questionItem.id}
            readOnly={!(roleId === 4 && location.hash === '#edit')}
            onChange={(value) => {
              setRichTextReqData(value);
            }}
            modules={{
              toolbar: {
                className: "ql-toolbar",
                container: [
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  ["bold", "italic", "underline"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  [{ align: [] }],
                  ["link", "image"],
                ],
              },
            }}
          />
          <QuestionComments
            surveyId={surveyId}
            questionId={questionItem.id}
            activeQuestionComments={activeQuestionComments}
            setActiveQuestionComments={setActiveQuestionComments}
          />
        </div>
      </div>
    );
  } else if (questionItem.type === "content") {
    return (
      <div className="question-card" key={j}>
        {questionItem.file && questionItem.file.url && (
          <div className={"text-center"}>
            <img
              src={questionItem.file.url}
              className="mb-2 img-fluid"
              style={{ width: "60%" }}
              alt="url"
            />
          </div>
        )}
        <div
          className="question"
          dangerouslySetInnerHTML={{ __html: questionItem.question }}
        />
        {questionItem.isRequired && (
          <span className="text-primary ml-1"> *</span>
        )}
        {questionItem.description && <small>{questionItem.description}</small>}
        {questionItem.toolTipText && (
          <Tooltip
              key={questionItem.id}
              destroyTooltipOnHide={true}
              overlayClassName="large-tooltip"
              className="pl-1 cursor-pointer text-primary"
              title={<div dangerouslySetInnerHTML={{__html: questionItem.toolTipText}} />}
          >
            <InfoCircleOutlined />
          </Tooltip>
        )}

        <QuestionComments
          surveyId={surveyId}
          questionId={questionItem.id}
          activeQuestionComments={activeQuestionComments}
          setActiveQuestionComments={setActiveQuestionComments}
        />
      </div>
    );
  } else if (questionItem.type === "simple" && !isRenewable) {
    return (
      <div className="question-card" key={j}>
        <div className="d-flex">
          <div
            className="question"
            dangerouslySetInnerHTML={{ __html: questionItem.question }}
          />
          {questionItem.isRequired && (
            <span className="text-primary ml-1"> *</span>
          )}
        </div>
        {questionItem.description && <small>{questionItem.description}</small>}
        {questionItem.toolTipText && (
          <Tooltip
              key={questionItem.id}
              destroyTooltipOnHide={true}
              overlayClassName="large-tooltip"
              className="pl-1 cursor-pointer text-primary"
              title={<div dangerouslySetInnerHTML={{__html: questionItem.toolTipText}} />}
          >
            <InfoCircleOutlined />
          </Tooltip>
        )}

        <Form
          layout={"inline"}
          initialValues={{
            [questionItem.id + "text"]: questionItem.responses?.[0]?.text,
            [questionItem.id + "unitId"]: questionItem.responses?.[0]?.unitId,
            [questionItem.id + "unitCategory"]:
              questionItem.responses?.[0]?.unitCategory,
          }}
        >
          <Form.Item
            className="fix-width-input"
            name={questionItem.id + "text"}
          >
            <Input
              onChange={(e) => {
                handleSimpleResponse(
                  questionItem.id,
                  questionItem.uuid,
                  { text: e.target.value },
                  questionItem.isAttachmentAllowed && fileId,
                  questionItem.unitCategory &&
                    (unitId || questionItem.responses?.[0]?.unitId)
                );
              }}
              disabled={(!(roleId === 4 && (location.hash === '#edit')))}
            />
          </Form.Item>
          <Form.Item name={questionItem.id + "unitCategory"}>
            {questionItem.unitCategory && surveyDetails.year && (
              <UnitSelect
                innitialValue={questionItem.responses?.[0]?.unitId}
                questionItem={questionItem}
                surveyYear={surveyDetails.year}
                onChange={(id: any) => handleUnitSelect(id)}
              />
            )}
          </Form.Item>
        </Form>

        <QuestionComments
          surveyId={surveyId}
          questionId={questionItem.id}
          activeQuestionComments={activeQuestionComments}
          setActiveQuestionComments={setActiveQuestionComments}
        />
      </div>
    );
  } else if (questionItem.type === "simple" && isRenewable) {
    return (
      <div className="question-card" key={j}>
        <div className="d-flex">
          <div
            className="question"
            dangerouslySetInnerHTML={{ __html: questionItem.question }}
          />
          {questionItem.isRequired && (
            <span className="text-primary ml-1"> *</span>
          )}
        </div>
        {questionItem.description && <small>{questionItem.description}</small>}
        {questionItem.toolTipText && (
          <Tooltip
              key={questionItem.id}
              destroyTooltipOnHide={true}
              overlayClassName="large-tooltip"
              className="pl-1 cursor-pointer text-primary"
              title={<div dangerouslySetInnerHTML={{__html: questionItem.toolTipText}} />}
          >
            <InfoCircleOutlined />
          </Tooltip>
        )}

        <Form
          layout="vertical"
          initialValues={{
            [questionItem.id + "text"]: questionItem.responses?.[0]?.text,
          }}
        >
          <Form.Item
            className="fix-width-input"
            name={questionItem.id + "text"}
          >
            <Input
              disabled={(roleId === 4 && (location.hash === '#edit'))  ? false : true}
              onChange={(e) => {
                handdleRenewalebleEnergyPercentageChange(e.target.value);
              }}
              addonAfter={"%"}
            />
          </Form.Item>
          <br />


          <Form.Item className="fix-width-input">
            <Input
              disabled={true}
              value={
                percentageRenewableEnergy &&
                roundOfValue(percentageRenewableEnergy)
              }
              addonAfter={"% Renewable Energy"}
            />
          </Form.Item>
        </Form>

        <QuestionComments
          surveyId={surveyId}
          questionId={questionItem.id}
          activeQuestionComments={activeQuestionComments}
          setActiveQuestionComments={setActiveQuestionComments}
        />
      </div>
    );
  } else if (questionItem.type === "location_dropdown") {
    return (
      <div className="question-card" key={j}>
        <div className="d-flex">
          <div
            className="question"
            dangerouslySetInnerHTML={{ __html: questionItem.question }}
          />
          {questionItem.isRequired && (
            <span className="text-primary ml-1"> *</span>
          )}
        </div>
        {questionItem.description && <small>{questionItem.description}</small>}
        {questionItem.toolTipText && (
          <Tooltip
              key={questionItem.id}
              destroyTooltipOnHide={true}
              overlayClassName="large-tooltip"
              className="pl-1 cursor-pointer text-primary"
              title={<div dangerouslySetInnerHTML={{__html: questionItem.toolTipText}} />}
          >
            <InfoCircleOutlined />
          </Tooltip>
        )}

        <Form
          layout={"inline"}
          initialValues={{
            [questionItem.id + "text"]: questionItem.responses?.[0]?.text,
            [questionItem.id + "unitId"]: questionItem.responses?.[0]?.unitId,
            [questionItem.id + "unitCategory"]:
              questionItem.responses?.[0]?.unitCategory,
          }}
        >
          <Form.Item
            className="fix-width-input"
            name={questionItem.id + "text"}
          >
            <LocationSelect
              innitialValue={questionItem.responses?.[0]?.text}
              questionItem={questionItem}
              onChange={(id: any) => {
                handleSimpleResponse(
                  questionItem.id,
                  questionItem.uuid,
                  { text: id },
                  questionItem.isAttachmentAllowed && fileId,
                  questionItem.unitCategory && unitId
                );
              }}
            />
          </Form.Item>
        </Form>

        <QuestionComments
          surveyId={surveyId}
          questionId={questionItem.id}
          activeQuestionComments={activeQuestionComments}
          setActiveQuestionComments={setActiveQuestionComments}
        />
      </div>
    );
  } else {
    return <div>No Question found</div>;
  }
};
