import {Col, ColProps, Row} from "antd";
import React from "react";
import classNames from "classnames";
import {Metric} from "@recoinsights/types";

interface Props extends ColProps {
    metric?: Metric,
    title?: string,
    description?: string,
}

function Chart({children, className, metric, title, description, ...props}: Props) {
    return (
        <Col className={classNames("p-2 text-center", className)} {...props}>
            {(metric || title) && (
                <h4>{metric?.name ?? title}</h4>
            )}
            {(metric || description) && (
                <p style={{ color: "gray" }}>{metric?.description ?? description ?? '-'}</p>
            )}
            <Row justify={"center"}>
                <Col className="p-2">
                    {children}
                </Col>
            </Row>
        </Col>
    )
}

export default Chart;
