import React, {useEffect, useState} from 'react';
import './index.scss';
import {Button, Input, notification, Pagination, Select, Table, TreeSelect, Typography} from 'antd';
import AdminListLayout from '@recoinsights/shared/components/layout/AdminListLayout/AdminListLayout';
import API_SERVICE from '../../services/api-service';
import {ConfirmationModal} from "../../components";
import {SearchOutlined} from '@ant-design/icons';
import AccessControl from 'components/AccessControl';

const {Text} = Typography;
const {Search} = Input;

export default function QuestionsMasterPage(props: any) {
    const {SHOW_CHILD} = TreeSelect;
    const [categories, setCategories] = useState([]);
    const [questions, setQuestions] = useState([] as any[]);
    const [selection, setSelection] = useState<any>({
        id: -1,
        data: {
            "categoryId": -1,
            "subcategoryIds": []
        }
    })
    const [search, setSearch] = useState('' as string);
    const [fetching, setFetching] = useState(false);
    const [pagination, setPagination] = useState({page: 1, perpage: '10'} as any);
    const [total, setTotal] = useState(10);
    const [confirmationModal, setConfirmationModal] = useState({} as any);
    const [activeFilters, setActiveFilters] = useState({});

    const getCategoryId = (item: any) => {
        if (selection.id === item.id) {
            return selection.data.categoryId;
        } else if (item.categories) {
            return item.categories.categoryId;
        } else return null;
    }

    const columns = [
        {
            title: 'S.No',
            key: 'sNO',
            render: (a: any, v: any, index: number) =>
                <Text>{(pagination.page - 1) * (pagination.perpage) + index + 1}</Text>,
        },
        {
            title: 'Question Type',
            dataIndex: 'type',
            filters: ['simple', 'single', 'multiple', 'rich_text', 'content'].map((i: any) => {
                return {text: i.titlecase(), value: i}
            }),
            onFilter: (value: any, record: any) => {
                return true;
            },
            render: (a: any) => <div style={{width: 120}}>
                <Text>{a.titlecase()}</Text>
            </div>,
        },
        {
            title: 'Question Title',
            width: 500,
            render: (a: any) => {
                return (
                    (a.type === 'rich_text') ?
                        <div style={{padding: 12}}
                             dangerouslySetInnerHTML={{__html: a.question}}/>
                        :
                        <Input.TextArea
                            style={{width: 500}}
                            className={'question-response'}
                            value={a.question.replace(/(<([^>]+)>)/ig, '')}
                            autoSize={true}/>
                );
            }
        },
        {
            title: 'Category',
            render: (a: any) => (
                <Select
                    style={{width: 140}}
                    placeholder="Select Category"
                    value={getCategoryId(a)}
                    onChange={(value) => {
                        setSelection({
                            id: a.id,
                            data: {
                                categoryId: value,
                                subcategoryIds: (
                                    (a.categories && (a.categories.categoryId === value)) ?
                                        [...a.subCategories.map((sub: any) => sub.subCategoryId)] : []
                                ),
                            }
                        })
                    }}
                    options={[
                        ...categories.filter((cat: any) => !!(cat.subCategories.length)).map(
                            (obj: any) => {
                                return {
                                    value: obj.id,
                                    label: obj.name
                                }
                            }
                        )
                    ]}/>
            )
        },
        {
            title: 'Subcategory',
            render: (a: any) => (
                <TreeSelect
                    style={{width: 240}}
                    treeData={[
                        ...categories.filter((cat: any) => !!(cat.subCategories.length)).map(
                            (category: any, index: number) => {
                                let catId = getCategoryId(a);
                                const children: any[] = [
                                    ...category.subCategories.map((sub: any, i: number) => {
                                        return {
                                            key: sub.id,
                                            value: sub.id,
                                            disabled: !(catId === category.id),
                                            title: sub.name,
                                        }
                                    })
                                ];
                                return {
                                    key: `cat-${index}`,
                                    value: `cat-${index}`,
                                    disabled: !(catId === category.id),
                                    title: category.name,
                                    children,
                                }
                            }
                        )
                    ]}
                    value={
                        (selection.id === a.id) ?
                            selection.data.subcategoryIds
                            :
                            [
                                ...a.subCategories.map(
                                    (sub: any) => sub.subCategoryId
                                )
                            ]
                    }
                    onChange={(ids) => {
                        setSelection({
                            id: a.id,
                            data: {
                                categoryId: getCategoryId(a),
                                subcategoryIds: ids
                            }
                        });
                    }}
                    treeCheckable={true}
                    showCheckedStrategy={SHOW_CHILD}
                    placeholder={'Select Subcategory'}
                />
            )
        },
        {
            title: '',
            render: (a: any) => (
                (
                    (selection.id === a.id) && (
                        (selection.data.categoryId !== -1) &&
                        (selection.data.subcategoryIds.length !== 0)
                    )
                ) ?
                    <Button type="primary"
                            onClick={setQuestionCategory}>
                        Update
                    </Button>
                    : <div/>

            )
        },
    ];

    const setQuestionCategory = async () => {
        try {
            setFetching(true);
            const {id, data} = selection;
            await API_SERVICE.setQuestionCategory(id, data);
            refresh();
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        } finally {
            setFetching(false);
        }
    }

    const getCategories = async () => {
        try {
            const {data: {data}} = await API_SERVICE.quesCategoryGet();
            setCategories(data.items);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };

    const refresh = async (page?: number, perPage?: number) => {
        setFetching(true);
        const params = {...pagination, ...activeFilters};
        if (search) {
            params['question'] = search;
            params['page'] = 1;
        }
        if (page) {
            params['page'] = page;
        }
        if (perPage) {
            params['perpage'] = perPage;
        }

        try {
            const {data: {data}} = await API_SERVICE.getQuestions(params)
            setSelection({
                id: -1,
                data: {
                    "categoryId": -1,
                    "subcategoryIds": []
                }
            })
            setQuestions(data.items);
            setTotal(data.total);
            setPagination({page: data.page, perpage: data.perpage});
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        } finally {
            setFetching(false);
        }
    };
    useEffect(() => {
        refresh();
    }, [props.location, search, activeFilters]);

    useEffect(() => {
        getCategories()
    }, []);
    return (

        <div className={'question-wrapper page-wrapper'}>
            {/*@ts-ignore*/}
            <AdminListLayout title={'Questions Master'}
                             titleSearch={<AccessControl id={5}>
                                 <div className='search-bar'>
                                     <Search
                                         enterButton={<SearchOutlined/>}
                                         placeholder='Question Title'
                                         onSearch={
                                             (e) => {
                                                 setSearch(e);
                                             }
                                         }
                                     />
                                 </div>
                             </AccessControl>}

                             pagination={<AccessControl id={1}>
                                 <Pagination current={pagination.page} pageSize={pagination.perpage}
                                             pageSizeOptions={[10, 20, 50]} showSizeChanger showQuickJumper
                                             total={total}
                                             onChange={(page, size) => {
                                                 setPagination({page: page, perpage: size})
                                                 refresh(page, size);
                                             }}/>
                             </AccessControl>}
            >
                <AccessControl id={1}>
                    <Table loading={fetching} scroll={{x: true, y: 'calc(100vh - 340px)'}} pagination={false}
                           dataSource={questions}
                           columns={columns}
                           onChange={(pagination, filters, sorter, extra) => {
                               setActiveFilters(filters);
                           }}/>
                </AccessControl>

                <ConfirmationModal {...confirmationModal}
                                   callback={(a: boolean) => {
                                       setConfirmationModal({})
                                       if (a) {
                                           refresh();
                                       }
                                   }}/>

            </AdminListLayout>
        </div>
    );
}
