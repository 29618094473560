import React, {useEffect, useState} from "react";
import "./index.scss";
import {Button, notification, Pagination, Space, Spin, Table, Typography} from "antd";
import AdminListLayout from "@recoinsights/shared/components/layout/AdminListLayout/AdminListLayout";
import API_SERVICE from "../../../services/api-service";
import {downloadFile} from "../../../services/util";
import {RouteComponentProps} from "react-router-dom"
import GroupInterface from "../../../../../shared/types/Group";
import SurveyInterface from "../../../../../shared/types/Survey.types";
import CompanyInterface from "../../../../../shared/types/Company";

const {Text} = Typography;

export default function Emissions(props: RouteComponentProps) {
    const [groups, setGroups] = useState<Array<GroupInterface>>([]);
    const [search] = useState<string>("");
    const [fetching, setFetching] = useState<boolean>(false);
    const [fetchingFile, setFetchingFile] = useState<boolean>(false);
    const [pagination, setPagination] = useState<Record<string, number>>({
        page: 1,
        perpage: 10,
    });
    const [total, setTotal] = useState<number>(10);
    const [filters, setFilters] = useState<Record<string, any>>({});
    const [surveys, setSurveys] = useState<Array<SurveyInterface>>([]);

    const columns = [
        {
            title: "Year",
            key: "year",
            render: (a: SurveyInterface) => <Text>{a.year}</Text>,
        },
        {
            title: "Company",
            dataIndex: "company",
            width: "100%",
            render: (a: CompanyInterface) => <Text>{a && a.name ? a.name : "-"}</Text>,
        },
        {
            title: "Group",
            dataIndex: "pef",
            filters: groups.map((group: GroupInterface, index: number) => {
                return {text: group.name, value: group.id, key: index};
            }),
            render: (a: GroupInterface) => <Text>{a && a.name ? a.name : "-"}</Text>,
        },
        {
            title: "",
            key: "results",
            width: 200,
            render: (value: SurveyInterface) => (
                <Space>
                    <Button
                        onClick={() => {
                            downloadResultData(value.id)
                        }}
                        type="ghost"
                    >
                        <i className={"bi bi-download mr-1"}></i>
                        {"Download"}
                    </Button>
                </Space>
            ),
        }
    ];

    const downloadResultData = async (surveyId: number) => {
        try {
            setFetchingFile(true);
            await API_SERVICE.downloadEmissionResults(surveyId).then(res => {
                downloadFile(res, 'Emissions', 'application/zip');
            });
        } catch (e: any) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        } finally {
            setFetchingFile(false);
        }
    }

    const getGroups = async () => {
        try {
            const {
                data: {data},
            } = await API_SERVICE.getGroups();
            setGroups(data.items);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };

    const handleTableChange = (
        pagination: Record<string, any>,
        filterValues: Record<string, any>
    ) => {
        setFilters({...filters, ...filterValues, page: 1});
    };

    const getSurveys = async (page?: number, perPage?: number) => {
        const reqParams = {...pagination, ...filters};
        if (search) {
            reqParams["q"] = search;
            reqParams["page"] = 1;
        }
        if (page) {
            reqParams["page"] = page;
        }
        if (perPage) {
            reqParams["perpage"] = perPage;
        }

        setFetching(true);
        try {
            const {
                data: {data},
            } = await API_SERVICE.getEmissionsList(reqParams);
            setSurveys(data.items);
            setTotal(data.total);
            setPagination({page: data.page, perpage: data.perpage});
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        getSurveys();
        getGroups();
        return () => {
            setFetching(false);
        }
    }, [props.location, search, filters]);


    return (
        <div className={"company-wrapper page-wrapper"}>
            {/*@ts-ignore*/}
            <AdminListLayout
                title="Emissions"
                pagination={
                    <Pagination
                        current={pagination.page}
                        pageSize={pagination.perpage}
                        pageSizeOptions={[10, 20, 50]}
                        showSizeChanger
                        showQuickJumper
                        total={total}
                        onChange={(page, size) => {
                            setPagination({page: page, perpage: size});
                            getSurveys(page, size);
                        }}
                    />
                }
            >
                {
                    fetchingFile &&
                    <div data-testid="loading-spinner" className="d-flex align-items-center justify-content-center" style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(0,0,0,0.3)",
                        zIndex: 999
                    }}><Spin/></div>
                }
                <Table
                    loading={fetching}
                    pagination={false}
                    dataSource={surveys}
                    columns={columns.filter(value => JSON.stringify(value) !== '{}')}
                    onChange={handleTableChange}
                    data-testid="emission-table"
                />
            </AdminListLayout>
        </div>
    );
}
