import {Col, Row, Steps} from "antd";
import {Metrics, resolveMetricResults} from "@recoinsights/metrics";
import React from "react";
import {ResultSet} from "@recoinsights/types";
import ScoreComparisonIndicator from "./ScoreComparisonIndicator";

interface Props {
    metricUid: Metrics;
    results: ResultSet[];
}

function ScoreTimeline({ metricUid, results }: Props) {
    return (
        <Row align={"middle"} justify={"center"}>
            <Col className={"p-2"}>
                <Steps progressDot
                       current={0}
                       direction="vertical"
                       items={results.map((result, index) => {
                           const metricResult = resolveMetricResults(metricUid, result);
                           const previousResult = results[index - 1];
                           const previousMetricResult = previousResult ? resolveMetricResults(metricUid, previousResult) : undefined;

                           return {
                               title: (
                                   <p className={"mb-0"} style={{ fontSize: index === results.length - 1 ? "3rem" : index === results.length - 2 ? "1.5rem" : "1rem", marginTop: index === results.length - 1 ? "-0.75rem" : "0", }}>
                                       {metricResult.renderScore()}
                                   </p>
                               ),
                               description: (
                                   <>
                                       {result.year}
                                       {previousMetricResult && (
                                           <>
                                               &nbsp;
                                               <ScoreComparisonIndicator metricResultA={metricResult} metricResultB={previousMetricResult} />
                                           </>
                                       )}
                                   </>
                               ),
                           };
                       }).reverse()}
                />
            </Col>
        </Row>
    )
}

export default ScoreTimeline;
