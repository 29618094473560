import {BoardMaturity} from "@recoinsights/metrics";
import {Column} from "@ant-design/charts";
import React from "react";

enum Labels {
    Year = "Year",
    Score = "Score",
    Type = "Type",
    Board = "Composition, independence & effectiveness",
    Management = "Management skills",
    TalentCompensation = "Talent & compensation",
    PoliciesProcedures = "Policies & procedures",
    ControlRisk = "Control & risk management",
    AuditDisclosure = "Audit & disclosure",
    ESG = "Other ESG factors",
    Total = "Total",
}

interface Props {
    result: BoardMaturity,
    year: number,
}

function BoardMaturityByCategoryChart({ result, year }: Props) {
    const data: { [Labels.Year]: number, [Labels.Score]?: number, [Labels.Type]: string, }[] = [];

    const { board, management, policiesProcedures, talentCompensation, controlRisk, auditDisclosure, esg,  } = result.getScoreBreakdown();

    data.push({
        [Labels.Score]: board.score !== undefined ? Math.round(board.score * 100) : undefined,
        [Labels.Type]: Labels.Board,
        [Labels.Year]: year,
    });

    data.push({
        [Labels.Score]: management.score !== undefined ? Math.round(management.score * 100) : undefined,
        [Labels.Type]: Labels.Management,
        [Labels.Year]: year,
    });

    data.push({
        [Labels.Score]: policiesProcedures.score !== undefined ? Math.round(policiesProcedures.score * 100) : undefined,
        [Labels.Type]: Labels.PoliciesProcedures,
        [Labels.Year]: year,
    });

    data.push({
        [Labels.Score]: talentCompensation.score !== undefined ? Math.round(talentCompensation.score * 100) : undefined,
        [Labels.Type]: Labels.TalentCompensation,
        [Labels.Year]: year,
    });

    data.push({
        [Labels.Score]: controlRisk.score !== undefined ? Math.round(controlRisk.score * 100) : undefined,
        [Labels.Type]: Labels.ControlRisk,
        [Labels.Year]: year,
    });

    data.push({
        [Labels.Score]: auditDisclosure.score !== undefined ? Math.round(auditDisclosure.score * 100) : undefined,
        [Labels.Type]: Labels.AuditDisclosure,
        [Labels.Year]: year,
    });

    data.push({
        [Labels.Score]: esg.score !== undefined ? Math.round(esg.score * 100) : undefined,
        [Labels.Type]: Labels.ESG,
        [Labels.Year]: year,
    });

    return (
        <Column data={data}
                xField={Labels.Year}
                yField={Labels.Score}
                xAxis={{label: null}}
                yAxis={{min: 0, max: 100}}
                legend={{ position: "right-bottom", maxWidthRatio: 0.33, padding: [0, 0, 0, 30], itemHeight: 10 }}
                isGroup={true}
                seriesField={Labels.Type}
                height={200} />
    );
}

export default BoardMaturityByCategoryChart;
