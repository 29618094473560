import React, {useEffect, useRef, useState} from 'react';
import './index.scss';
import {Button, notification, Pagination, Space, Table, Typography, Input} from 'antd';
import AdminListLayout from '@recoinsights/shared/components/layout/AdminListLayout/AdminListLayout';
import API_SERVICE from '../../services/api-service';
import CompanyCreateOrEditPage from './CompaniesCreateOrEditPage';
import {ConfirmationModal} from "../../components";
import {PlusOutlined, SearchOutlined} from '@ant-design/icons';
import {DeleteOutlined, EditOutlined} from '@material-ui/icons';
import AccessControl, {accessIncludes} from 'components/AccessControl';
import {getUser} from "../../services/local-storage";
import AppConstants from "@recoinsights/shared/constants/Constants";

const {Text} = Typography;
const {Search} = Input;

export default function CompanyPage(props: any) {
    const firstUpdate = useRef(true);
    const [companies, setCompanies] = useState([] as any[]);
    const [search, setSearch] = useState('' as string);
    const [addNew, setAddNew] = useState(false);
    const [editCompany, setEditCompany] = useState(null as any);
    const [fetching, setFetching] = useState(false);
    const [pagination, setPagination] = useState({page: 1, perpage: '10'} as any);
    const [total, setTotal] = useState(10);
    const [filters, setFilters] = useState({} as any);
    const [confirmationModal, setConfirmationModal] = useState({} as any);
    const [groups, setGroups] = useState([] as any[]);
    const [sectors, setSectors] = useState([] as any[]);
    const [industries, setIndustries] = useState([] as any[]);
    const roleId = getUser()?.user?.role?.id;

    const isAdmin: boolean = ([AppConstants.roles.SUPER_ADMIN_ID, AppConstants.roles.ADMIN_ID].includes(roleId));
    const isConsultantsAndAuditor: boolean = ([AppConstants.roles.CONSULTANT_ID, AppConstants.roles.AUDITOR_ID].includes(roleId));
    const isPortFolioManager: boolean = ([AppConstants.roles.FUND_MANAGER_ID].includes(roleId));

    const columns = [
        {
            title: 'Company Name',
            key: 'name',
            width: 200,
            ellipsis: true,
            render: (a: any) => <Text>{a.name}</Text>,
        },
        (isAdmin || isConsultantsAndAuditor || isPortFolioManager) ?
            {
                title: 'Group Name',
                dataIndex: 'pef',
                filters: groups.map((group: any) => {
                    return {text: group.name, value: group.id}
                }),
                width: 200,
                ellipsis: true,
                render: (a: any) => <Text>{(a && a.name) ? a.name : '-'}</Text>,
            } : {},
        (isAdmin) ?
            {
                title: 'Sector',
                key: 'sectors',
                filters: sectors.map((sector: any) => {
                    return {text: sector.name, value: sector.id}
                }),
                width: 200,
                ellipsis: true,
                render: (a: any) => <Text>{(a && a.industry && a.industry.sector) ? a.industry.sector.name : '-'}</Text>,
            } : {},
        (isAdmin) ?
            {
                title: 'Industry',
                dataIndex: 'industry',
                width: 300,
                filters: industries.map((industry: any) => {
                    return {text: industry.name, value: industry.id}
                }),
                ellipsis: true,
                render: (a: any) => <Text>{(a && a.name) ? a.name : '-'}</Text>
            } : {},
        (isAdmin) ?
            {
                title: 'Added by',
                dataIndex: 'createdBy',
                width: 180,
                render: (a: any) => <Text>{(a && a.name) ? a.name : '-'}</Text>
            } : {},
        (isAdmin) ?
            {
                title: 'is Active',
                key: 'isActive',
                width: 160,
                filters: [
                    {text: 'Active', value: 1},
                    {text: 'InActive', value: 0}
                ],
                render: (value: any) => <Button className={value.isActive ? 'active-button' : 'inactive-button'}
                                                disabled={!accessIncludes([11])}
                                                onClick={() => {
                                                    const reqObj = {
                                                        isActive: !value.isActive
                                                    }
                                                    setConfirmationModal({
                                                        message: `Change status to ${value.isActive ? "InActive" : "Active"}`,
                                                        action: API_SERVICE.companyStatus,
                                                        params: [value.id, reqObj]
                                                    })
                                                }}>
                    {value.isActive ? "Active" : "InActive"}
                </Button>
            } : {},
        (isAdmin) ?
            {
                title: 'Actions',
                key: 'action',
                width: 200,
                render: (value: any) => <Space>
                    {
                        accessIncludes([8, 9]) ?
                            <>
                                <AccessControl id={8}>
                                    <Button type='text'
                                            icon={<EditOutlined/>}
                                            onClick={() => {
                                                setEditCompany(value);
                                            }}/>
                                </AccessControl>

                                <AccessControl id={9}>
                                    <Button type='text'
                                            icon={<DeleteOutlined/>}
                                            onClick={() => {
                                                setConfirmationModal({
                                                    message: `Delete company?`,
                                                    action: API_SERVICE.companyDelete,
                                                    params: [value.id],
                                                })
                                            }}/>
                                </AccessControl>
                            </>
                            :
                            <div style={{width: 100}}>-</div>
                    }
                </Space>
            } : {},
    ];

    const setFormInitialValues = () => {
            let reqObj = {
                id: editCompany && editCompany.id,
                name: editCompany && editCompany.name,
                pefId: editCompany && editCompany.pefId,
                sectorId: editCompany && editCompany.industry?.sector?.id,
                industryId: editCompany && editCompany.industry?.id
            }
            return reqObj;
    }

    const setDefaultFilters = () => {
        const {match: {params}} = props;
        if (params.groupId) {
            setFilters({...filters, ...{pef: [params.groupId]}})
        } else {
            setFilters({...filters, ...{pef: []}})
        }
    }

    const handleTableChange = (pagination: any, filterValues: any, sorter: any) => {
        setFilters({...filters, ...filterValues, page: 1})
    };

    const refresh = async (page?: number, perPage?: number) => {
        const reqParams = {...pagination, ...filters};
        if (search) {
            reqParams['q'] = search;
            reqParams['page'] = 1;
        }
        if (page) {
            reqParams['page'] = page;
        }
        if (perPage) {
            reqParams['perpage'] = perPage;
        }

        setFetching(true);
        try {
            const {data: {data}} = await API_SERVICE.getCompanies(reqParams);
            setCompanies(data.items);
            setTotal(data.total);
            setPagination({page: data.page, perpage: data.perpage});
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        } finally {
            setFetching(false);
        }
    };

    const getGroups = async () => {
        try {
            const {data: {data}} = await API_SERVICE.getGroups();
            setGroups(data.items);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };

    const getIndustries = async () => {
        const params = {order: 'asc'}
        try {
            const {data: {data}} = await API_SERVICE.getIndustries(params);
            setIndustries(data.items);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };

    const getSectors = async () => {
        try {
            const {data: {data}} = await API_SERVICE.getSectors();
            setSectors(data.items);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        refresh();
    }, [props.location, search, filters]);

    useEffect(() => {
        setDefaultFilters();
        getGroups();
        getSectors();
        getIndustries();
    },[]);

    return (
        <div className={'company-wrapper page-wrapper'}>
            {/*@ts-ignore*/}
            <AdminListLayout title={'Companies'}
                             titleSearch={<AccessControl id={6}>
                                 <div className='search-bar'>
                                     <Search
                                         enterButton={<SearchOutlined/>}
                                         placeholder='Search'
                                         onSearch={
                                             (e) => {
                                                 setSearch(e);
                                             }
                                         }
                                     />
                                 </div>
                             </AccessControl>}

                             titleAction={<AccessControl id={7}>
                                 <Button type={'primary'} className='theme-button primary'
                                         onClick={() => setAddNew(true)} icon={<PlusOutlined/>}>
                                     Add New
                                 </Button>
                             </AccessControl>}

                             pagination={<AccessControl id={10}>
                                 <Pagination current={pagination.page} pageSize={pagination.perpage}
                                             pageSizeOptions={[10, 20, 50]} showSizeChanger showQuickJumper
                                             total={total}
                                             onChange={(page, size) => {
                                                 setPagination({page: page, perpage: size});
                                                 refresh(page, size);
                                             }}/>
                             </AccessControl>}
            >
                {
                    addNew && <CompanyCreateOrEditPage onClose={
                        (e: boolean) => {
                            if (e) {
                                refresh();
                            }
                            setAddNew(false);
                        }
                    }/>}
                {
                    editCompany && <CompanyCreateOrEditPage
                        details={setFormInitialValues()}
                        onClose={
                            (e: boolean) => {
                                if (e) {
                                    refresh();
                                }
                                setEditCompany(null);
                            }
                        }/>
                }
                <AccessControl id={10}>
                    <Table loading={fetching}
                           scroll={{x: true, y: 'calc(100vh - 340px)'}}
                           pagination={false}
                           dataSource={companies}
                           columns={columns.filter(value => JSON.stringify(value) !== '{}')}
                           rowClassName={record => !record.isActive ? "disabled-row" : ''}
                           onChange={handleTableChange}
                    /></AccessControl>

                <ConfirmationModal {...confirmationModal}
                                   callback={(a: boolean) => {
                                       setConfirmationModal({})
                                       if (a) {
                                           refresh();
                                       }
                                   }}/>

            </AdminListLayout>
        </div>
    );
}
