let AppConstants;

export default AppConstants = {
    roles: {
      SUPER_ADMIN_ID: 1,
      ADMIN_ID: 2,
      FUND_MANAGER_ID: 3,
      COMPANY_ANALYST_ID: 4,
      COMPANY_MANAGER_ID: 5,
      CONSULTANT_ID: 6,
      AUDITOR_ID: 7
    },
    adminArray: [2,3],
    companyUserArray: [4,5],
    consultantAndAuditor: [6,7],
}
