import React, {useEffect, useState} from 'react';
import './index.scss';
import {Col, Divider, Progress, Row, Steps} from 'antd';
import AdminListLayout from '@recoinsights/shared/components/layout/AdminListLayout/AdminListLayout';
import {ConfirmationModal} from 'components';
import Title from 'antd/lib/typography/Title';


const { Step } = Steps;



export default function Weightings(props: any) {
    const [search, ] = useState('' as string);
    const [pagination] = useState({page: 1, perpage: '10'} as any);
    const [filters] = useState({} as any);
    const [confirmationModal, setConfirmationModal] = useState({} as any);
  
    
    const refresh = async () => {
        const params = {...pagination, ...filters};
        if (search) {
            params['q'] = search;
            params['page'] = 1;
        }
    };
    useEffect(() => {
        refresh();
    }, [props.location, pagination, search, filters]);
    return (
        
        <div className={'group-wrapper page-wrapper'}>
            {/*@ts-ignore*/}
            <AdminListLayout>
                <Row>
                    <Col sm={24}>
                        <div className="p-2">
                        <Title level={4}>Choose your ESG weightings</Title>
                            <Steps progressDot current={2}>
                                <Step title="Environmental" description="25%" />
                                <Step title="Social" description="40%" />
                                <Step title="Governance" description="35%" />
                            </Steps>
                            <Divider />
                            <Title level={4}>Choose your factor weightings</Title>
                            <table>
                                <tr>
                                    <th>Environmental</th>
                                    <th>Social</th>
                                    <th>Governance </th>
                                </tr>
                                <tr>
                                    <td>% Renewable Energy  </td>
                                    <td>Employee Satisfaction</td>
                                    <td>Cyber Security Maturity</td>
                                </tr>
                                <tr>
                                    <td><Progress percent={67} size="small" /> </td>
                                    <td><Progress percent={57} size="small" /></td>
                                    <td><Progress percent={58} size="small" /></td>
                                </tr>
                                <tr>
                                    <td>GHG Emissions Intensity  </td>
                                    <td>Gender Diversity</td>
                                    <td>Board Maturity </td>
                                </tr>
                                <tr>
                                    <td><Progress percent={33} size="small" /></td>
                                    <td><Progress percent={43} size="small" /></td>
                                    <td><Progress percent={42} size="small" /></td>
                                </tr>
                            </table>
                        </div>
                    </Col>
                </Row>

                <ConfirmationModal {...confirmationModal}
                                   callback={(a: boolean) => {
                                       setConfirmationModal({})
                                       if (a) {
                                           refresh();
                                       }
                                   }}/>

            </AdminListLayout>
        </div>
    );
}
