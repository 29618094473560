import React, {useEffect, useState} from 'react';
import ReactQuill from "react-quill";
import {Form, Input} from "antd";
import AddQuestionMetricComponent from "./AddQuestionMetricComponent";

export default function ScopeQuestionCard({question, isModifiable, isGHGDefaultQuestion, questionNumber, handleFormValueChange, contentMetrics, deleteQuestionMetric, addQuestionMetric, handleContentMetricChange}: any) {
    const [form] = Form.useForm();
    const [questionTitle, setQuestionTitle] = useState('' as string);


    useEffect(() => {
        setQuestionTitle(question.question);
    }, []);

    return (
        <Form form={form} className="question-wrapper" initialValues={question} id={question.uuid}>
            <div className="d-flex align-items-center justify-content-between">
                <h4 className="quesid">#ques-id-{questionNumber} - GHG Question</h4>
            </div>
            <div className="quill-container">
                <ReactQuill className={`survey-quill ${(isGHGDefaultQuestion ? '' : !isModifiable && 'disabled')}`}
                            theme="snow" placeholder="type question title" value={questionTitle}
                            onChange={(value) => {
                                setQuestionTitle(value);
                                handleFormValueChange(question.uuid, 'question', value);
                            }}
                            readOnly={isGHGDefaultQuestion ? false : !isModifiable}
                            modules={{
                                toolbar: {
                                    container: [
                                        [{'header': [1, 2, 3, 4, 5, 6, false]}],
                                        ['bold', 'italic', 'underline'],
                                        [{'list': 'ordered'}, {'list': 'bullet'}],
                                        [{'align': []}],
                                        ['link', 'image']
                                    ]
                                }
                            }}
                />
            </div>

            <Form.Item className="mt-1" name='description'>
                <Input placeholder="type question description" disabled={isGHGDefaultQuestion ? false : !isModifiable}
                       onChange={(event) => {
                           handleFormValueChange(question.uuid, 'description', event.target.value)
                       }}/>
            </Form.Item>
            <Form.Item name='toolTipText'>
                <Input placeholder="type question hint/tooltip text"
                       disabled={isGHGDefaultQuestion ? false : !isModifiable} onChange={(event) => {
                    handleFormValueChange(question.uuid, 'toolTipText', event.target.value)
                }}/>
            </Form.Item>

            <div className="mt-3">
                <AddQuestionMetricComponent
                    key={`add${question.uuid}`}
                    question={question}
                    isModifiable={isModifiable}
                    contentMetrics={contentMetrics}
                    deleteQuestionMetric={deleteQuestionMetric}
                    handleContentMetricChange={handleContentMetricChange}
                    form={form}
                    addQuestionMetric={addQuestionMetric}
                />
            </div>
        </Form>
    )
}
