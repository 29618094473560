import React, {useEffect, useState} from "react";
import "./index.scss";
import {Button, Form, Input, notification, Pagination, Select, Space, Table, Typography,} from "antd";
import AdminListLayout from "@recoinsights/shared/components/layout/AdminListLayout/AdminListLayout";
import {PlusOutlined,} from "@ant-design/icons";
import {DeleteOutlined} from "@material-ui/icons";
import {ConfirmationModal} from "components";
import API_SERVICE from "services/api-service";
import AccessControl, {accessIncludes} from "../../../components/AccessControl";

const { Text } = Typography;

export default function FundsConfigure(props: any) {
  const [form] = Form.useForm();
  const [search] = useState("" as string);
  const [fundDetail, setFundDetail] = useState({} as any);
  const [fundId, setFundId] = useState();
  const [metric, setMetrics] = useState([] as any[]);
  // const [kpi, setKpis] = useState([] as any[]);
  const [fundConfig, setFundConfigs] = useState([] as any[]);
  const [saving, setSaving] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({} as any);
  const [filters] = useState({} as any);
  const [pagination, setPagination] = useState({page: 1, perpage: "10"} as any);
  const [total] = useState(10);


  const columns = [
    {
      title: "S.No",
      key: "sNO",
      render: (a: any, v: any, index: number) => (
          <Text>{(pagination.page - 1) * pagination.perpage + index + 1}</Text>
      ),
    },
    {
      title: "Metric",
      dataIndex: "metric",
      ellipses: true,
      render: (a: any) => <Text>{a && a.name ? a.name : "-"}</Text>,
    },
    {
      title: "Order",
      key: "order",
      render: (data: any) => <Input key={+new Date() + Math.random()} style={{maxWidth: '50px'}} defaultValue={data.order} onBlur={(event)=> {handleOrderChange(event, data.id)}} disabled={data.metric.name.toLowerCase() === "background" ? true : false} />,
    },
    {
      title: "Actions",
      key: "action",
      width: 120,
      render: (value: any) => (
          <Space>
            {
              accessIncludes([45]) && <Button
                  type="text"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    setConfirmationModal({
                      message: `Delete Data?`,
                      action: API_SERVICE.deleteFundConfig,
                      params: [value.id],
                    });
                  }}
              />
            }

          </Space>
      ),
    },
  ];

  const handleOrderChange = async (event: any, configId: number) => {
    const params = {"order" : event.target.value ? event.target.value : null}
    try {
      await API_SERVICE.updateFundConfig(configId, params);
      notification.success({ message: 'Successfully Saved' });
      // refresh();
    } catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    }
  }

  /*const handleVisibilityChange = async (configId: number, isVisible: boolean) => {
    const params = {isVisible : !isVisible}
    try {
      await API_SERVICE.updateFundConfig(configId, params);
      refresh();
    } catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    }
  }*/

  const getMetrics = async () => {
    try {
      const {data: { data }} = await API_SERVICE.getMetric();
      setMetrics(data.items);
    } catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    }
  };

/*  const getkpis = async () => {
    try {const {data: { data }} = await API_SERVICE.getKpi();
      setKpis(data.items);
    } catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    }
  };*/

  const getFundDetail = async (fundId: number) => {
    try {const {data: { data }} = await API_SERVICE.getFunds({id:fundId});
      setFundDetail(data.items);
    } catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    }
  };


  const refresh = async () => {
    setFetching(true);
    const params = { ...pagination, ...filters };
    if (search) {
      params["q"] = search;
      params["page"] = 1;
    }
    if(!fundId){return;}
    try {
      const {data: {data}} = await API_SERVICE.getFundConfig(fundId, params);
      setFundConfigs(data.items);
    } catch (e) {
      notification.error({message: API_SERVICE.handleErrors(e)});
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    const {match: {params}} = props;
    setFundId(params.fundId);
    getMetrics();
    // getkpis();
    getFundDetail(params.fundId)
  }, []);

  useEffect(() => {
    refresh();
  }, [props.location, pagination, search, filters, fundId]);

  return (
      <div className={"group-wrapper page-wrapper"}>
        {/*@ts-ignore*/}
        <AdminListLayout
            title={fundDetail && fundDetail.length > 0 && fundDetail[0].name}
            titleAction={
              accessIncludes([43]) && <Form
                  form={form}
                  fields={[]}
                  onFinish={(value) => {
                    setSaving(true);
                    value['isVisible'] = true;
                    if(!fundId){return;}
                    API_SERVICE.createFundConfig(fundId, value)
                        .then((d) => {
                          notification.success({message: "Configuration added successfully"});
                          form.resetFields();
                          refresh();
                        })
                        .catch((e) => {
                          notification.error({ message: API_SERVICE.handleErrors(e) });
                        })
                        .finally(() => {
                          setSaving(false);
                        });
                  }}
                  layout="inline"
                  requiredMark={true}
              >
                <Form.Item
                    name="metricId"
                    // label="Select Metric"
                    rules={[{ required: true, message: "Metric required" }]}
                >
                  <Select placeholder={"Select Metric"}>
                    {metric.map((metric: any, index: number) => {
                      return (
                          <Select.Option value={metric.id} key={index}>
                            {metric.name}
                          </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Button
                    loading={saving}
                    type={"primary"}
                    htmlType="submit"
                    className="theme-button primary"
                    icon={<PlusOutlined />}
                >
                  Add
                </Button>
              </Form>
            }
            pagination={
              accessIncludes([42]) && <Pagination
                  showSizeChanger
                  showQuickJumper
                  defaultCurrent={1}
                  total={total}
                  onChange={(page, size) => {
                    setPagination({ page: page, perpage: size });
                  }}
              />
            }
        >
          <AccessControl id = {42}>
          <Table
              loading={fetching}
              scroll={{ x: true, y: "calc(100vh - 340px)" }}
              pagination={false}
              dataSource={fundConfig}
              columns={columns}
          />
          </AccessControl>

          <ConfirmationModal
              {...confirmationModal}
              callback={(a: boolean) => {
                setConfirmationModal({});
                if (a) {
                  refresh();
                }
              }}
          />
        </AdminListLayout>
      </div>
  );
}
