import * as React from 'react';

import { Route, Redirect } from 'react-router-dom';

import { AuthConsumer } from '@recoinsights/shared/contexts/AuthContext';
import {DASHBOARD, COMPANY_LIST, GROUPS_LIST, HOME, ADMIN_DATA_COLLECTION, SURVEYS_LIST} from '../constants/RouteConstants';
import AppConstants from '../constants/Constants';

export default function ProtectedRoute({ component: Component, ...rest }: any) {
    if (rest.path) {
        console.log(rest.path);
    }
    return (
        <Route
            {...rest}
            render={(props) => (
                <AuthConsumer>
                    {({ isAuth, user }) =>
                        isAuth ? (
                                    (user.roleId === AppConstants.roles.SUPER_ADMIN_ID || user.roleId === AppConstants.roles.ADMIN_ID)?
                                        rest.path.startsWith('/admin') ? <Component {...props} /> : <Redirect
                                            to={{
                                                pathname: SURVEYS_LIST,
                                            }} />

                                    :
                                    (user.roleId === AppConstants.roles.FUND_MANAGER_ID)?
                                        rest.path.startsWith('/admin') ? <Component {...props} /> : <Redirect
                                            to={{
                                                pathname: SURVEYS_LIST,
                                            }} />

                                    :
                                    (
                                        (user.roleId === AppConstants.roles.CONSULTANT_ID || user.roleId === AppConstants.roles.AUDITOR_ID)?
                                          rest.path.startsWith('/admin') ? <Component {...props} /> : <Redirect
                                              to={{
                                                  pathname: HOME,
                                              }} />
                                        :
                                          rest.path.startsWith('/admin') ? <Component {...props} /> : <Redirect
                                              to={{
                                                  pathname: HOME,
                                              }} />
                                    )
                              )

                              :

                              (
                                  <Redirect
                                      to={{
                                          pathname: '/login',
                                          // state: { from: rest.location },
                                      }}
                                  />
                              )
                    }
                </AuthConsumer>
            )}
        />
    );
}
