import AXIOS from 'axios';
import {downloadFile} from "./util";


function getPublicInstance() {
    return AXIOS.create({
        // @ts-ignore
        'accept': 'application/json',
        'baseURL': process.env.REACT_APP_API_URL,
        'headers': {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });
}

function getProtectedInstance() {
    let user = localStorage.getItem('user') as string;
    let token = '';
    if (user) {
        token = JSON.parse(user).token;
    } else {
        window.location.href = '/login';
    }
    return AXIOS.create({
        // @ts-ignore
        'accept': 'application/json',
        'baseURL': process.env.REACT_APP_API_URL,
        'headers': {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });
}

function getProtectedFileUpload() {
    let user = localStorage.getItem('user') as string;
    let token = '';
    if (user) {
        token = JSON.parse(user).token;
    } else {
        window.location.href = '/login';
    }
    return AXIOS.create({
        // @ts-ignore
        'accept': 'application/json',
        'baseURL': process.env.REACT_APP_API_URL,
        'headers': {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        },
    });
}

function handleErrors(error: any) {
    let message = 'Something went wrong!!';
    if (error && error.response && error.response.data) {
        const data = error.response.data;
        if (data.error) {
            message = data.error;
        } else if (data.message) {
            const keys = Object.keys(data.message)
            if (keys.length) {
                message = data.message[keys[0]];
            }
        }
    }
    return message;
}

function getProtectedFetchInstance(path: string, method: string = 'GET', params: any = {}, contentType: string = 'application/json') {
    let user = localStorage.getItem('user') as string;
    let token = '';
    let url = '';

    if (user) {
        token = JSON.parse(user).token;
    } else {
        window.location.href = '/login';
    }

    if (method === 'GET') {
        url = `${process.env.REACT_APP_API_URL}${path}?${new URLSearchParams(params)}`;
    } else {
        url = `${process.env.REACT_APP_API_URL}${path}`;
    }

    return fetch(url, {
        method: method,
        headers: new Headers({
            Accept: contentType,
            Authorization: `Bearer ${token}`,
            'Content-Type': contentType
        }),
        body: (method !== 'GET' ? JSON.stringify(params) : undefined)
    })
    .then((response) => {
        if (response.ok) {
            if (contentType === 'application/json') {
                return response.json();
            } else if (contentType === 'application/zip' || contentType === "text/csv") {
                return response.blob().then((data) => {
                    const filename = response.headers.get('Content-Disposition')?.split('filename=')[1].replaceAll('"', '');

                    downloadFile(data, filename, contentType);
                });
            } else {
                return response.text();
            }
        }
        throw new Error('Something went wrong');
    })
    .then((result) => {
        return result;
    })
    .catch((error) => {
        console.error(error);
        throw new Error('Something went wrong');
    });
}

export interface GetResultsRequest {
    company_id?: number,
    group_id?: number
}

export interface DownloadResultsRequest {
    company_id?: number,
    group_id?: number
    year?: number
}


//auth
async function login(email: string, password: string) {
    const instance = getPublicInstance();
    return await instance.post('/api/login', {email, password});
}

async function verify(uuid: string, otp: string) {
    const instance = getPublicInstance();
    return await instance.post('/api/login', {uuid, otp});
}

async function self() {
    const instance = getProtectedInstance();
    return await instance.get('/api/self');
}


async function otpVerifcation(email: string, otp: string) {
    const instance = getPublicInstance();
    // return await instance.post('/api/otp', {email, otp});
}

async function forgotPassword(email: string) {
    const instance = getPublicInstance();
    return await instance.post('/api/forgot-password', {email});
}

async function resetPassword(reqObj: any) {
    const instance = getPublicInstance();
    return await instance.post('/api/reset-password', reqObj);
}


async function fileUpload(type: any, file: any) {
    const formdata = new FormData();
    formdata.append('file', file);
    formdata.append('type', type);
    const instance = getPublicInstance();
    return await instance.post('/api/files', formdata);
}


//groups/pefs
async function getGroups(params: any = {perpage: 1000}) {
    const instance = getProtectedInstance();
    return await instance.get('/api/pefs', {params});
}
async function getSurveyYears(params: any = {perpage: 1000}) {
    const instance = getProtectedInstance();
    return await instance.get('/api/survey-years', {params});
}

async function handleApprovByUser(id: any,data:any) {
    const instance = getProtectedInstance();
    return await instance.post(`api/surveys/${id}/approve`, data);
}

async function groupCreate(data: any) {
    const instance = getProtectedInstance();
    return await instance.post('/api/pefs', data);
}

async function groupUpdate(id: any, data: any) {
    const instance = getProtectedInstance();
    return await instance.put(`/api/pefs/${id}`, data);
}

async function groupDelete(id: any) {
    const instance = getProtectedInstance();
    return await instance.delete(`/api/pefs/${id}`);
}

//users
async function users(params: any = {perpage: 1000}) {
    const instance = getProtectedInstance();
    return await instance.get('/api/users', {params});
}

async function userCreate(data: any) {
    const instance = getProtectedInstance();
    return await instance.post('/api/users', data);
}

async function userStatus(id: number, details: any) {
    const instance = getProtectedInstance();
    return await instance.patch(`/api/users/${id}/status-update`, details);
}

async function userUpdate(id: number, data: any) {
    const instance = getProtectedInstance();
    return await instance.put(`/api/users/${id}`, data);
}

async function userDelete(id: number) {
    const instance = getProtectedInstance();
    return await instance.delete('/api/users/' + id);
}

//industries
async function getIndustries(params: any = {perpage: 1000}) {
    const instance = getProtectedInstance();
    return await instance.get('/api/industries', {params});
}

//companies
async function getCompanies(params: any) {
    const instance = getProtectedInstance();
    return await instance.get('/api/companies', {params});
}

async function companyUpdate(id: number, details: any) {
    const instance = getProtectedInstance();
    return await instance.put(`/api/companies/${id}`, details);
}

async function companyCreate(details: any) {
    const instance = getProtectedInstance();
    return await instance.post(`/api/companies`, details);
}

async function companyDelete(id: number) {
    const instance = getProtectedInstance();
    return await instance.delete(`/api/companies/${id}`);
}

async function companyStatus(id: number, details: any) {
    const instance = getProtectedInstance();
    return await instance.patch(`/api/companies/${id}/status-update`, details);
}

//roles
async function getRoles(params: any = {perpage: 1000}) {
    const instance = getProtectedInstance();
    return await instance.get('/api/roles', {params});
}

//files
async function getFilesList(params: any) {
    const instance = getProtectedInstance();
    return await instance.get('/api/file-masters', {params});
}

async function fileUpdate(id: number, details: any) {
    const instance = getProtectedInstance();
    return await instance.put(`/api/file-masters/${id}`, details);
}

async function fileCreate(details: any) {
    const instance = getProtectedInstance();
    return await instance.post(`/api/file-masters`, details);
}

async function fileDelete(id: number) {
    const instance = getProtectedInstance();
    return await instance.delete('/api/file-masters/' + id);
}

async function fileStatus(id: number, details: any) {
    const instance = getProtectedInstance();
    return await instance.patch(`/api/file-masters/${id}/status-update`, details);
}

async function getFileTypes() {
    const instance = getProtectedInstance();
    return await instance.get('/api/file-types');
}

//countries
async function getCountries(params = {page: -1, order: 'asc'}) {
    const instance = getProtectedInstance();
    return await instance.get('/api/countries', {params});
}

//Sectors
async function getSectors() {
    const instance = getProtectedInstance();
    return await instance.get('/api/sectors');
}

//Content
async function updateContent(id: number, req: any) {
    const instance = getProtectedInstance();
    return await instance.post(`/api/company/${id}/contents`, req);
}

//company timelines
async function getCompanyTimelines(companyId: number) {
    const instance = getProtectedInstance();
    return await instance.get(`/api/company/${companyId}/timelines`);
}

async function updateCompanyTimelines(id: number, req: any) {
    const instance = getProtectedInstance();
    return await instance.put(`/api/company/${id}/timelines`, req);
    // return await instance.put(`/api/company/${id}/timelines`, req);
}

//profile
async function getProfile() {
    const instance = getProtectedInstance();
    return await instance.get('/api/self');
}

async function profileUpdate(req: any) {
    const instance = getProtectedInstance();
    return await instance.put('/api/users', req);
}

// roles and permissions
async function getAllRoles(params: any = {perpage: 1000}) {
    const instance = getProtectedInstance();
    return await instance.get('/api/roles', {params});
}

async function getAllPermissions(params: any = {}) {
    const instance = getProtectedInstance();
    return await instance.get('/api/permissions', {params})
}

async function updatePermissions(id: number, arr: any = []) {
    const instance = getProtectedInstance();
    const data = {
        permissionIds: arr,
    }
    return await instance.post(`/api/roles/${id}/permissions`, data)
}

async function getFiles(params: any = {}) {
    const instance = getProtectedInstance();
    return await instance.get('/api/files', {params})
}

//funds
async function getFunds(params: any = {}) {
    const instance = getProtectedInstance();
    return await instance.get('/api/funds', {params})
}

async function createFund(params: any) {
    const instance = getProtectedInstance();
    return await instance.post('/api/funds', params)
}

async function updateFund(id: number, params: any = {}) {
    const instance = getProtectedInstance();
    return await instance.put(`/api/funds/${id}`, params)
}

async function updateFundCompany(fundId: number, companyId: number, params: any = {}) {
    const instance = getProtectedInstance();
    return await instance.put(`/api/funds/${fundId}/companies/${companyId}`, params)
}

async function deleteFund(id: number, params: any = {}) {
    const instance = getProtectedInstance();
    return await instance.delete(`/api/funds/${id}`, {params})
}

//surveys
async function getSurveys(params: any = {}) {
    const instance = getProtectedInstance();
    return await instance.get('/api/surveys', {params})
}

async function getSurveyDetails(surveyId: any) {
    const instance = getProtectedInstance();
    return await instance.get(`/api/surveys/${surveyId}`)
}

async function createSurvey(params: any) {
    const instance = getProtectedInstance();
    return await instance.post('/api/surveys', params)
}

async function updateSurvey(id: number, params: any = {}) {
    const instance = getProtectedInstance();
    return await instance.put(`/api/surveys/${id}`, params)
}

async function deleteSurvey(id: number, params: any = {}) {
    const instance = getProtectedInstance();
    return await instance.delete(`/api/surveys/${id}`, {params})
}

async function copySurvey(id: number, params: any = {}) {
    const instance = getProtectedInstance();
    return await instance.post(`/api/surveys/${id}/duplicate`, params);
}

async function downloadSurveyResponses(surveyId: number, params: any = {}) {
    const instance = getProtectedInstance();
    return await instance.get(`/api/surveys/${surveyId}/download-questions`, {params})
}

//KPI
// TODO if configure does not use it remove these (kpi) apis
async function getKpi(params: any = {}) {
    const instance = getProtectedInstance();
    return await instance.get('/api/kpis', {params})
}

async function createKpi(params: any) {
    const instance = getProtectedInstance();
    return await instance.post('/api/kpis', params)
}

async function deleteKpi(id: number, params: any = {}) {
    const instance = getProtectedInstance();
    return await instance.delete(`/api/kpis/${id}`, {params})
}

//dkpi
async function getDkpi(params: any = {}) {
    const instance = getProtectedInstance();
    return await instance.get('/api/dkpis', {params})
}

//METRIC
async function getMetric(params: any = {}) {
    const instance = getProtectedInstance();
    return await instance.get('/api/metrics', {params})
}

async function createMetric(params: any) {
    const instance = getProtectedInstance();
    return await instance.post('/api/metrics', params)
}

async function deleteMetric(id: number) {
    const instance = getProtectedInstance();
    return await instance.delete(`/api/metrics/${id}`)
}

//fund_configurations
async function getFundConfig(fundId: number, params: any) {
    const instance = getProtectedInstance();
    return await instance.get(`api/funds/${fundId}/configurations`, {params})
}

async function createFundConfig(fundId: number = 0, params: any) {
    const instance = getProtectedInstance();
    return await instance.post(`api/funds/${fundId}/configurations`, params)
}

async function updateFundConfig(configId: number, params: any = {}) {
    const instance = getProtectedInstance();
    return await instance.patch(`/api/fund-configurations/${configId}`, params)
}

async function deleteFundConfig(configId: number, params: any = {}) {
    const instance = getProtectedInstance();
    return await instance.delete(`/api/fund-configurations/${configId}`, {params})
}

//Company Analyst Data collection survey
async function getSurveyMetrics(id:number,params: any = {}) {
    const instance = getProtectedInstance();
    return await instance.get(`api/user/surveys/${id}/metrics`, params);
}

async function getUserMetricsData(surveyId:any,MetricId: any, params: any = {}) {
    const instance = getProtectedInstance();
    return await instance.get(`api/user/surveys/${surveyId}/metrics/${MetricId}/questions`, params);
}

async function saveMetricResponsesToDraft(surveyId:any, MetricId: any, params: any = {}) {
    const instance = getProtectedInstance();
    return await instance.put(`api/user/surveys/${surveyId}/metrics/${MetricId}/responses`, params);
}

// TODO remove it if finalize button api get deleted
async function saveAnalystSurveyFinalData(surveyId: number) {
    const instance = getProtectedInstance();
    return await instance.post(`api/user/surveys/${surveyId}/responses/publish`);
}

async function publishSurveyByAdmin(surveyId: number) {
    const instance = getProtectedInstance();
    return await instance.post(`api/survey/${surveyId}/questions/publish`);
}

//units
async function getUnits(params: any = {}) {
    const instance = getProtectedInstance();
    return await instance.get(`api/units`, params);
}

async function createUnit(params: any) {
    const instance = getProtectedInstance();
    return await instance.post('/api/units', params)
}

async function deleteUnit(id: number, params: any = {}) {
    const instance = getProtectedInstance();
    return await instance.delete(`/api/units/${id}`, {params})
}

// admin survey questionnaire
async function getSurveyMetricsById(surveyId: any, params = {}) {
    const instance = getProtectedInstance();
    return await instance.get(`api/surveys/${surveyId}/metrics`, params);
}

async function getSurveyQuestionsByMetricsId(surveyId:any,MetricId: any, params: any = {page: -1}) {
    const instance = getProtectedInstance();
    return await instance.get(`api/survey/${surveyId}/metrics/${MetricId}/questions`, {params});
}

async function getUnitCategories(params: any = {page: -1}) {
    const instance = getProtectedInstance();
    return await instance.get(`api/units/categories`, {params});
}

// questions master right panel
async function getMasterQuestions(params: any = {page: -1}) {
    const instance = getProtectedInstance();
    return await instance.get(`api/question-masters`, {params});
}

async function getUserPercentageComplete(surveyId: any,params: any = {}) {
    const instance = getProtectedInstance();
    return await instance.get(`api/user/surveys/${surveyId}/percent-complete`, {params});
}

//Admin-> data collection-> table data
async function getDataCollection(params: any = {}) {
    const instance = getProtectedInstance();
    return await instance.get(`api/data-collection`, {params});
}

//GHG master management
async function downloadGHGFile() {
    const instance = getProtectedInstance();
    return await instance.get('/api/ghg-file/download');
}

async function uploadGHGFile(details: any) {
    const instance = getProtectedFileUpload();
    return await instance.post(`/api/ghg-file/upload`, details);
}

async function getGHGFileDetails() {
    const instance = getProtectedInstance();
    return await instance.get('/api/ghg-file/download-details');
}

async function getGHGQuestionTableData(params: any) {
    const instance = getProtectedInstance();
    return await instance.get('/api/ghg', {params});
}

async function getTotalCarbonIntensities(surveyId: any, params = {page: -1, order: 'asc'}) {
    const instance = getProtectedInstance();
    return await instance.get(`/api/user/surveys/${surveyId}/carbon-intensity`, {params});
}

async function getPercentageRenewableEnergy(surveyId:any,params:any) {
    const instance = getProtectedInstance();
    return await instance.get(`/api/user/surveys/${surveyId}/percentage-renewable-energy`, {params});
}

async function getQuestionRemarks(surveyId:any,questionId:any) {
    const instance = getProtectedInstance();
    return await instance.get(`/api/surveys/${surveyId}/questions/${questionId}/remarks`);
}

async function postComments(surveyId:any, questionId: any, data: any) {
    const instance = getProtectedInstance();
    return await instance.post(`api/surveys/${surveyId}/questions/${questionId}/comments`, data);
}

async function roleApproval(surveyId:number, questionId: number) {
    const instance = getProtectedInstance();
    return await instance.get(`api/surveys/${surveyId}/questions/${questionId}/approve`);
}

async function createSurveyQuestion(surveyId: number, data: any) {
    const instance = getProtectedInstance();
    return await instance.post(`/api/survey/${surveyId}/questions`, data);
}

async function deleteSurveyPage(pageId: number, params: any = {}) {
    const instance = getProtectedInstance();
    return await instance.delete(`/api/pages/${pageId}`, {params})
}

async function deleteMasterQuestion(surveyId: number, questionId: number, params: any = {}) {
    const instance = getProtectedInstance();
    return await instance.delete(`/api/surveys/${surveyId}/questions/${questionId}`, {params})
}

async function deleteSurveySection(sectionId: number, params: any = {}) {
    const instance = getProtectedInstance();
    return await instance.delete(`/api/sections/${sectionId}`, {params})
}

//benchmark colors master management
async function getColorLevels() {
    const instance = getProtectedInstance();
    return await instance.get(`api/levels`);
}

async function setColorLevels(levels: any[]) {
    const instance = getProtectedInstance();
    return await instance.post(`api/levels`, {levels: levels});
}

//fund benchmarking
async function pushDkpi(
    data: {
      name: string,
      description: string,
      metricId: number,
      fundId: number,
      year: number
    }) {
    const instance = getProtectedInstance();
    return await instance.post(`api/dkpi`, data);
}

async function deleteDkpi(dkpiId: number) {
  const instance = getProtectedInstance();
  return await instance.delete(`api/dkpi/${dkpiId}`);
}

async function pushIndustries(
    data: {
      name: string,
      year: string,
      fundId: number
    }) {
    const instance = getProtectedInstance();
    return await instance.post(`/api/esg-industries`, data);
}

async function deleteIndustries(industries: number[]) {
  const instance = getProtectedInstance();
  return await instance.put(`api/esg-industries-delete`, {industryIds: industries});
}

async function setBenchmark(
    data: {
      fundId: number,
      year: string,
      dkpiId: number,
      metricId: number,
      industryId: number,
      type: string,
      startValue: string,
      endValue: string,
      unitValue: string,
      string: string,
      levelId: number
    }) {
    const instance = getProtectedInstance();
    return await instance.post(`/api/benchmarks`, data);
}

async function getBenchmarkData(fundId: number, year: number) {
    const instance = getProtectedInstance();
    return await instance.get(`/api/benchmarks?fundId=${fundId}&year=${year}`);
}

//KPI result calculation
async function getKpiResults(surveyId: number, year: number) {
    const instance = getProtectedInstance();
    return await instance.get(`/api/surveys/${surveyId}/dkpi-results?year=${year}`);
}

async function getSurveyQuestions(surveyId: number, params: any = {}) {
    const instance = getProtectedInstance();
    return await instance.get(`/api/surveys/${surveyId}/questions`, {params});
}

async function calculateKpiResult(dkpiId: number, data: {
      fundId: number,
      companyId: number,
      dkpiId: number,
      formula: string,
      year: string,
      isRangeBased: boolean,
      type: string,
      dkpiResultsRanges: any[]
    }) {
    const instance = getProtectedInstance();
    return await instance.post(`/api/kpis/${dkpiId}/preview-data`, data);
}

async function getKpiResultDetails(dkpiId: number, fundId: number, year: number, companyId: number) {
    const instance = getProtectedInstance();
    return await instance.get(`/api/kpis/${dkpiId}/details?fundId=${fundId}&year=${year}&companyId=${companyId}`);
}

//esg weightages
async function getEsgWeightages(fundId: number, params: any) {
    const instance = getProtectedInstance();
    return await instance.get(`/api/funds/${fundId}/esg-weightages`, {params});
}

async function setEsgWeightages(fundId: number, data: any) {
    const instance = getProtectedInstance();
    return await instance.post(`/api/funds/${fundId}/esg-weightages`, data);
}

//kpi summary
async function getKpiSummary(fundId: number, year: number) {
    const instance = getProtectedInstance();
    return await instance.get(`/api/funds/${fundId}/kpi-summary?year=${year}`);
}

async function getEsgIndustries(fundId: number, year: number) {
    const instance = getProtectedInstance();
    return await instance.get(`/api/esg-industries?year=${year}&fundId=${fundId}`);
}

async function pushIndustryCompanyMap(
    data: {
      fundId: number,
      year: number,
      industryId: number,
      companyId: number
    }) {
    const instance = getProtectedInstance();
    return await instance.post(`/api/industry-company-map`, data);
}

async function popIndustryCompanyMap(
    fundId: number,
    data: {
      year: number,
      industryId: number,
      companyId: number
    }) {
    const instance = getProtectedInstance();
    return await instance.post(`/api/funds/${fundId}/delete-company-industry-map`, data);
}

async function updateGoodBad(data: any) {
    const instance = getProtectedInstance();
    return await instance.post(`/api/update-good-bad`, data);
}

//esg scores
async function getEsgScores(fundId: number, year: number) {
    const instance = getProtectedInstance();
    return await instance.get(`/api/funds/${fundId}/esg-scores?year=${year}`);
}

//company results targets
async function getCompanyResultTargets(surveyId: number) {
    const instance = getProtectedInstance();
    return await instance.get(`/api/surveys/${surveyId}/company-targets-results`);
}

async function getCompanyInitiatives(params: any) {
    const instance = getProtectedInstance();
    return await instance.get(`/api/initiatives`, {params});
}

async function setCompanyInitiatives(data: {
    name: string,
    year: string,
    fundId: number,
    industryId: number
    }) {
    const instance = getProtectedInstance();
    return await instance.post(`/api/initiatives`, data);
}

async function getIndustry(surveyId: number) {
    const instance = getProtectedInstance();
    return await instance.get(`/api/surveys/${surveyId}/industry`);
}

async function pushInitiativeDkpiMap(data: {
    initiativeId: number,
    dkpiId: number,
    industryId: number,
    companyId: number,
    fundId: number,
    year: number
    }) {
    const instance = getProtectedInstance();
    return await instance.post(`/api/company-result-targets`, data);
}

async function saveTargets(targets: any[]) {
    const instance = getProtectedInstance();
    return await instance.post(`/api/bulk-company-result-targets`, {results: targets});
}

async function deleteInitiativeDkpiMap(initiativeDkpiMapId: number) {
    const instance = getProtectedInstance();
    return await instance.delete(`/api/delete-initiatives-map/${initiativeDkpiMapId}`);
}

async function deleteInitiative(initiativeId: number) {
    const instance = getProtectedInstance();
    return await instance.delete(`/api/initiatives/${initiativeId}`);
}

//reports
async function getProgressReport(params: any = {}) {
    const instance = getProtectedInstance();
    return await instance.get(`/api/users-progress-report`, {params});
}

async function deleteQuestion(surveyId: any, questionId: number) {
    const instance = getProtectedInstance();
    return await instance.delete(`/api/surveys/${surveyId}/questions/${questionId}`);
}

//questions master
async function quesCategoryGet() {
    const instance = getProtectedInstance();
    return await instance.get(`/api/categories`);
}

async function quesCategoryCreate(data: any) {
    const instance = getProtectedInstance();
    return await instance.post(`/api/categories`, data);
}

async function quesCategoryDelete(id: number) {
    const instance = getProtectedInstance();
    return await instance.delete(`/api/categories/${id}`);
}

async function quesSubCategoryCreate(data: any) {
    const instance = getProtectedInstance();
    return await instance.post(`/api/sub-categories`, data);
}

async function quesSubCategoryDelete(id: number) {
    const instance = getProtectedInstance();
    return await instance.delete(`/api/sub-categories/${id}`);
}

async function getQuestions(params: any ={}) {
  const instance = getProtectedInstance();
  return await instance.get(`/api/question-masters`, {params});
}

async function setQuestionCategory(
  quesId: number,
  data: {
    categoryId: number,
    subcategoryIds: number[]
  }) {
    const instance = getProtectedInstance();
    return await instance.put(`/api/questions/${quesId}/masters`, data);
}

async function getSurveyPercentage(id: number) {
  const instance = getProtectedInstance();
  return await instance.get(`/api/surveys/${id}/percentage-complete`);
}

async function setSurveyPercentage(id: number, pageIds: number[]) {
    const instance = getProtectedInstance();
    return await instance.post(`/api/surveys/${id}/percentage-complete`, { pageIds, "isFullySubmitted": true });
}

async function getContentMetrics() {
    const instance = getProtectedInstance();
    return await instance.get(`/api/content-metrics`);
}

async function getResults(params: GetResultsRequest) {
    const instance = getProtectedInstance();
    return await instance.get(`/api/results`, {params});
}

async function downloadResults(params: DownloadResultsRequest) {
    return await getProtectedFetchInstance('/api/results', 'GET', params, 'text/csv');
}

async function downloadEmissionResults(surveyId:number) {
    const instance = getProtectedFetchInstance(`/api/surveys/${surveyId}/emissions`, 'GET', {}, 'application/zip');
    return await instance;
}

async function downloadSurveyResponsesCSV(surveyId: number, params: Record<string, any>) {
    return await getProtectedFetchInstance(`/api/surveys/${surveyId}`, 'GET', params, 'text/csv');
}

async function downloadSurveyResponsesZip(surveyId: number, params: Record<string, any>) {
    return await getProtectedFetchInstance(`/api/surveys/${surveyId}`, 'GET', params, 'application/zip');
}

async function getEmissionsList(params: any = {}) {
    const instance = getProtectedInstance();
    return await instance.get(`api/surveys-ghg-emissions`, {params});
}

const API_SERVICE = {
    downloadEmissionResults,
    deleteMasterQuestion,
    login,
    verify,
    self,
    otpVerifcation,
    forgotPassword,
    resetPassword,

    fileUpload,

    getGroups,
    getSurveyYears,
    handleApprovByUser,
    groupCreate,
    groupUpdate,
    groupDelete,

    getIndustries,

    getCompanies,
    companyCreate,
    companyUpdate,
    companyDelete,
    companyStatus,
    users,
    userCreate,
    userUpdate,
    userDelete,
    userStatus,
    getRoles,
    getFilesList,
    fileCreate,
    fileUpdate,
    fileDelete,
    fileStatus,
    getFileTypes,
    getCountries,
    getSectors,
    updateContent,

    getCompanyTimelines,
    updateCompanyTimelines,

    getProfile,
    profileUpdate,
    handleErrors,

    getAllRoles,
    getAllPermissions,
    updatePermissions,

    getFiles,

    getFunds,
    createFund,
    updateFund,
    updateFundCompany,
    deleteFund,

    getSurveys,
    getSurveyDetails,
    createSurvey,
    updateSurvey,
    deleteSurvey,

    getKpi,
    createKpi,
    deleteKpi,
    getDkpi,

    getMetric,
    createMetric,
    deleteMetric,

    getFundConfig,
    createFundConfig,
    updateFundConfig,
    deleteFundConfig,

    getSurveyMetrics,
    getUserMetricsData,
    saveMetricResponsesToDraft,
    // saveSurveyFinalData,
    saveAnalystSurveyFinalData,
    publishSurveyByAdmin,
    getUnits,
    createUnit,
    deleteUnit,


    getSurveyMetricsById,
    getSurveyQuestionsByMetricsId,
    getUnitCategories,
    getMasterQuestions,
    getUserPercentageComplete,
    getDataCollection,
    downloadGHGFile,
    uploadGHGFile,
    getGHGFileDetails,
    getGHGQuestionTableData,
    getTotalCarbonIntensities,
    getPercentageRenewableEnergy,
    copySurvey,
    downloadSurveyResponses,
    getQuestionRemarks,
    postComments,
    roleApproval,
    createSurveyQuestion,
    deleteSurveyPage,
    deleteSurveySection,

    //benchmark colors master management
    getColorLevels,
    setColorLevels,

    //fund benchmarking
    pushDkpi,
    deleteDkpi,
    pushIndustries,
    deleteIndustries,
    getBenchmarkData,
    setBenchmark,

    //KPI result calculation
    getKpiResults,
    getSurveyQuestions,
    calculateKpiResult,
    getKpiResultDetails,

    //esg weightages
    getEsgWeightages,
    setEsgWeightages,

    //kpi summary
    getKpiSummary,
    getEsgIndustries,
    pushIndustryCompanyMap,
    popIndustryCompanyMap,
    updateGoodBad,

    //esg Scores
    getEsgScores,

    //company result targets
    getIndustry,
    getCompanyResultTargets,
    getCompanyInitiatives,
    setCompanyInitiatives,
    pushInitiativeDkpiMap,
    saveTargets,
    deleteInitiativeDkpiMap,
    deleteInitiative,

    //reports
    getProgressReport,
    deleteQuestion,

    //questions masters
    quesCategoryGet,
    quesCategoryCreate,
    quesCategoryDelete,
    quesSubCategoryCreate,
    quesSubCategoryDelete,
    getQuestions,
    setQuestionCategory,
    getSurveyPercentage,
    setSurveyPercentage,
    getContentMetrics,
    getResults,
    downloadResults,
    downloadSurveyResponsesCSV,
    downloadSurveyResponsesZip,
    getEmissionsList
};
export default API_SERVICE;
