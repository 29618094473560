import React from 'react';
import './index.scss';
import AdminListLayout from '@recoinsights/shared/components/layout/AdminListLayout/AdminListLayout';
import EditProfile from "./EditProfile";

export default function UserProfile() {
    return (
        <div className={"role-wrapper page-wrapper"}>
            {/*@ts-ignore*/}
            <AdminListLayout
                title={"User Profile Details"}
            >
                <EditProfile />
            </AdminListLayout>
        </div>
    );
}
