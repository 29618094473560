import React from "react";
import {Link} from "react-router-dom";
import {Button, Col, Divider, Row, Spin, Table} from "antd";
import {ResultSet} from "@recoinsights/types";
import CompanyInterface from "@recoinsights/shared/types/Company";
import {COMPANY_RESULT_DASHBOARD} from "@recoinsights/shared/constants/RouteConstants";
import AlertNoResults from "../results/AlertNoResults";
import AlertUnauthorized from "../results/AlertUnauthorized";
import {useResults} from "../../helpers/metrics";
import {useUser} from "../../helpers/user";
import {sortGroupsByName, sortResultsByCompany, useFunds, useGroups} from "../../helpers/portfolio";
import {useRouter} from "../../helpers/location";
import GroupInterface from "../../../../shared/types/Group";

interface CompanyResults {
    company: CompanyInterface;
    group: GroupInterface;
    results: ResultSet[];
}

function CompanyResultList() {
    const { href, route, handleButtonClick } = useRouter();
    const { user } = useUser();
    const { groups, isLoadingGroups } = useGroups();
    const { companies, isLoadingFunds } = useFunds();

    const params: { companyId?: number, groupId?: number } = {}; // Admin

    if (user.companies.length === 1) { // Company manager / analyst
        params.companyId = user.companies[0].id;
    } else if (user.groups.length === 1) { // Portfolio manager
        params.groupId = user.groups[0].id;
    }

    const { results, years, isLoadingResults, isAuthorised } = useResults(params);

    const companyResults: CompanyResults[] = [];

    results.sort(sortResultsByCompany(companies)).forEach((result) => {
        let companyResult = companyResults.find((value) => value.company.uuid === result.company);

        if (!companyResult) {
            companyResult = {
                company: companies.find((company) => company.uuid === result.company) as CompanyInterface,
                group: groups.find((group) => group.uuid === result.group) as GroupInterface,
                results: [],
            };

            companyResults.push(companyResult);
        }

        companyResult.results.push(result);
    })

    return (
        <>
            <div className="page-wrapper company-result-wrapper" style={{boxShadow: "none"}}>
                <Row className="justify-content-between">
                    <Col>
                        <h1 className="page-title">Company Results</h1>
                    </Col>
                </Row>
                {(isLoadingGroups || isLoadingFunds || isLoadingResults) ? <div data-testid="loading-spinner" className="d-flex align-items-center justify-content-center py-5"><Spin /></div> : (
                    !isAuthorised ? <AlertUnauthorized /> : (
                        !results.length ? <AlertNoResults /> : groups.sort(sortGroupsByName).map((group) => (
                            <Row data-testid="company-results-list">
                                <Col flex={1} className="bg-white mb-4">
                                    <Row className="p-2" style={{ backgroundColor: "#fafafa" }}>
                                        <Col>
                                            <h3 className="mb-0">{ group.name }</h3>
                                        </Col>
                                    </Row>

                                    <Divider className="my-0"/>

                                    <Table
                                        columns={[
                                            {
                                                title: (
                                                    <h4 className="mb-0">Company</h4>
                                                ),
                                                dataIndex: "company",
                                                width: "100%",
                                                render: (company: CompanyInterface) => {
                                                    return (
                                                        <Link
                                                            to={href(route({
                                                                route: COMPANY_RESULT_DASHBOARD,
                                                                routeParams: {companyId: company.id},
                                                            }))}>
                                                            {company.name}
                                                        </Link>
                                                    );
                                                },
                                            },
                                            ...years.filter((year) => companyResults.filter((companyResult) => companyResult.group.id === group.id).find((companyResult) => companyResult.results.find((result) => result.year === year))).map((year) => ({
                                                title: (
                                                    <h4 className="mb-0">{year}</h4>
                                                ),
                                                render: (companyResult: CompanyResults) => {
                                                    const result = companyResult.results.find((value) => value.year === year);

                                                    if (!result) {
                                                        return "-";
                                                    }

                                                    const isMostRecentYear = year === years[years.length - 1];

                                                    return (
                                                        <Button
                                                            type={isMostRecentYear ? "primary" : "default"}
                                                            href={href(route({
                                                                route: COMPANY_RESULT_DASHBOARD,
                                                                routeParams: {companyId: companyResult.company.id},
                                                                searchParams: {year: result.year},
                                                            }))}
                                                            onClick={handleButtonClick}
                                                        >
                                                            View Results
                                                        </Button>
                                                    );
                                                },
                                            }))
                                        ]}
                                        dataSource={companyResults.filter((companyResult) => companyResult.group.id === group.id)}
                                        pagination={false}
                                    />
                                </Col>
                            </Row>
                        ))
                    )
                )}
            </div>
        </>
    );
}

export default CompanyResultList;
