import React, {useEffect, useState} from "react";
import {Button, Col, Form, notification, Row, Select, Table, Tooltip, Typography} from "antd";
import "./index.scss";
import API_SERVICE from '../../../services/api-service';
import {getUser} from '../../../services/local-storage';
import Header from '../../../components/Header';
import ExportPPT from '../../../components/ExportPPT';

import PptxGenJS from "pptxgenjs";

export default function EsgScores(props: any) {
  const { Text } = Typography;
  const {match: {params: { fundId }}} = props;
  const [fundYear, setFundYear] = useState <number> (2022);

  const [loading, setLoading] = useState(true);
  const [esgScores, setEsgScores] = useState <any> ({
    header: [],
    data: []
  });
  const [dataSource, setDataSource] = useState <any[]> ([]);
  const [activeCell, setActiveCell] = useState <string>('0:0');
  const roleId = getUser()?.user?.role?.id;
  const [editMode] = useState([1,2].includes(roleId));
  const [esgTableData, setEsgTableData] = useState <any> ({});
  const first = 0;
  const tableData: any = {
    headers: [
      [
        { text: "ESG Scores", options: {colspan: 2} }
      ],
      [
        { text: "Industry", options: {} },
        { text: "Company", options: {} }
      ]
    ],
    rows: [],
    visited: []
  };


  const industryHeaders: any[] = [

        {
          title: "ESG Scores",
          children: [
            {
              title: "Industry",
              key: "industry",
              className: "pt-0 pb-0 pl-2 pr-2",
              width: 120,
              render: (obj: any) => {
                  return (
                    <Tooltip placement="top" title={(obj.industry && obj.industry.name) || ' '}>
                      <div style={{overflow: 'hidden'}}>
                        <Text>{obj.industry && obj.industry.name}</Text>
                      </div>
                    </Tooltip>
                  )
                },
              onCell: (_: any, index: number) => {
                  return { rowSpan: dataSource[index].industry['span'] };
                },
            },
            {
              title: "Company",
              key: "company",
              className: "pt-0 pb-0 pl-2 pr-2",
              width: 120,
              render: (obj: any) => {
                  return (
                    <Tooltip placement="top" title={(obj.company && obj.company.name) || ' '}>
                      <div style={{overflow: 'hidden'}}>
                        <Text>{obj.company && obj.company.name}</Text>
                      </div>
                    </Tooltip>
                  )
                }
            },
          ]
        }
  ];

  const getKpiHeaders = () => {
    return esgScores.header.map((metric: any, i: number) => {
        tableData.headers[first].push({ text: metric.name, options: {rowspan: 2} });
        return {
                title: metric.name,
                align: "center",
                className: "p-0",
                render: (obj: any, data: any, data_index: number) => {
                      const dataObj = data[metric.name.toLowerCase()]
                      const cellId = `${data_index}:${i}`;
                      const cellStyle: any ={
                        borderColor: '#3297FD',
                        borderWidth: (activeCell === cellId)? 2:0,
                        backgroundColor: dataObj.cellColor && dataObj.cellColor.colorCode,
                        overflow: 'hidden',
                        display: 'flex'
                      };
                      let value = (dataObj.cellColor && dataObj.cellColor.name.titlecase()) || '';
                      if (!tableData.visited.includes(cellId)) {
                        tableData.visited.push(cellId);
                        if (tableData.rows.length <= data_index) {
                          tableData.rows.push([
                            { text: obj.industry.name, options: {} },
                            { text: obj.company.name, options: {} }
                          ])
                        }
                        tableData.rows[data_index].push({
                          text: value,
                          options: {fill: dataObj.cellColor && dataObj.cellColor.colorCode}
                        });
                      }
                      return (
                        <Button onClick={()=>setActiveCell(cellId)} style={cellStyle} ghost block>
                          <Text className="p-8">
                            {value}
                          </Text>
                        </Button>
                      )
                    }
                }
            })
          }

  const getEsgScores = async () => {
      try {
          setLoading(true);
          const {data: {data}} = await API_SERVICE.getEsgScores(fundId, fundYear);
          setEsgScores(data);
      } catch (e) {
          notification.error({message: API_SERVICE.handleErrors(e)});
      } finally {
        setLoading(false)
      }
  };

  const export_table = async (page_size = 3, slide_options={}) => {
    const headers = [...esgTableData.headers];
    const rows = [...esgTableData.rows];
    const pres = new PptxGenJS();
    let slide: any = pres.addSlide();
    let table: any[] = [];
    rows.map((r,i)=>{
      if (i!==0 && i%page_size===0) {
          slide.addTable(headers.concat(table), { ...slide_options });
          slide = pres.addSlide();
          table = [];
      }
      else if (i+1 === rows.length) {
          table.push(r)
          slide.addTable(headers.concat(table), { ...slide_options });
      }
      table.push(r)
    });
    pres.writeFile({ fileName: `esg-scores id_${fundId} ${fundYear}.pptx` });
  }

  useEffect(()=>{
    getEsgScores();
  },[fundYear])

  useEffect(()=>{
    const obj: any[] = [];
    const industries: any[] = [];
    esgScores.data.map((industryObj: any) => (
      industryObj.industryCompaniesMaps.map((industryMap: any)=> {
          obj.push({
            industry: {
              id: industryObj.id,
              name: industryObj.name,
              span: !industries.includes(industryObj.id)?
                industryObj.industryCompaniesMaps.length : 0
            },
            ...industryMap
          })
          industries.push(industryObj.id)
      })
    ));
    setDataSource(obj);
  },[esgScores])

  useEffect(()=>{
    setEsgTableData(tableData);
  },[dataSource])

  return (
    <div className="page-wrapper Benchmarking-role-wrapper">
      <InputHeader
        editable={editMode}
        year={fundYear}
        onYearChange={setFundYear}
        fundId={fundId}
        onUpdate={getEsgScores}
        />
      <Row justify="start">
        <Col className="p-2">
          <Table
            id="test-table"
            columns={industryHeaders.concat(getKpiHeaders())}
            dataSource={dataSource}
            size="small"
            bordered={true}
            scroll={{ x: "calc(700px + 50%)", y: 500 }}
            pagination={false}
            loading={loading}
          />
        </Col>
      </Row>
      {
        !loading &&
        <Row justify="end" style={{margin: 12, marginTop: 0}}>
          <Col>
            <ExportPPT
              total={esgTableData.rows.length}
              onExport={export_table} />
          </Col>
        </Row>
      }
    </div>
  );
}



const InputHeader = (props: any) => {
  const arrYear = [2020, 2021, 2022];
  const [years] = useState(arrYear);
  const [fundDetails, setFundDetails] = useState <any> ({})
  const [levels, setLevels] = useState <any[]> ([]);

  const getFundDetails = async () => {
      try {
          const {data: {data}} = await API_SERVICE.getFunds({id: props.fundId});
          if (data.items.length) {
            setFundDetails(data.items[0]);
          }
      } catch (e) {
          notification.error({message: API_SERVICE.handleErrors(e)});
      }
  };

  const getLevels = async () => {
      try {
          const {data: {data}} = await API_SERVICE.getColorLevels();
          if (data.items.length) {
            const arr: any[] = data.items.sort( (a: any,b: any) => (a.id < b.id)? -1 : 1);
            setLevels(arr);
          }
      } catch (e) {
      }
  };

  useEffect(()=>{
    getLevels();
    getFundDetails();
  },[])

  return (
    <div>
      <Row justify="space-between" align="middle" className="p-2 bottomLine">
        <Col>
          <Header headings={[
              "ESG Scores",
              fundDetails.name,
              fundDetails.pef && fundDetails.pef.name,
              props.year
            ]} />
        </Col>
        <Col>
          <div className="d-flex">
            <Form layout="horizontal">
              <Form.Item className="mb-0" label="Select Year:">
                <Select placeholder="year" value={props.year} disabled={!props.editable} onChange={(i)=>props.onYearChange(i)}>
                  {years.map((obj: any, i: number) => {
                    return <Select.Option key={i} value={obj}>{obj}</Select.Option>;
                  })}
                </Select>
              </Form.Item>
            </Form>
          </div>
        </Col>
        <div style={{marginLeft: 'auto'}}>
          <div className="d-flex align-items-center">
          {
            levels.map((obj,i) => {
              return (
                <div
                  style={{
                    width: 100,
                    backgroundColor: obj.colorCode,
                    padding: 2,
                    textAlign: 'center'
                  }}>
                  {obj.name.titlecase()}
                </div>
              )
            })
          }
          </div>
        </div>
      </Row>
    </div>
  )
}
