import React, { useEffect, useState } from "react";
import PermissionManagement from "./PermissionManagement/PermissionManagement";
import { notification, Tabs } from "antd";
import API_SERVICE from "../../services/api-service";
import AccessControl from "../../components/AccessControl";
import PermissionReadOnly from "./PermissionReadOnly/PermissionReadOnly";
const { TabPane } = Tabs;

export default function Permissions(this: any, props: any) {
  const [roles, setRoles] = useState([] as any[]);
  const [allPermissions, setAllPermissions] = useState([]);

  const getAllRoles = async () => {
    try {
      const {
        data: {
          data: { items },
        },
      } = await API_SERVICE.getAllRoles();
      setRoles(items);
    } catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    }
  };

  const getAllPermissions = async () => {
    try {
      const data = await API_SERVICE.getAllPermissions();
      setAllPermissions(data.data.data[0]);
    } catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    }
  };

  useEffect(() => {
    getAllRoles();
    getAllPermissions();
  }, [props.location]);

  return (
    <>
      <div className="role-wrapper page-wrapper">
        <div className="profile_section mb-3">
          <h1>Permission Management</h1>
        </div>
        <Tabs tabPosition={"left"}>
          {roles &&
            roles.length > 0 &&
            roles.map((item: any) => {
              return (
                <TabPane tab={item.name} key={item.id}>
                  <AccessControl
                    id={30}
                    closed={
                      <PermissionReadOnly
                        permissionIds={item.permissionIds}
                        allPermissions={allPermissions}
                      />
                    }
                  >
                    <PermissionManagement
                      getAllRoles={getAllRoles}
                      roleId={item.id}
                      permissionIds={item.permissionIds}
                      allPermissions={allPermissions}
                    />
                  </AccessControl>
                </TabPane>
              );
            })}
        </Tabs>
      </div>
    </>
  );
}
