import {useEffect, useState} from "react";
import {notification} from "antd";
import API_SERVICE from "../services/api-service";
import User from "../../../shared/types/User";

export function useUser() {
    const persistedUser = JSON.parse(window.localStorage.getItem("user") as string).user as User;
    const [user, setUser] = useState<User>(persistedUser);
    const [isLoadingUser, setIsLoadingUser] = useState(!user);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const {data: {data}} = await API_SERVICE.self();

                setUser(data);
            } catch (e: any) {
                notification.error({message: API_SERVICE.handleErrors(e)});
            }
        };

        if (!user) {
            fetchUser().finally(() => {
                setIsLoadingUser(false);
            });
        }
    }, []);

    return { user, isLoadingUser };
}
