import {ResultSet} from "@recoinsights/types";
import {
    DataPoints,
    Metrics,
    RenewableEnergyConsumption,
    resolveMetricResults
} from "@recoinsights/metrics";
import {Column} from "@ant-design/charts";

enum Labels {
    Year = "Year",
    EnergyConsumption = "Energy Consumption (kWh)",
    Type = "Type",
    Facility = "Facility",
    Vehicle = "Vehicle",
    Refrigerant = "Refrigerant",
    Electricity = "Electricity",
    Total = "Total",
}

interface Props {
    results: ResultSet[];
}

function EnergyConsumptionChart({ results }: Props) {
    const barData: { [Labels.Year]: number, [Labels.EnergyConsumption]?: number, [Labels.Type]: string, }[] = [];

    results.forEach((result) => {
        const metric = resolveMetricResults(Metrics.RenewableEnergyConsumption, result) as RenewableEnergyConsumption;
        const facilityEnergyConsumption = metric.data[DataPoints.TotalFacilityEnergyConsumption];
        const vehicleEnergyConsumption = metric.data[DataPoints.TotalVehicleEnergyConsumption];
        const refrigerantEnergyConsumption = metric.data[DataPoints.TotalRefrigerantEnergyConsumption];
        const electricityEnergyConsumption = metric.data[DataPoints.TotalElectricityEnergyConsumption];

        barData.push({
            [Labels.Year]: result.year,
            [Labels.EnergyConsumption]: facilityEnergyConsumption !== undefined ? Math.round(facilityEnergyConsumption / 1000) : undefined,
            [Labels.Type]: Labels.Facility,
        });

        barData.push({
            [Labels.Year]: result.year,
            [Labels.EnergyConsumption]: vehicleEnergyConsumption !== undefined ? Math.round(vehicleEnergyConsumption / 1000) : undefined,
            [Labels.Type]: Labels.Vehicle,
        });

        barData.push({
            [Labels.Year]: result.year,
            [Labels.EnergyConsumption]: refrigerantEnergyConsumption !== undefined ? Math.round(refrigerantEnergyConsumption / 1000) : undefined,
            [Labels.Type]: Labels.Refrigerant,
        });

        barData.push({
            [Labels.Year]: result.year,
            [Labels.EnergyConsumption]: electricityEnergyConsumption !== undefined ? Math.round(electricityEnergyConsumption / 1000) : undefined,
            [Labels.Type]: Labels.Electricity,
        });
    });

    return (
        <Column
            data={barData}
            isStack={true}
            xField={Labels.Year}
            yField={Labels.EnergyConsumption}
            seriesField={Labels.Type}
            legend={{ position: "right-bottom", maxWidthRatio: 0.33, padding: [0, 0, 0, 30], itemHeight: 10, }}
            height={200}
        />
    );
}

export default EnergyConsumptionChart;
