import React, {useEffect, useState} from "react";
import "./index.scss";
import {Button, Col, Form, Input, Modal, notification, Select, Space, Spin, Typography} from "antd";
import {CloseOutlined} from "@ant-design/icons";
import API_SERVICE from "../../../services/api-service";
import AccessControl from "@recoinsights/client/src/components/AccessControl";
import CompanyInterface from "@recoinsights/shared/types/Company";
import GroupInterface from "@recoinsights/shared/types/Group";
import FundInterface from "@recoinsights/shared/types/Fund";
import AppConstants from "@recoinsights/shared/constants/Constants";
import {getUser} from "../../../services/local-storage";

const {Text} = Typography;

interface FundPropsInterface {
    onClose: any;
    details?: FundInterface;
}

export default function FundCreateOrEditPage({onClose, details}: FundPropsInterface) {
    const [form] = Form.useForm();
    const [groups, setGroups] = useState<Array<GroupInterface>>([]);
    const [companies, setCompanies] = useState<Array<CompanyInterface>>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const roleId = getUser()?.user?.role?.id;
    const isFundManager = (roleId === AppConstants.roles.FUND_MANAGER_ID);

    const handleGroupsChange = (value: number, reset: boolean) => {
        const companies = groups.find((group: {id: number}) => group.id === value)?.companies ?? [];
        reset && form.setFieldsValue({companyIds: []})
        setCompanies(companies);
    }

    const getGroups = async () => {
        try {
            const {data: {data}} = await API_SERVICE.getGroups();
            setGroups(data.items);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        } finally {
            setLoading(false);
        }
    };

    const getInitialValues = (details: any) => {
        if (!details) {
            return {};
        }
        const obj = {...details};
        const {fundCompanies, pef} = obj;
        if (fundCompanies && fundCompanies.length) {
            obj.companyIds = fundCompanies.map((item: any) => {
                return item.company?.id;
            });
        }
        if (pef) {
            obj.pefId = pef.id;
        }
        return obj;
    };

    const handleFundAddEdit = (value: any) => {
        if (details) {
            API_SERVICE.updateFund(details.id, value)
                .then((d) => {
                    notification.success({message: 'Fund Updated Successfully'});
                    onClose(true);
                })
                .catch((e) => {
                    notification.error({message: API_SERVICE.handleErrors(e)});
                })
        } else {
            API_SERVICE.createFund(value)
                .then((d) => {
                    notification.success({message: 'Fund Created Successfully'});
                    onClose(true);
                })
                .catch((e) => {
                    console.log(e);
                    notification.error({message: API_SERVICE.handleErrors(e)});
                })
        }
    }

    useEffect(() => {
        getGroups();
    }, []);

    useEffect(() => {
        if (details && details.pef) {
            handleGroupsChange(details.pef.id, false);
        }
    }, [groups])

    return (
            <Modal
                title={<Text>{details ? "Edit Fund" : "Add Fund"}</Text>}
                width={600}
                footer={null}
                open={true}
                maskClosable={false}
                cancelText="Cancel"
                okText={details ? "Update" : "+ Add"}
                okType="primary"
                cancelButtonProps={{ghost: true}}
                onOk={
                    () => {
                        form.submit();
                    }
                }
                onCancel={onClose}
            >
                {
                    loading ? <div className="modal-spin-container"><Spin/></div> : <Form
                        form={form}
                        initialValues={getInitialValues(details) || {}}
                        fields={[]}
                        labelCol={{span: 8}}
                        wrapperCol={{span: 16}}
                        onFinish={(value) => {handleFundAddEdit(value);}}
                        layout="horizontal"
                        requiredMark={true}
                    >
                        <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label={"Name"}
                                name={"name"}
                                rules={[{required: true, message: "Fund Name required"}]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        {
                            !isFundManager && <>
                                <Col md={24} sm={24} xs={24}>
                                    <Form.Item
                                        label={"Select Group"}
                                        name={"pefId"}
                                        rules={[{required: true, message: "Please Select Group"}]}
                                    >
                                        <Select filterOption={(input, option: any) =>
                                            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                                allowClear
                                                placeholder={'Select Group'}
                                                onChange={(value) => {
                                                    handleGroupsChange(value, true)
                                                }}
                                        >
                                            {
                                                groups.map((group: any) => {
                                                    return <Select.Option
                                                        value={group.id}>{group.name}</Select.Option>;
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col md={24} sm={24} xs={24}>
                                    <Form.Item
                                        label={"Companies"}
                                        name={"companyIds"}
                                        rules={[{required: false}]}
                                    >
                                        <Select mode="multiple"
                                                maxTagCount={2}
                                                filterOption={(input, option: any) =>
                                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                allowClear
                                                placeholder={'Select Companies'}>
                                            {
                                                companies.map((company: any) => {
                                                    return <Select.Option
                                                        value={company.id}>{company.name}</Select.Option>;
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </>
                        }

                        <div className="text-right mt-3">
                            <Space>
                                <Button
                                    type="primary"
                                    ghost
                                    htmlType="button"
                                    icon={<CloseOutlined/>}
                                    className="theme-button primary full-width"
                                    onClick={onClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="theme-button primary full-width "
                                >
                                    {details ? "Update" : "+ Add"}
                                </Button>
                            </Space>
                        </div>
                    </Form>
                }
            </Modal>
    );
}

