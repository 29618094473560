import {CyberSecurityMaturity} from "@recoinsights/metrics";
import {Column} from "@ant-design/charts";
import React from "react";

enum Labels {
    Year = "Year",
    Score = "Score",
    Type = "Type",
    CyberSecurity = "Cyber security",
}

interface Props {
    result: CyberSecurityMaturity,
    year: number,
}

function CyberSecurityMaturityByCategoryChart({ result, year }: Props) {
    const data: { [Labels.Year]: number, [Labels.Score]?: number, [Labels.Type]: string, }[] = [];

    data.push({
        [Labels.Score]: result.score !== undefined ? Math.round(result.score * 100) : undefined,
        [Labels.Type]: Labels.CyberSecurity,
        [Labels.Year]: year,
    });

    return (
        <Column data={data}
                xField={Labels.Year}
                yField={Labels.Score}
                xAxis={{label: null}}
                yAxis={{min: 0, max: 100}}
                legend={{ position: "right-bottom", maxWidthRatio: 0.33, padding: [0, 0, 0, 30], itemHeight: 10 }}
                isGroup={true}
                seriesField={Labels.Type}
                height={200} />
    );
}

export default CyberSecurityMaturityByCategoryChart;
