import React from "react";
import {Checkbox, Collapse, Table} from "antd";

import API_SERVICE from "../../../services/api-service";

export default function PermissionManagement({allPermissions, roleId, permissionIds, getAllRoles}: any) {
  const { Panel } = Collapse;


  const permission_columns = {
    name: 'Permission rights',
    Show: 'Enable/Disable',
  } as any;

  async function toggle_access(id: any) {
    let arr = permissionIds;
    const index = permissionIds.indexOf(id)
    if (index>-1)
      arr.splice(index,1)
    else arr.push(id)

    await API_SERVICE.updatePermissions(roleId, arr);
    getAllRoles();
  }

  function access_control(obj: any) {
    if (obj)
      return <Checkbox onChange={()=>toggle_access(obj.id)} checked={permissionIds.includes(obj.id)} />
    else return <Checkbox disabled />
  }

  function createColumn(id: string) {
    const obj = {
      key: id,
      title: permission_columns[id] || id,
    }
    if (id==='name')
      //@ts-ignore
      obj.render = (a: any) => a[id].capitalize()
    else {
      //@ts-ignore
      obj.align = 'center'
      //@ts-ignore
      obj.render = (a: any) => access_control(a[id])
    }
    return obj
  }

  return (
    <>
      {(allPermissions) && <div>
        <Collapse accordion ghost>
          {Object.keys(allPermissions).map((item: any,index) => {

            const sub_modules = [] as any;
            const operations = [] as any;
            const table_columns = [createColumn('name')]
            Object.keys(allPermissions[item]).map((sub_module,module_index)=>{
              const obj = { name: sub_module }
              allPermissions[item][sub_module].map((permission: any, permission_index: number)=>{
                //@ts-ignore
                obj[permission.name] = permission

                if (!operations.includes(permission.name)){
                  operations.push(permission.name)
                  table_columns.push(createColumn(permission.name))
                }

              })
              sub_modules.push(obj)
            })

            return (
                <Panel header={item.titlecase()} key={index}>
                  <Table pagination={false} dataSource={sub_modules} columns={table_columns} />
                </Panel>
            )}
          )}
        </Collapse>
      </div>
      }
    </>
  );
}
