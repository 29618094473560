import React, {useState, useEffect} from 'react';

import {Form, Input, Button, Row, Col, Modal, notification, Space, Popover} from 'antd';
import {CloseOutlined, ReloadOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {FORGOT_PASSWORD} from '../../../shared/constants/RouteConstants';
import API_SERVICE from '@recoinsights/client/src/services/api-service';

interface Props {
    onLogin: (email: string, password: string) => void;
    onVerify: (uuid: string, otp: string) => void;
    loading: boolean;
}

const otp_inputs = [0, 1, 2, 3, 4, 5];

export default function LoginForm({
                                      onLogin,
                                      onVerify,
                                      loading,
                                  }: Props) {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const history = useHistory();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [recoveringPassword, setRecoveringPassword] = useState(false);
    const [verificationId, setVerificationId] = useState('');
    const [credentials, setCredentials] = useState({});
    const [resend_clock, set_resend_clock] = useState(0);

    const popOverContent = (
        <div>
            <h4>Multi-factor authentication</h4>
            <p>To ensure your account is extra secure, we’ve introduced <a
                href="https://en.wikipedia.org/wiki/Multi-factor_authentication" target="_blank">multi-factor
                authentication</a> via email.</p>
            <p>A random 6-digit code will be sent to your email address each time you attempt to sign in to the Re:Co
                platform, to verify access to your account, you will be required to enter this authentication code into
                this secondary sign-in screen.</p>
            <p>This prevents your account being accessed by an unauthorised third party that may have been able to
                discover your password.</p>
            <p>If you require any further assistance, please contact us at: <a
                href="mailto: help@re.co.com">help@re.co.com</a></p>
        </div>
    )

    async function handleSubmit(values: any) {
        if (onLogin) {
            const {email, password} = values;
            const uuid = await onLogin(email, password);
            if (uuid) {
                setVerificationId(uuid);
                setCredentials(values);
            }
        }
    }

    async function resendOtp(values: any) {
        if (onLogin) {
            const {email, password} = credentials;
            const uuid = await onLogin(email, password);
            if (uuid) {
                setVerificationId(uuid);
                notification.success({message: 'Your authentication code has been sent'})
                set_resend_clock(45);
            }
        }
    }


    const showModal = () => {
        setIsModalVisible(true);
    };


    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        if (resend_clock > 0) {
            setTimeout(() => {
                set_resend_clock(resend_clock - 1);
            }, 1000);
        }
    }, [resend_clock])


    return (
        <>
            {
                verificationId ?
                    <Form
                        form={form}
                        className={'login-form'}
                        fields={[]}
                        onFinish={(obj) => {
                            onVerify(
                                verificationId,
                                Object.values(obj).join('')
                            );
                        }}
                        labelCol={{span: 8}}
                        wrapperCol={{span: 16}}
                        requiredMark={false}
                    >
                        <Col sm={{span: 16, offset: 8}}>
                            <p className="mb-1">Please verify access to your account by <br/> entering the
                                authentication code
                                sent to your email address.</p>
                            <Popover content={popOverContent} overlayClassName="popover-on-otp" trigger="click">
                                <div className="text-primary cursor-pointer mb-3">What's this?</div>
                            </Popover>
                            <Space>
                                {
                                    otp_inputs.map((k, i) => (
                                        <Form.Item
                                            key={i}
                                            className={'mb-3'}
                                            name={i}>
                                            <Input
                                                style={{width: 32}}
                                                autoComplete={'off'}
                                                maxLength={1}
                                                id={`inp-${i}`}
                                                onChange={(e) => {
                                                    if (e.target.value) {
                                                        document.getElementById(`inp-${i + 1}`).focus();
                                                        if (i + 1 === otp_inputs.length) {
                                                            form.submit();
                                                        }
                                                    } else if (i)
                                                        document.getElementById(`inp-${i - 1}`).focus();
                                                }}
                                                onPaste={(e) => {
                                                    const pastedData = e.clipboardData.getData('text/plain').trim() || null;
                                                    if (pastedData && pastedData.length) {
                                                        otp_inputs.forEach((item, index) => {
                                                            const formattedValue = {};
                                                            formattedValue[index] = pastedData[index];
                                                            form.setFieldsValue(formattedValue);
                                                        })
                                                        document.getElementById(`inp-${pastedData.length - 1}`)?.focus();
                                                        if (pastedData.length >= 6)
                                                            form.submit();
                                                    } else {
                                                        e.preventDefault();
                                                    }
                                                }}
                                            />
                                        </Form.Item>
                                    ))
                                }
                            </Space>
                        </Col>
                        <Col sm={{span: 16, offset: 8}}>
                            <Row justify="space-between">
                                <Form.Item>
                                    <Button
                                        id={`inp-${otp_inputs.length}`}
                                        type='primary'
                                        htmlType='submit'
                                        loading={loading}
                                        className='theme-button primary'>
                                        Sign in
                                    </Button>
                                </Form.Item>
                                {
                                    resend_clock ?
                                        <a className="mt-1">
                                            Try again in {resend_clock}s
                                        </a>
                                        :
                                        <Button
                                            type="link"
                                            onClick={resendOtp}>
                                            Resend code
                                        </Button>
                                }
                            </Row>
                        </Col>
                    </Form>
                    :
                    <Form
                        className={'login-form'}
                        form={form}
                        fields={[]}
                        initialValues={
                            {
                                email: '',
                                password: '',
                            }
                        }
                        onFinish={handleSubmit}
                        labelCol={{span: 8}}
                        wrapperCol={{span: 16}}
                        requiredMark={false}
                    >
                        <Form.Item
                            className={'mb-3'}
                            label={'Email'}
                            name='email'
                            rules={[
                                {required: true, message: 'Email required'},
                            ]}
                        >
                            <Input autoComplete={'off'} placeholder="enter your registered email"
                            />
                        </Form.Item>
                        <Form.Item
                            className={'mb-3'}
                            label={'Password'}
                            name='password'
                            rules={[
                                {required: true, message: 'Password required'},
                            ]}>
                            <Input
                                autoComplete={'off'}
                                type='password'
                                placeholder="enter your password"
                            />
                        </Form.Item>

                        <Row>
                            <Col sm={{span: 16, offset: 8}}>
                                <Button
                                    type='primary'
                                    htmlType='submit'
                                    loading={loading}
                                    className='theme-button primary mb-3 mr-2'
                                >
                                    Sign in
                                </Button>
                                <small><a className="secondary-link" onClick={showModal}>Forgot Password?</a></small>
                            </Col>
                        </Row>
                    </Form>
            }
            <Row>
                <Col sm={{span: 16, offset: 8}}>
                    <small>for any help, send email to: <a href="mailTo: help@re.co.com"
                                                           className="default-link">help@re.co.com</a></small>
                </Col>
            </Row>

            <Modal title="Forgot password? Recover Now."
                   footer={null}
                   open={isModalVisible}
                   onCancel={handleCancel}
                   destroyOnClose={true}
            >
                <Form
                    className={'forgot-password-form'}
                    fields={[]}
                    onFinish={async (value: string) => {
                        try {
                            setRecoveringPassword(true);
                            // @ts-ignore
                            await API_SERVICE.forgotPassword(value.f_email);
                            notification.success({
                                message: 'An email with new password has been sent to you!',
                            });
                            handleCancel();
                        } catch (e) {
                            notification.error({
                                message: t(API_SERVICE.handleErrors(e)),
                            });
                        } finally {
                            setRecoveringPassword(false);
                        }
                    }}
                    requiredMark={false}
                >
                    <Form.Item
                        style={{flex: 1}}
                        label={'Email ID'}
                        name='f_email'
                        rules={[
                            {required: true, message: 'Email required'},
                        ]}
                    >
                        <Input autoComplete={'off'}
                        />
                    </Form.Item>

                    <div className="text-right">
                        <Space>
                            <Button
                                type='primary'
                                ghost
                                htmlType='button'
                                icon={<CloseOutlined/>}
                                loading={loading}
                                className='theme-button primary full-width'
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>

                            <Button
                                type='primary'
                                htmlType='submit'
                                icon={<ReloadOutlined/>}
                                loading={recoveringPassword}
                                className='theme-button primary full-width '
                            >
                                Recover
                            </Button>
                        </Space>
                    </div>
                </Form>
            </Modal>
        </>
    );
}
