import React, {useEffect, useState} from 'react';
import {Progress, Tag, Typography} from 'antd';
import 'react-quill/dist/quill.snow.css';
import {useTranslation} from "react-i18next";



export default function PortfolioProgress(props: any) {
    const [value, setValue] = useState('');
    const [saving, setSaving] = useState(false);


    const handleUpdateContent = async () => {
        setSaving(true);
    };

    useEffect(() => {
        props.data?.contactUs ? setValue(props.data.contactUs) : setValue('');
    }, [props])

    return (
        <>
            <table>
                <tr>
                    <th>Company</th>
                    <th>Progress</th>
                    <th>Status</th>
                </tr>
                <tr>
                    <td>Horizon Co.</td>
                    <td><Progress percent={55} size="small" /></td>
                    <td><Tag color="processing">In Progress</Tag></td>
                </tr>
                <tr>
                    <td>Plance Co.</td>
                    <td><Progress percent={100} size="small" /></td>
                    <td><Tag color="success">Complete</Tag></td>
                </tr>
                <tr>
                    <td>Acture Co.</td>
                    <td><Progress percent={0} size="small" /></td>
                    <td><Tag color="error">Not Started</Tag></td>
                </tr>
                <tr>
                    <td>Paraxion Co.</td>
                    <td><Progress percent={69} size="small" /></td>
                    <td><Tag color="processing">In Progress</Tag></td>
                </tr>
            </table>
        </>
    )
}
