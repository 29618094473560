import {ResultSet} from "@recoinsights/types";
import {
    EmissionsIntensity,
    Metrics,
    resolveMetricResults
} from "@recoinsights/metrics";
import {Column} from "@ant-design/charts";
import CompanyInterface from "../../../../shared/types/Company";
import {getCompany, sortResultsByCompany} from "../../helpers/portfolio";

enum Labels {
    Year = "Year",
    Score = "Score",
    Company = "Company",
}

interface Props {
    companies: CompanyInterface[];
    results: ResultSet[];
}

function PortfolioResultsEmissionsIntensityChart({ companies, results }: Props) {
    const lineData: { [Labels.Year]: number, [Labels.Score]?: number, [Labels.Company]: string }[] = [];

    results.sort(sortResultsByCompany(companies)).forEach((result) => {
        const metric = resolveMetricResults(Metrics.EmissionsIntensity, result) as EmissionsIntensity;

        lineData.push({
            [Labels.Year]: result.year,
            [Labels.Score]: metric.score !== undefined ? Math.round(metric.score * 10000) / 100 : undefined,
            [Labels.Company]: getCompany(companies, result.company)?.name ?? '-',
        });
    });

    return (
        <Column
            data={lineData}
            isGroup={true}
            xField={Labels.Year}
            yField={Labels.Score}
            seriesField={Labels.Company}
            height={400}
            legend={{ position: "right-bottom", maxWidthRatio: 0.33, padding: [0, 0, 0, 30], itemHeight: 10, }}
        />
    );
}

export default PortfolioResultsEmissionsIntensityChart;
