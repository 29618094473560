import * as React from 'react';
import {Suspense, useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';

import {Avatar, Col, Dropdown, Layout, Menu, Row, Space, Typography, Divider} from 'antd';
import {DownOutlined, LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';

import LoaderOverlay from '@recoinsights/shared/components/LoaderOverlay';
import logo from '@recoinsights/shared/assets/logo.jpg';

import {accessIncludes} from '@recoinsights/client/src/components/AccessControl';
import {getUser} from '@recoinsights/client/src/services/local-storage';
import './Layout.scss';
import {AuthConsumer, logout} from '../../contexts/AuthContext';
import {useLocation} from 'react-use';
import groupLogo from '@recoinsights/shared/assets/images/searchlightGroup.png';
//all icons
import ApprovalsIcon from '@recoinsights/shared/assets/icons/card-checklist.svg';
import SurveyListIcon from '@recoinsights/shared/assets/icons/SurveyListIcon.svg';
import PortfolioResultsIcon from '@recoinsights/shared/assets/icons/card-text.svg';
import GroupsIcon from '@recoinsights/shared/assets/icons/GroupsIcon.svg';
import CompaniesIcon from '@recoinsights/shared/assets/icons/CompaniesIcon.svg';
import UserIcon from '@recoinsights/shared/assets/icons/UserIcon.svg';
import PermissionIcon from '@recoinsights/shared/assets/icons/PermissionIcon.svg';
import FundsIcon from '@recoinsights/shared/assets/icons/FundsIcon.svg';
import SurveyIcon from '@recoinsights/shared/assets/icons/SurveyIcon.svg';
import DataListIcon from '@recoinsights/shared/assets/icons/DataListIcon.svg';
import FilesIcon from '@recoinsights/shared/assets/icons/FIlesIcon.svg';
import ContentIcon from '@recoinsights/shared/assets/icons/ContentIcon.svg';
import OtherMasterIcon from '@recoinsights/shared/assets/icons/OtherMasterIcon.svg';
import ReportIcon from '@recoinsights/shared/assets/icons/ReportIcon.svg';
import ContactIcon from '@recoinsights/shared/assets/icons/ContactIcon.svg';
import SupportIcon from '@recoinsights/shared/assets/icons/SupportIcon.svg';
import HomeIcon from '@recoinsights/shared/assets/icons/HomeIcon.svg';
import DashboardIcon from '@recoinsights/shared/assets/icons/dashboard.svg';


import {
    ADMIN_DATA_COLLECTION,
    ADMIN_FUNDS,
    ADMIN_SURVEYS,
    COMPANY_LIST,
    CONTACTUS,
    CONTENT,
    DATA,
    FILES,
    GROUPS_LIST,
    HOME,
    INSIGHTS,
    MASTER_MANAGEMENT,
    PERMISSION,
    PORTFOLIO_BENCHMARKS,
    REPORTS,
    SUPPORT,
    COMPANY_RESULT_LIST,
    USER_LIST,
    ADMIN_QUESTIONS_MASTER,
    SURVEYS_LIST,
    PORTFOLIO_RESULTS,
    COMPANY_RESULT_DASHBOARD,
    PORTFOLIO_RESULTS_DASHBOARD,
    EMISSIONS
} from '../../constants/RouteConstants';
import AppConstants from '@recoinsights/shared/constants/Constants';
import useWindowDimensions from '@recoinsights/shared/hooks/WindowsDimension'

import '@recoinsights/shared/style/fonts/icons/style.scss';
import {isResultsEnabled, isEmissionsEnabled} from "@recoinsights/client/src/services/util";
import {useRouter} from "@recoinsights/client/src/helpers/location";


const { Header, Sider, Content } = Layout;
const { Text } = Typography;

interface LayoutProps {
    selectedMenu?: string | null;
    onMenuClick?: (route: string) => void;
    children: any;
    hasHeader?: boolean;
    logout?: boolean;
    hasLink?: boolean;
    linkText?: string;
    linkUrl?: string;
}

export default function DesktopAdminLayout({
                                               children,
                                           }: LayoutProps) {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('');
    const [isSiderCollapsed, setIsSiderCollapsed] = useState(false);
    const location = useLocation();
    const history = useHistory();

    const windowDimensions = useWindowDimensions();

    const user = getUser();
    const firstInitial = user && user.user?.firstName?.charAt(0)?.toUpperCase();
    const lastNameInitial = user && user.user?.lastName?.charAt(0)?.toUpperCase();
    const roleId=getUser()?.user?.role?.id;
    const { navigate, route, href } = useRouter();

    useEffect(() => {
        if (
          [
            ADMIN_DATA_COLLECTION,
            ADMIN_FUNDS,
            ADMIN_SURVEYS,
            COMPANY_LIST,
            CONTACTUS,
            CONTENT,
            DATA,
            FILES,
            GROUPS_LIST,
            HOME,
            INSIGHTS,
            MASTER_MANAGEMENT,
            PERMISSION,
            PORTFOLIO_BENCHMARKS,
            REPORTS,
            SUPPORT,
            COMPANY_RESULT_LIST,
            USER_LIST,
            ADMIN_QUESTIONS_MASTER,
              SURVEYS_LIST,
              PORTFOLIO_RESULTS,
              COMPANY_RESULT_DASHBOARD,
              EMISSIONS
          ].includes(location.pathname || '')
        ) { setActiveTab(location.pathname || '') }
        else setActiveTab(
          localStorage.getItem('admin-session') || ''
        );
    }, [location]);

    const handleClick = (e: any) => {
        localStorage.setItem('admin-session', e.key);
        history.push(e.key);
        return true;
    };

    const toggleSider = () => {
        setIsSiderCollapsed(!isSiderCollapsed);
    };

    const _handleLogout = () => {
        const analystDataCollectionRouteRegEx = new RegExp('\/admin\/analyst\/datacollection\/[0-9]+', 'i');
        if(analystDataCollectionRouteRegEx.test(location.pathname as string) && roleId===4){
            localStorage.setItem('fromLogout', 'true');
            history.push('/login');
            return;
        }else {
            logout();
        }

    }

    const menu = (
        <Menu className="menu-hide">
            <Menu.Item onClick={()=>history.push('/admin/profile')}>
                <Space><UserOutlined /> <Text>Profile</Text></Space>
            </Menu.Item>
            <Menu.Item onClick={_handleLogout}>
                <Space><LogoutOutlined /> <Text>Logout</Text></Space>
            </Menu.Item>
        </Menu>
    );

    return (
        <Layout
            hasSider={false}
            style={{ minHeight: '100vh' }}
            className={`desktop-layout`}
        >
            <Header className="p-0" style={{ position: 'fixed', zIndex: 999 }}>
                <Row gutter={0} align="middle" justify="space-between">
                    <Col>
                        <div className="d-flex align-items-center">
                            {
                                (windowDimensions.width < 768) && React.createElement(isSiderCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                    className: 'sider-trigger pl-2',
                                    onClick: toggleSider,
                                })
                            }
                            <div className="custom-nav-link header-logo-wrapper">
                                <Link to={'/home'}>
                                    <img src={logo} className="logo" />
                                </Link>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <AuthConsumer>
                                    {({ isAuth, user }) =>
                                        (<Dropdown className="dropdown" overlay={menu} placement='bottomRight'>
                                            <div className='custom-nav-link account-details'>
                                                <div className=" p-1" >
                                                    <Avatar className="name-initial-avatar">{`${firstInitial} ${lastNameInitial}`}</Avatar>
                                                    <Text className="pl-1 user-name">{user?.name}</Text>
                                                    <DownOutlined style={{color: "#E30513"}} />
                                                </div>
                                            </div>
                                        </Dropdown>)
                                    }
                                </AuthConsumer>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Header>

            <Layout>
                <Sider width={250} theme={'light'} className="custom-sidebar" breakpoint="lg"
                       collapsedWidth="0" trigger={null} collapsible collapsed={isSiderCollapsed}>
                    <Menu
                        style={{ width: 250 }}
                        onClick={handleClick}
                        selectedKeys={[activeTab]}
                        mode='inline'>

                        {/*TODO remove this permission id, combining this in survey page*/}
                        {/*{
                          accessIncludes([36]) && <Menu.Item icon={<img src={DataListIcon} className="admin-icon"/>} key={ADMIN_DATA_COLLECTION}>
                            {
                                (roleId === AppConstants.roles.FUND_MANAGER_ID)? 'Collection Progress'
                                :'Data Collection'
                            }
                          </Menu.Item>
                        }*/}
                        {
                          accessIncludes([32]) &&
                            <Menu.Item icon={<i className="menu-icon bi bi-house"/>} key={HOME}>
                              Home
                            </Menu.Item>
                        }

                        <Menu.Item
                          icon={<i className="menu-icon bi bi-card-checklist"/>}
                          key={SURVEYS_LIST}>
                            Surveys
                        </Menu.Item>
                        {
                            ([AppConstants.roles.COMPANY_MANAGER_ID].includes(roleId)) && isResultsEnabled() &&
                            <Menu.Item icon={<i className="menu-icon bi bi-bar-chart"/>}
                                       key={ href(route({ route: COMPANY_RESULT_DASHBOARD, routeParams: { companyId: user?.user?.company?.id } })) }
                            >
                                Results
                            </Menu.Item>
                        }
                        {
                            ([AppConstants.roles.FUND_MANAGER_ID, AppConstants.roles.ADMIN_ID, AppConstants.roles.SUPER_ADMIN_ID].includes(roleId)) && isResultsEnabled() &&
                            <Menu.Item icon={<i className="menu-icon bi bi-bar-chart"/>} key={COMPANY_RESULT_LIST}>
                              Company Results
                            </Menu.Item>
                        }
                        {
                            ([AppConstants.roles.FUND_MANAGER_ID, AppConstants.roles.ADMIN_ID, AppConstants.roles.SUPER_ADMIN_ID].includes(roleId)) && isResultsEnabled() &&
                            <Menu.Item icon={<i className="menu-icon bi bi-sliders"/>}
                                       key={
                                           user?.user?.groups.length === 1 ?
                                               href(route({ route: PORTFOLIO_RESULTS_DASHBOARD, routeParams: { groupId: user?.user?.groups[0]?.id} })) :
                                               PORTFOLIO_RESULTS
                                       }
                            >
                                Portfolio Results
                            </Menu.Item>
                        }
                        {
                            ([AppConstants.roles.COMPANY_MANAGER_ID, AppConstants.roles.FUND_MANAGER_ID, AppConstants.roles.ADMIN_ID, AppConstants.roles.SUPER_ADMIN_ID].includes(roleId)) && isEmissionsEnabled() &&
                            <Menu.Item icon={<i className="menu-icon bi bi-cloud-haze2"/>} key={EMISSIONS}>
                                Emissions
                            </Menu.Item>
                        }
                        {accessIncludes([33]) && <Menu.Item icon={<i className="menu-icon bi bi-graph-up"/>} key={INSIGHTS}>Insights</Menu.Item>}

                        {accessIncludes([1,2,3,4,5,37,38,39,40,41,54,6,7,8,9,10,11,12,13,14,15,16,17,30,34]) && <Divider />}


                        {
                            ([AppConstants.roles.SUPER_ADMIN_ID].includes(roleId)) &&
                            <Menu.Item
                                icon={<i className="menu-icon bi bi-card-checklist"/>}
                                key={ADMIN_SURVEYS}>
                                Surveys
                            </Menu.Item>
                        }
                        {accessIncludes([1,2,3,4,5]) && <Menu.Item icon={<i className="menu-icon bi bi-collection"/>} key={GROUPS_LIST}>Groups</Menu.Item>}
                        {accessIncludes([37,38,39,40,41,54]) && <Menu.Item icon={<i className="menu-icon bi bi-briefcase"/>} key={ADMIN_FUNDS}>Funds</Menu.Item>}
                        {accessIncludes([6,7,8,9,10,11]) && <Menu.Item icon={<i className="menu-icon bi bi-building"/>} key={COMPANY_LIST}>Companies</Menu.Item>}
                        {accessIncludes([13,16]) && <Menu.Item icon={<i className="menu-icon bi bi-people"/>} key={USER_LIST}>Users</Menu.Item>}
                        {accessIncludes([30,34]) && <Menu.Item icon={<i className="menu-icon bi bi-shield-lock"/>} key={PERMISSION}>Permissions</Menu.Item>}

                        {(accessIncludes([27,28,29,55,56,57,18,19,20,21,22,23,24,25,26]) || ([AppConstants.roles.SUPER_ADMIN_ID, AppConstants.roles.ADMIN_ID].includes(roleId))) && <Divider />}

                        {accessIncludes([27,28,29]) && <Menu.Item icon={<i className="menu-icon bi bi-card-text"/>} key={CONTENT}>Content</Menu.Item>}
                        {
                            ([AppConstants.roles.SUPER_ADMIN_ID, AppConstants.roles.ADMIN_ID].includes(roleId)) &&
                            <Menu.Item
                                icon={<i className="menu-icon bi bi-card-list"/>}
                                key={ADMIN_QUESTIONS_MASTER}>
                                Questions
                            </Menu.Item>
                        }
                        {accessIncludes([18,19,20,21,22,23,24]) && <Menu.Item icon={<i className="menu-icon bi bi-files"/>} key={FILES}>Files</Menu.Item>}
                        {
                            (roleId === AppConstants.roles.SUPER_ADMIN_ID) &&
                            <Menu.Item icon={<i className="menu-icon bi bi-file-earmark-arrow-down"/>} key={REPORTS}>
                                Reports
                            </Menu.Item>
                        }
                        {accessIncludes([25,26]) && <Menu.Item icon={<i className="menu-icon bi bi-file-earmark-spreadsheet"/>} key={MASTER_MANAGEMENT}>Data</Menu.Item>}

                        {accessIncludes([31, 35]) && <Divider />}

                        {accessIncludes([31]) && <Menu.Item icon={<i className="menu-icon bi bi-question-circle"/>} key={SUPPORT}>Support</Menu.Item>}
                        {accessIncludes([35]) && <Menu.Item icon={<i className="menu-icon bi bi-headset"/>} key={CONTACTUS}>Contact Us</Menu.Item>}

                        {/*TODO remove this code, if not required PLAT-102*/}
                        {/*{
                          (roleId === AppConstants.roles.FUND_MANAGER_ID) &&
                            <Menu.Item icon={<img src={FundsIcon} className="admin-icon"/>} key={PORTFOLIO_BENCHMARKS}>
                              Portfolio Benchmarks
                            </Menu.Item>
                        }*/}

                        {/*{accessIncludes([47,48,49,50,51]) && <Menu.Item icon={<img src={SurveyIcon} className="admin-icon"/>} key={ADMIN_SURVEYS}>Surveys</Menu.Item>}*/}



                    </Menu>
                </Sider>
                <Content className={'content-layout'}>
                    <Suspense fallback={<LoaderOverlay size='large' loading={true} />}>
                        {children}
                    </Suspense>
                </Content>
            </Layout>
        </Layout>
    )
        ;
}
