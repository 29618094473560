import * as React from 'react';
import './index.scss';
import {Col, Row} from 'antd';

interface LayoutProps {
    children: any;
    title?: any,
    titleIcon?: any,
    titleSearch?: any,
    titleAction?: any,
    onMenuClick?: (route: string) => void;
    panel?: any;
    pagination?: any;
    filters?: any;
}

export default function AdminListLayout({children, titleSearch, titleAction, title, pagination, filters}: LayoutProps) {
    return (
        <div className={'admin-list-layout'}>
            <Row>
                <Col xs={24}>
                    <div className="page-title">{title}</div>
                </Col>
            </Row>
            <div className="bg-white">
                {
                    (titleSearch || titleAction || filters) &&
                    <Row className='title-bar' align={'middle'} justify="space-between" gutter={20} wrap={false}>
                        {
                            (titleSearch || filters) && <Col>
                                <Row gutter={20} align={'middle'}>
                                    {
                                        titleSearch && <Col>
                                            {titleSearch}
                                        </Col>
                                    }

                                    {
                                        filters ? filters : <></>
                                    }
                                </Row>
                            </Col>
                        }

                        {
                            titleAction && <Col>
                                {titleAction}
                            </Col>
                        }
                    </Row>
                }

                {
                    children
                }

                <Row>
                    {
                        pagination && <Col flex={'auto'} className={'text-right py-2'}>
                            {pagination}
                        </Col>
                    }
                </Row>
            </div>
        </div>
    );
}
