import React from "react";
import {Link} from "react-router-dom";
import {Table} from "antd";
import {Metric, ResultSet} from "@recoinsights/types";
import {Metric as MetricData, Metrics, resolveMetricResults} from "@recoinsights/metrics";
import CompanyInterface from "@recoinsights/shared/types/Company";
import {COMPANY_RESULT_DASHBOARD} from "@recoinsights/shared/constants/RouteConstants";
import {resolveMetric, useMetricsWithResults} from "../../helpers/metrics";
import {useRouter} from "../../helpers/location";
import {sortResultsByCompany} from "../../helpers/portfolio";
import ScoreComparisonIndicator from "./ScoreComparisonIndicator";

interface Props {
    companies: CompanyInterface[];
    metrics: Metric[];
    results: ResultSet[];
    year: number;
}

function PortfolioSingleYearResults({ companies, metrics, results, year }: Props) {
    const { href, route } = useRouter();

    const currentYearResults = results.filter((result: ResultSet) => {
        return result.year === year;
    });
    const previousYearResults = results.filter((result: ResultSet) => {
        return result.year === year - 1;
    });

    const { hasMetricScore, hasEnvironmentalMetrics, hasSocialMetrics, hasGovernanceMetrics } = useMetricsWithResults(results);

    function getCompany(companyUid: string) {
        return companies.find((company) => company.uuid === companyUid);
    }

    function configureMetricColumn(metricUid: Metrics) {
        const metric = resolveMetric(metricUid, metrics);

        return {
            title: (
                <>
                    <h4 className="text-center mb-0">{metric.name}</h4>
                    <p className="text-center mb-0" style={{ color: "gray" }}><small>{metric.description}</small></p>
                </>
            ),
            key: metricUid,
            render: (data: ResultSet) => {
                const result = resolveMetricResults(metricUid, data) as MetricData;

                const previousYearResult = previousYearResults.find((results) => results.company === data.company);
                const previousMetricResult = previousYearResult ? resolveMetricResults(metricUid, previousYearResult) : undefined;

                return (
                    <>
                        <p className="text-center mb-0">
                            {result.renderScore()}
                            {previousMetricResult && (
                                <span className={"ml-1"} style={{ color: "grey", fontSize: "smaller" }}>
                                    <ScoreComparisonIndicator metricResultA={result} metricResultB={previousMetricResult} />
                                    &nbsp;
                                    {result.renderDifference(result.compare(previousMetricResult))}
                                </span>
                            )}
                        </p>
                    </>
                )
            },
        }
    }

    const environmentalMetrics = [];
    if (hasMetricScore(Metrics.RenewableEnergyConsumption)) environmentalMetrics.push(configureMetricColumn(Metrics.RenewableEnergyConsumption));
    if (hasMetricScore(Metrics.EmissionsIntensity)) environmentalMetrics.push(configureMetricColumn(Metrics.EmissionsIntensity));

    const socialMetrics = [];
    if (hasMetricScore(Metrics.CommunityEngagement)) socialMetrics.push(configureMetricColumn(Metrics.CommunityEngagement));
    if (hasMetricScore(Metrics.CustomerSatisfaction)) socialMetrics.push(configureMetricColumn(Metrics.CustomerSatisfaction));
    if (hasMetricScore(Metrics.EmployeeSatisfaction)) socialMetrics.push(configureMetricColumn(Metrics.EmployeeSatisfaction));
    if (hasMetricScore(Metrics.GenderDiversity)) socialMetrics.push(configureMetricColumn(Metrics.GenderDiversity));
    if (hasMetricScore(Metrics.IncidentFrequency)) socialMetrics.push(configureMetricColumn(Metrics.IncidentFrequency));

    const governanceMetrics = [];
    if (hasMetricScore(Metrics.BoardMaturity)) governanceMetrics.push(configureMetricColumn(Metrics.BoardMaturity));
    if (hasMetricScore(Metrics.CyberSecurityMaturity)) governanceMetrics.push(configureMetricColumn(Metrics.CyberSecurityMaturity));

    const tableHeaders: any[] = [
        {
            title: "",
            children: [
                {
                    title: (
                        <h3 className="mb-0">Company</h3>
                    ),
                    dataIndex: "company",
                    render: (companyUid: string) => {
                        const company = getCompany(companyUid);

                        if (!company) {
                            return '-';
                        }

                        return (
                            <Link
                                to={href(route({
                                    route: COMPANY_RESULT_DASHBOARD,
                                    routeParams: {companyId: company.id},
                                    searchParams: {year: year, fundId: null},
                                }))}>
                                {company.name}
                            </Link>
                        );
                    },
                },
            ]
        },
        hasEnvironmentalMetrics && {
            title: (
                <h3 className="text-center mb-0 p-1"><i className="bi bi-globe mr-1" />Environmental</h3>
            ),
            children: environmentalMetrics,
        },
        hasSocialMetrics && {
            title: (
                <h3 className="text-center mb-0 p-1"><i className="bi bi-people mr-1" />Social</h3>
            ),
            children: socialMetrics,
        },
        hasGovernanceMetrics && {
            title: (
                <h3 className="text-center mb-0 p-1"><i className="bi bi-bank mr-1" />Governance</h3>
            ),
            children: governanceMetrics,
        },
    ].filter((value) => value);

    return (
        <section data-testid="portfolio-scorecard" className="table-responsive">
            <Table
                columns={tableHeaders}
                dataSource={currentYearResults.sort(sortResultsByCompany(companies))}
                pagination={false}
            />
        </section>
    );
}

export default PortfolioSingleYearResults;
