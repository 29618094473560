import * as React from 'react';
import {Suspense} from 'react';

import {Layout} from 'antd';

import LoaderOverlay from '@recoinsights/shared/components/LoaderOverlay';

import './Layout.scss';


const { Content } = Layout;
interface LayoutProps {
    selectedMenu?: string | null;
    onMenuClick?: (route: string) => void;
    children: any;
    hasHeader?: boolean;
    logout?: boolean;
    hasLink?: boolean;
    linkText?: string;
    linkUrl?: string;
}

export const globals = {
  refresh: ()=>false,
}

export default function DesktopLayout({
                                          children,
                                      }: LayoutProps) {
    return (
        <Layout
            hasSider={false}
            style={{ minHeight: '100vh' }}
            className={`desktop-layout`}
        >
            <Content className={'content-layout'}>
                <Suspense fallback={<LoaderOverlay size="large" loading={true}/>}>
                    {children}
                </Suspense>
            </Content>
        </Layout>
    );
}
