import React, {useState} from 'react';
import './index.scss';
import {Button, Checkbox, Col, Divider, Drawer, Input, notification, Row, Spin,} from 'antd';
import {DownloadOutlined, LoadingOutlined, MessageOutlined} from '@ant-design/icons';
import API_SERVICE from "../../../services/api-service";
import {AttachFile} from "@material-ui/icons";
import {FileUpload} from 'components';
import {getUser} from '@recoinsights/client/src/services/local-storage';
import moment from 'moment';
import {UploadFile} from "antd/es/upload/interface";

const antIcon = <LoadingOutlined style={{fontSize: 24}} spin/>;

export default function QuestionComments(props: any) {
    const [visible, setVisible] = React.useState(false);
    const [fetching, setFetching] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [remarks, setRemarks] = useState([]) as any[];
    const [input, setInput] = useState("") as any;
    const {TextArea} = Input;
    const [fileId, setFileId] = useState('');
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [remarkid, setRemarkid] = useState('') as any;
    const [fundManagerCheck, setFundManagerCheck] = useState() as any;
    const [managerCheck, setManagerCheck] = useState() as any;
    const [consultantCheck, setConsultantCheck] = useState() as any;
    const [auditorCheck, setAuditorCheck] = useState() as any;
    
    const roleId = getUser()?.user?.role?.id;

    const showDrawer = () => {
        setVisible(true);
        getQuestionRemarks();
        props.setActiveQuestionComments && props.setActiveQuestionComments(props.questionId);
    };

    const onClose = () => {
        setVisible(false);
        props.setActiveQuestionComments && props.setActiveQuestionComments(undefined);
    };


    const getQuestionRemarks = async () => {
        setFetching(true);
        try {
            const {data: {data}} = await API_SERVICE.getQuestionRemarks(props.surveyId, props.questionId);
            if(data) {
                setRemarks(data);
                setRemarkid(data.id);
                setFundManagerCheck(data.isApprovedByFundManager);
                setManagerCheck(data.isApprovedByManager);
                setConsultantCheck(data.isReviewedByConsultant);
                setAuditorCheck(data.isReviewedByAuditor);
            }
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        } finally {
            setFetching(false);
        }
    };

    const setApproval = async () => {
        try {
            await API_SERVICE.roleApproval(props.surveyId, props.questionId);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    }

    const postComments = async () => {
        setLoading(true);
        let reqObj: Record<string, string | number> = {
            "body": input,
        };

        if(fileId) {
            reqObj["attachmentId"] = fileId;
        }

        try {
            await API_SERVICE.postComments(props.surveyId, props.questionId, reqObj);
            setInput('');
            setFileId('');
            setFileList([]);
            getQuestionRemarks();
        } catch (e) {
            setInput('');
            notification.error({message: API_SERVICE.handleErrors(e)});
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Button className={`remark-button my-2 ${props.activeQuestionComments===props.questionId ? " active":""}`} onClick={() => showDrawer()}>
                <MessageOutlined/>
            </Button>

            
            <Drawer
                title="Remarks"
                width={425}
                onClose={onClose}
                visible={visible}
                className="comments-drawer-wrapper"
            >

                <div className="checkbox-wrapper">
                    <Row>
                        <Col xs={12}>
                            <Checkbox className="check-manager" onChange={(e) => {
                                setManagerCheck(e.target.checked);
                                setApproval();
                            }} checked={managerCheck} disabled={roleId !== 5}><p className='size-check-box'>Approved by
                                Manager?</p></Checkbox>
                        </Col>
                        <Col xs={12}>
                            <Checkbox className="check-manager" onChange={(e) => {
                                setFundManagerCheck(e.target.checked);
                                setApproval();
                            }} checked={fundManagerCheck} disabled={roleId !== 3}><p className='size-check-box'>Approved by Fund
                                Manager?</p></Checkbox>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <Checkbox className="check-manager" onChange={(e) => {
                                setConsultantCheck(e.target.checked);
                                setApproval();
                            }} checked={consultantCheck} disabled={roleId !== 6}><p className='size-check-box'>Received by
                                Consultant?</p></Checkbox>
                        </Col>
                        <Col xs={12}>
                            <Checkbox className="check-manager" onChange={(e) => {
                                setAuditorCheck(e.target.checked);
                                setApproval();
                            }} checked={auditorCheck} disabled={roleId !== 7}><p className='size-check-box'>Received by
                                Auditor?</p></Checkbox>
                        </Col>
                    </Row>
                </div>

                <Divider className="my-1"/>

                <div className="comment-wrapper">
                    {
                        fetching || loading ? <div className="text-center"><Spin indicator={antIcon}/></div> :
                            <>
                                {remarks && remarks.comments && remarks.comments.length > 0 && remarks.comments.map((comment: any, index: number) => {
                                    return (
                                        <div className='comment-item' key={index}>
                                            <p key={comment.id} className='comment'>
                                                {comment.body} {comment.attachmentId &&
                                            <span className='cursor-pointer download-file' onClick={()=>window.open(comment.attachment.url)}><DownloadOutlined/></span>}
                                            </p>
                                            <h5 key={comment.id} className='post-name'>
                                                {comment.user.name} ({comment?.user?.role?.name})
                                            </h5>
                                            <h5 key={comment.id} className='post-date'>
                                                {moment(comment.createdAt).format('lll')}   
                                            </h5>
                                        </div>
                                    );
                                })}
                            </>
                    }
                </div>

                <Divider className='my-1'/>
                <div className='footer'>
                    <div className='input-wrapper'>
                        <TextArea rows={3}  className='input' value={input} onChange={(e) => setInput(e.target.value)}/>
                    </div>
                    <FileUpload
                        onChange={(id: any) => setFileId(id)}
                        type={'DOCUMENT'}
                        setFileId={setFileId}
                        setFileList={setFileList}
                        fileList={fileList}
                    >
                        <span className="cursor-pointer"><AttachFile/></span>
                    </FileUpload>
                    <Button className='btn-reply' type="primary" onClick={postComments} block danger>
                        reply
                    </Button>
                </div>
            </Drawer>

        </>

    );
}

