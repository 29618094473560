import React, {useEffect, useState} from "react";
import {Button, Form, Input, notification, Pagination, Space, Table, Typography,} from "antd";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import API_SERVICE from "../../../services/api-service";
import {useTranslation} from "react-i18next";
import AccessControl from 'components/AccessControl';
import {ConfirmationModal} from "../../../components";

const { Text } = Typography;


export default function SurveyUnits() {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [units, setUnits] = useState([] as any[]);
    const [addNew, setAddNew] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [confirmationModal, setConfirmationModal] = useState({} as any);
    const [pagination, setPagination] = useState({page: 1, perpage: '10'} as any);
    const [total, setTotal] = useState(10);

    const columns = [
        {
            title: "Unit Year",
            dataIndex: "year",
            width: "120px",
            render: (a: any) => (
                <Text>{a}</Text>
            ),
        },
        {
            title: "Category",
            dataIndex: "category",
            width: 150,
            ellipsis: true,
            render: (a: any) => <Text>{a}</Text>,
        },
        {
            title: "Input Unit",
            dataIndex: "unit",
            width: 120,
            ellipsis: true,
            render: (a: any) => <Text>{a}</Text>,
        },
        {
            title: "Conversion Factor",
            dataIndex: "conversionFactor",
            width: 120,
            ellipsis: true,
            render: (a: any) => <Text>{a}</Text>,
        },
        {
            title: "Output Unit",
            dataIndex: "outputUnit",
            width: 120,
            ellipsis: true,
            render: (a: any) => <Text>{a}</Text>,
        },

        {
            title: "Action",
            key: "action",
            width: 120,
            render: (value: any) => (
                <AccessControl id = {25} closed={<div>-</div>}>
                <Button
                    type="text"
                    icon={<DeleteOutlined />}
                    onClick={() => {
                        setConfirmationModal({
                            message: `Delete Unit?`,
                            action: API_SERVICE.deleteUnit,
                            params: [value.id],
                        });
                    }}
                />
                </AccessControl>
            ),
        },
    ];

    const getSurveyUnits = async (page?: number, perPage? : number) => {
        const reqParams = {...pagination};
        if(page){
            reqParams['page'] = page;
        }
        if(perPage){
            reqParams['perpage'] = perPage;
        }
        setFetching(true);
        try {
            const {data: { data }} = await API_SERVICE.getUnits(reqParams);
            setUnits(data.items);
            setTotal(data.total);
            setPagination({page: data.page, perpage: data.perpage});
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }finally {
            setFetching(false)
        }
    };

    useEffect(() => {
        getSurveyUnits();
    }, []);

    return (
        <div>
            <AccessControl id = {25}>
            <Form
                form={form}
                fields={[]}
                layout="inline"
                onFinish={async (value: string) => {
                    setAddNew(true);
                    try {
                        // @ts-ignore
                        await API_SERVICE.createUnit(value);
                        notification.success({
                            message: ' Created Successfully',
                        });
                        form.resetFields();
                        getSurveyUnits();
                    } catch (e) {
                        notification.error({
                            message: t(API_SERVICE.handleErrors(e)),
                        });
                    } finally {
                        setAddNew(false);
                    }
                }}
                requiredMark={false}
            >
                <Space>
                    <Form.Item name="year">
                        <Input placeholder="enter unit year" />
                    </Form.Item>
                    <Form.Item name="category">
                        <Input placeholder="enter category" />
                    </Form.Item>
                    <Form.Item name="unit">
                        <Input placeholder="enter input unit" />
                    </Form.Item>
                    <Form.Item name="conversionFactor">
                        <Input placeholder="enter conversion factor" />
                    </Form.Item>
                    <Form.Item name="outputUnit">
                        <Input placeholder="output unit" />
                    </Form.Item>

                    <Form.Item>
                        <Button loading={addNew} type={'primary'} htmlType='submit' className='theme-button primary' icon={<PlusOutlined/>}>
                            Add New
                        </Button>
                    </Form.Item>

                </Space>
            </Form>
            </AccessControl>

            <AccessControl id = {26}>
                <Table
                    loading={fetching}
                    scroll={{ x: true, y: "calc(100vh - 340px)" }}
                    pagination={false}
                    dataSource={units}
                    columns={columns}
                    className="mt-2"
                />
                <div className="d-flex justify-content-end mt-2">
                    <Pagination current={pagination.page} pageSize={pagination.perpage} pageSizeOptions={[10,20,50]} showSizeChanger showQuickJumper total={total}
                                onChange={(page, size) => {
                                    setPagination({page: page, perpage: size});
                                    getSurveyUnits(page, size);
                                }}/>
                </div>
            </AccessControl>

            <ConfirmationModal {...confirmationModal}
                               callback={(a: boolean) => {
                                   setConfirmationModal({})
                                   if (a) {
                                       getSurveyUnits();
                                   }
                               }}/>
        </div>
    );
}


