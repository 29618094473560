import {ResultSet} from "@recoinsights/types";
import {EmissionsIntensity, Metrics, resolveMetricResults} from "@recoinsights/metrics";
import {Column} from "@ant-design/charts";

enum Labels {
    Year = "Year",
    Emissions = "Emissions (tCO2e)",
    Type = "Type",
    Scope1 = "Scope 1",
    Scope2 = "Scope 2",
    Scope3 = "Scope 3",
    Total = "Total",
}

interface Props {
    results: ResultSet[];
}

function EmissionsChart({ results }: Props) {
    const barData: { [Labels.Year]: number, [Labels.Emissions]?: number, [Labels.Type]: string, }[] = [];

    results.forEach((result) => {
        const metric = resolveMetricResults(Metrics.EmissionsIntensity, result) as EmissionsIntensity;

        const scope1Emissions = metric.getScope1Emissions();
        const scope2Emissions = metric.getScope2Emissions();
        const scope3Emissions = metric.getScope3Emissions();

        barData.push({
            [Labels.Year]: result.year,
            [Labels.Emissions]: scope1Emissions ? Math.round(scope1Emissions / 1000) : undefined,
            [Labels.Type]: Labels.Scope1,
        });

        barData.push({
            [Labels.Year]: result.year,
            [Labels.Emissions]: scope2Emissions ? Math.round(scope2Emissions / 1000) : undefined,
            [Labels.Type]: Labels.Scope2,
        });

        barData.push({
            [Labels.Year]: result.year,
            [Labels.Emissions]: scope3Emissions ? Math.round(scope3Emissions / 1000) : undefined,
            [Labels.Type]: Labels.Scope3,
        });
    });

    return (
        <Column
            data={barData}
            isStack={true}
            xField={Labels.Year}
            yField={Labels.Emissions}
            seriesField={Labels.Type}
            legend={{ position: "right-bottom", maxWidthRatio: 0.33, padding: [0, 0, 0, 30], itemHeight: 10, }}
            height={200}
        />
    );
}

export default EmissionsChart;
