import React from "react";
import {Metric} from "@recoinsights/metrics";
import ScoreComparisonIndicator from "./ScoreComparisonIndicator";

interface Props {
    currentResult: Metric;
    previousResult?: Metric;
}

function Score({ currentResult, previousResult }: Props) {
    previousResult && console.log(currentResult.compare(previousResult));

    return (
        <>
            <p className={"mb-0"} style={{ fontSize: "3rem" }}>{currentResult.renderScore()}</p>
            {previousResult && (
                <p className={"mb-0"} style={{ color: "grey" }}>
                    <ScoreComparisonIndicator metricResultA={currentResult} metricResultB={previousResult} />
                    &nbsp;
                    {currentResult.renderDifference(currentResult.compare(previousResult))}
                </p>
            )}
        </>
    )
}

export default Score;
