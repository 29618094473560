import React, {useEffect, useState} from 'react';
import ReactQuill from "react-quill";
import {Checkbox, Col, Divider, Form, Input, Popconfirm, Row, Select, Space} from "antd";
import {CopyOutlined, DeleteOutlined, PlusSquareFilled} from "@ant-design/icons";
import AddQuestionMetricComponent from "./AddQuestionMetricComponent";

const {Option} = Select;

export default function SimpleQuestionCard({question, unitCategories, questionIndex, validationTypeAndCondition, isModifiable, isGHGDefaultQuestion, questionNumber, deleteQuestion, handleFormValueChange, allQuestionUUIDs, cloneQuestion, contentMetrics, deleteQuestionMetric, addQuestionMetric, handleContentMetricChange}: any) {
    const [form] = Form.useForm();
    const [currentConditionList, setCurrentConditionList] = useState([] as any []);
    const [selectedCondition, setSelectedCondition] = useState([] as any []);
    const [isValidationRequired, setIsValidationRequired] = useState(false as boolean);
    const [isAttachmentAllowed, setIsAttachmentAllowed] = useState(false as boolean);
    const [isRequired, setIsRequired] = useState(false as boolean);
    const [isMaster, setIsMaster] = useState(false as boolean);
    const [questionTitle, setQuestionTitle] = useState('' as string);

    const handleValidationTypeChange = (value:any, resetCondition: boolean) => {
        resetCondition && form.setFieldsValue({validationCondition: undefined})
        let currentConditions = validationTypeAndCondition.filter((item: any) => {
            return item.value === value
        })

        setCurrentConditionList(currentConditions[0].conditions);
        if(!resetCondition){
            handleConditionChange(question.validations[0].condition, currentConditions[0].conditions);
        }else{
            setSelectedCondition([]);
            // form.setFieldsValue({validation_condition: undefined})
        }
    }

    const handleConditionChange = (value:any, initialConditions?: any) => {
        let selectedCondition;

        if(initialConditions && initialConditions.length> 0){
            selectedCondition = initialConditions.filter((item: any) => {
                return item.value === value
            })
        }else{
            selectedCondition = currentConditionList.filter((item: any) => {
                return item.value === value
            })
        }
        setSelectedCondition(selectedCondition[0].input);
    }


    useEffect(() => {
        if(question.validations.length){
            handleValidationTypeChange(question.validations[0].type, false);
            handleFormValueChange(question.uuid, 'validationType', question.validations[0].type);
            handleFormValueChange(question.uuid, 'validationCondition', question.validations[0].condition);
            question.validations[0].input1 && handleFormValueChange(question.uuid, 'input1', question.validations[0].input1)
            question.validations[0].input2 && handleFormValueChange(question.uuid, 'input2', question.validations[0].input2)
        }
        setIsValidationRequired(question.isValidationRequired)
        setIsAttachmentAllowed(question.isAttachmentAllowed);
        setIsRequired(question.isRequired);
        setIsMaster(question.isMaster);
        setQuestionTitle(question.question);
    }, []);

    return (
        <Form form={form} className="question-wrapper" initialValues={question} id={question.uuid}>
            <div className="d-flex align-items-center justify-content-between">
                <h4 className="quesid">#ques-id-{questionNumber} - simple</h4>
                <div>
                    <div className='d-flex'>
                        <span className="text-primary mr-1">*</span><Form.Item name="orderNo"><Input placeholder="Question order no" disabled={isGHGDefaultQuestion ? false : !isModifiable}  onChange={(event) => {handleFormValueChange(question.uuid, 'orderNo', event.target.value)}} /></Form.Item>
                    </div>
                </div>
            </div>
            <span className="text-primary">*</span>
            <div className="quill-container">
                <ReactQuill className={`survey-quill ${(isGHGDefaultQuestion ? '' : !isModifiable && 'disabled')}`} theme="snow" placeholder="type question title" value={questionTitle}
                            onChange={(value) => {setQuestionTitle(value); handleFormValueChange(question.uuid, 'question', value);}}
                            readOnly={isGHGDefaultQuestion ? false : !isModifiable}
                            modules={{
                                toolbar: {
                                    container: [
                                        [{'header': [1, 2, 3, 4, 5, 6, false]}],
                                        ['bold', 'italic', 'underline'],
                                        [{'list': 'ordered'}, {'list': 'bullet'}],
                                        [{'align': []}],
                                        ['link', 'image']
                                    ]
                                }
                            }}
                />
            </div>

            <Form.Item className="mt-1" name='description'>
                <Input placeholder="type question description" disabled={isGHGDefaultQuestion ? false : !isModifiable} onChange={(event) => {handleFormValueChange(question.uuid, 'description', event.target.value)}} />
            </Form.Item>
            <Form.Item name='toolTipText'>
                    <Input placeholder="type question hint/tooltip text" disabled={isGHGDefaultQuestion ? false : !isModifiable} onChange={(event) => {handleFormValueChange(question.uuid, 'toolTipText', event.target.value)}} />
            </Form.Item>

            <Form.Item name={'unitCategory'} className={'d-inline-block'}>
                <Select placeholder={'select unit category'} style={{minWidth: 150}} disabled={isGHGDefaultQuestion ? false : !isModifiable}  onChange={(value) => {handleFormValueChange(question.uuid, 'unitCategory', value)}}>
                    {
                        unitCategories.map((category: any, index: number) => {
                            return <Select.Option value={category} key={index}>{category}</Select.Option>;
                        })
                    }
                </Select>
            </Form.Item>

            <Divider className="my-2" />

            <Row>
                <Form.Item name={'isValidationRequired'}>
                    <Checkbox disabled={isGHGDefaultQuestion ? false : !isModifiable} value={true} checked={isValidationRequired} onChange={(e: any)=> {setIsValidationRequired(e.target.checked); handleFormValueChange(question.uuid, 'isValidationRequired', e.target.checked);}}><span style={{whiteSpace: 'nowrap'}}>Response Validation</span></Checkbox>
                </Form.Item>
                <Form.Item name={'isAttachmentAllowed'} className="ml-2">
                    <Checkbox disabled={isGHGDefaultQuestion ? false : !isModifiable} value={true} checked={isAttachmentAllowed} onChange={(e: any)=> {setIsAttachmentAllowed(e.target.checked); handleFormValueChange(question.uuid, 'isAttachmentAllowed', e.target.checked);}}>Allow Attachment?</Checkbox>
                </Form.Item>
                <Form.Item name={'isRequired'} className="ml-2">
                    <Checkbox disabled={isGHGDefaultQuestion ? false : !isModifiable} value={true} checked={isRequired} onChange={(e: any)=> {setIsRequired(e.target.checked); handleFormValueChange(question.uuid, 'isRequired', e.target.checked);}}>Required</Checkbox>
                </Form.Item>
            </Row>

            {
                isValidationRequired &&
                <Row>
                    <Form.Item name={'validationType'} initialValue={question.validations.length ? question.validations[0].type : undefined}>
                        <Select onChange={(value) => {handleValidationTypeChange(value, true); handleFormValueChange(question.uuid, 'validationType', value);}} placeholder='Validation Type' disabled={isGHGDefaultQuestion ? false : !isModifiable}>
                            {
                                validationTypeAndCondition && validationTypeAndCondition.length> 0 && validationTypeAndCondition.map((validation: any, i: number) => {
                                    return <Option value={validation.value}>{validation.name}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item name={'validationCondition'} className="ml-2" initialValue={question.validations.length ? question.validations[0].condition : undefined}>
                        <Select onChange={(value) => {handleConditionChange(value); handleFormValueChange(question.uuid, 'validationCondition', value);}} placeholder='Condition' disabled={isGHGDefaultQuestion ? false : !isModifiable}>
                            {
                                currentConditionList && currentConditionList.length> 0 && currentConditionList.map((condition: any, i: number) => {
                                    return <Option value={condition.value} key={i}>{condition.name}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>

                    {
                        selectedCondition.includes(1) &&
                        <Form.Item name={'input_1'} className="ml-2" initialValue={question.validations.length ? question.validations[0].input1 : ''} >
                            <Input placeholder="Enter value" disabled={isGHGDefaultQuestion ? false : !isModifiable} onChange={(event) => {handleFormValueChange(question.uuid, 'input1', event.target.value)}} />
                        </Form.Item>
                    }

                    {
                        selectedCondition.includes(2) &&
                        <Form.Item name={'input_2'} className="ml-2" initialValue={question.validations.length ? question.validations[0].input2 : ''} >
                            <Input placeholder="Enter value 2" disabled={isGHGDefaultQuestion ? false : !isModifiable} onChange={(event) => {handleFormValueChange(question.uuid, 'input2', event.target.value)}} />
                        </Form.Item>
                    }
                </Row>
            }

            <Divider className="my-2" />

            <Row>
                <Col flex={1}>
                    <AddQuestionMetricComponent
                        key={`add${question.uuid}`}
                        question={question}
                        isModifiable={isModifiable}
                        contentMetrics={contentMetrics}
                        deleteQuestionMetric={deleteQuestionMetric}
                        handleContentMetricChange={handleContentMetricChange}
                        form={form}
                        addQuestionMetric={addQuestionMetric}
                    />
                </Col>
                <Col flex={1}>
                    <p className="mb-1">Reveals next question:</p>

                    <Row>
                        <Col>
                            <Form.Item name={'nextQuestionUuid'}>
                                <Select disabled={isGHGDefaultQuestion ? false : !isModifiable} placeholder="Next Question" onChange={(value) => {handleFormValueChange(question.uuid, 'nextQuestionUuid', value);}}>
                                    <Option value={null}>None</Option>
                                    {
                                        allQuestionUUIDs && allQuestionUUIDs.length > 0 && allQuestionUUIDs.map((questionUUID: any, i: number) => {
                                            return <Option value={questionUUID.value} key={i}>{questionUUID.name}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Divider className="my-2" />

            <Row className="align-items-center justify-content-end">
                <Form.Item name={'isMaster'}>
                    <Checkbox disabled={isGHGDefaultQuestion ? false : !isModifiable} value={true} checked={isMaster} onChange={(e: any)=> {setIsMaster(e.target.checked);  handleFormValueChange(question.uuid, 'isMaster', e.target.checked);}}>Add to master</Checkbox>
                </Form.Item>
                {
                    isModifiable &&
                        <>
                            <span className="cursor-pointer d-inline-block mb-2" onClick={()=> {cloneQuestion(question, questionIndex)}}><CopyOutlined/></span>
                            <span className="d-inline-block mb-2">
                                <Popconfirm title="Sure to delete question?" onConfirm={() => deleteQuestion(question.uuid, question.id || null)}><DeleteOutlined /></Popconfirm>
                            </span>
                        </>
                }
            </Row>
        </Form>
    )
}
