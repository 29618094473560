import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import 'antd/dist/antd.css';
import '../index.scss';
import './index.scss';
import {Button, Input, InputNumber, notification, Popconfirm, Select, Space, Tooltip, Typography,} from "antd";
import {InfoCircleOutlined, MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import API_SERVICE from "../../../../services/api-service";
import {getScopeTableData} from "@recoinsights/shared/services/Utility";
import QuestionComments from "../QuestionComments";


const {Option} = Select;
const {Text} = Typography;
export default function ScopeThreeTable1GHGQuestion({questionItem, j, roleId, surveyId, activeQuestionComments, setActiveQuestionComments, surveyYear}: any) {
    const location = useLocation();
    const [countries, setCountries] = useState([] as any []);
    const [category, setCategories] = useState([] as any []);

    const getCountries = async () => {
        try {
            const {data: {data}} = await API_SERVICE.getCountries();
            setCountries(data.items);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };

    const getScopeCategories = async () => {
        const params = {
            year: surveyYear,
            scope: 'scope3',
            table_mapper: 'table1',
            get: 'customer_category'
        }
        try {
            const {data: {data}} = await API_SERVICE.getGHGQuestionTableData(params);
            setCategories(data);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };

    const [rows, setRows] = useState(getScopeTableData(questionItem.id) as any[]);
    const _validateLastRow = () => {
        // TODO validate Last Row else throw error
        return true;
    }
    useEffect(() => {
        getCountries();
        getScopeCategories();
    }, []);

    return (
        <div className="question-card" key={j}>
            <h4 className="question">
                {questionItem.question}
                {!questionItem.description && questionItem.toolTipText &&
                <Tooltip className="pl-1 cursor-pointer text-primary"
                         title={questionItem.toolTipText}><InfoCircleOutlined/></Tooltip>}
            </h4>
            {questionItem.description && <small>{questionItem.description}</small>}
            {questionItem.description && questionItem.toolTipText &&
            <Tooltip className="pl-1 cursor-pointer text-primary" title={questionItem.toolTipText}><InfoCircleOutlined/></Tooltip>}
            <div style={{width: '100%', overflowY: 'auto'}}>
                {rows && rows.length > 0 && <table className="custom-scope-data-table">
                    <thead>
                        <tr>
                            <td style={{minWidth: 50}} />
                            <td style={{minWidth: 50}}>S.No</td>
                            <td>Description</td>
                            <td>Country</td>
                            <td>Travel Type</td>
                            <td>Transport Type</td>
                            <td>Amount</td>
                            <td>Unit</td>
                            <td>GHG Factor</td>
                            <td>KgCO2e GHG Emission</td>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        rows.map((row, index: number) => {
                            return <TablesRow
                                year={surveyYear}
                                value={row}
                                categories={category}
                                countries={countries}
                                roleId={roleId}
                                handleDelete={
                                    () => {
                                        const temp = [...rows]
                                        temp.splice(index, 1);
                                        const scopeTableData = localStorage.getItem('scopeTableData') ? JSON.parse(localStorage.getItem('scopeTableData') as string) : {};
                                        scopeTableData[questionItem.id] = temp;
                                        localStorage.setItem('scopeTableData', JSON.stringify(scopeTableData));
                                        setRows(temp)
                                    }
                                } index={index} disabled={index < (rows.length - 1)}
                                onChange={
                                    (e: any) => {
                                        const temp = [...rows];
                                        temp[index] = e;
                                        setRows(temp)
                                        const scopeTableData = localStorage.getItem('scopeTableData') ? JSON.parse(localStorage.getItem('scopeTableData') as string) : {};
                                        scopeTableData[questionItem.id] = temp;
                                        localStorage.setItem('scopeTableData', JSON.stringify(scopeTableData));
                                    }
                                }/>
                        })
                    }
                    </tbody>
                </table>}
            </div>

            <Button type={'primary'} htmlType='submit'
                    disabled={(roleId === 4 && (location.hash === '#edit')) ? false : true}
                    className='theme-button primary mt-3' onClick={async () => {
                const isLasRowValid = _validateLastRow();
                if (isLasRowValid) {
                    setRows([...rows, {}]);
                } else {
                    notification.error({message: 'Last row is invalid'});
                }
            }}
                    icon={<PlusOutlined/>}>
                Add Row
            </Button>
            <QuestionComments surveyId={surveyId} questionId={questionItem.id}
                              activeQuestionComments={activeQuestionComments}
                              setActiveQuestionComments={setActiveQuestionComments}/>
        </div>

    );
}
const TablesRow = ({index, year, categories, countries, onChange, handleDelete, value, roleId}: any) => {
    const location = useLocation();
    const [fuels, setFuels] = useState([]);
    const [factorDetails, setGHGFactorDetails] = useState(null as any);
    const [GHGFactorData, setGHGFactorData] = useState({} as any);

    const [units, setUnits] = useState([]);

    const getGHGQuestionTableData = async (year: any, get: any, optional: any = {
        category: '',
        fuelType: '',
        unit: ''
    }) => {
        const params: Record<string, string | number> = {
            year: year,
            scope: 'scope3',
            table_mapper: 'table1',
            get: get
        }

        if (optional.category) {
            params['customer_category'] = optional.category;
        }

        if (optional.fuelType) {
            params['entity'] = optional.fuelType;
        }

        if (optional.unit) {
            params['input_unit'] = optional.unit;
        }
        try {
            const {data: {data}} = await API_SERVICE.getGHGQuestionTableData(params);
            if (get === 'entity') {
                setFuels(data);
            } else if (get === 'input_unit') {
                setUnits(data);
            } else if (get === 'ghg_factor_kgco2e') {
                setGHGFactorDetails(data[0].ghgFactorKgco2e);
                setGHGFactorData(data[0]);
            }
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };
    useEffect(() => {
        if (factorDetails || factorDetails === 0) {
            handleChange(factorDetails, 'ghgFactor')
        }
    }, [factorDetails, GHGFactorData]);

    const handleChange = (_value: any, key: any, resetKeys?: string[]) => {
        const _temp = {...value, [key]: _value, ...GHGFactorData};
        if (!isNaN(_temp.ghgFactor * _temp.amount)) {
            _temp['temp_energyFactoryKwh'] = _temp.ghgFactor * _temp.amount
        }
        if (resetKeys) {
            resetKeys.forEach((k) => {
                delete _temp[k]
            })
        }
        onChange(_temp);
        if (key === 'travelType') {
            setFuels([])
            setUnits([])
            getGHGQuestionTableData(year, 'entity', {category: _value});
        }
        if (key === 'transportType') {
            setUnits([])
            getGHGQuestionTableData(year, 'input_unit', {
                category: value.travelType,
                fuelType: _value
            });
        }
        if (key === 'unit') {
            getGHGQuestionTableData(year, 'ghg_factor_kgco2e', {
                category: value.travelType,
                fuelType: value.transportType,
                unit: _value
            });
        }
    }
    return <tr>
        <td>
            {
                <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete()}>
                    <div className='text-primary cursor-pointer text-center'><MinusCircleOutlined/></div>
                </Popconfirm>
            }
        </td>
        <td>
            <Text> {index + 1}</Text>
        </td>
        <td>
            <Space size="middle">
                <div style={{width: 150}}><Input disabled={(!(roleId === 4 && (location.hash === '#edit')))}
                                                 value={value.description}
                                                 placeholder="enter description"
                                                 onChange={(e) => {
                                                     handleChange(e.target.value, 'description')
                                                 }}/></div>
            </Space>
        </td>


        <td>
            <Space size="middle">
                <div><Select showSearch={true} disabled={(!(roleId === 4 && (location.hash === '#edit')))}
                             value={value.countryId} placeholder="select"
                             style={{width: 150}}
                             onChange={(e) => {
                                 handleChange(e, 'countryId',)
                             }}>
                    {
                        countries.map((item: any, index: number) => {
                            return <Option value={item.name} key={index}>{item.name}</Option>
                        })
                    }
                </Select></div>
            </Space>
        </td>
        <td>
            <Space size="middle">
                <div><Select showSearch={true} placeholder="select"
                             disabled={(!(roleId === 4 && (location.hash === '#edit')))}
                             value={value.travelType} style={{width: 200}}
                             onChange={(e) => {
                                 handleChange(e, 'travelType', ['transportType', 'unit', 'ghgFactor'])

                             }}>
                    {
                        categories && categories.map((item: any, index: number) => {
                            return <Option value={item} key={index}>{item}</Option>
                        })
                    }
                </Select></div>
            </Space>
        </td>
        <td>
            <Space size="middle">
                <div><Select showSearch={true} placeholder="select"
                             disabled={(!(roleId === 4 && (location.hash === '#edit')))}
                             value={value.transportType} style={{width: 150}}
                             onChange={(e) => {
                                 handleChange(e, 'transportType', ['unit', 'ghgFactor'])
                             }}
                             onFocus={() => {
                                 fuels.length < 1 && value.travelType && getGHGQuestionTableData(year, 'entity', {category: value.travelType});
                             }}>
                    {
                        fuels && fuels.map((item: any, index: number) => {
                            return <Option value={item} key={index}>{item}</Option>
                        })
                    }
                </Select></div>
            </Space>
        </td>
        <td>
            <Space size="middle">
                <div style={{width: 100}}><InputNumber disabled={(!(roleId === 4 && (location.hash === '#edit')))}
                                                       value={value.amount} placeholder="enter amt."
                                                       onChange={(e) => {
                                                           handleChange(e, 'amount',)

                                                       }}/></div>
            </Space>
        </td>
        <td>
            <Space size="middle">
                <div><Select showSearch={true} placeholder="select"
                             disabled={(!(roleId === 4 && (location.hash === '#edit')))}
                             value={value.unit} style={{width: 150}}
                             onChange={(e) => {
                                 handleChange(e, 'unit',)
                             }}
                             onFocus={() => {
                                 units.length < 1 && value.travelType && value.transportType && getGHGQuestionTableData(year, 'input_unit', {
                                     category: value.travelType,
                                     fuelType: value.transportType
                                 });
                             }}>
                    {
                        units && units.map((item: any, index: number) => {
                            return <Option value={item} key={index}>{item}</Option>
                        })
                    }
                </Select></div>
            </Space>
        </td>
        <td>
            <Space size="middle">
                <div style={{width: 150}}><InputNumber
                    value={value.ghgFactor} placeholder="--" disabled={true}/></div>
            </Space>
        </td>

        <td>
            <Space size="middle">
                <div style={{width: 150}}><InputNumber
                    value={
                        value.temp_energyFactoryKwh
                            ? value.temp_energyFactoryKwh
                            : (
                                (!isNaN(value.ghgFactor * value.amount))
                                    ? value.ghgFactor * value.amount
                                    : '--'
                            )
                    }
                    placeholder="--" disabled={true}/></div>
            </Space>
        </td>
    </tr>
}
