import React, {useEffect, useState} from 'react';
import './index.scss';
import {Carousel, Col, notification, Row, Typography} from 'antd';
import AdminListLayout from '@recoinsights/shared/components/layout/AdminListLayout/AdminListLayout';
import Title from 'antd/lib/typography/Title';
import API_SERVICE from "../../../services/api-service";

import docCardBg from "@recoinsights/shared/assets/images/docCardBg.png"
import videoCardBg from "@recoinsights/shared/assets/images/videoCardBg.png"


const { Text, Link } = Typography;

const SliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    // slidesToShow: 5,
    // slidesToScroll: 1,
    draggable: true,
    arrows: true
};


export default function Insights(props: any) {
    const [videos, setVideos] = useState([] as any);
    const [docs, setDocs] = useState([] as any);


    const getVideos = async () => {
        try {
            const {data: {data}} = await API_SERVICE.getFiles({extensions: 'mp4|mov', page: -1, order: 'asc'});
            setVideos(data.items || []);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };

    const getDocs = async () => {
        try {
            const {data: {data}} = await API_SERVICE.getFiles({exclude_extensions: 'mp4|mov' , page: -1, order: 'asc'});
            setDocs(data.items || []);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };
    useEffect(() => {
        getVideos();
        getDocs();
    }, []);
    return (

        <div className={'group-wrapper page-wrapper'}>
            {/*@ts-ignore*/}
            <AdminListLayout title={'Insights'}>
                <Row>
                    <Col sm={24}>
                        <div className="p-2">
                            <Title level={4}>Market Intelligence</Title>
                            <Text>Read Future in Sight quarterly market intelligence reports to find signal in the ESG noise</Text>
                            <div className="site-card-wrapper">
                                {
                                    docs && docs.length < 5 ?
                                      <Row gutter={16}>
                                 {
                                        docs.map((item: any, index: number) => {
                                            return  <Col className="image-space" span={6}><Link key={index} href={item.url} target="_blank">
                                                {/*<img src={docCardBg} alt="doc" />
                                                <div className="text-center text-truncate">{item.name}</div>*/}

                                                {
                                                    item.fileMaster && item.fileMaster.thumbnail && item.fileMaster.thumbnail.url
                                                        ? <img src={item.fileMaster.thumbnail.url} alt="doc" height="217" className="img-fluid"  />
                                                        : <img src={docCardBg} alt="doc" />
                                                }
                                                <div className="text-center text-truncate">{(item.fileMaster && item.fileMaster.name) ? item.fileMaster.name : ''}</div>
                                            </Link></Col>
                                        })
                                    }
                                    </Row>
                                
                                                             
                                    
                                                    
                               :<Carousel {...SliderSettings} slidesToShow={docs.length < 5 ? docs.length : 5} slidesToScroll={docs.length < 5 ? docs.length : 5} >
                                        {
                                            docs.map((item: any, index: number) => {
                                                return <Link className="carousel-card" key={index} href={item.url} target="_blank">
                                                    {/*<img src={docCardBg} alt="doc" />
                                                    <div className="text-center text-truncate">{item.name}</div>*/}

                                                    {
                                                        item.fileMaster && item.fileMaster.thumbnail && item.fileMaster.thumbnail.url
                                                            ? <img src={item.fileMaster.thumbnail.url} alt="doc" height="217" className="img-fluid" />
                                                            : <img src={docCardBg} alt="doc" />
                                                    }
                                                    <div className="text-center text-truncate">{(item.fileMaster && item.fileMaster.name) ? item.fileMaster.name : ''}</div>
                                                </Link>
                                            })
                                        }
                                    </Carousel>
                                }
                            </div>

                            <Title className="mt-5" level={4}>Video Briefings</Title>
                            <Text>Save time getting up to speed on key ESG concepts by watching these customized video briefings </Text>
                            <div className="site-card-wrapper">
                                {
                                    videos && videos.length < 5  ?
                                    <Row gutter={16}>
                                {
                                        videos.map((item: any, index: number) => {
                                       
                                            return <Col className="image-space" span={6}> <Link key={index} href={item.url}target="_blank">
                                                {
                                                    item.fileMaster && item.fileMaster.thumbnail && item.fileMaster.thumbnail.url
                                                        ? <img src={item.fileMaster.thumbnail.url} alt="doc" height="124" className="img-fluid" />
                                                        : <img src={videoCardBg} alt="doc" />
                                                }
                                                <div className="text-center text-truncate">{(item.fileMaster && item.fileMaster.name) ? item.fileMaster.name : ''}</div>
                                            </Link></Col>
                                        })
                                    }
                                    </Row>
                                    
                                    
                                    
                                    
                                   : <Carousel {...SliderSettings} slidesToShow={videos.length < 5 ? videos.length : 5} slidesToScroll={videos.length < 5 ? videos.length : 5} >
                                        {
                                            videos.map((item: any, index: number) => {
                                                return <Link className="carousel-card" key={index} href={item.url} target="_blank">
                                                    {/*<img src={videoCardBg} alt="doc" />
                                                    <div className="text-center text-truncate">{item.name}</div>*/}

                                                    {
                                                        item.fileMaster && item.fileMaster.thumbnail && item.fileMaster.thumbnail.url
                                                            ? <img src={item.fileMaster.thumbnail.url} alt="doc" height="124" className="img-fluid"/>
                                                            : <img src={videoCardBg} alt="doc" />
                                                    }
                                                    <div className="text-center text-truncate">{(item.fileMaster && item.fileMaster.name) ? item.fileMaster.name : ''}</div>
                                                </Link>
                                            })
                                        }
                                    </Carousel>
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
            </AdminListLayout>
        </div>
    );
}
