import {Select} from "antd";
import React from "react";

import {useRouter} from "../../helpers/location";
import FundInterface from "../../../../shared/types/Fund";
import {sortFundsByName} from "../../helpers/portfolio";

interface Props {
    groupId?: number;
    fundId?: number;
    funds: FundInterface[];
}

function SelectFund({ groupId, fundId, funds }: Props) {
    const { navigate, route } = useRouter();

    const options = funds
        .filter((fund) => groupId ? fund.pefId === groupId : true)
        .sort(sortFundsByName);

    function handleFundChange(value: number|null) {
        navigate(route({
            searchParams: {
                fundId: value,
            }
        }));
    }

    return (
        <Select value={fundId}
                showSearch={true}
                filterOption={true}
                placeholder={'Select Fund'}
                style={{minWidth: 150}}
                onChange={(value) => {
                    handleFundChange(value)
                }}
                data-testid="funds-dropdown"
        >
            {options.length > 1 && <Select.Option data-testid="all-option" value={null}>All funds</Select.Option>}
            {
                options.map((fund: any) => {
                    return <Select.Option
                        value={fund.id} key={fund.id}>{fund.name}</Select.Option>;
                })
            }
        </Select>
    );
}

export default SelectFund;
