import {ResultSet} from "@recoinsights/types";
import {BoardMaturity, Metrics, resolveMetricResults} from "@recoinsights/metrics";
import {Column} from "@ant-design/charts";
import CompanyInterface from "../../../../shared/types/Company";
import {getCompany, sortResultsByCompany} from "../../helpers/portfolio";
import React from "react";

enum Labels {
    Year = "Year",
    Score = "Score",
    Company = "Company",
}

interface Props {
    companies: CompanyInterface[];
    results: ResultSet[];
}

function PortfolioResultsBoardMaturityChart({ companies, results }: Props) {
    const barData: { [Labels.Year]: number, [Labels.Score]?: number, [Labels.Company]: string }[] = [];

    results.sort(sortResultsByCompany(companies)).forEach((result) => {
        const metric = resolveMetricResults(Metrics.BoardMaturity, result) as BoardMaturity;

        barData.push({
            [Labels.Year]: result.year,
            [Labels.Score]: metric.score !== undefined ? Math.round(metric.score * 100) : undefined,
            [Labels.Company]: getCompany(companies, result.company)?.name ?? '-',
        });
    });

    return (
        <Column
            data={barData}
            isGroup={true}
            xField={Labels.Year}
            yField={Labels.Score}
            seriesField={Labels.Company}
            legend={{ position: "right-bottom", maxWidthRatio: 0.33, padding: [0, 0, 0, 30], itemHeight: 10, }}
        />
    );
}

export default PortfolioResultsBoardMaturityChart;
