import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, notification, Row, Select, Typography} from "antd";
import Header from '../../../components/Header';
import {getUser} from '../../../services/local-storage';
import API_SERVICE from '../../../services/api-service';
import "./index.scss";

const editMode = [1,2].includes(getUser()?.user?.role?.id);
var esgObj: any = [];

const EsgWeightages = (props: any) => {
  const { Text } = Typography;
  const {match: {params: { fundId }}} = props;

  const arrYears = [{ year: 2020 }, { year: 2021 }, { year: 2022 }];

  const [fundDetails, setFundDetails] = useState <any> ({})
  const [years] = useState(arrYears);
  const [fundYear, setFundYear] = useState(2022);
  const [industries, setIndustries] = useState <any[]> ([]);
  const [industryId, setIndustryId] = useState <number> (0);
  const [esgWeightages, setEsgWeightages] = useState <any[]> ([]);
  const [esgData, setEsgData] = useState <any[]> (esgObj);

  const getFundDetails = async () => {
      try {
          const {data: {data}} = await API_SERVICE.getFunds({id: fundId});
          if (data.items.length) {
            setFundDetails(data.items[0]);
          }
      } catch (e) {
          notification.error({message: API_SERVICE.handleErrors(e)});
      }
  };

  const getIndustries = async () => {
      try {
          const {data: {data}} = await API_SERVICE.getEsgIndustries(fundId, fundYear);
          setIndustries(data.items);
          if (data.items.length) {
            setIndustryId(data.items[0]['id']);
          }
      } catch (e) {
          notification.error({message: API_SERVICE.handleErrors(e)});
      }
  };

  const getEsgWeightages = async () => {
      try {
          setEsgWeightages([]);
          esgObj = [];
          setEsgData(esgObj);
          const {data: {data}} = await API_SERVICE.getEsgWeightages(
            fundId,
            {
              year: fundYear,
              industryId: industryId
            }
          );
          if (data.weightages) {
            setEsgWeightages(data.weightages);
          }
      } catch (e) {
          notification.error({message: API_SERVICE.handleErrors(e)});
      }
  };

  const saveEsgWeightages = async () => {
      let dkpi_error_flag = false;
      let esg_percentage = 0
      esgData.map((metric, i) => {
        esg_percentage += parseInt(metric.percentage);
        if (metric.dkpis[metric.dkpis.length-1]['kpiPercentage'] === 'Error') {
          dkpi_error_flag = true;
        }
      })
      if (dkpi_error_flag) {
        notification.error({message: "Invalid KPI values"});
      }
      else if (esg_percentage !== 100) {
        notification.error({message: "Invalid Metric values"});
      }
      else if (esgData.length) {
          try {
              await API_SERVICE.setEsgWeightages(
                fundId,
                {
                  "year": fundYear,
                  "industryId": industryId,
                  "weightages": esgData
                }
              );
              await getEsgWeightages();
              notification.success({ message: 'Saved Successfully' })
          } catch (e) {
              notification.error({message: API_SERVICE.handleErrors(e)});
          }
      }
      else {
        console.log('esgData',esgData)
      }
  };

  useEffect(()=>{
    getFundDetails();
  },[])

  useEffect(()=>{
    getIndustries();
  },[fundYear])

  useEffect(()=>{
    if (fundYear && industryId) {
      getEsgWeightages();
    }
  },[fundYear, industryId])

  useEffect(()=>{
    setEsgData(esgObj);
  },[esgWeightages])


  return (
    <div className="page-wrapper Benchmarking-role-wrapper">
      <Row
        justify="space-between"
        align="middle">
        <Col>
          <Row align="middle">
            <Header
              headings={[
                "ESG Weightages",
                fundDetails.name,
                fundDetails.pef && fundDetails.pef.name,
              ]} />
            <Input
              disabled
              defaultValue="100"
              style={{ width: 100, margin: 8 }}
              addonAfter="%"
              size="large" />
            <Text>Total ESG (100%)</Text>
          </Row>
        </Col>
        <Col style={{padding: 8}}>
          <div className="d-flex align-self-center">
            <Form layout="horizontal">
              <Form.Item label="Select Year & Industry:">
                <Select placeholder="Year" value={fundYear} onChange={(i)=>setFundYear(i)}>
                  {years.map((obj: any, i: number) => {
                    return <Select.Option key={i} value={obj.year}>{obj.year}</Select.Option>;
                  })}
                </Select>
              </Form.Item>
            </Form>
            <Form layout="horizontal">
              <Form.Item>
                <Select style={{width: 200}} placeholder="Industry" value={industryId} onChange={(i)=>setIndustryId(i)}>
                  {industries.map((obj: any, i: number) => {
                    return <Select.Option key={i} value={obj.id}>{obj.name}</Select.Option>;
                  })}
                </Select>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
      <Row>
        {
          esgWeightages.map((metric ,index) => {
              let endPercentage = 100;
              if (esgObj.length < index+1) {
                esgObj.push({
                  id: metric.id,
                  percentage: metric.percentage,
                  dkpis: []
                })
              }
              return (
                  <Col key={index} flex={1}>
                      <InputCell
                        label={metric.name}
                        defaultValue={metric.percentage}
                        onChange={(v: any) => {
                          const obj = [...esgData];
                          obj[index].percentage = v;
                          setEsgData(obj);
                        }}
                        header />
                      <InputCell
                        label={`--${metric.name[0]}-KPIs Total`}
                        value={100}
                        header
                        disabled />
                      {
                        metric.dkpis.map((dkpi: any, i: number) => {
                          if (esgObj[index].dkpis.length < i+1) {
                            esgObj[index].dkpis.push({
                              id: dkpi.id,
                              kpiPercentage: dkpi.kpiPercentage
                            })
                          }
                          if (metric.dkpis.length === i+1) {
                            return (
                              <InputCell
                                key={i}
                                label={(dkpi.description? `${dkpi.name} (${dkpi.description})`: dkpi.name)}
                                value={(endPercentage >= 0)? endPercentage : 'Error'}
                                onChange={(v: any) => {
                                  const obj = [...esgData];
                                  obj[index]['dkpis'][i].kpiPercentage = v;
                                  setEsgData(obj);
                                }}
                                disabled />
                            )
                          }
                          else {
                            const dkpiObj = (esgData.length)? esgData[index].dkpis[i]: dkpi;
                            endPercentage -= dkpiObj.kpiPercentage;
                            return (
                              <InputCell
                                key={i}
                                label={(dkpi.description? `${dkpi.name} (${dkpi.description})`: dkpi.name)}
                                defaultValue={dkpi.kpiPercentage}
                                onChange={(v: any) => {
                                  const obj = [...esgData];
                                  obj[index]['dkpis'][i].kpiPercentage = v;
                                  setEsgData(obj);
                                }} />
                            )
                          }
                        })
                      }
                  </Col>
                )
            })
        }
      </Row>
      {
        editMode &&
          <Row justify="end">
            <Col style={{padding: 8}}>
              <Button type="primary" onClick={saveEsgWeightages}>
                Save
              </Button>
            </Col>
          </Row>
      }
    </div>
  );
};

const InputCell = (props: any) => {
  const [value, setValue] = useState(props.defaultValue);
  useEffect(()=>{
    if (props.value || (props.value === 0)) {
      setValue(props.value)
    }
  },[props.value])

  useEffect(()=>{
    if (props.onChange) {
      props.onChange(value);
    }
  },[value])

  return (
    <div style={{
      display: 'flex',
      border: '1px solid #E4E4E4'
      }}>
      <Input
        style={{ width: 100, margin: 10 }}
        addonAfter="%"
        value={value}
        onChange={(e: any) => {
          if (editMode) {
            setValue(e.target.value);
          }
        }}
        disabled={props.disabled}
        />
      <p className="m-2"
        style={{
          fontWeight: props.header? "bold": "400",
          fontSize: "16px"
        }}>
        {props.label}
      </p>
    </div>
  )
}

export default EsgWeightages;
