import React, {useEffect, useState} from 'react';
import './index.scss';
import {Card, Form, notification, Select, Tabs} from 'antd';
import API_SERVICE from '../../services/api-service';
import Homepage from "./HomepageTab/Homepage";
import ContactUs from './ContactTab/ContactUs';
import Timeline from './TimelineTab/Timeline';
import AccessControl, {accessIncludes} from 'components/AccessControl';

const { TabPane } = Tabs;


export default function Content() {
    const [companyData, setCompanyData] = useState({} as any);
    const [companyId, setCompanyId] = useState(0 as number);

    function handleTabChange(key: any) {
        setCompanyData({});
        setCompanyId(0);
    }

    function setDefaultTabId() {
        if(accessIncludes([55,56])){
            return '1'
        } else if(accessIncludes([28,57])){
            return '2'
        } else if(accessIncludes([27,29])){
            return '3'
        }
    }
    return (<AccessControl id = {28}>
        <>
            <div className={'role-wrapper page-wrapper'} style={{flex: 1, padding: '30px'}}>
                {/*@ts-ignore*/}
                <div className="profile_section mb-3">
                    <h1>Master Data Management</h1>
                </div>
                <Tabs defaultActiveKey={setDefaultTabId()} size={'small'} className="bg-white-tab" destroyInactiveTabPane={true} onChange={handleTabChange}>
                    {
                        accessIncludes([55,56]) && <TabPane tab="Homepage" key="1">
                            <Card title="Content Management - Homepage" extra={<SelectCompanyField setCompanyData={setCompanyData} setCompanyId={setCompanyId} />}>
                                <Homepage data={companyData} companyId={companyId} />
                            </Card>
                        </TabPane>
                    }

                    {
                        accessIncludes([28,57]) && <TabPane tab="Contact Us" key="2">
                            <Card title="Content Management - Contact Us Page" extra={<SelectCompanyField setCompanyData={setCompanyData} setCompanyId={setCompanyId} />}>
                                <ContactUs data={companyData} companyId={companyId} />
                            </Card>
                        </TabPane>
                    }

                    {
                        accessIncludes([27,29]) && <TabPane tab="Timeline" key="3">
                            <Card title="Timeline Management" extra={<SelectCompanyField setCompanyData={setCompanyData} setCompanyId={setCompanyId} />}>
                                <Timeline data={companyData} companyId={companyId} />
                            </Card>
                        </TabPane>
                    }
                </Tabs>
            </div>

        </>
        </AccessControl>);
}

function SelectCompanyField (props: any) {
    const [form] = Form.useForm();
    const [companies, setCompanies] = useState([] as any);

    const fetchCompanies = async () => {
        try {
            const {data: {data}} = await API_SERVICE.getCompanies({ perpage: 1000 });
            setCompanies(data.items);
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    };

    const handleCompanyChange = async (value: any) => {
        props.setCompanyId(value);

        let selectedCompany = companies.filter((company: any) => {
            return company.id === value;
        });
        //@ts-ignore
        selectedCompany.length > 0 && props.setCompanyData(selectedCompany[0].companyContent);
    };

    useEffect(() => {
        fetchCompanies();
    }, [props]);

    return(
        <Form
            form={form}
            fields={[]}
            layout='inline'
            requiredMark={false}
        >
            <Form.Item
                className="mr-0"
                label={'Select Company:'}
                name={'companyId'}
                rules={[
                    { required: false, message: 'Company required' },
                ]}
            >
                <Select style={{width: 200}} placeholder="Select Company" onChange={handleCompanyChange}>
                    {
                        companies.map((company: any) => {
                            return <Select.Option
                                value={company.id}>{company.name}</Select.Option>;
                        })
                    }
                </Select>
            </Form.Item>
        </Form>
    )
}
