import React, { useState } from 'react';
import { Redirect, useHistory, Link } from 'react-router-dom';
import { Row, Col, Carousel, Typography, notification } from 'antd';
import LoginForm from './LoginForm';

import './Login.scss';
import { useTranslation } from 'react-i18next';

import loginProp from '@recoinsights/shared/assets/images/LoginPageImage.png';


import { AuthConsumer } from '@recoinsights/shared/contexts/AuthContext';
import API_SERVICE from '@recoinsights/client/src/services/api-service';
import AccessControl from 'components/AccessControl';
import logo from "../../assets/logo.jpg";

const { Title } = Typography;

interface Props {
    location: any;
}

const contentStyle = {
    height: '373px',
    color: '#fff',
    lineHeight: '373px',
    textAlign: 'center',
    background: '#364d79',
};

export default function Login({ location }: Props) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { from } = location.state || { from: { pathname: '/' } };

    return (
        <AuthConsumer>
            {({ isAuth, updateAuthToken }) => {
                return (isAuth ? (
                    <Redirect to={from} />
                ) : (
                    <div className='login'>
                        <div className="login-box-wrapper">
                            <Row>
                                <Col sm={24} className="grow-1">
                                    <div className="login-page-name">
                                        <img src={logo} className="logo" />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='login-form-row px-4 py-4'>
                                <Col sm={24} md={14} className="login-text-column">
                                    <div className="login-text-wrapper">
                                        <img src={loginProp} alt="login" className="img-fluid login-prop-image" />
                                    </div>
                                </Col>
                                <Col sm={24} md={10} className={'login-form-wrapper-column'}>
                                    <div className='login-form-wrapper'>
                                        <Row>
                                            <Col sm={{span: 16, offset:8}} >
                                                <div className="login-title">Sign In</div>
                                            </Col>
                                        </Row>
                                        <LoginForm
                                            onLogin={async (email: string, password: string) => {
                                                setLoading(true);
                                                try {
                                                    const { data: { uuid } } = await API_SERVICE.login(email, password);
                                                    return uuid;
                                                } catch (e) {
                                                    notification.error({
                                                        message: t(API_SERVICE.handleErrors(e)),
                                                    });
                                                    setLoading(false);
                                                } finally {
                                                  setLoading(false);
                                                }
                                            }}
                                            onVerify={async (uuid: string, otp: string) => {
                                                setLoading(true);
                                                try {
                                                    const { data: { data } } = await API_SERVICE.verify(uuid, otp);
                                                    updateAuthToken(data);
                                                } catch (e) {
                                                    notification.error({
                                                        message: t(API_SERVICE.handleErrors(e)),
                                                    });
                                                    setLoading(false);
                                                }
                                            }}
                                            loading={loading}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>));
            }
            }
        </AuthConsumer>
    );
}
