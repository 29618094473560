import React from "react";
import {Link} from "react-router-dom";
import {Button, Col, Divider, Row, Spin, Table} from "antd";
import {ResultSet} from "@recoinsights/types";
import CompanyInterface from "@recoinsights/shared/types/Company";
import {PORTFOLIO_RESULTS_DASHBOARD} from "@recoinsights/shared/constants/RouteConstants";
import AlertNoResults from "../results/AlertNoResults";
import AlertUnauthorized from "../results/AlertUnauthorized";
import {useResults} from "../../helpers/metrics";
import {useUser} from "../../helpers/user";
import {sortGroupsByName, useFunds, useGroups} from "../../helpers/portfolio";
import {useRouter} from "../../helpers/location";
import GroupInterface from "../../../../shared/types/Group";
import FundInterface from "../../../../shared/types/Fund";
import {RightOutlined} from "@ant-design/icons";

interface FundResults {
    fund: FundInterface;
    group: GroupInterface;
    results: ResultSet[];
}

function PortfolioResultList() {
    const { href, route, handleButtonClick } = useRouter();
    const { user } = useUser();
    const { groups, isLoadingGroups } = useGroups();
    const { funds, isLoadingFunds, companies } = useFunds();

    const params: { companyId?: number, groupId?: number } = {}; // Admin

    if (user.groups.length === 1) { // Portfolio manager
        params.groupId = user.groups[0].id;
    }

    const { results, years, isLoadingResults, isAuthorised } = useResults(params);

    const fundResults: FundResults[] = [];

    results.forEach((result) => {
        const company = companies.find((company) => company.uuid === result.company) as CompanyInterface;

        funds.filter((fund) => fund.fundCompanies?.find((fundCompany) => fundCompany.companyId === company.id)).forEach((fund) => {
            let fundResult = fundResults.find((value) => value.fund.id === fund.id);

            if (!fundResult) {
                fundResult = {
                    fund,
                    group: groups.find((group) => group.uuid === result.group) as GroupInterface,
                    results: [],
                };

                fundResults.push(fundResult);
            }

            fundResult.results.push(result);
        });
    })

    return (
        <>
            <div className="page-wrapper company-result-wrapper" style={{boxShadow: "none"}}>
                <Row className="justify-content-between">
                    <Col>
                        <h1 className="page-title">Portfolio Results</h1>
                    </Col>
                </Row>
                {(isLoadingGroups || isLoadingFunds || isLoadingResults) ? <div data-testid="loading-spinner" className="d-flex align-items-center justify-content-center py-5"><Spin /></div> : (
                    !isAuthorised ? <AlertUnauthorized /> : (
                        !results.length ? <AlertNoResults /> : groups.sort(sortGroupsByName).map((group) => (
                            <Row data-testid="company-results-list">
                                <Col flex={1} className="bg-white mb-4">
                                    <Row className="p-2" style={{ backgroundColor: "#fafafa" }} align={"middle"}>
                                        <Col flex={1}>
                                            <h3 className="mb-0">{ group.name }</h3>
                                        </Col>
                                        <Col>
                                            <Link
                                                to={href(route({
                                                    route: PORTFOLIO_RESULTS_DASHBOARD,
                                                    routeParams: {groupId: group.id},
                                                }))}
                                            >
                                                View All
                                                &nbsp;
                                                <RightOutlined />
                                            </Link>
                                        </Col>
                                    </Row>

                                    <Divider className="my-0"/>

                                    <Table
                                        columns={[
                                            {
                                                title: (
                                                    <h4 className="mb-0">Fund</h4>
                                                ),
                                                dataIndex: "fund",
                                                width: "100%",
                                                render: (fund: FundInterface) => {
                                                    return (
                                                        <Link
                                                            to={href(route({
                                                                route: PORTFOLIO_RESULTS_DASHBOARD,
                                                                routeParams: {groupId: group.id},
                                                                searchParams: {fundId: fund.id},
                                                            }))}>
                                                            {fund.name}
                                                        </Link>
                                                    );
                                                },
                                            },
                                            ...years.filter((year) => fundResults.filter((fundResult) => fundResult.group.id === group.id).find((fundResult) => fundResult.results.find((result) => result.year === year))).map((year) => ({
                                                title: (
                                                    <Row align={"middle"} wrap={false}>
                                                        <Col flex={1}>
                                                            <h4 className="mb-0">
                                                                {year}
                                                            </h4>
                                                        </Col>
                                                        <Col>
                                                            <small>
                                                                <Link
                                                                    to={href(route({
                                                                        route: PORTFOLIO_RESULTS_DASHBOARD,
                                                                        routeParams: {groupId: group.id},
                                                                        searchParams: {year: year},
                                                                    }))}
                                                                >
                                                                    All Funds
                                                                    &nbsp;
                                                                    <RightOutlined />
                                                                </Link>
                                                            </small>
                                                        </Col>
                                                    </Row>
                                                ),
                                                render: (fundResult: FundResults) => {
                                                    const result = fundResult.results.find((value) => value.year === year);

                                                    if (!result) {
                                                        return "-";
                                                    }

                                                    const isMostRecentYear = year === years[years.length - 1];

                                                    return (
                                                        <Button
                                                            type={isMostRecentYear ? "primary" : "default"}
                                                            href={href(route({
                                                                route: PORTFOLIO_RESULTS_DASHBOARD,
                                                                routeParams: {groupId: group.id},
                                                                searchParams: {fundId: fundResult.fund.id, year: result.year},
                                                            }))}
                                                            onClick={handleButtonClick}
                                                        >
                                                            View Results
                                                        </Button>
                                                    );
                                                },
                                            }))
                                        ]}
                                        dataSource={fundResults.filter((fundResult) => fundResult.group.id === group.id)}
                                        pagination={false}
                                    />
                                </Col>
                            </Row>
                        ))
                    )
                )}
            </div>
        </>
    );
}

export default PortfolioResultList;
