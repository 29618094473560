import React from "react";
import {Metric, ResultSet} from "@recoinsights/types";
import {Metrics} from "@recoinsights/metrics";
import {Col, Divider, Row} from "antd";
import {resolveMetric, useMetricsWithResults} from "../../helpers/metrics";
import CompanyInterface from "@recoinsights/shared/types/Company";
import PortfolioResultsRenewableEnergyConsumptionChart from "../charts/PortfolioResultsRenewableEnergyConsumptionChart";
import PortfolioResultsEmissionsIntensityChart from "../charts/PortfolioResultsEmissionsIntensityChart";
import PortfolioResultsGenderDiversityChart from "../charts/PortfolioResultsGenderDiversityChart";
import PortfolioResultsCustomerSatisfactionChart from "../charts/PortfolioResultsCustomerSatisfactionChart";
import PortfolioResultsEmployeeSatisfactionChart from "../charts/PortfolioResultsEmployeeSatisfactionChart";
import PortfolioResultsBoardMaturityChart from "../charts/PortfolioResultsBoardMaturityChart";
import PortfolioResultsCyberSecurityMaturityChart from "../charts/PortfolioResultsCyberSecurityMaturityChart";
import MetricScorecard from "./MetricScorecard";
import MetricComponent from "./Metric";

interface Props {
    results: ResultSet[];
    metrics: Metric[];
    companies: CompanyInterface[];
    years: number[];
}

function PortfolioMultiYearResults({ results, metrics, companies, years }: Props) {
    const { hasMetricScore, hasEnvironmentalMetrics, hasSocialMetrics, hasGovernanceMetrics } = useMetricsWithResults(results);

    return (
        <>
            {hasEnvironmentalMetrics && (
                <Row data-testid="charts-component">
                    <Col flex={1} className="bg-white mb-4">
                        <Row className="p-2" style={{ backgroundColor: "#fafafa" }}>
                            <Col>
                                <h3 className="mb-0"><i className="bi bi-globe mr-1"/>Environmental</h3>
                            </Col>
                        </Row>

                        <Divider className="my-0"/>

                        {hasMetricScore(Metrics.RenewableEnergyConsumption) && (
                            <>
                                <Row justify={"center"}>
                                    <MetricComponent metric={resolveMetric(Metrics.RenewableEnergyConsumption, metrics)} />
                                </Row>
                                <Row wrap={false}>
                                    <Col className={"p-2"}>
                                        <MetricScorecard companies={companies} metrics={metrics} metricUid={Metrics.RenewableEnergyConsumption} results={results} years={years} />
                                    </Col>
                                    <Col className={"p-2"} flex={1}>
                                        <PortfolioResultsRenewableEnergyConsumptionChart companies={companies} results={results} />
                                    </Col>
                                </Row>
                            </>
                        )}

                        {hasMetricScore(Metrics.RenewableEnergyConsumption) && hasMetricScore(Metrics.EmissionsIntensity) && (
                            <Divider className="m-2" style={{ width: "auto", minWidth: "auto" }} />
                        )}

                        {hasMetricScore(Metrics.EmissionsIntensity) && (
                            <>
                                <Row justify={"center"}>
                                    <MetricComponent metric={resolveMetric(Metrics.EmissionsIntensity, metrics)} />
                                </Row>
                                <Row wrap={false}>
                                    <Col className={"p-2"}>
                                        <MetricScorecard companies={companies} metrics={metrics} metricUid={Metrics.EmissionsIntensity} results={results} years={years} />
                                    </Col>
                                    <Col className={"p-2"} flex={1}>
                                        <PortfolioResultsEmissionsIntensityChart companies={companies} results={results} />
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Col>
                </Row>
            )}

            {hasSocialMetrics && (
                <Row>
                    <Col flex={1} className="bg-white mb-4">
                        <Row className="p-2" style={{ backgroundColor: "#fafafa" }}>
                            <Col>
                                <h3 className="mb-0"><i className="bi bi-people mr-1" />Social</h3>
                            </Col>
                        </Row>

                        <Divider className="my-0"/>

                        {hasMetricScore(Metrics.CustomerSatisfaction) && (
                            <>
                                <Row justify={"center"}>
                                    <MetricComponent metric={resolveMetric(Metrics.CustomerSatisfaction, metrics)} />
                                </Row>
                                <Row wrap={false}>
                                    <Col className={"p-2"}>
                                        <MetricScorecard companies={companies} metrics={metrics} metricUid={Metrics.CustomerSatisfaction} results={results} years={years} />
                                    </Col>
                                    <Col className={"p-2"} flex={1}>
                                        <PortfolioResultsCustomerSatisfactionChart companies={companies} results={results} />
                                    </Col>
                                </Row>
                            </>
                        )}

                        {hasMetricScore(Metrics.CustomerSatisfaction) && hasMetricScore(Metrics.EmployeeSatisfaction) && (
                            <Divider className="m-2" style={{ width: "auto", minWidth: "auto" }} />
                        )}

                        {hasMetricScore(Metrics.EmployeeSatisfaction) && (
                            <>
                                <Row justify={"center"}>
                                    <MetricComponent metric={resolveMetric(Metrics.EmployeeSatisfaction, metrics)} />
                                </Row>
                                <Row wrap={false}>
                                    <Col className={"p-2"}>
                                        <MetricScorecard companies={companies} metrics={metrics} metricUid={Metrics.EmployeeSatisfaction} results={results} years={years} />
                                    </Col>
                                    <Col className={"p-2"} flex={1}>
                                        <PortfolioResultsEmployeeSatisfactionChart companies={companies} results={results} />
                                    </Col>
                                </Row>
                            </>
                        )}

                        {((hasMetricScore(Metrics.CustomerSatisfaction) || hasMetricScore(Metrics.EmployeeSatisfaction)) && hasMetricScore(Metrics.GenderDiversity)) && (
                            <Divider className="m-2" style={{ width: "auto", minWidth: "auto" }} />
                        )}

                        {hasMetricScore(Metrics.GenderDiversity) && (
                            <>
                                <Row justify={"center"}>
                                    <MetricComponent metric={resolveMetric(Metrics.GenderDiversity, metrics)} />
                                </Row>
                                <Row wrap={false}>
                                    <Col className={"p-2"}>
                                        <MetricScorecard companies={companies} metrics={metrics} metricUid={Metrics.GenderDiversity} results={results} years={years} />
                                    </Col>
                                    <Col className={"p-2"} flex={1}>
                                        <PortfolioResultsGenderDiversityChart companies={companies} results={results} />
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Col>
                </Row>
            )}

            {hasGovernanceMetrics && (
                <Row>
                    <Col flex={1} className="bg-white mb-4">
                        <Row className="p-2" style={{ backgroundColor: "#fafafa" }}>
                            <Col>
                                <h3 className="mb-0"><i className="bi bi-bank mr-1"/>Governance</h3>
                            </Col>
                        </Row>

                        <Divider className="my-0"/>

                        {hasMetricScore(Metrics.BoardMaturity) && (
                            <>
                                <Row justify={"center"}>
                                    <MetricComponent metric={resolveMetric(Metrics.BoardMaturity, metrics)} />
                                </Row>
                                <Row wrap={false}>
                                    <Col className={"p-2"}>
                                        <MetricScorecard companies={companies} metrics={metrics} metricUid={Metrics.BoardMaturity} results={results} years={years} />
                                    </Col>
                                    <Col className={"p-2"} flex={1}>
                                        <PortfolioResultsBoardMaturityChart companies={companies} results={results} />
                                    </Col>
                                </Row>
                            </>
                        )}

                        {hasMetricScore(Metrics.BoardMaturity) && hasMetricScore(Metrics.CyberSecurityMaturity) && (
                            <Divider className="m-2" style={{ width: "auto", minWidth: "auto" }} />
                        )}

                        {hasMetricScore(Metrics.CyberSecurityMaturity) && (
                            <>
                                <Row justify={"center"}>
                                    <MetricComponent metric={resolveMetric(Metrics.CyberSecurityMaturity, metrics)} />
                                </Row>
                                <Row wrap={false}>
                                    <Col className={"p-2"}>
                                        <MetricScorecard companies={companies} metrics={metrics} metricUid={Metrics.CyberSecurityMaturity} results={results} years={years} />
                                    </Col>
                                    <Col className={"p-2"} flex={1}>
                                        <PortfolioResultsCyberSecurityMaturityChart companies={companies} results={results} />
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Col>
                </Row>
            )}
        </>
    );
}

export default PortfolioMultiYearResults;
