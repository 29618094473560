import * as React from 'react';
import {Redirect, Route, Switch, useHistory, useLocation} from 'react-router-dom';
import DesktopLayout from '@recoinsights/shared/components/layout/DesktopLayout';

type Props = {}

const Layout: React.FunctionComponent<Props> = () => {
    const { pathname } = useLocation();
    const history = useHistory();
    const selectedMenu = pathname;

    function handleMenuClick(route: string) {
        if (route !== pathname) {
            history.push(route);
        }
    }

    const AppLayout =  DesktopLayout;

    return (
        <AppLayout
            selectedMenu={selectedMenu}
            onMenuClick={handleMenuClick}
        >
            <Switch>
                <Route exact path="/">
                  <Redirect to="/home" />
                </Route>
            </Switch>
        </AppLayout>
    );
};

export default Layout;
