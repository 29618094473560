import React, {useEffect, useState} from "react";
import {Drawer, notification, Spin, Tree} from "antd";
import {BarChartOutlined} from "@ant-design/icons";
import API_SERVICE from '@recoinsights/client/src/services/api-service';

var init = true;
var completed_pageIds: any = [];

const CollectionProgress = (props: any) => {
  const {surveyId} = props;
  const [open, setOpen] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState<any[]>([]);
  const [pageIds, setPageIds] = useState<any[]>([]);
  const [progress, setProgress] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const getSurveyPercentage = async () => {
      init = true;
      try {
          const {data: {data: {items}}} = await API_SERVICE.getSurveyPercentage(surveyId);
          setProgress(items);
      } catch (e) {
          notification.error({message: API_SERVICE.handleErrors(e)})
      }
  };
  const setSurveyPercentage = async () => {
      try {
          setLoading(true);
          await API_SERVICE.setSurveyPercentage(surveyId, completed_pageIds);
          await getSurveyPercentage();
          const percentage = await props.getPercentage();
          notification.success({message: `Progress: ${parseFloat(`${percentage}`).toFixed(2)}%`, placement: 'bottomRight'})
      } catch (e) {
          notification.error({message: API_SERVICE.handleErrors(e)})
      } finally {
          setLoading(false)
      }
  };
  useEffect(()=>{
    getSurveyPercentage();
  },[])

  useEffect(()=>{
    let values = pageIds.filter((i)=>(typeof i === "number"));
    if (completed_pageIds.join() !== values.join()) {
      completed_pageIds = values;
      setSurveyPercentage();
    }
  },[pageIds])

  useEffect(()=>{
    setExpandedKeys([`metric-${props.activeMetricsId}`])
  },[props.activeMetricsId])

  return (
    <div>
      <div className="floating-btn-user-2 clickable" onClick={()=>setOpen(true)}>
        <BarChartOutlined />
      </div>
     {/*@ts-ignore*/}
     <Drawer title="Update Progress" placement="right" onClose={()=>setOpen(false)} visible={open} getContainer={false} align="center">
        <div>Tick the checkbox below to mark the Survey Module as Complete</div>
        <br/>
        {
          (loading)?
            <Spin />
            :
            <Tree
              style={{ width: '100%' }}
              expandedKeys={expandedKeys}
              onExpand={setExpandedKeys}
              checkable
              checkedKeys={pageIds}
              onCheck={(values: any)=>{
                setPageIds(values)
              }}
              treeData={[
                ...progress.map((metric: any, i:number) => {
                    if (i+1 === progress.length && metric.pages.length === 0 ) {
                      if (init) {
                        init = false;
                        setTimeout(()=>setPageIds([...completed_pageIds]), 500);
                      }
                    }
                    const children = [
                          ...metric.pages.map((page: any, j: number)=>{
                              if (i === 0 && j === 0) {
                                completed_pageIds = [];
                              }
                              if (page.isFullySubmitted) {
                                completed_pageIds.push(page.id)
                              }
                              if (init && (i+1 === progress.length) && (j+1 === metric.pages.length)) {
                                init = false;
                                setTimeout(()=>setPageIds([...completed_pageIds]), 500);
                              }
                              return {
                                key: page.id,
                                value: page.id,
                                title: page.name
                              };
                          })
                    ];
                    return {
                      key: `metric-${metric.metric.id}`,
                      title: metric.metric.name,
                      children
                    }
                })
              ]}
            />
        }
        <br />
     </Drawer>
    </div>
  )
}

export default CollectionProgress;
