import React from 'react';
import './index.scss';
import {Col, Divider, Row, Tabs, Tag, Typography} from 'antd';
import {DownloadOutlined, FallOutlined, RiseOutlined,} from '@ant-design/icons';
import PortfolioProgress from './Progress/progress';
import TableData from 'views/Analyst/Targets/tabledata';
import Weightings from './Weightings/weighting';

const { Text } = Typography;
const { TabPane } = Tabs;


export default function Portfolio(props: any) {

    function handleTabChange(key: any) {
    }
    return (
        <>
            <div className={'role-wrapper page-wrapper'} style={{flex: 1, padding: '30px'}}>
                {/*@ts-ignore*/}
                <Tabs defaultActiveKey="1" size={'small'} className="bg-white-tab" destroyInactiveTabPane={true} onChange={handleTabChange}>
                    <TabPane tab="Progress" key="1">
                            <PortfolioProgress />
                    </TabPane>
                    <TabPane tab="Benchmark" key="2">
                        <Row>
                    <Col sm={24}>
                        <div className="p-2">
                        <Divider orientation="left"></Divider>
                            <div>
                            <Tag color="error">Week</Tag>
                            <Tag color="default">Neutral</Tag>
                            <Tag color="success">Strong</Tag>
                            <div className="text-right mt-3">
                                <Text type="danger" className="text-right mt-3">Edit</Text>
                                <DownloadOutlined />
                            </div>
                            <Divider />

                            <TableData />

                            </div>
                        </div>
                    </Col>
                </Row>                                          
                    </TabPane>
                    <TabPane tab="Weightings" key="3">
                    <Weightings />
                
                   
                    </TabPane>
                    <TabPane tab="Results" key="4">
                        <div>
                            <Tabs defaultActiveKey="1" size={'small'} className="bg-white-tab" destroyInactiveTabPane={true} onChange={handleTabChange}> 
                                <TabPane tab="Portfolio KPI Summary" key="1">
                                    <Row>
                                        <Col sm={24}>
                                            <div className="p-2">
                                            <Divider orientation="left"></Divider>
                                                <div>
                                                <Tag color="error">Week</Tag>
                                                <Tag color="default">Neutral</Tag>
                                                <Tag color="success">Strong</Tag>
                                                <Tag color="default"><RiseOutlined />Improvement</Tag>
                                                <Tag color="default"><FallOutlined />Digression</Tag>
                                                <div className="text-right mt-3">
                                                    <Text type="danger" className="text-right mt-3">Edit</Text>
                                                    <DownloadOutlined />
                                                </div>
                                                <Divider />

                                                <TableData />

                                                </div>
                                            </div>
                                        </Col>
                                    </Row>       
                                </TabPane> 
                                <TabPane tab="Portfolio ESG Scores" key="2">
                                <Row>
                                        <Col sm={24}>
                                            <div className="p-2">
                                            <Divider orientation="left"></Divider>
                                            <Text>Overall ESG scores calculated based on key performance indicator (KPI) performance of each portfolio company compared to market benchmarks. Scores have been customized based on the weighting selected on the ‘Scores’ tab.</Text>
                                                <div>
                                                <Tag color="error">Week</Tag>
                                                <Tag color="default">Neutral</Tag>
                                                <Tag color="success">Strong</Tag>
                                                <div className="text-right mt-3">
                                                    <Text type="danger" className="text-right mt-3">Edit</Text>
                                                    <DownloadOutlined />
                                                </div>
                                                <Divider />

                                                <TableData />

                                                </div>
                                            </div>
                                        </Col>
                                    </Row>   
                                </TabPane> 
                            </Tabs> 
                        </div>                   
                    </TabPane>
                </Tabs>
            </div>

        </>
        );
}
