import {ResultSet} from "@recoinsights/types";
import {EmployeeSatisfaction, Metrics, resolveMetricResults} from "@recoinsights/metrics";
import {Line} from "@ant-design/charts";

enum Labels {
    Year = "Year",
    eNPS = "eNPS",
}

interface Props {
    results: ResultSet[];
}

function EmployeeSatisfactionChart({ results }: Props) {
    const data = results.map((result) => {
        const metric = resolveMetricResults(Metrics.EmployeeSatisfaction, result) as EmployeeSatisfaction;

        return {
            [Labels.Year]: result.year,
            [Labels.eNPS]: metric.score,
        }
    });

    return (
        <Line
            data={data}
            xField={Labels.Year}
            yField={Labels.eNPS}
            legend={{ position: "right-bottom", maxWidthRatio: 0.33, padding: [0, 0, 0, 30], itemHeight: 10, }}
            height={200}
        />
    );
}

export default EmployeeSatisfactionChart;
