import React, {useEffect, useState} from "react";
import {Button, Col, Input, Row, Spin, Typography} from 'antd';
import '../index.scss';
import API_SERVICE from '../../../services/api-service';
import {getColorLevels} from '../../../services/local-storage'

const { Text} = Typography;

var defaults: any[] = [];

export default function BenchMarkingColors(){
    const [levels, setLevels] = useState <any[]> ([]);
    const [loading, setLoading] = useState <boolean> (true);
    const [error, setError ] = useState <string> ('');
    const getLevels = async () => {
        try {
            setError('');
            setLoading(true);
            const {data: {data}} = await API_SERVICE.getColorLevels();
            if (data.items.length) {
              const arr: any[] = data.items.sort( (a: any,b: any) => (a.id < b.id)? -1 : 1);
              localStorage.setItem('levels', JSON.stringify(arr));
              defaults = arr;
              setLevels(arr);
            }
        } catch (e) {
            setError(API_SERVICE.handleErrors(e))
        }
        finally {
          setLoading(false)
        }
    };
    //@ts-ignore
    defaults = getColorLevels();

    const postLevels = async () => {
        try {
            setLoading(true);
            setError('');
            await API_SERVICE.setColorLevels(levels);
            getLevels()
        } catch (e) {
            const err: string = API_SERVICE.handleErrors(e);
            setError(err);
            setLoading(false)
        }
    };

    async function update(index: number, property: string, value: any) {
      const arr: any = [...levels];
      arr[index][property] = (value || defaults[index][property]);
      setLevels(arr);
    }

    useEffect(()=>{
      getLevels();
    },[])

    if (loading) {
      return <Spin />
    }
    return(
            <div>
              {
                (!loading && error) &&
                  <Text type="danger">{error}</Text>
              }
              {
                (levels.length > 0) &&
                  <div>
                    <Row justify="start">
                         <Col span={4}></Col>
                         <Col span={3}><Text strong className="BenchMarkingSubHead">&nbsp;Score Range</Text></Col>
                    </Row>
                  </div>
               }
               {
                 levels.map((level: any, i: number) => {
                   return (
                     <Row key={i} justify="start" align="middle">
                          {/* @ts-ignore */}
                         <Col sm={1}><div className="BenchMarkingSubHead">{level.name.titlecase()}</div></Col>
                         <Col sm={3}>
                             <div className="d-flex align-items-center">
                                 <Input className="BenchMarkingInput" placeholder={defaults[i]['colorCode']}
                                   onChange={(e:any)=>{update(i,'colorCode',e.target.value)}} />
                                 <div style={{height:20,width:20, backgroundColor:level.colorCode}}></div>
                             </div>
                         </Col>
                         <Col sm={3}>
                             <div>
                                 <Input className="ScoreRangeInput" placeholder={defaults[i]['rangeStart'].toString()}
                                    onChange={(e:any)=>{update(i,'rangeStart',e.target.value)}} />
                                 <span className="m-1">-</span>
                                 <Input className="ScoreRangeInput" placeholder={defaults[i]['rangeEnd'].toString()}
                                    onChange={(e:any)=>{update(i,'rangeEnd',e.target.value)}} />
                             </div>
                         </Col>
                         {
                           (i === levels.length-1) &&
                           <Col>
                             <Button className="theme-button primary ml-1" htmlType="submit"
                              onClick={postLevels} disabled={loading}>
                               Save
                             </Button>
                           </Col>
                         }
                     </Row>
                   )
                 })
               }
            </div>
    )
}
