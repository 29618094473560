import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";

String.prototype.titlecase = function() {
    const str = this.replace('_', ' ');
    const arr = str.split(' ').map((sub)=>
      sub.charAt(0).toUpperCase() + sub.slice(1).toLowerCase())
    return arr.join(' ')
}
String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}
String.prototype.toFloat = function(n) {
    if (parseFloat(`${this}`)) {
      const float = parseFloat(`${this}`).toFixed(n)
      if (`${float}`.includes('.00')) {
        return float.split('.')[0];
      }
      else return `${float}`;
    }
    return `${this}`;
}

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV ?? "local",
    integrations: [new BrowserTracing()],
    tracesSampleRate: process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE,
});
  
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
