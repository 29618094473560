import React, {useEffect, useState} from 'react';
import './index.scss';
import {Button, Form, Input, notification, Pagination, Space, Table, Typography, Upload} from 'antd';
import {useHistory} from 'react-router-dom';
import AdminListLayout from '@recoinsights/shared/components/layout/AdminListLayout/AdminListLayout';

import API_SERVICE from '../../services/api-service';
import {ConfirmationModal} from "../../components";

import {PlusOutlined, SearchOutlined, UploadOutlined} from '@ant-design/icons';
import {CloseOutlined, DeleteOutlined} from '@material-ui/icons';
import AccessControl, {accessIncludes} from 'components/AccessControl';

const {Text, Link} = Typography;
const {Search} = Input;


export default function GroupPage(props: any) {
    const [form] = Form.useForm();
    const history = useHistory();
    const [groups, setGroups] = useState([] as any[]);
    const [search, setSearch] = useState('' as string);
    const [saving, setSaving] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [pagination, setPagination] = useState({page: 1, perpage: '10'} as any);
    const [total, setTotal] = useState(10);
    const [filters] = useState({} as any);
    const [confirmationModal, setConfirmationModal] = useState({} as any);

    async function handleFileUpload(groupId: any, file: any) {
        try {
            if (file) {
                const {data: {data: {id}}} = await API_SERVICE.fileUpload('THUMBNAIL', file);
                await API_SERVICE.groupUpdate(groupId, {logo_id: id});
            } else {
                await API_SERVICE.groupUpdate(groupId, {logo_id: null});
            }
            notification.success({message: 'Group updated'});
            refresh();
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        }
    }

    const columns = [
        {
            title: 'S.No',
            key: 'sNO',
            render: (a: any, v: any, index: number) =>
                <Text>{(pagination.page - 1) * (pagination.perpage) + index + 1}</Text>,
        },
        {
            title: 'Group Name',
            dataIndex: 'name',
            ellipses: true,
            render: (a: any) => <Text>{a}</Text>,
        },
        {
            title: 'No. of Companies',
            dataIndex: 'companiesCount',
            width: 180,
            ellipses: true,
            render: (a: any) => <Link onClick={() => {
                history.push(`/admin/companies/${a.pefId}`)
            }}>{a ? a.sum : 0}</Link>
        },
        {
            title: 'is Active?',
            key: 'isActive',
            render: (value: any) => <Button className={value.isActive ? 'active-button' : 'inactive-button'}
                                            disabled={!accessIncludes([3])}
                                            onClick={() => {
                                                const reqObj = {
                                                    isActive: !value.isActive
                                                }
                                                setConfirmationModal({
                                                    message: `Change status to ${value.isActive ? "InActive" : "Active"}`,
                                                    action: API_SERVICE.groupUpdate,
                                                    params: [value.id, reqObj]
                                                })
                                            }}>
                {value.isActive ? "Active" : "InActive"}
            </Button>
        },

        {
            title: 'Upload Image',
            width: 180,
            render: (a: any) => {
                return (
                    <Space>
                        <Upload showUploadList={false}
                                beforeUpload={(file) => {
                                    handleFileUpload(a.id, file)
                                }}>
                            <Button type="primary" style={{height: 44}}>
                                <UploadOutlined/>
                            </Button>
                        </Upload>
                        {
                            a.logo && <>
                                <img src={a.logo?.url} style={{height: 44}} alt="logo"/>
                                <Button type="link" className="link-button" onClick={() => {
                                    handleFileUpload(a.id, null)
                                }}>
                                    <CloseOutlined/>
                                </Button>
                            </>
                        }
                    </Space>
                )
            }
        },

        {
            title: 'Actions',
            key: 'action',
            render: (value: any) => <AccessControl id={4} closed={<Text>-</Text>}><Button type="text"
                                                                                          icon={<DeleteOutlined/>}
                                                                                          onClick={() => {
                                                                                              setConfirmationModal({
                                                                                                  message: `Delete Group?`,
                                                                                                  action: API_SERVICE.groupDelete,
                                                                                                  params: [value.id],
                                                                                              })
                                                                                          }}/></AccessControl>
        },
    ];

    const refresh = async (page?: number, perPage?: number) => {
        setFetching(true);
        const params = {...pagination, ...filters};
        if (search) {
            params['q'] = search;
            params['page'] = 1;
        }
        if (page) {
            params['page'] = page;
        }
        if (perPage) {
            params['perpage'] = perPage;
        }

        try {
            const {data: {data}} = await API_SERVICE.getGroups(params);
            setGroups(data.items);
            setTotal(data.total);
            setPagination({page: data.page, perpage: data.perpage});
        } catch (e) {
            notification.error({message: API_SERVICE.handleErrors(e)});
        } finally {
            setFetching(false);
        }
    };
    useEffect(() => {
        refresh();
    }, [props.location, search, filters]);
    return (

        <div className={'group-wrapper page-wrapper'}>
            {/*@ts-ignore*/}
            <AdminListLayout title={'Groups'}
                             titleSearch={<AccessControl id={5}>
                                 <div className='search-bar'>
                                     <Search
                                         enterButton={<SearchOutlined/>}
                                         placeholder='Search a Group by Name'
                                         onSearch={
                                             (e) => {
                                                 setSearch(e);
                                             }
                                         }
                                     />
                                 </div>
                             </AccessControl>}

                             titleAction={
                                 accessIncludes([2]) && <Form
                                     form={form}
                                     fields={[]}
                                     onFinish={(value) => {
                                         setSaving(true);
                                         API_SERVICE.groupCreate(value).then((d) => {
                                             notification.success({message: 'Group created successfully'});
                                             form.resetFields();
                                             refresh();
                                         }).catch((e) => {
                                             notification.error({message: API_SERVICE.handleErrors(e)});
                                         }).finally(() => {
                                             setSaving(false);
                                         });
                                     }}
                                     layout='inline'
                                     requiredMark={true}
                                 >
                                     <Form.Item
                                         style={{flex: 1}}
                                         name='name'
                                         rules={[
                                             {required: true, message: 'Group Name required'},
                                         ]}
                                     >
                                         <Input autoComplete={'off'} placeholder="Enter a new Group Name"
                                         />
                                     </Form.Item>
                                     <Button loading={saving} type={'primary'} htmlType="submit"
                                             className='theme-button primary'
                                             icon={<PlusOutlined/>}>
                                         Add New
                                     </Button>
                                 </Form>
                             }


                             pagination={<AccessControl id={1}>
                                 <Pagination current={pagination.page} pageSize={pagination.perpage}
                                             pageSizeOptions={[10, 20, 50]} showSizeChanger showQuickJumper
                                             total={total}
                                             onChange={(page, size) => {
                                                 setPagination({page: page, perpage: size})
                                                 refresh(page, size);
                                             }}/>
                             </AccessControl>}
            ><AccessControl id={1}>
                <Table loading={fetching} scroll={{x: true, y: 'calc(100vh - 340px)'}} pagination={false}
                       dataSource={groups}
                       columns={columns}/></AccessControl>

                <ConfirmationModal {...confirmationModal}
                                   callback={(a: boolean) => {
                                       setConfirmationModal({})
                                       if (a) {
                                           refresh();
                                       }
                                   }}/>

            </AdminListLayout>
        </div>
    );
}
